import styled, { css } from "styled-components";
import { maxOmniMatches } from "../../../../modules/search";
import {
  blueDark,
  bluePowder,
  greyLight,
  greyLighter,
  greyMedium,
  iconColor
} from "../../../../utils/colorsLocal";
import { white } from "../../../../utils/colorsSystem";
import {
  defaultBoxShadow,
  hideScrollbar,
  mobileBreakpoint,
  rotate,
  scaleUp
} from "../../../../utils/styling";

const statusWidthDesktop = 60;
const statusWidthMobile = 40;

export const Container = styled.div`
  background-color: ${white};
  position: relative;
  height: 100%;
`;

export const Status = styled.div`
  position: absolute;
  width: ${statusWidthDesktop}px;
  padding: 18px 18px 0px;
  cursor: pointer;
  bottom: 0px;
  left: 0px;
  top: 0px;

  @media (max-width: ${mobileBreakpoint}px) {
    padding-top: ${({ isFocused }) => (isFocused ? 15 : 7)}px;
    width: ${statusWidthMobile}px;
    padding-right: 11px;
    padding-left: 11px;
  }

  svg {
    fill: ${({ isFocused }) => (isFocused ? greyMedium : greyLight)};
    animation-name: ${({ isLoading }) => (isLoading ? rotate : scaleUp)};
    animation-duration: ${({ isLoading }) => (isLoading ? 600 : 200)}ms;
    animation-iteration-count: ${({ isLoading }) =>
      isLoading ? "infinite" : 1};
    animation-timing-function: ${({ isLoading }) =>
      isLoading ? "linear" : "ease-in"};

    @media (max-width: ${mobileBreakpoint}px) {
      fill: ${({ isFocused, hasFilters, isMapOpen }) =>
        isMapOpen
          ? greyMedium
          : !isFocused && !hasFilters
          ? greyLight
          : greyMedium};
    }
  }
`;

export const Content = styled.div`
  ${hideScrollbar};
  position: absolute;
  overflow-x: auto;
  white-space: nowrap;
  left: ${statusWidthDesktop}px;
  cursor: text;
  bottom: 0px;
  right: 0px;
  top: 0px;

  @media (max-width: ${mobileBreakpoint}px) {
    opacity: ${({ isFocused, hasFilters }) =>
      !isFocused && hasFilters ? 0 : 1};
    z-index: ${({ isFocused, hasFilters }) =>
      !isFocused && hasFilters ? -1 : "auto"};
    left: ${statusWidthMobile}px;
  }
`;

export const Input = styled.input`
  color: ${greyMedium};
  height: 100%;
  width: 200px;

  &::placeholder {
    color: ${greyLight};
  }
`;

const pillHeightDesktop = 34;
const pillHeightMobile = 26;

export const Pill = styled.div`
  display: inline-block;
  vertical-align: top;
  transition: background-color 300ms;
  background-color: ${({ isHovered, field }) =>
    isHovered ? iconColor(field) : "white"};
  text-decoration: ${({ isHovered }) => (isHovered ? "line-through" : "none")};
  color: ${({ isHovered, field }) => (isHovered ? "white" : iconColor(field))};
  border-color: ${({ field }) => iconColor(field)};
  border-radius: ${pillHeightDesktop / 2}px;
  line-height: ${pillHeightDesktop - 4}px;
  height: ${pillHeightDesktop}px;
  border-style: solid;
  border-width: 2px;
  margin-right: 8px;
  margin-top: 12px;
  padding: 0px 14px;
  cursor: pointer;
  font-size: 14px;

  @media (max-width: ${mobileBreakpoint}px) {
    border-radius: ${pillHeightMobile / 2}px;
    line-height: ${pillHeightMobile - 4}px;
    height: ${pillHeightMobile}px;
    margin-top: 11px;
    padding: 0px 8px;
    font-size: 13px;

    &:hover {
      text-decoration: none;
      background-color: white;
      color: ${({ field }) => iconColor(field)};
    }

    &::after {
      content: "✕";
      margin-left: 4px;
    }
  }
`;

export const MobileEdit = styled.div`
  display: none;
  position: absolute;
  left: ${statusWidthMobile}px;
  color: ${blueDark};
  padding-top: 4px;
  cursor: text;
  bottom: 0px;
  right: 0px;
  top: 0px;

  &::before {
    content: "Edit Criteria (${({ filterCount }) => filterCount})";
  }

  @media (max-width: ${mobileBreakpoint}px) {
    display: ${({ isFocused, hasFilters }) =>
      !isFocused && hasFilters ? "block" : "none"};
  }
`;

export const optionHeight = 54;

export const Options = styled.div`
  position: fixed;
  overflow-y: scroll;
  ${defaultBoxShadow};
  background-color: white;
  border-top: 1px solid ${greyLighter};
  max-height: ${optionHeight * maxOmniMatches + 1}px;
  top: calc(100% + 1px);
  right: -1px;
  left: -1px;
`;

const optionContent = css`
  display: inline-block;
  vertical-align: top;
  height: 100%;
`;

export const Option = styled.div`
  position: relative;
  background-color: ${({ isHighlighted }) =>
    isHighlighted ? bluePowder : "transparent"};
  line-height: ${optionHeight}px;
  height: ${optionHeight}px;
  cursor: pointer;
`;

export const Icon = styled.div`
  ${optionContent}
  padding: 17px 15px 0px;
  width: 50px;

  svg {
    fill: ${({ field }) => iconColor(field)};
  }
`;

export const Label = styled.div`
  ${optionContent}
  color: ${greyMedium};
`;
