import qs from "qs";
import { compact } from "lodash";
import { buildListingsFilters } from "./forge";

const newStreamUrl = `${process.env.REACT_APP_CLOUD_STREAMS_URL}/streams/new`;
const newCmaUrl = `${process.env.REACT_APP_CLOUD_CMA_URL}/cmas/new`;
const newPropertyUrl = `${process.env.REACT_APP_CLOUD_CMA_URL}/properties/new`;
const newFlyerUrl = `${process.env.REACT_APP_CLOUD_CMA_URL}/flyers/new`;
const newTourUrl = `${process.env.REACT_APP_CLOUD_CMA_URL}/tours/new`;

export function getNewV2StreamUrl({ queryObject, statusMapping, ssoParams }) {
  const streamsQueryObject = Object.keys(queryObject).reduce((state, field) => {
    if (field === "proximity") {
      return state;
    }
    return { ...state, [field]: queryObject[field] };
  }, {});

  const filtersArray = buildListingsFilters({
    queryObject: streamsQueryObject,
    statusMapping,
    isForStreams: true
  });
  const criteriaObject = filtersArray.reduce(
    (state, filter, index) => {
      return { criteria: { ...state.criteria, [index]: filter } };
    },
    { criteria: {} }
  );

  return `${newStreamUrl}?${qs.stringify(criteriaObject, {
    arrayFormat: "brackets"
  })}&${ssoParams}`;
}

export function getNewCmaSubjectUrl({ listing, locale, ssoParams }) {
  const { address, city, state, zip, beds, baths, sqft } = listing || {};
  const fullAddress = `${address}, ${city}, ${state} ${zip}`;
  const finalAddress =
    locale === "en" ? `${fullAddress}, United States` : fullAddress;
  return `${newCmaUrl}?address=${finalAddress}&beds=${beds}&baths=${baths}&sqft=${(
    sqft || ""
  ).replace(",", "")}&${ssoParams}&mlsnums=`;
}

export function getNewCmaCompsUrl({ mlsnums, listings, locale, ssoParams }) {
  if (mlsnums.length > 1) {
    return `${newCmaUrl}?mlsnums=${mlsnums}&${ssoParams}`;
  }
  const listing = listings.find((listing) => listing.mlsnum === mlsnums[0]);
  return getNewCmaSubjectUrl({ listing, locale, ssoParams });
}

export function getNewPropertyUrl({ mlsnums, ssoParams }) {
  return `${newPropertyUrl}?mlsnums=${mlsnums}&${ssoParams}`;
}

export function getNewFlyerUrl({ mlsnums, ssoParams }) {
  return `${newFlyerUrl}?mlsnums=${mlsnums}&${ssoParams}`;
}

export function getNewTourUrl({ mlsnums, ssoParams }) {
  return `${newTourUrl}?mlsnums=${mlsnums}&${ssoParams}`;
}

export function getAuthedResourceType({
  isSearchRoute,
  isCollectionRoute,
  isListingRoute
}) {
  if (isSearchRoute) {
    return "Search";
  }
  if (isCollectionRoute) {
    return "Collection";
  }
  if (isListingRoute) {
    return "Listing";
  }
  return null;
}

export function getSharedResourceType(sharedSearch) {
  if (sharedSearch.role === "saved_search" && !sharedSearch.detailId) {
    return "Search";
  }
  if (sharedSearch.role === "collection" && !sharedSearch.detailId) {
    return "Collection";
  }
  if (sharedSearch.role === "singleton" || !!sharedSearch.detailId) {
    return "Listing";
  }
  return null;
}

export function getMailTo({ resourceType, resource, shortUrl, currentUser }) {
  switch (resourceType) {
    case "Search":
      return `${searchMailTo({ search: resource, shortUrl })}${mailToSignature(
        currentUser
      )}`;
    case "Listing":
      return `${listingMailTo({
        listing: resource,
        shortUrl
      })}${mailToSignature(currentUser)}`;
    case "Collection":
      return `${collectionMailTo({
        collection: resource,
        shortUrl
      })}${mailToSignature(currentUser)}`;
    default:
      return "";
  }
}

function searchMailTo({ search, shortUrl }) {
  const subject = "Saved Search of Properties";
  const body = `The following link will take you to a saved search with criteria specific to you. Every day this search will update with the latest properties that meet those criteria.\n\n${search.inputValue}\n${shortUrl}\n\nKeep this link handy and please do let me know if you have any questions about these properties.\n\n`;
  return `mailto:?subject=${window.encodeURIComponent(
    subject
  )}&body=${window.encodeURIComponent(body)}`;
}

function listingMailTo({ listing, shortUrl }) {
  const subject = `${listing.address}, ${listing.city} ${listing.state} ${listing.zip}`;
  const body = `I’ve found this property for you:\n\n${listing.address}\n${listing.city} ${listing.state} ${listing.zip}\n${shortUrl}\n\nPlease take a look at the property’s listing page and let me know what you think.\n\n`;
  return `mailto:?subject=${window.encodeURIComponent(
    subject
  )}&body=${window.encodeURIComponent(body)}`;
}

function collectionMailTo({ collection, shortUrl }) {
  const subject = "Collection of Properties";
  const body = `The following link will take you to a collection of properties I’ve organized for you.\n\n${collection.name}\n${shortUrl}\n\n`;
  return `mailto:?subject=${window.encodeURIComponent(
    subject
  )}&body=${window.encodeURIComponent(body)}`;
}

function mailToSignature(currentUser) {
  const signatureLines = compact([
    currentUser.name,
    currentUser.office,
    currentUser.phone,
    currentUser.email
  ]);
  return signatureLines
    .map((line) => window.encodeURIComponent(line))
    .join("%0D%0A");
}
