import {
  ApplicationSwitcher,
  Navbar,
  NavbarAdditionalItems,
  NavbarHelpLink,
  NavbarNavigationBar,
  NavbarUserMenu
} from "@helix/navbar";
import React, { useEffect, useState } from "react";
import { Link } from "./styled/temp-header";

export default function LegacyNavigation({
  avatarUrl,
  userId,
  userName,
  userEmail,
  id,
  jwt,
  mlsCode,
  isLoggedInAs,
  emitAction,
  logoutUser
}) {
  const { data: appSwitcher = {} } = useAppSwitcher({
    userId,
    userEmail,
    casUserId: id,
    casJwt: jwt,
    mlsCode
  });

  return (
    <Navbar
      dataLwtId="default"
      applicationName={!appSwitcher.enabled ? "Cloud MLX" : undefined}>
      {appSwitcher.enabled && (
        <ApplicationSwitcher
          applicationName="Cloud MLX"
          appSections={[
            {
              sectionName: "Promote",
              sectionItems: appSwitcher.products.map((product) => ({
                selected: product.key === "cloud_mlx",
                icon:
                  product.key === "cloud_cma"
                    ? "compare"
                    : product.key === "cloud_streams"
                    ? "alert"
                    : product.key === "cloud_mlx"
                    ? "search"
                    : product.key === "cloud_attract"
                    ? "agent"
                    : product.key === "launchpad"
                    ? "cards"
                    : undefined,
                label: product.name || "N/A",
                description: product.tagline || "",
                onClick:
                  product.key !== "cloud_mlx"
                    ? () => product.url && window.open(product.url, "_self")
                    : undefined
              }))
            }
          ]}
        />
      )}
      <NavbarNavigationBar
        navItems={[
          { label: "Search", url: "/dashboard" },
          { label: "Searches", url: "/searches" },
          { label: "Areas", url: "/areas" },
          { label: "Collections", url: "/collections" }
        ]}
        linkWrapper={({ item }) => (
          <Link
            to={item.url}
            onClick={() =>
              item.url === "/dashboard"
                ? emitAction({ type: "SET_AUTOFOCUS_TIMESTAMP" })
                : undefined
            }>
            {item.url === "/dashboard" && (
              <svg
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 21 20">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M9.5 3.75a5.25 5.25 0 1 0 0 10.5 5.25 5.25 0 0 0 0-10.5ZM2.75 9a6.75 6.75 0 1 1 12.024 4.213l2.756 2.757a.75.75 0 1 1-1.06 1.06l-2.757-2.756A6.75 6.75 0 0 1 2.75 9Z"
                />
              </svg>
            )}
            <span>{item.label}</span>
          </Link>
        )}
      />
      <NavbarAdditionalItems>
        <NavbarHelpLink
          url="https://lonewolf.my.site.com/s/topic/0TO2L0000008JR8WAM/cloud-mlx"
          linkWrapper={({ url, label }) => (
            <a href={url} target="_blank" rel="noopener noreferrer">
              {label}
            </a>
          )}
        />
        <NavbarUserMenu
          id="avatar"
          user={{
            name: userName,
            email: userEmail,
            avatarURL: avatarUrl
          }}
          menuItems={[
            {
              label: "Account Settings",
              url: `${process.env.REACT_APP_CAS_URL}/app/settings?return_to_app=cloud_mlx`
            },
            { divider: true },
            {
              label: "Learning Resources",
              url: "https://learn.cloudagentsuite.com/videos-mlx"
            },
            {
              label: "Support Center",
              url:
                "https://lonewolf.my.site.com/s/topic/0TO2L0000008JR8WAM/cloud-mlx"
            },
            !!id
              ? {
                  label: "Launchpad",
                  url: `${process.env.REACT_APP_CAS_URL}/app`
                }
              : undefined,
            { divider: true },
            {
              label: isLoggedInAs ? "Revert" : "Sign Out",
              url: "/logout"
            }
          ].filter(Boolean)}
          linkWrapper={({ item }) => {
            if (
              item.label === "Launchpad" ||
              item.label === "Account Settings"
            ) {
              return <a href={item.url}>{item.label}</a>;
            }

            const isExternal = [
              "Learning Resources",
              "Support Center"
            ].includes(item.label);

            if (isExternal) {
              return (
                <a href={item.url} target="_blank" rel="noopener noreferrer">
                  {item.label}
                </a>
              );
            }

            if (item.url === "/logout") {
              return (
                <a
                  href={item.url}
                  onClick={(e) => {
                    e.preventDefault();
                    logoutUser();
                  }}>
                  {item.label}
                </a>
              );
            }

            return <Link to={item.url}>{item.label}</Link>;
          }}
        />
      </NavbarAdditionalItems>
    </Navbar>
  );
}

function useAppSwitcher({ userId, userEmail, casUserId, casJwt, mlsCode }) {
  const [data, setData] = useState();

  useEffect(() => {
    fetch(`${process.env.REACT_APP_CAS_URL}/graphql/protected`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${casJwt}`,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        operationName: "ProductAvailability",
        query: /* GraphQL */ `
          mutation ProductAvailability($user: ExternalUserInputType!) {
            userProductAvailability(external_user: $user) {
              enabled: enable_app_switcher
              products {
                key
                name
                tagline
                url: switch_route
              }
            }
          }
        `,
        variables: {
          user: {
            application_key: "cloud_mlx",
            id: userId,
            email: userEmail,
            cas_user_id: casUserId,
            mls_code: mlsCode
          }
        }
      })
    })
      .then((res) => res.json())
      .then((res) => {
        const productsWithLaunchpad = res.data.userProductAvailability.products;
        const enableLaunchpad = !!casUserId;

        if (enableLaunchpad) {
          productsWithLaunchpad.push({
            key: "launchpad",
            name: "Launchpad",
            tagline: "Learn and manage billing",
            url: `${process.env.REACT_APP_CAS_URL}/launchpad`
          });
        }

        setData({
          enabled: res.data.userProductAvailability.enabled,
          products: productsWithLaunchpad.map((product) => ({
            ...product,
            url: `${product.url}?jwt=${casJwt}`
          }))
        });
      })
      .catch(() => {
        setData({ enabled: false, products: [] });
      });
  }, [casJwt, casUserId, mlsCode, userEmail, userId]);

  return { data };
}
