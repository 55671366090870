import { combineReducers } from "redux";
import { currentUser } from "./currentUser";
import { sharingAgent } from "./sharingAgent";
import { application } from "./application";
import { queryObject } from "./queryObject";
import { staticOptions } from "./staticOptions";
import { advancedSearch } from "./advancedSearch";
import { recentSearches } from "./recentSearches";
import { savedSearches } from "./savedSearches";
import { sharedSearch } from "./sharedSearch";
import { savedSearch } from "./savedSearch";
import { collections } from "./collections";
import { savedAreas } from "./savedAreas";
import { collection } from "./collection";
import { omniSearch } from "./omniSearch";
import { cheatSheet } from "./cheatSheet";
import { mlsConfigs } from "./mlsConfigs";
import { pagination } from "./pagination";
import { collector } from "./collector";
import { mapSearch } from "./mapSearch";
import { listings } from "./listings";
import { listing } from "./listing";
import { print } from "./print";
import { agent } from "./agent";

export default combineReducers({
  currentUser,
  sharingAgent,
  application,
  queryObject,
  staticOptions,
  advancedSearch,
  recentSearches,
  savedSearches,
  sharedSearch,
  savedSearch,
  collections,
  savedAreas,
  collection,
  omniSearch,
  cheatSheet,
  mlsConfigs,
  pagination,
  collector,
  mapSearch,
  listings,
  listing,
  print,
  agent
});
