import { connect } from "react-redux";
import { emitAction } from "../../../../utils/redux";
import { getUgcStaticOptions } from "../../../../actions/session";
import { createAdhocSearch, getSavedSearch } from "../../../../actions/search";
import { updateSavedSearch } from "../../../../actions/search";
import { getFilters } from "../../../../modules/search";
import { getSearchEventName } from "../../../../modules/event";
import { trackEvent } from "../../../../actions/event";
import SearchRoute from "../SearchRoute";

function select(store, { location }) {
  return {
    id: store.savedSearch.id,
    pathId: location.pathname.split("/searches/")[1],
    pathname: location.pathname || "",
    isPublic: store.application.isPublic,
    isAppReady: store.application.isAppReady,
    isCreating: store.savedSearch.isCreating,
    isLoading: store.listings.isLoading,
    isMapOpen: store.mapSearch.isOpen,
    isFocused: store.savedSearch.isFocused,
    isNaming: store.savedSearch.isNaming,
    isCards: store.listings.isCards,
    isNonDisclosure: store.mlsConfigs.isNonDisclosure,
    hasFilters: getFilters({ queryObject: store.queryObject }).length > 0,
    selectedMlsnums: store.listings.selectedMlsnums,
    savedName: store.savedSearch.savedName,
    inputValue: store.savedSearch.inputValue,
    statusMapping: store.mlsConfigs.statusMapping,
    queryObject: store.queryObject,
    listings: store.listings.data,
    totalCount: store.listings.count,
    page: store.pagination.listingsPage,
    limit: store.pagination.listingsLimit,
    sort: store.pagination.listingsSort,
    shouldTrack: store.savedSearch.shouldTrack,
    eventName: getSearchEventName({
      queryObject: store.queryObject,
      role: store.savedSearch.role
    })
  };
}

const actions = {
  getSavedSearch,
  createAdhocSearch,
  updateSavedSearch,
  getUgcStaticOptions,
  trackEvent,
  emitAction
};

export default connect(select, actions)(SearchRoute);
