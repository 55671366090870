import React, { Component } from "react";
import PropTypes from "prop-types";
import { history } from "../../../utils/routing";
import DynamicIcon from "../../common/DynamicIcon";
import { Icon, Label, optionHeight } from "./styled/recent-searches";
import { Container, Option, Handler } from "./styled/recent-searches";

class RecentSearches extends Component {
  state = { highlighted: 0 };

  componentDidMount() {
    this.addEventListeners();
  }

  componentWillUnmount() {
    this.removeEventListeners();
  }

  render() {
    return (
      <Container ref={(node) => (this.container = node)}>
        {this.props.recentSearches.map((search, index) => {
          const isHighlighted = index === this.state.highlighted;
          const onMouseMove = () => {
            if (!isHighlighted) {
              this.setState({ highlighted: index });
            }
          };

          return (
            <Option
              key={index}
              onMouseMove={onMouseMove}
              isHighlighted={isHighlighted}>
              <Handler
                onMouseDown={this.handleSelect}
                onTouchStart={this.handleSelect}>
                <Icon field={search.field}>
                  <DynamicIcon name={search.field} />
                </Icon>
                <Label>{search.name}</Label>
              </Handler>
            </Option>
          );
        })}
      </Container>
    );
  }

  handleWindowKeyDown = (event) => {
    if (["Escape", "ArrowUp", "ArrowDown", "Enter"].includes(event.key)) {
      event.preventDefault();
    }

    switch (event.key) {
      case "Enter":
        return this.handleSelect();
      case "ArrowUp":
        return this.handleUpArrow();
      case "ArrowDown":
        return this.handleDownArrow();
      default:
        return;
    }
  };

  handleSelect = () => {
    const { highlighted } = this.state;
    const { recentSearches } = this.props;
    const recentSearch = recentSearches[highlighted] || {};
    history.push(recentSearch.url);
  };

  handleUpArrow = () => {
    if (this.state.highlighted > 0) {
      this.container.scrollTop = (this.state.highlighted - 3) * optionHeight;
      this.setState({ highlighted: this.state.highlighted - 1 });
    }
  };

  handleDownArrow = () => {
    if (this.state.highlighted < this.props.recentSearches.length - 1) {
      this.container.scrollTop = (this.state.highlighted - 1) * optionHeight;
      this.setState({ highlighted: this.state.highlighted + 1 });
    }
  };

  addEventListeners = () => {
    window.addEventListener("keydown", this.handleWindowKeyDown);
  };

  removeEventListeners = () => {
    window.removeEventListener("keydown", this.handleWindowKeyDown);
  };
}

RecentSearches.propTypes = {
  recentSearches: PropTypes.array
};

export default RecentSearches;
