import PropTypes from "prop-types";
import React, { Component } from "react";
import { history } from "../../utils/routing";
import { mobileBreakpoint } from "../../utils/styling";
import GenericHeader from "../common/GenericHeader";
import Route from "../common/Route";
import AdvancedIcon from "../icons/AdvancedIcon";
import MapIcon from "../icons/MapIcon";
import OmniSearch from "../search/omni/connected/OmniSearch";
import {
  AdvancedButton,
  Center,
  MapButton,
  MobileBlurButton,
  OmniLayout
} from "./styled/header";

class Header extends Component {
  render() {
    const {
      isOmniFocused,
      isAdvancedOpen,
      isMapOpen,
      isSearchable,
      isSwitcherEnabled,
      hasFilters
    } = this.props;

    return (
      <GenericHeader>
        <GenericHeader.Back
          hideLabelOnMobile
          onClick={() => history.goBack()}
        />
        <Center
          isSearchable={isSearchable}
          isOmniFocused={isOmniFocused}
          hasFilters={hasFilters}
          isMapOpen={isMapOpen}
          isSwitcherEnabled={isSwitcherEnabled}>
          <OmniLayout isOmniFocused={isOmniFocused}>
            <Route component={OmniSearch} />
          </OmniLayout>
          <AdvancedButton
            isOmniFocused={isOmniFocused}
            isAdvancedOpen={isAdvancedOpen}
            onClick={this.handleAdvancedClick}>
            <AdvancedIcon />
          </AdvancedButton>
          <MapButton
            isOmniFocused={isOmniFocused}
            hasFilters={hasFilters}
            isMapOpen={isMapOpen}
            onClick={this.handleMapClick}>
            <MapIcon />
          </MapButton>
          <MobileBlurButton
            isOmniFocused={isOmniFocused}
            onClick={this.handleMobileBlurClick}>
            Done
          </MobileBlurButton>
        </Center>
      </GenericHeader>
    );
  }

  handleHomeClick = () => {
    const { pathname, emitAction } = this.props;
    history.push("/dashboard");

    if (pathname === "/dashboard") {
      emitAction({ type: "SET_IS_MAP_OPEN_VIA_SYSTEM", payload: false });
    }
  };

  handleAdvancedClick = () => {
    const {
      isOmniFocused,
      isAdvancedOpen,
      isMapOpen,
      hasFilters,
      emitAction
    } = this.props;

    if (window.innerWidth > mobileBreakpoint && !isOmniFocused && !hasFilters) {
      emitAction({ type: "SET_IS_OMNI_SEARCH_FOCUSED", payload: true });
    }

    if (
      window.innerWidth > mobileBreakpoint &&
      isOmniFocused &&
      !isMapOpen &&
      isAdvancedOpen &&
      !hasFilters
    ) {
      emitAction({ type: "SET_IS_OMNI_SEARCH_FOCUSED", payload: false });
    }

    emitAction({
      type: "SET_IS_ADVANCED_SEARCH_OPEN",
      payload: !isAdvancedOpen
    });
  };

  handleMapClick = () => {
    const {
      isOmniFocused,
      isMapOpen,
      isAdvancedOpen,
      hasFilters,
      emitAction
    } = this.props;

    if (window.innerWidth > mobileBreakpoint && !isOmniFocused && !hasFilters) {
      emitAction({ type: "SET_IS_OMNI_SEARCH_FOCUSED", payload: true });
    }

    if (
      window.innerWidth > mobileBreakpoint &&
      isOmniFocused &&
      isMapOpen &&
      !isAdvancedOpen &&
      !hasFilters
    ) {
      emitAction({ type: "SET_IS_OMNI_SEARCH_FOCUSED", payload: false });
    }

    if (!isMapOpen && !hasFilters) {
      emitAction({ type: "RESET_QUERY_OBJECT" });
    }

    emitAction({ type: "SET_IS_MAP_OPEN_VIA_USER", payload: !isMapOpen });
  };

  handleMobileBlurClick = () => {
    this.props.emitAction({
      type: "SET_IS_ADVANCED_SEARCH_OPEN",
      payload: false
    });
    this.props.emitAction({
      type: "SET_IS_OMNI_SEARCH_FOCUSED",
      payload: false
    });
  };

  handleNavClick = () => {
    if (this.props.isNavOpen) {
      return;
    }
    this.props.emitAction({ type: "SET_IS_NAV_OPEN", payload: true });
    window.addEventListener("mouseup", this.handleWindowClick);
  };

  handleWindowClick = () => {
    if (!this.props.isNavOpen) {
      return;
    }
    window.setTimeout(
      () => this.props.emitAction({ type: "SET_IS_NAV_OPEN", payload: false }),
      0
    );
    window.removeEventListener("mouseup", this.handleWindowClick);
  };

  handleSwitcherConfigs = (configs) => {
    if (configs.isEnabled && !this.props.isSwitcherEnabled) {
      this.props.emitAction({ type: "SET_IS_SWITCHER_ENABLED", payload: true });
    }
  };
}

Header.propTypes = {
  isNavOpen: PropTypes.bool,
  isOmniFocused: PropTypes.bool,
  isAdvancedOpen: PropTypes.bool,
  isMapOpen: PropTypes.bool,
  isSearchable: PropTypes.bool,
  hasFilters: PropTypes.bool,
  queryObject: PropTypes.object,
  showBackButton: PropTypes.bool,
  isSwitcherEnabled: PropTypes.bool,
  isLoggedInAs: PropTypes.bool,
  pathname: PropTypes.string,
  avatarUrl: PropTypes.string,
  initials: PropTypes.string,
  userName: PropTypes.string,
  userId: PropTypes.string,
  userEmail: PropTypes.string,
  id: PropTypes.string,
  jwt: PropTypes.string,
  lwaId: PropTypes.string,
  lwaJwt: PropTypes.string,
  mlsCode: PropTypes.string,
  goBack: PropTypes.func,
  emitAction: PropTypes.func
};

export default Header;
