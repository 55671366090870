import React from "react";

function UserIcon() {
  return (
    <svg viewBox="0 0 100 100" preserveAspectRatio="xMidYMid meet">
      <path d="M50,50 C63.8125,50 75,38.8125 75,25 C75,11.1875 63.8125,0 50,0 C36.1875,0 25,11.1875 25,25 C25,38.8125 36.1875,50 50,50 Z M50,62.5 C33.3125,62.5 0,70.875 0,87.5 L0,100 L100,100 L100,87.5 C100,70.875 66.6875,62.5 50,62.5 Z"></path>
    </svg>
  );
}

export default UserIcon;
