export function transformPins({ pins, statusMapping }) {
  return pins.reduce((state, pin, index) => {
    return {
      ...state,
      [pin.geohash]: {
        id: pin.geohash,
        lat: pin.lat,
        lon: pin.lon,
        records: pin.records.map(({ id, status }) => ({
          id,
          statusValue: getMappedStatus({ status, statusMapping })
        }))
      }
    };
  }, {});
}

export function filterPins({ pins, listings }) {
  const listingIds = listings.map(({ id }) => id);

  return pins.reduce((state, pin) => {
    const isSingle = pin.records.length === 1;
    const pinListingId = (pin.records[0] || {}).id;

    if (isSingle && listingIds.includes(pinListingId)) {
      return state;
    }

    return [...state, pin];
  }, []);
}

function getMappedStatus({ status, statusMapping }) {
  if (statusMapping.active.includes(status)) {
    return "active";
  }
  if (statusMapping.backup.includes(status)) {
    return "backup";
  }
  if (statusMapping.pending.includes(status)) {
    return "pending";
  }
  if (statusMapping.closed.includes(status)) {
    return "closed";
  }
  return "";
}
