import React from "react";
import PropTypes from "prop-types";
import { Container } from "./styled/avatar";

function Avatar({ size, initials, avatarUrl }) {
  return <Container size={size} initials={initials} avatarUrl={avatarUrl} />;
}

Avatar.defaultProps = {
  size: "small"
};

Avatar.propTypes = {
  initials: PropTypes.string,
  avatarUrl: PropTypes.string,
  size: PropTypes.oneOf(["small", "medium", "large", "xl", "xxl"])
};

export default Avatar;
