import styled from "styled-components";
import { green, red, gray } from "../../../utils/colorsSystem";

export const Container = styled.span`
  color: ${({ shouldDim }) => shouldDim && gray[700]};
`;

export const Arrow = styled.span`
  color: ${({ isPositive }) => (isPositive ? green[700] : red[600])};
`;
