import React, { Component } from "react";
import PropTypes from "prop-types";
import { Container, Input, Dash } from "./styled/range-input";

class RangeInput extends Component {
  state = { minValue: "", maxValue: "" };

  componentDidMount() {
    this._isMounted = true;
    this.handleInitialProps();
  }

  componentDidUpdate(prevProps) {
    this.handleUpdatedProps(prevProps);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { label } = this.props;

    return (
      <Container>
        <Input
          value={this.state.minValue}
          placeholder={label ? `Min ${label}` : "Min"}
          onKeyDown={this.handleMinKeyDown}
          onChange={this.handleChangeMin}
          onFocus={this.handleFocusMin}
          onBlur={this.handleBlurMin}
        />
        <Dash />
        <Input
          value={this.state.maxValue}
          placeholder={label ? `Max ${label}` : "Max"}
          onKeyDown={this.handleMaxKeyDown}
          onChange={this.handleChangeMax}
          onFocus={this.handleFocusMax}
          onBlur={this.handleBlurMax}
        />
      </Container>
    );
  }

  handleInitialProps = () => {
    window.setTimeout(() => {
      if (this._isMounted) {
        this.setState({
          minValue: this.props.minValue || "",
          maxValue: this.props.maxValue || ""
        });
      }
    }, 0);
  };

  handleUpdatedProps = ({ minValue, maxValue }) => {
    if (minValue !== this.props.minValue) {
      this.setState({ minValue: this.props.minValue || "" });
    }

    if (maxValue !== this.props.maxValue) {
      this.setState({ maxValue: this.props.maxValue || "" });
    }
  };

  handleChangeMin = ({ target }) => {
    this.setState({ minValue: target.value });
  };

  handleChangeMax = ({ target }) => {
    this.setState({ maxValue: target.value });
  };

  handleFocusMin = () => {
    if (this.props.minValue) {
      this.setState({ minValue: this.props.minValue });
    }
  };

  handleFocusMax = () => {
    if (this.props.maxValue) {
      this.setState({ maxValue: this.props.maxValue });
    }
  };

  handleBlurMin = () => {
    this.props.handleSubmitMin(this.state.minValue);
  };

  handleBlurMax = () => {
    this.props.handleSubmitMax(this.state.maxValue);
  };

  handleMinKeyDown = ({ key }) => {
    if (key === "Enter") {
      this.props.handleSubmitMin(this.state.minValue);
    }
  };

  handleMaxKeyDown = ({ key }) => {
    if (key === "Enter") {
      this.props.handleSubmitMax(this.state.maxValue);
    }
  };

  resetState = () => {
    this.setState({ minValue: "", maxValue: "" });
  };
}

RangeInput.defaultProps = {
  handleSubmitMin: () => {},
  handleSubmitMax: () => {}
};

RangeInput.propTypes = {
  label: PropTypes.string,
  minValue: PropTypes.string,
  maxValue: PropTypes.string,
  handleSubmitMin: PropTypes.func,
  handleSubmitMax: PropTypes.func
};

export default RangeInput;
