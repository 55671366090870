import React from "react";
import { times } from "lodash";
import PropTypes from "prop-types";
import ListingCard from "./ListingCard";
import { pagePadding } from "../../utils/styling";
import { ListingCardLoading } from "@wrstudios/components";
import {
  ThreeColumnContainer,
  ThreeColumnItem
} from "../common/styled/three-columns";

function ListingCards(props) {
  if (props.isLoading) {
    return (
      <ThreeColumnContainer>
        {times(props.limit, (index) => (
          <ThreeColumnItem
            isScreenSplit={props.isMapOpen}
            bottom={pagePadding}
            key={index}>
            <ListingCardLoading />
          </ThreeColumnItem>
        ))}
      </ThreeColumnContainer>
    );
  } else {
    return (
      <ThreeColumnContainer>
        {props.listings.map((listing, index) => (
          <ThreeColumnItem
            isScreenSplit={props.isMapOpen}
            bottom={pagePadding}
            key={index}>
            <ListingCard
              listing={listing}
              isPublic={props.isPublic}
              parentPath={props.parentPath}
              hasControls={props.hasControls}
              isSharedRoute={props.isSharedRoute}
              isNonDisclosure={props.isNonDisclosure}
              selectedMlsnums={props.selectedMlsnums}
              handleClickCheckBox={props.handleClickCheckBox}
              handleHoverListing={props.handleHoverListing}
              handleClickListing={props.handleClickListing}
            />
          </ThreeColumnItem>
        ))}
      </ThreeColumnContainer>
    );
  }
}

ListingCards.defaultProps = {
  limit: 9
};

ListingCards.propTypes = {
  isPublic: PropTypes.bool,
  isLoading: PropTypes.bool,
  isMapOpen: PropTypes.bool,
  isSharedRoute: PropTypes.bool,
  isNonDisclosure: PropTypes.bool,
  hasControls: PropTypes.bool,
  limit: PropTypes.number,
  parentPath: PropTypes.string,
  listings: PropTypes.array,
  selectedMlsnums: PropTypes.array,
  handleClickCheckBox: PropTypes.func,
  handleHoverListing: PropTypes.func,
  handleClickListing: PropTypes.func
};

export default ListingCards;
