export const initial = {
  isOpen: false,
  arbitraryFields: [],
  currentArbitraryField: {},
  currentArbitraryOptions: [],
  currentArbitraryOptionsTime: Date.now(),
  isArbitraryLoading: false
};

export function advancedSearch(state = initial, { type, payload }) {
  switch (type) {
    case "SET_IS_ADVANCED_SEARCH_OPEN":
      return { ...state, isOpen: payload };
    case "GET_AVAILABLE_FIELDS_SUCCEEDED":
      return { ...state, arbitraryFields: payload.arbitrary };
    case "SET_CURRENT_ARBITRARY_FIELD":
      return {
        ...state,
        currentArbitraryOptions: [],
        currentArbitraryField: payload
      };
    case "GET_ARBITRARY_OPTIONS_INITIATED":
      return {
        ...state,
        currentArbitraryOptions: [],
        isArbitraryLoading: true
      };
    case "GET_ARBITRARY_OPTIONS_SUCCEEDED":
      return {
        ...state,
        currentArbitraryOptions: payload,
        isArbitraryLoading: false
      };
    case "GET_ARBITRARY_OPTIONS_FAILED":
      return {
        ...state,
        currentArbitraryOptions: [],
        isArbitraryLoading: false
      };
    case "GET_AUTOCOMPLETE_SUGGESTIONS_SUCCEEDED":
      if (payload.requestedAt < state.currentArbitraryOptionsTime) {
        return state;
      }
      return {
        ...state,
        currentArbitraryOptions: payload.data,
        isArbitraryLoading: false
      };
    case "RESET_ARBITRARY_OPTIONS":
      return { ...state, currentArbitraryOptions: [] };
    default:
      return state;
  }
}
