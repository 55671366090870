import React, { Component } from "react";
import PropTypes from "prop-types";
import { times, parseInt } from "lodash";
import CommonPhotoCard from "../../common/PhotoCard";
import { PhotoCardLoading } from "@wrstudios/components";
import Notice from "../../common/Notice";
import SelectTag from "../../common/SelectTag";
import Pagination from "../../common/Pagination";
import CardCreate from "../../common/CardCreate";
import DeleteNotice from "../../common/DeleteNotice";
import Notification from "../../common/Notification";
import { sortOptions } from "../../../modules/search";
import { pagePadding } from "../../../utils/styling";
import { getNewV2StreamUrl } from "../../../modules/interProduct";
import { ThreeColumnContainer } from "../../common/styled/three-columns";
import { ThreeColumnItem } from "../../common/styled/three-columns";
import { Header, Title, Sorting } from "../../common/styled/heading";
import { SearchLinkLayout, SearchLink } from "./styled/searches-route";
import { Container, Body } from "./styled/searches-route";

class SearchesRoute extends Component {
  componentDidMount() {
    this.handleNewLocation();
  }

  componentDidUpdate(prevProps) {
    this.handleReloadLocation(prevProps);
    this.handlePaginationChange(prevProps);
    this.handleAsyncDelete(prevProps);
  }

  render() {
    return (
      <Container>
        <Header>
          <Title>Saved Searches</Title>
          <Sorting>
            <SelectTag
              handleSelect={this.handleSelectSort}
              selected={this.props.sort}
              options={sortOptions}
              width={184}
            />
          </Sorting>
        </Header>
        <Body>{this.renderBody()}</Body>
        <Pagination
          page={this.props.page}
          limit={this.props.limit}
          totalCount={this.props.count}
          renderedCount={this.props.searches.length}
          isVisible={!this.props.isLoading && this.props.searches.length > 0}
          handleClickPage={this.handleClickPage}
        />
        <Notification
          ref={(node) => (this.deleteAlert = node)}
          isDismissable={false}
          shouldAutoDismiss={false}>
          <DeleteNotice
            body="This search will be permantently deleted"
            handleDelete={this.handleFinishDelete}
            handleCancel={this.handleCancelDelete}
          />
        </Notification>
        <Notification
          ref={(node) => (this.deleteSuccess = node)}
          type="success">
          <Notice title="Success" body="Your search was deleted" />
        </Notification>
        <Notification ref={(node) => (this.deleteError = node)} type="error">
          <Notice title="Error" body="Your search could not be deleted" />
        </Notification>
      </Container>
    );
  }

  renderBody = () => {
    if (this.props.isLoading) {
      return (
        <ThreeColumnContainer>
          {times(9).map((index) => (
            <ThreeColumnItem key={index} bottom={pagePadding}>
              <PhotoCardLoading />
            </ThreeColumnItem>
          ))}
        </ThreeColumnContainer>
      );
    }

    if (this.props.searches.length === 0) {
      return (
        <ThreeColumnContainer>
          <ThreeColumnItem>
            <CardCreate url="/" text="Create a Search" />
          </ThreeColumnItem>
        </ThreeColumnContainer>
      );
    }

    return (
      <ThreeColumnContainer>
        {this.props.searches.map((search, index) => {
          const streamUrl = getNewV2StreamUrl({
            queryObject: search.queryObject,
            statusMapping: this.props.statusMapping,
            ssoParams: this.props.ssoParams
          });

          return (
            <ThreeColumnItem key={index} bottom={pagePadding}>
              <CommonPhotoCard
                url={`/searches/${search.id}`}
                name={search.name}
                photos={search.photos}
                lineOne={search.locationsFormatted}
                lineTwo={search.criteria}
                hasLineTwo={!!search.criteria}
                handleClickLink={this.handleClickSearch}
                handleClickDelete={() => this.handleBeginDelete(search.id)}
                deleteLabel="Delete Search"
                shouldHaveTwoLines={true}
                showMenu={true}
                footer={
                  <SearchLinkLayout>
                    <SearchLink
                      href={streamUrl}
                      target="_blank"
                      rel="noopener noreferrer">
                      Get Alerts
                    </SearchLink>
                  </SearchLinkLayout>
                }
              />
            </ThreeColumnItem>
          );
        })}
      </ThreeColumnContainer>
    );
  };

  handleNewLocation = () => {
    if (this.props.isAppReady) {
      this.props.getSavedSearches(1);
    }
  };

  handleReloadLocation = ({ isAppReady }) => {
    if (!isAppReady && this.props.isAppReady) {
      this.props.getSavedSearches(this.props.page);
    }
  };

  handlePaginationChange = ({ page, sort }) => {
    const isNewPage = page !== this.props.page;
    const isNewSort = sort !== this.props.sort;

    if (!this.props.isLoading && (isNewPage || isNewSort)) {
      this.props.getSavedSearches(isNewPage ? this.props.page : 1);
    }
  };

  handleSelectSort = (value) => {
    const { emitAction } = this.props;
    emitAction({ type: "SET_SEARCHES_SORT", payload: value });
  };

  handleClickPage = (value) => {
    const { emitAction } = this.props;
    emitAction({ type: "SET_SEARCHES_PAGE", payload: parseInt(value) });
  };

  handleClickSearch = () => {
    this.props.emitAction({ type: "RESET_QUERY_OBJECT" });
  };

  handleBeginDelete = (id) => {
    this.deleteAlert.display();
    this.deleteId = id;
  };

  handleFinishDelete = () => {
    this.props.deleteSavedSearch(this.deleteId);
    this.deleteAlert.dismiss();
    this.deleteId = null;
  };

  handleCancelDelete = () => {
    this.deleteAlert.dismiss();
    this.deleteId = null;
  };

  handleAsyncDelete = ({ hasError, isDeleting }) => {
    if (!hasError && this.props.hasError) {
      return this.deleteError.display();
    }
    if (isDeleting && !this.props.isDeleting) {
      this.deleteSuccess.display();
      this.props.getUgcStaticOptions();
    }
  };
}

SearchesRoute.propTypes = {
  ssoParams: PropTypes.string,
  statusMapping: PropTypes.object,
  count: PropTypes.number,
  page: PropTypes.number,
  limit: PropTypes.number,
  sort: PropTypes.string,
  searches: PropTypes.array,
  hasError: PropTypes.bool,
  isLoading: PropTypes.bool,
  isDeleting: PropTypes.bool,
  isAppReady: PropTypes.bool,
  getSavedSearches: PropTypes.func,
  deleteSavedSearch: PropTypes.func,
  getUgcStaticOptions: PropTypes.func,
  emitAction: PropTypes.func
};

export default SearchesRoute;
