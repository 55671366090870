import { css } from "styled-components";

export const page = {
  twoColumn: 768,
  oneColumn: 576
};

export const xxs = 325;
export const xs = 400;
export const sm = 576;
export const md = 768;
export const lg = 992;
export const xl = 1200;

const sizes = {
  xxs,
  xs,
  sm,
  md,
  lg,
  xl
};

// CMA uses this but may be beneficial for other apps
export const media = Object.keys(sizes).reduce((acc, label) => {
  if (label === "sm" || label === "xs") {
    acc[label] = (...args) => css`
      @media (max-width: ${sizes[label] - 1}px),
        (min-device-width: 414px) and (max-device-width: 736px) /* iPhone 6+ 7+ and 8+ */,
        (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) /* iPhone X */ {
        ${css(...args)};
      }
    `;
  } else {
    acc[label] = (...args) => css`
      @media (max-width: ${sizes[label] - 1}px) {
        ${css(...args)};
      }
    `;
  }

  return acc;
}, {});
