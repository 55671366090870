import React from "react";
import PropTypes from "prop-types";
import Button from "./Button";
import { Title, Body } from "./styled/notice";
import {
  Container,
  Notice,
  Buttons,
  ButtonLayout
} from "./styled/notice-with-buttons";

const buttonsWidthDesktop = 210;
const buttonsWidthMobile = 150;

function PublicNotice({ handleReturnToAgent }) {
  return (
    <Container>
      <Notice {...{ buttonsWidthDesktop, buttonsWidthMobile }}>
        <Title>Client View</Title>
        <Body>What clients will see</Body>
      </Notice>
      <Buttons {...{ buttonsWidthDesktop, buttonsWidthMobile }}>
        <ButtonLayout>
          <Button
            label="Back to Agent View"
            handleClick={handleReturnToAgent}
          />
        </ButtonLayout>
      </Buttons>
    </Container>
  );
}

PublicNotice.propTypes = {
  handleReturnToAgent: PropTypes.func
};

export default PublicNotice;
