import React from "react";

function BedIcon() {
  return (
    <svg viewBox="0 0 100 100" preserveAspectRatio="xMidYMid meet">
      <path d="M27.2727273,52.3636364 C34.8181818,52.3636364 40.9090909,46.2727273 40.9090909,38.7272727 C40.9090909,31.1818182 34.8181818,25.0909091 27.2727273,25.0909091 C19.7272727,25.0909091 13.6363636,31.1818182 13.6363636,38.7272727 C13.6363636,46.2727273 19.7272727,52.3636364 27.2727273,52.3636364 L27.2727273,52.3636364 Z M81.8181818,25.0909091 L45.4545455,25.0909091 L45.4545455,56.9090909 L9.09090909,56.9090909 L9.09090909,16 L0,16 L0,84.1818182 L9.09090909,84.1818182 L9.09090909,70.5454545 L90.9090909,70.5454545 L90.9090909,84.1818182 L100,84.1818182 L100,43.2727273 C100,33.2272727 91.8636364,25.0909091 81.8181818,25.0909091 L81.8181818,25.0909091 Z"></path>
    </svg>
  );
}

export default BedIcon;
