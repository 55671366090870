import styled from "styled-components";
import { greyDark, greyMedium, greyLight } from "../../../../utils/colorsLocal";
import {
  mobileBreakpoint,
  routeContainer,
  noWrap
} from "../../../../utils/styling";

export const Container = styled.div`
  ${routeContainer};
`;

export const Header = styled.div`
  position: relative;
  margin-bottom: 15px;
  height: 105px;

  @media (max-width: ${mobileBreakpoint}px) {
    margin-bottom: 5px;
    height: 85px;
  }
`;

export const AvatarLayout = styled.div`
  position: absolute;
  left: 0px;
  top: 10px;
`;

export const InfoLayout = styled.div`
  margin-left: 100px;

  @media (max-width: ${mobileBreakpoint}px) {
    margin-left: 80px;
  }
`;

export const Title = styled.div`
  ${noWrap};
`;

export const Name = styled.div`
  display: inline-block;
  vertical-align: top;
  color: ${greyDark};
  font-size: 36px;

  @media (max-width: ${mobileBreakpoint}px) {
    font-size: 28px;
  }
`;

export const Bre = styled.div`
  display: inline-block;
  vertical-align: top;
  color: ${greyLight};
  font-style: italic;
  margin-top: 21px;
  margin-left: 5px;

  @media (max-width: ${mobileBreakpoint}px) {
    display: none;
  }
`;

export const Office = styled.div`
  ${noWrap};
  font-size: 24px;
  margin-bottom: 3px;
  color: ${greyMedium};

  @media (max-width: ${mobileBreakpoint}px) {
    font-size: 20px;
    margin-bottom: 0px;
  }
`;

export const Contact = styled.div`
  ${noWrap};
  font-size: 14px;

  @media (max-width: ${mobileBreakpoint}px) {
    font-size: 13px;
  }
`;

export const Body = styled.div`
  padding-top: 40px;

  @media (max-width: ${mobileBreakpoint}px) {
    padding-top: 20px;
  }
`;
