import React from "react";

function CheckmarkIcon() {
  return (
    <svg viewBox="0 0 100 100" preserveAspectRatio="xMidYMid meet">
      <polygon points="31.8197012 72.2301488 7.95492531 48.3653728 0 56.3202982 31.8197012 88.1399994 100.004775 19.9549253 92.04985 12"></polygon>
    </svg>
  );
}

export default CheckmarkIcon;
