import { connect } from "react-redux";
import { getListingToRender } from "../../../../modules/listing";
import { setFullscreenMediaDetails } from "../../../../actions/listing";
import ListingFullscreenMedia from "../ListingFullscreenMedia";

function select({ application, listing, sharedSearch }) {
  const listingToRender = getListingToRender({ listing, sharedSearch });

  return {
    street: listingToRender.address,
    city: listingToRender.city,
    state: listingToRender.state,
    zip: listingToRender.zip,
    markerLat: listingToRender.lat,
    markerLon: listingToRender.lon,
    photos: listingToRender.imageUrls,
    virtualTour: listingToRender.virtualTour,
    initialTab: listing.fullscreenMediaTab,
    initialSlideIndex: listing.fullscreenMediaPhotoIndex,
    mapboxApiToken: process.env.REACT_APP_MAPBOX_API_TOKEN,
    env: process.env.REACT_APP_API_ENV,
    useFullscreen: false
  };
}

const actions = {
  setFullscreenMediaDetails
};

export default connect(select, actions)(ListingFullscreenMedia);
