import styled from "styled-components";
import { white } from "../../../utils/colorsSystem";
import {
  headerHeightDesktop,
  headerHeightMobile,
  mobileBreakpoint,
  zIndexHeader
} from "../../../utils/styling";

export const Container = styled.header`
  display: flex;
  align-items: center;
  width: 100%;
  height: ${headerHeightDesktop}px;
  background: ${white};
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2), 0 1px 2px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: ${zIndexHeader};

  @media (max-width: ${mobileBreakpoint}px) {
    height: ${headerHeightMobile}px;
    padding: 0 0.8rem;
  }
`;

export const Back = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10.9rem;
  cursor: pointer;

  svg {
    margin: 0 0.8rem 0 0;
  }

  @media (max-width: ${mobileBreakpoint}px) {
    width: auto;
    padding-right: 0.8rem;
  }
`;

export const BackLabel = styled.span`
  @media (max-width: ${mobileBreakpoint}px) {
    display: none;
  }
`;
