export const raised =
  "0 0.1rem 0.2rem rgba(0, 0, 0, 0.1), 0 0 0.2rem rgba(0, 0, 0, 0.2)";

export const raisedInset =
  "0 0.1rem 0.2rem rgba(0, 0, 0, 0.1) inset, 0 0 0.2rem rgba(0, 0, 0, 0.2) inset";

export const sticky =
  "0 0.2rem 0.4rem rgba(0, 0, 0, 0.1), 0 0 0.1rem rgba(0, 0, 0, 0.2)";

export const stickyInset =
  "0 0.2rem 0.4rem rgba(0, 0, 0, 0.1) inset, 0 0 0.1rem rgba(0, 0, 0, 0.2) inset";

export const overlay =
  "0 0.4rem 0.8rem rgba(0, 0, 0, 0.1), 0 0 0.1rem rgba(0, 0, 0, 0.2)";

export const overlayInset =
  "0 0.4rem 0.8rem rgba(0, 0, 0, 0.1) inset, 0 0 0.1rem rgba(0, 0, 0, 0.2) inset";

export const modal =
  "0 1.2rem 2.4rem rgba(0, 0, 0, 0.1), 0 0 0.1rem rgba(0, 0, 0, 0.2)";

export const modalInset =
  "0 1.2rem 2.4rem rgba(0, 0, 0, 0.1) inset, 0 0 0.1rem rgba(0, 0, 0, 0.2) inset";
