import { connect } from "react-redux";
import { chunk } from "lodash";
import { emitAction } from "../../../utils/redux";
import CheatSheet from "../CheatSheet";

function select({ cheatSheet, application }) {
  return {
    data: chunk(Object.values(cheatSheet.data), Math.ceil(7)),
    isOpen: cheatSheet.isOpen,
    isSwitcherEnabled: application.isSwitcherEnabled
  };
}

const actions = {
  emitAction
};

export default connect(select, actions)(CheatSheet);
