import { omit, without } from "lodash";

const initial = {
  isLoading: false,
  isDeleting: false,
  hasError: false,
  count: 0,
  order: [],
  data: {}
};

export function savedSearches(state = initial, { type, payload }) {
  switch (type) {
    case "GET_SAVED_SEARCHES_INITIATED":
      return { ...state, isLoading: true };
    case "GET_SAVED_SEARCHES_SUCCEEDED":
      return {
        ...state,
        count: payload.count,
        order: payload.order,
        data: payload.data,
        isLoading: false
      };
    case "GET_SAVED_SEARCHES_FAILED":
      return { ...state, isLoading: false };
    case "DELETE_SAVED_SEARCH_INITIATED":
      return { ...state, isDeleting: true, hasError: false };
    case "DELETE_SAVED_SEARCH_FAILED":
      return { ...state, isDeleting: false, hasError: true };
    case "DELETE_SAVED_SEARCH_SUCCEEDED":
      return {
        isDeleting: false,
        order: without(state.order, payload.id),
        data: omit(state.data, payload.id)
      };
    case "CREATE_ADHOC_SEARCH_SUCCEEDED":
    case "UPDATE_SAVED_SEARCH_SUCCEEDED":
      return initial;
    default:
      return state;
  }
}
