import { omit, without, uniq } from "lodash";

export function transformArbitraryField(field) {
  return {
    id: `${field.id}`,
    label: field.title,
    field: field.key,
    isSelect: field.input_type === "single" || field.input_type === "multi",
    isSingular: field.input_type === "single",
    isCurrency: field.input_type === "money",
    isAutoComplete: field.autocomplete,
    isArbitrary: true
  };
}

export function buildArbitraryFilter({ fieldObject, value, isMin, isMax }) {
  return { ...fieldObject, value, isMin, isMax };
}

export function transformArbitraryOptions(dataArray) {
  return (dataArray || []).sort().map((option) => ({
    label: option,
    value: option
  }));
}

export function addArbitraryToQueryObject({ filter, queryObject }) {
  const { isMin, isMax, ...rest } = filter;

  if (isMin || isMax) {
    return {
      ...queryObject,
      arbitrary: {
        ...queryObject.arbitrary,
        [filter.id]: {
          ...rest,
          isRange: true,
          value: {
            min: isMin
              ? filter.value
              : ((queryObject.arbitrary[filter.id] || {}).value || {}).min,
            max: isMax
              ? filter.value
              : ((queryObject.arbitrary[filter.id] || {}).value || {}).max
          }
        }
      }
    };
  } else {
    return {
      ...queryObject,
      arbitrary: {
        ...queryObject.arbitrary,
        [filter.id]: {
          ...rest,
          value: filter.isSingular
            ? filter.value
            : uniq([
                ...((queryObject.arbitrary[filter.id] || {}).value || []),
                filter.value
              ])
        }
      }
    };
  }
}

export function removeArbitraryFromQueryObject({ filter, queryObject }) {
  switch (true) {
    case !queryObject.arbitrary[filter.id]:
      return queryObject;
    case filter.isMin && !queryObject.arbitrary[filter.id].value.max:
      return {
        ...queryObject,
        arbitrary: omit(queryObject.arbitrary, filter.id)
      };
    case filter.isMin && !!queryObject.arbitrary[filter.id].value.max:
      return {
        ...queryObject,
        arbitrary: {
          ...queryObject.arbitrary,
          [filter.id]: {
            ...queryObject.arbitrary[filter.id],
            value: { ...queryObject.arbitrary[filter.id].value, min: undefined }
          }
        }
      };
    case filter.isMax && !queryObject.arbitrary[filter.id].value.min:
      return {
        ...queryObject,
        arbitrary: omit(queryObject.arbitrary, filter.id)
      };
    case filter.isMax && !!queryObject.arbitrary[filter.id].value.min:
      return {
        ...queryObject,
        arbitrary: {
          ...queryObject.arbitrary,
          [filter.id]: {
            ...queryObject.arbitrary[filter.id],
            value: { ...queryObject.arbitrary[filter.id].value, max: undefined }
          }
        }
      };
    case filter.isRange ||
      filter.isSingular ||
      queryObject.arbitrary[filter.id].value.length === 1:
      return {
        ...queryObject,
        arbitrary: omit(queryObject.arbitrary, filter.id)
      };
    default:
      return {
        ...queryObject,
        arbitrary: {
          ...queryObject.arbitrary,
          [filter.id]: {
            ...queryObject.arbitrary[filter.id],
            value: without(queryObject.arbitrary[filter.id].value, filter.value)
          }
        }
      };
  }
}

export function getArbitraryFilters(queryObject) {
  return Object.values(queryObject.arbitrary || {}).reduce((state, filter) => {
    if (filter.isRange || filter.isSingular) {
      return [
        ...state,
        { ...filter, label: buildArbitraryFilterLabel(filter) }
      ];
    } else {
      return [
        ...state,
        ...filter.value.map((value) => {
          return {
            ...filter,
            value,
            label: buildArbitraryFilterLabel({ ...filter, value })
          };
        })
      ];
    }
  }, []);
}

function buildArbitraryFilterLabel({
  label,
  value,
  isRange,
  isCurrency,
  isSingular
}) {
  if (isRange && value.min && value.max) {
    if (isCurrency) {
      return `${label}: $${value.min} - $${value.max}`;
    }
    if (!isCurrency) {
      return `${label}: ${value.min} - ${value.max}`;
    }
  }

  if (isRange && !value.min && value.max) {
    if (isCurrency) {
      return `${label}: $0 - $${value.max}`;
    }
    if (!isCurrency) {
      return `${label}: 0 - ${value.max}`;
    }
  }

  if (isRange && value.min && !value.max) {
    if (isCurrency) {
      return `${label}: $${value.min} +`;
    }
    if (!isCurrency) {
      return `${label}: ${value.min} +`;
    }
  }

  return `${label}: ${value}`;
}
