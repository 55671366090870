import { initial as initialUser } from "../reducers/currentUser";
import { initial as initialApplication } from "../reducers/application";
import { initial as initialStaticOptions } from "../reducers/staticOptions";
import { initial as initialAdvancedSearch } from "../reducers/advancedSearch";
import { initial as initialSharedSearch } from "../reducers/sharedSearch";
import { initial as initialOmniSearch } from "../reducers/omniSearch";
import { initial as initialCheatSheet } from "../reducers/cheatSheet";
import { initial as initialMlsConfigs } from "../reducers/mlsConfigs";
import { initial as initialMapSearch } from "../reducers/mapSearch";

export function readFromSessionStorage() {
  try {
    if (!window.sessionStorage.getItem("cloudMLX")) {
      return {};
    }
    return JSON.parse(window.sessionStorage.getItem("cloudMLX"));
  } catch (error) {
    return {};
  }
}

export function writeToSessionStorage(store) {
  const state = store.getState();

  try {
    window.sessionStorage.setItem(
      "cloudMLX",
      JSON.stringify({
        ...state,
        currentUser: { ...initialUser, jwt: state.currentUser.jwt },
        sharingAgent: initialUser,
        application: initialApplication,
        staticOptions: initialStaticOptions,
        advancedSearch: initialAdvancedSearch,
        sharedSearch: initialSharedSearch,
        omniSearch: initialOmniSearch,
        cheatSheet: initialCheatSheet,
        mlsConfigs: initialMlsConfigs,
        mapSearch: {
          ...state.mapSearch,
          isReady: initialMapSearch.isReady,
          map: initialMapSearch.map
        },
        listing: {
          ...state.listing,
          isFullscreenMediaOpen: false,
          fullscreenMediaPhotoIndex: 0,
          fullscreenMediaTab: "map"
        }
      })
    );
  } catch (error) {
    console.error(error);
  }
}
