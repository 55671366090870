import { connect } from "react-redux";
import { emitAction } from "../../../../utils/redux";
import { addFilter, removeFilter } from "../../../../actions/queryObject";
import {
  getArbitraryOptions,
  getAutocompleteSuggestions
} from "../../../../actions/arbitrary";
import { isSearchRoute, isDashboardRoute } from "../../../../utils/routing";
import { getFilters } from "../../../../modules/search";
import AdvancedSearch from "../AdvancedSearch";

function select(
  {
    application,
    advancedSearch,
    mapSearch,
    listings,
    staticOptions,
    queryObject
  },
  { location: { pathname } }
) {
  return {
    isOpen: advancedSearch.isOpen,
    isMapOpen: mapSearch.isOpen,
    isDashboard: isDashboardRoute(pathname),
    isSearchRoute: isSearchRoute(pathname),
    isArbitraryLoading: advancedSearch.isArbitraryLoading,
    hasFilters: getFilters({ queryObject, pathname }).length > 0,
    totalCount: listings.count,
    status: staticOptions.status,
    type: staticOptions.type,
    subType: staticOptions.subType,
    arbitraryFields: advancedSearch.arbitraryFields,
    currentArbitraryField: advancedSearch.currentArbitraryField,
    currentArbitraryOptions: advancedSearch.currentArbitraryOptions,
    queryObject,
    pathname
  };
}

const actions = {
  addFilter,
  removeFilter,
  getArbitraryOptions,
  getAutocompleteSuggestions,
  emitAction
};

export default connect(select, actions)(AdvancedSearch);
