import Overflow from "../Overflow";
import { connect } from "react-redux";
import { emitAction } from "../../../utils/redux";
import { getListingSlug } from "../../../actions/listing";
import {
  getShortUrl,
  isSearchRoute,
  isSharedRoute,
  isListingRoute,
  isCollectionRoute
} from "../../../utils/routing";
import {
  getAuthedResourceType,
  getSharedResourceType,
  getNewV2StreamUrl,
  getNewCmaSubjectUrl,
  getNewCmaCompsUrl,
  getNewTourUrl,
  getNewFlyerUrl,
  getNewPropertyUrl,
  getMailTo
} from "../../../modules/interProduct";

function select(
  {
    currentUser,
    application,
    mlsConfigs,
    sharedSearch,
    savedSearch,
    listing,
    listings,
    collection,
    queryObject,
    overflow
  },
  { handleDelete }
) {
  if (isSharedRoute(window.location.pathname)) {
    return {
      isPublic: true,
      isSharedRoute: true,
      openRight: !sharedSearch.detailId,
      resourceType: getSharedResourceType(sharedSearch),
      sharedSearch
    };
  } else {
    const pathname = window.location.pathname;
    const isSearchRte = isSearchRoute(pathname);
    const isListingRte = isListingRoute(pathname);
    const isCollectionRte = isCollectionRoute(pathname);

    const resourceType = getAuthedResourceType({
      isSearchRoute: isSearchRte,
      isListingRoute: isListingRte,
      isCollectionRoute: isCollectionRte
    });

    const resource = {
      Search: savedSearch,
      Listing: listing,
      Collection: collection
    }[resourceType];

    return {
      isPublic: application.isPublic,
      openRight: isSearchRte || isCollectionRte,
      newStreamUrl: getNewV2StreamUrl({
        statusMapping: mlsConfigs.statusMapping,
        ssoParams: currentUser.ssoParams,
        queryObject
      }),
      newListingCmaUrl: getNewCmaSubjectUrl({
        listing: listing,
        locale: mlsConfigs.locale,
        ssoParams: currentUser.ssoParams
      }),
      newSelectedCmaUrl: getNewCmaCompsUrl({
        mlsnums: listings.selectedMlsnums,
        listings: listings.data,
        locale: mlsConfigs.locale,
        ssoParams: currentUser.ssoParams
      }),
      newSelectedTourUrl: getNewTourUrl({
        mlsnums: listings.selectedMlsnums,
        ssoParams: currentUser.ssoParams
      }),
      newListingFlyerUrl: getNewFlyerUrl({
        mlsnums: listing.mlsnum,
        ssoParams: currentUser.ssoParams
      }),
      newSelectedFlyerUrl: getNewFlyerUrl({
        mlsnums: listings.selectedMlsnums,
        ssoParams: currentUser.ssoParams
      }),
      newListingPropertyUrl: getNewPropertyUrl({
        mlsnums: listing.mlsnum,
        ssoParams: currentUser.ssoParams
      }),
      newSelectedPropertyUrl: getNewPropertyUrl({
        mlsnums: listings.selectedMlsnums,
        ssoParams: currentUser.ssoParams
      }),
      mailTo: getMailTo({
        shortUrl: getShortUrl((resource || {}).slug),
        currentUser: currentUser,
        resourceType,
        resource
      }),
      shortUrl: getShortUrl((resource || {}).slug),
      selectedMlsnums: listings.selectedMlsnums,
      isSharedRoute: false,
      isListingRoute: isListingRte,
      isCollectionRoute: isCollectionRte,
      resourceId: resource.id,
      sharedSearch,
      resourceType,
      handleDelete
    };
  }
}

const actions = {
  getListingSlug,
  emitAction
};

export default connect(select, actions)(Overflow);
