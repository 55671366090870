import { invert, find, omit, parseInt } from "lodash";
import { initial as initialQueryObject } from "../reducers/queryObject";
import { buildListingsFilters, mlxToForgeFieldMap } from "./forge";

export function getCasFiltersFromQueryObject({ queryObject, statusMapping }) {
  return buildListingsFilters({ queryObject, statusMapping });
}

export function getQueryObjectFromCasFilters({
  filters,
  savedAreas,
  arbitraryFields
}) {
  return filters.reduce((state, filter) => {
    const mlxField = invert(mlxToForgeFieldMap)[filter.field];
    const filterValue = Object.values(omit(filter, "field"))[0];
    const isGroup = ["active_group", "non_active_group"].includes(filter.field);
    const isArbitrary = !mlxField;
    const isMin = !!filter.gte;
    const isMax = !!filter.lte;

    if (isGroup) {
      const status = find(filter.filter_group, (filter) => {
        return filter.field === "status";
      });

      const offMarketDays = find(filter.filter_group, (filter) => {
        return filter.field === "date_offmarket";
      });

      return {
        ...state,
        ...(status && {
          status: [...(state.status || []), ...(status.eq || [])]
        }),
        ...(offMarketDays && {
          offMarketDays: ((offMarketDays || {}).gte || "").split(" ")[0]
        })
      };
    }

    if (isArbitrary) {
      const arbitraryField = find(arbitraryFields, (arbitrary) => {
        return arbitrary.field === filter.field;
      });

      if (arbitraryField && (isMin || isMax)) {
        return {
          ...state,
          arbitrary: {
            ...state.arbitrary,
            [arbitraryField.id]: {
              ...arbitraryField,
              isRange: true,
              value: {
                ...(state.arbitrary[arbitraryField.id] || {}).value,
                ...(isMin && { min: filterValue }),
                ...(isMax && { max: filterValue })
              }
            }
          }
        };
      }

      if (arbitraryField) {
        return {
          ...state,
          arbitrary: {
            ...state.arbitrary,
            [arbitraryField.id]: {
              ...arbitraryField,
              value: filterValue
            }
          }
        };
      }

      return state;
    }

    if (["polygon", "proximity"].includes(mlxField)) {
      if (filter.circle) {
        const circle = JSON.parse(filter.circle)[0];
        return {
          ...state,
          proximity: {
            lat: circle[0],
            lon: circle[1],
            radius: parseInt((String(circle[2]) || "").replace("mi", ""))
          }
        };
      } else {
        const value = (filter.within || "").substr(1).slice(0, -1);
        const area = savedAreas.find((area) => area.value === value) || {};

        return {
          ...state,
          polygon: [
            ...(state.polygon || []),
            {
              field: "polygon",
              label: area.name || "Custom Area",
              value
            }
          ]
        };
      }
    }

    if (/(Min|Max)/.test(mlxField)) {
      const rawField = mlxField.substring(0, mlxField.length - 3);

      return {
        ...state,
        ...(isMin && { [`${rawField}Min`]: filterValue }),
        ...(isMax && { [`${rawField}Max`]: filterValue })
      };
    }

    if (mlxField === "offMarketDays") {
      return { ...state, offMarketDays: (filterValue || "").split(" ")[0] };
    }

    return { ...state, [mlxField]: filterValue };
  }, initialQueryObject);
}
