import React from "react";

function GarageIcon() {
  return (
    <svg viewBox="0 0 100 100" preserveAspectRatio="xMidYMid meet">
      <path d="M88.4444444,11.6111111 C87.3333333,8.33333333 84.2222222,6 80.5555556,6 L19.4444444,6 C15.7777778,6 12.7222222,8.33333333 11.5555556,11.6111111 L0,44.8888889 L0,89.3333333 C0,92.3888889 2.5,94.8888889 5.55555556,94.8888889 L11.1111111,94.8888889 C14.1666667,94.8888889 16.6666667,92.3888889 16.6666667,89.3333333 L16.6666667,83.7777778 L83.3333333,83.7777778 L83.3333333,89.3333333 C83.3333333,92.3888889 85.8333333,94.8888889 88.8888889,94.8888889 L94.4444444,94.8888889 C97.5,94.8888889 100,92.3888889 100,89.3333333 L100,44.8888889 L88.4444444,11.6111111 L88.4444444,11.6111111 Z M19.4444444,67.1111111 C14.8333333,67.1111111 11.1111111,63.3888889 11.1111111,58.7777778 C11.1111111,54.1666667 14.8333333,50.4444444 19.4444444,50.4444444 C24.0555556,50.4444444 27.7777778,54.1666667 27.7777778,58.7777778 C27.7777778,63.3888889 24.0555556,67.1111111 19.4444444,67.1111111 L19.4444444,67.1111111 Z M80.5555556,67.1111111 C75.9444444,67.1111111 72.2222222,63.3888889 72.2222222,58.7777778 C72.2222222,54.1666667 75.9444444,50.4444444 80.5555556,50.4444444 C85.1666667,50.4444444 88.8888889,54.1666667 88.8888889,58.7777778 C88.8888889,63.3888889 85.1666667,67.1111111 80.5555556,67.1111111 L80.5555556,67.1111111 Z M11.1111111,39.3333333 L19.4444444,14.3333333 L80.5555556,14.3333333 L88.8888889,39.3333333 L11.1111111,39.3333333 L11.1111111,39.3333333 Z"></path>
    </svg>
  );
}

export default GarageIcon;
