import styled from "styled-components";
import {
  twoColBkptWithSplit,
  oneColBkptWithSplit
} from "../../../utils/styling";

const itemSidePadding = 15;

export const ThreeColumnContainer = styled.div`
  margin: 0px -${itemSidePadding}px;
`;

export const ThreeColumnItem = styled.div`
  width: ${(1 / 3) * 100}%;
  padding: 0px ${itemSidePadding}px;
  margin-bottom: ${({ bottom }) => (bottom ? bottom : 0)}px;
  margin-top: ${({ top }) => (top ? top : 0)}px;
  display: inline-block;
  vertical-align: top;
  text-align: left;

  @media (max-width: ${({ isScreenSplit }) =>
      twoColBkptWithSplit(isScreenSplit)}px) {
    display: ${({ hideForTwoColumn }) =>
      hideForTwoColumn ? "none" : "inline-block"};
    width: 50%;
  }

  @media (max-width: ${({ isScreenSplit }) =>
      oneColBkptWithSplit(isScreenSplit)}px) {
    display: inline-block;
    width: 100%;
  }
`;
