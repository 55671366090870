import styled from "styled-components";
import { Card as BaseCard } from "@wrstudios/components";

export const Container = styled.div`
  height: 100%;
  position: relative;
`;

export const StyledCard = styled(BaseCard)`
  height: 21rem;
`;

export const Overlay = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 0.3rem;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
`;
