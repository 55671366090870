const initial = {
  id: "",
  slug: "",
  role: "",
  savedName: "",
  inputValue: "",
  isFocused: false,
  isCreating: false,
  isNaming: false,
  hasError: false,
  shouldTrack: false
};

export function savedSearch(state = initial, { type, payload }) {
  switch (type) {
    case "CREATE_ADHOC_SEARCH_INITIATED":
      return { ...initial, shouldTrack: state.shouldTrack, isCreating: true };
    case "CREATE_ADHOC_SEARCH_FAILED":
      return { ...state, hasError: true, isCreating: false };
    case "CREATE_ADHOC_SEARCH_SUCCEEDED":
      return {
        ...state,
        id: payload.id,
        slug: payload.slug,
        role: payload.role,
        isCreating: false
      };
    case "UPDATE_SAVED_SEARCH_INITIATED":
      return { ...initial, isNaming: payload.isNaming };
    case "UPDATE_SAVED_SEARCH_FAILED":
      return { ...state, hasError: true, isNaming: false };
    case "UPDATE_SAVED_SEARCH_SUCCEEDED":
      return {
        ...state,
        id: payload.id,
        slug: payload.slug,
        role: payload.role,
        savedName: payload.name || "",
        inputValue: payload.name || "",
        isNaming: false
      };
    case "GET_SAVED_SEARCH_SUCCEEDED":
      return {
        ...state,
        id: payload.id,
        slug: payload.slug,
        role: payload.role,
        savedName: payload.name || "",
        inputValue: payload.name || ""
      };
    case "SET_SAVED_SEARCH_INPUT_VALUE":
      return { ...state, inputValue: payload };
    case "SET_IS_SAVED_SEARCH_NAME_FOCUSED":
      return { ...state, isFocused: payload };
    case "SET_SHOULD_TRACK_SEARCH":
      return { ...state, shouldTrack: true };
    case "TRACK_EVENT_FAILED":
    case "TRACK_EVENT_SUCCEEDED":
      return { ...state, shouldTrack: false };
    case "GET_SAVED_SEARCH_INITIATED":
    case "GET_COLLECTION_INITIATED":
    case "RESET_AND_ADD_TO_QUERY_OBJECT":
    case "RESET_QUERY_OBJECT":
      return { ...initial, shouldTrack: state.shouldTrack };
    default:
      return state;
  }
}
