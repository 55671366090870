import { connect } from "react-redux";
import { getListingToRender } from "../../../../modules/listing";
import { setFullscreenMediaDetails } from "../../../../actions/listing";
import ListingSlider from "../ListingSlider";

function select({ listing, sharedSearch }, ownProps) {
  const listingToRender = getListingToRender({ listing, sharedSearch });

  return {
    mapboxApiToken: process.env.REACT_APP_MAPBOX_API_TOKEN,
    latitude: listingToRender.lat,
    longitude: listingToRender.lon,
    photos: listingToRender.imageUrls,
    virtualTour: listingToRender.virtualTour
  };
}

const actions = {
  setFullscreenMediaDetails
};

export default connect(select, actions)(ListingSlider);
