import { connect } from "react-redux";
import { emitAction } from "../../../../utils/redux";
import { addFilter } from "../../../../actions/queryObject";
import { getUgcStaticOptions } from "../../../../actions/session";
import { getSavedAreas, deleteSavedArea } from "../../../../actions/area";
import AreasRoute from "../AreasRoute";

function select({ application, savedAreas, pagination }, { location }) {
  return {
    pathname: location.pathname,
    savedAreas: savedAreas.order.map((id) => savedAreas.data[id]),
    hasError: savedAreas.hasDeleteError,
    isDeleting: savedAreas.isDeleting,
    isLoading: savedAreas.isLoading,
    isAppReady: application.isAppReady,
    count: savedAreas.count,
    page: pagination.searchesPage,
    limit: pagination.searchesLimit,
    sort: pagination.searchesSort
  };
}

const actions = {
  getSavedAreas,
  deleteSavedArea,
  getUgcStaticOptions,
  addFilter,
  emitAction
};

export default connect(select, actions)(AreasRoute);
