export const initial = {
  isOpen: false,
  isOpenViaUser: true,
  hoveredListingId: "",
  listingsForPins: {},
  pins: {}
};

export function mapSearch(state = initial, { type, payload }) {
  switch (type) {
    case "SET_IS_NOT_FOUND":
      return { ...state, isOpen: false };
    case "SET_IS_MAP_OPEN_VIA_SYSTEM":
      return { ...state, isOpen: payload };
    case "SET_IS_MAP_OPEN_VIA_USER":
      return { ...state, isOpen: payload, isOpenViaUser: payload };
    case "SET_HOVERED_LISTING_ID":
      return { ...state, hoveredListingId: payload };
    case "GET_LISTINGS_FOR_PIN_SUCCEEDED":
      return {
        ...state,
        listingsForPins: { ...state.listingsForPins, ...payload }
      };
    case "GET_SEARCH_PINS_SUCCEEDED":
      return { ...state, pins: { ...state.pins, ...payload } };
    case "GET_SEARCH_PINS_FAILED":
    case "RESET_SEARCH_PINS":
      return { ...state, pins: initial.pins };
    default:
      return state;
  }
}
