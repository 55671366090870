import { getProxiedImageUrl } from "@wrstudios/image-proxy";
import { compact, take, without } from "lodash";
import pluralize from "pluralize";
import { formatDelimiters } from "../utils/number";
import { formatTitle } from "../utils/string";

export function transformCollection({ search }) {
  const listings = search.listings.reduce(
    (state, { attributes }) => {
      return {
        mlsnums: [...state.mlsnums, attributes.mlsnum],
        listingIds: [...state.listingIds, attributes.id]
      };
    },
    { mlsnums: [], listingIds: [] }
  );

  return {
    id: `${search.id}`,
    slug: search.slug,
    name: search.name,
    mlsnums: compact(listings.mlsnums),
    listingIds: listings.listingIds,
    description: pluralize("Property", search.listings.length, true),
    lowerCase: (search.name || "").toLowerCase(),
    updatedAt: new Date(search.updated_at)
  };
}

export function transformCollections({ searches, photoProxyStrategy }) {
  return searches.reduce(
    (state, search) => {
      return {
        order: [...state.order, `${search.id}`],
        data: {
          ...state.data,
          [search.id]: {
            ...transformCollection({ search }),
            photos: take(search.listings, 3).map(({ attributes }) => {
              return getProxiedImageUrl({
                url: (attributes.photos || [])[0] || "",
                strategy: photoProxyStrategy
              });
            }),
            listings: search.listings.map(({ attributes }) => {
              return {
                mlsnum: attributes.mlsnum,
                zip: attributes.zipcode,
                city: attributes.city,
                state: attributes.state,
                address: formatTitle(attributes.address),
                beds: `${attributes.beds || ""}`,
                baths: `${attributes.baths || ""}`,
                sqft: `${formatDelimiters(attributes.sqft) || ""}`
              };
            })
          }
        }
      };
    },
    { order: [], data: {} }
  );
}

export function transformCollector(searches) {
  return searches.reduce(
    (state, search) => {
      return {
        order: [...state.order, `${search.id}`],
        data: {
          ...state.data,
          [search.id]: transformCollection({ search })
        }
      };
    },
    { order: [], data: {} }
  );
}

export function updateListingsInCollection({ collection, listingId }) {
  return {
    ...collection,
    listingIds: collection.listingIds.includes(listingId)
      ? without(collection.listingIds, listingId)
      : [...collection.listingIds, listingId]
  };
}

export function isCollectionNameUnique({ collection, collections }) {
  const names = Object.values(collections.data).map(({ name }) =>
    (name || "").toLowerCase()
  );
  return !names.includes((collection.name || "").toLowerCase());
}

export function getInCollectorCount({ listingId, collector }) {
  const collectorData = Object.values(collector.data);

  return collectorData.reduce((state, collection) => {
    return collection.listingIds.includes(listingId) ? state + 1 : state;
  }, 0);
}
