import styled from "styled-components";
import { defaultBorderRadius } from "../../../utils/styling";
import {
  blueMedium,
  greyMedium,
  greyLighter,
  greyLightest
} from "../../../utils/colorsLocal";

export const Container = styled.div`
  border-radius: ${defaultBorderRadius}px;
  border: 2px solid ${greyLighter};
  display: inline-block;
  vertical-align: top;
  position: relative;
  overflow: hidden;
`;

export const Button = styled.button`
  background-color: ${({ isSelected }) =>
    isSelected ? "white" : greyLightest};
  color: ${greyMedium};
  line-height: 30px;
  padding: 0px 12px;
  font-size: 14px;
  cursor: pointer;
  height: 32px;
  width: 42px;

  &:first-child {
    border-right: 1px solid ${greyLighter};
  }

  &:last-child {
    border-left: 1px solid ${greyLighter};
  }

  svg,
  path {
    fill: ${({ isSelected }) => (isSelected ? blueMedium : greyMedium)};
  }
`;
