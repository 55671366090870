import React from "react";
import PropTypes from "prop-types";
import { blueMedium } from "../../utils/colorsLocal";
import { Image } from "./styled/static-map";

function StaticMap({
  lat,
  lon,
  zoom,
  color,
  width,
  height,
  size,
  area,
  hasMarker
}) {
  const baseUrl = "https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/";
  const params = `?access_token=${process.env.REACT_APP_MAPBOX_API_TOKEN}&attribution=false&logo=false`;
  const marker = hasMarker
    ? `pin-${size}-circle+${color.replace("#", "")}(${lon},${lat})/`
    : "";
  const urlForPoint = `${baseUrl}${marker}${lon},${lat},${zoom},0/${width}x${height}@2x${params}`;
  const urlForArea = `${baseUrl}geojson(${getAreaGeoJson(
    area
  )})/auto/${width}x${height}@2x${params}`;
  const src = area.length > 0 ? urlForArea : urlForPoint;

  return <Image src={src} width={width} alt="Mapbox Map" />;
}

function getAreaGeoJson(area) {
  const geoJson = JSON.stringify({
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [area]
        },
        properties: {
          "stroke-width": 2,
          "fill-opacity": 0.5,
          fill: blueMedium
        }
      }
    ]
  });

  return window.encodeURIComponent(geoJson);
}

StaticMap.defaultProps = {
  color: blueMedium,
  zoom: 14,
  width: 600,
  height: 300,
  size: "s",
  area: []
};

StaticMap.propTypes = {
  lat: PropTypes.number,
  lon: PropTypes.number,
  zoom: PropTypes.number,
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  hasMarker: PropTypes.bool,
  size: PropTypes.oneOf(["s", "m", "l"]),
  area: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number))
};

export default StaticMap;
