import { connect } from "react-redux";
import { ListingShowings } from "@wrstudios/components";

function select({ listing }) {
  return {
    showings: listing.showing,
    agentUrl: `/agents/${listing.agentId}`,
    agentName: listing.agentName,
    agentEmail: listing.agentEmail,
    agentPhone: listing.agentPhone,
    agentInitials: listing.agentInitials,
    agentInitialsColor: listing.agentColor,
    privateRemarks: listing.privateRemarks,
    officeName: listing.agentOffice
  };
}

const actions = {};

export default connect(select, actions)(ListingShowings);
