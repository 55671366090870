import styled from "styled-components";
import { absoluteFill, mobileBreakpoint } from "../../../../utils/styling";

export const Container = styled.div`
  ${absoluteFill};
`;

export const Title = styled.div`
  position: relative;
  font-weight: 600;
  transform: translateX(-50%);
  text-align: center;
  margin-bottom: 30px;
  margin-top: 100px;
  font-size: 48px;
  color: #233040;
  width: 400px;
  left: 50%;

  @media (max-width: ${mobileBreakpoint}px) {
    margin-bottom: 20px;
    margin-top: 60px;
    font-size: 34px;
    width: 300px;
  }
`;

export const Description = styled.div`
  position: relative;
  font-weight: 400;
  transform: translateX(-50%);
  text-align: center;
  margin-top: 120px;
  font-size: 12px;
  color: #576474;
  width: 600px;
  left: 50%;

  @media (max-width: ${mobileBreakpoint}px) {
    margin-top: 80px;
    width: 300px;
  }
`;

export const IllustrationLayout = styled.div`
  position: relative;
  transform: translateX(-50%);
  text-align: center;
  margin-top: 120px;
  font-size: 12px;
  color: #576474;
  width: 1432px;
  left: 50%;

  @media (max-width: ${mobileBreakpoint}px) {
    margin-top: 80px;
  }
`;
