import React from "react";

function ChevronLeftIcon() {
  return (
    <svg viewBox="0 0 100 100" preserveAspectRatio="xMidYMid meet">
      <path d="M18,50.0605398 L64.2297979,3.76932046 C68.1325165,-0.138445103 74.464165,-0.142536448 78.3719306,3.76018219 C78.3722036,3.76045482 78.3724765,3.76072748 78.3727495,3.76100014 L78.9255651,4.32130241 C82.831621,8.22321678 82.836419,14.5523821 78.9362835,18.4602142 L47.4650496,50 L78.9729335,81.3826852 C82.864609,85.2588968 82.901397,91.5484402 79.0553307,95.4699105 L78.3690097,96.1696872 C74.5018595,100.112655 68.170508,100.174117 64.2275404,96.3069667 C64.2039351,96.2838153 64.1804448,96.2605469 64.1570703,96.2371625 L18,50.0605398 Z"></path>
    </svg>
  );
}

export default ChevronLeftIcon;
