import { defaultCheatSheetData } from "../modules/cheatSheet";

export const initial = {
  isOpen: false,
  data: defaultCheatSheetData
};

export function cheatSheet(state = initial, { type, payload }) {
  switch (type) {
    case "GET_OPTIONS_FOR_TYPE_SUCCEEDED": {
      const options = payload.options || [];
      if (options.length < 2) {
        return state;
      }

      return {
        ...state,
        data: {
          ...state.data,
          type: { ...state.data.type, value: ["Residential", options[0].label] }
        }
      };
    }
    case "GET_OPTIONS_FOR_STATUS_SUCCEEDED": {
      const options = payload.options || [];
      if (options.length < 2) {
        return state;
      }

      return {
        ...state,
        data: {
          ...state.data,
          status: {
            ...state.data.status,
            value: ["Active", options[options.length - 1].label]
          }
        }
      };
    }
    case "GET_OPTIONS_FOR_CITY_SUCCEEDED": {
      const options = payload.options || [];
      if (options.length < 2) {
        return state;
      }

      return {
        ...state,
        data: {
          ...state.data,
          locations: {
            ...state.data.locations,
            value: [options[0].label, state.data.locations.value[1]]
          }
        }
      };
    }
    case "GET_OPTIONS_FOR_ZIP_SUCCEEDED": {
      const options = payload.options || [];
      if (options.length < 2) {
        return state;
      }

      return {
        ...state,
        data: {
          ...state.data,
          locations: {
            ...state.data.locations,
            value: [
              state.data.locations.value[0],
              options[Math.round(options.length / 2)].label
            ]
          }
        }
      };
    }
    case "SET_IS_CHEAT_SHEET_OPEN":
      return { ...state, isOpen: payload };
    default:
      return state;
  }
}
