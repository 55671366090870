import React from "react";
import PropTypes from "prop-types";
import { formatDelimiters } from "../../utils/number";
import { Container } from "./styled/results-count";

function ResultsCount({
  isLoading,
  selectedCount,
  listingsLength,
  totalCount,
  limit
}) {
  return (
    <Container>
      {innerContent({
        isLoading,
        selectedCount,
        listingsLength,
        totalCount,
        limit
      })}
    </Container>
  );
}

function innerContent({
  isLoading,
  selectedCount,
  listingsLength,
  totalCount,
  limit
}) {
  if (isLoading) {
    return "0 Results";
  }
  if (totalCount === 0) {
    return "No results found";
  }
  if (selectedCount > 0) {
    return `${selectedCount} of ${formatDelimiters(totalCount)} Selected`;
  }
  if (totalCount < limit && listingsLength < totalCount) {
    return `${formatDelimiters(listingsLength)} Results`;
  }
  return `${formatDelimiters(totalCount)} Results`;
}

ResultsCount.propTypes = {
  isLoading: PropTypes.bool,
  selectedCount: PropTypes.number,
  listingsLength: PropTypes.number,
  totalCount: PropTypes.number,
  limit: PropTypes.number
};

export default ResultsCount;
