import { connect } from "react-redux";
import { getListingToRender } from "../../../../modules/listing";
import { ListingDescription } from "@wrstudios/components";

function select({ listing, sharedSearch }) {
  const listingToRender = getListingToRender({ listing, sharedSearch });

  return {
    description: listingToRender.comments
  };
}

const actions = {};

export default connect(select, actions)(ListingDescription);
