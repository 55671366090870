import React from "react";
import Illustration from "./Illustration";
import SearchBar from "../../search/text/connected/SearchBar";
import {
  Container,
  Title,
  Description,
  IllustrationLayout
} from "./styled/dashboard-route";

function DashboardRoute() {
  return (
    <Container>
      <Title>Search Your MLS</Title>
      <SearchBar />
      <Description>
        Cloud MLX is the easy way to search your MLS. You can type just about
        anything. Not sure? Try "help".
      </Description>
      <IllustrationLayout>
        <Illustration />
      </IllustrationLayout>
    </Container>
  );
}

export default DashboardRoute;
