const initial = {
  isLoading: false,
  isLoadingSlug: false,
  isCollectorOpen: false,
  isFullscreenMediaOpen: false,
  fullscreenMediaTab: "map",
  fullscreenMediaPhotoIndex: 0,
  showingTimeUrl: "",
  shouldTrack: false
};

export function listing(state = initial, { type, payload }) {
  switch (type) {
    case "GET_LISTING_INITIATED":
      return { ...initial, shouldTrack: state.shouldTrack, isLoading: true };
    case "GET_LISTING_FAILED":
      return { ...initial, isLoading: false };
    case "GET_LISTING_SUCCEEDED":
      return { ...state, ...payload, isLoading: false };
    case "GET_LISTING_SLUG_INITIATED":
      return { ...state, isLoadingSlug: true };
    case "GET_LISTING_SLUG_FAILED":
      return { ...state, isLoadingSlug: false };
    case "GET_LISTING_SLUG_SUCCEEDED":
      return { ...state, slug: payload, isLoadingSlug: false };
    case "GET_SHOWING_TIME_SUCCEEDED":
      return { ...state, showingTimeUrl: payload };
    case "SET_IS_LISTING_COLLECTOR_OPEN":
      return { ...state, isCollectorOpen: payload };
    case "SET_LISTING_FULLSCREEN_MEDIA_DETAILS":
      return {
        ...state,
        isFullscreenMediaOpen: payload.open,
        fullscreenMediaTab: payload.tab,
        fullscreenMediaPhotoIndex: payload.photoIndex
      };
    case "SET_SHOULD_TRACK_LISTING":
      return { ...state, shouldTrack: true };
    case "TRACK_EVENT_FAILED":
    case "TRACK_EVENT_SUCCEEDED":
      return { ...state, shouldTrack: false };
    default:
      return state;
  }
}
