import React from "react";
import PropTypes from "prop-types";
import { Link as ReactRouterLink } from "react-router-dom";

function Link({ to, children }) {
  return <ReactRouterLink to={to}>{children}</ReactRouterLink>;
}

Link.propTypes = {
  to: PropTypes.string,
  children: PropTypes.any
};

export default Link;
