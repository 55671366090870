import { connect } from "react-redux";
import { emitAction } from "../../../../utils/redux";
import { getUgcStaticOptions } from "../../../../actions/session";
import { deleteSavedSearch } from "../../../../actions/search";
import { getSavedSearches } from "../../../../actions/search";
import SearchesRoute from "../SearchesRoute";

function select({
  application,
  currentUser,
  savedSearches,
  mlsConfigs,
  pagination
}) {
  return {
    ssoParams: currentUser.ssoParams,
    statusMapping: mlsConfigs.statusMapping,
    searches: savedSearches.order.map((id) => savedSearches.data[id]),
    hasError: savedSearches.hasError,
    isLoading: savedSearches.isLoading,
    isDeleting: savedSearches.isDeleting,
    isAppReady: application.isAppReady,
    count: savedSearches.count,
    page: pagination.searchesPage,
    limit: pagination.searchesLimit,
    sort: pagination.searchesSort
  };
}

const actions = {
  getSavedSearches,
  deleteSavedSearch,
  getUgcStaticOptions,
  emitAction
};

export default connect(select, actions)(SearchesRoute);
