import { connect } from "react-redux";
import { emitAction } from "../../../utils/redux";
import { getFirstName } from "../../../utils/string";
import { isListingRoute, isPseudoListingRoute } from "../../../utils/routing";
import PublicHeader from "../PublicHeader";

function select(
  { application, sharedSearch, sharingAgent, mapSearch },
  { location }
) {
  const isPseudoListing = isPseudoListingRoute(sharedSearch);
  const isListing = isListingRoute(location.pathname);
  const isSingleton = sharedSearch.role === "singleton";

  return {
    isAuthed: !!sharingAgent.jwt,
    isMapOpen: mapSearch.isOpen,
    isNotFound: application.isNotFound,
    showMap: !isPseudoListing && !isListing && !isSingleton,
    showBack: isPseudoListing,
    hasPhoneNumber: !!sharingAgent.phone,
    firstName: sharingAgent.name ? getFirstName(sharingAgent.name) : "",
    initials: sharingAgent.initials,
    avatar: sharingAgent.avatarUrl,
    name: sharingAgent.name,
    email: sharingAgent.email,
    phone: sharingAgent.phone,
    company: sharingAgent.office,
    city: sharingAgent.city,
    state: sharingAgent.state,
    hasCompleteAddress: false
  };
}

const actions = {
  emitAction
};

export default connect(select, actions)(PublicHeader);
