import React from "react";
import PropTypes from "prop-types";
import CheckmarkIcon from "../icons/CheckmarkIcon";
import { Container } from "./styled/checkbox";

function Checkbox({ onClick, isChecked, isTransparent }) {
  return (
    <Container
      onClick={onClick}
      isChecked={isChecked}
      isTransparent={isTransparent}>
      <CheckmarkIcon />
    </Container>
  );
}

Checkbox.propTypes = {
  isChecked: PropTypes.bool,
  isTransparent: PropTypes.bool,
  onClick: PropTypes.func
};

export default Checkbox;
