import React from "react";

function TypeIcon() {
  return (
    <svg viewBox="0 0 100 100" preserveAspectRatio="xMidYMid meet">
      <polygon points="40 92 40 62 60 62 60 92 85 92 85 52 100 52 50 7 0 52 15 52 15 92"></polygon>
    </svg>
  );
}

export default TypeIcon;
