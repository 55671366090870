import styled, { css } from "styled-components";
import {
  blueMedium,
  greyLighter,
  greyMedium
} from "../../../utils/colorsLocal";
import {
  defaultBorderRadius,
  mobileBreakpoint,
  resetPositionalContext
} from "../../../utils/styling";

export const outerButtonWidthDesktop = 109;
export const outerButtonWidthMobile = 39;

export const appSwitcherWidthDesktop = 36;
export const appSwitcherWidthMobile = 30;

const outerButton = css`
  position: absolute;
  width: ${outerButtonWidthDesktop}px;
  text-align: center;
  bottom: 0px;
  top: 0px;

  @media (max-width: ${mobileBreakpoint}px) {
    width: ${outerButtonWidthMobile}px;
  }
`;

export const NavButton = styled.div`
  ${outerButton};
  border-left-width: 1px;
  border-left-style: solid;
  border-color: ${({ isOmniFocused }) =>
    isOmniFocused ? greyLighter : "transparent"};
  padding-right: 19px;
  padding-left: 19px;
  padding-top: 14px;
  right: 0px;

  @media (max-width: ${mobileBreakpoint}px) {
    display: ${({ isOmniFocused }) => (isOmniFocused ? "none" : "block")};
    padding-top: 9px;
    padding-right: 9px;
    padding-left: 9px;
  }

  div {
    cursor: pointer;
  }
`;

export const Center = styled.div`
  position: absolute;
  ${resetPositionalContext};
  opacity: ${({ isSearchable }) => (isSearchable ? 1 : 0)};
  left: ${outerButtonWidthDesktop}px;
  right: 0;
  border: 1px solid transparent;
  bottom: 0px;
  top: 0px;
  @media (max-width: ${mobileBreakpoint}px) {
    border-color: ${({ isOmniFocused, hasFilters, isMapOpen }) =>
      isOmniFocused
        ? "transparent"
        : (!isOmniFocused && hasFilters) || isMapOpen
        ? greyLighter
        : "transparent"};
    left: ${({ isOmniFocused }) =>
      isOmniFocused ? 0 : outerButtonWidthMobile}px;
    right: 0.8rem;
    bottom: ${({ isOmniFocused }) => (isOmniFocused ? 0 : 8)}px;
    top: ${({ isOmniFocused }) => (isOmniFocused ? 0 : 8)}px;
    border-radius: ${defaultBorderRadius}px;
  }
`;

const innerButtonWidthDesktop = 36;
const innerButtonWidthMobile = 24;
const innerButtonRightOffset = 10;
const blurButtonWidth = 60;

export const OmniLayout = styled.div`
  position: absolute;
  right: ${innerButtonWidthDesktop * 2 + innerButtonRightOffset}px;
  bottom: 0px;
  left: 0px;
  top: 0px;

  @media (max-width: ${mobileBreakpoint}px) {
    right: ${({ isOmniFocused }) =>
      isOmniFocused
        ? blurButtonWidth
        : innerButtonWidthMobile + innerButtonRightOffset}px;
  }
`;

const innerButton = css`
  position: absolute;
  width: ${innerButtonWidthDesktop}px;
  padding: 21px 9px 0px;
  cursor: pointer;
  bottom: 0px;
  top: 0px;

  @media (max-width: ${mobileBreakpoint}px) {
    width: ${innerButtonWidthMobile}px;
    padding: 7px 3px 0px;
  }
`;

export const AdvancedButton = styled.div`
  ${innerButton};
  right: ${innerButtonWidthDesktop + innerButtonRightOffset}px;

  @media (max-width: ${mobileBreakpoint}px) {
    display: none;
  }

  svg {
    fill: ${({ isAdvancedOpen }) => (isAdvancedOpen ? blueMedium : greyMedium)};
  }
`;

export const MapButton = styled.div`
  ${innerButton};
  right: ${innerButtonRightOffset}px;

  @media (max-width: ${mobileBreakpoint}px) {
    display: ${({ isOmniFocused }) => (isOmniFocused ? "none" : "block")};
  }

  svg {
    fill: ${({ isMapOpen }) => (isMapOpen ? blueMedium : greyMedium)};

    @media (max-width: ${mobileBreakpoint}px) {
      fill: ${({ isMapOpen, isOmniFocused, hasFilters }) =>
        isMapOpen
          ? blueMedium
          : !isOmniFocused && !hasFilters
          ? greyMedium
          : greyMedium};
    }
  }
`;

export const MobileBlurButton = styled.div`
  display: none;
  position: absolute;
  color: ${greyMedium};
  width: ${blurButtonWidth}px;
  text-align: center;
  padding-top: 13px;
  font-size: 14px;
  cursor: pointer;
  bottom: 0px;
  right: 0px;
  top: 0px;

  @media (max-width: ${mobileBreakpoint}px) {
    display: ${({ isOmniFocused }) => (isOmniFocused ? "block" : "none")};
  }
`;
