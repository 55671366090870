import React from "react";

function AdvancedIcon() {
  return (
    <svg viewBox="0 0 100 100" preserveAspectRatio="xMidYMid meet">
      <path d="M0,77.7777778 L0,88.8888889 L33.3333333,88.8888889 L33.3333333,77.7777778 L0,77.7777778 L0,77.7777778 Z M0,11.1111111 L0,22.2222222 L55.5555556,22.2222222 L55.5555556,11.1111111 L0,11.1111111 L0,11.1111111 Z M55.5555556,100 L55.5555556,88.8888889 L100,88.8888889 L100,77.7777778 L55.5555556,77.7777778 L55.5555556,66.6666667 L44.4444444,66.6666667 L44.4444444,100 L55.5555556,100 L55.5555556,100 Z M22.2222222,33.3333333 L22.2222222,44.4444444 L0,44.4444444 L0,55.5555556 L22.2222222,55.5555556 L22.2222222,66.6666667 L33.3333333,66.6666667 L33.3333333,33.3333333 L22.2222222,33.3333333 L22.2222222,33.3333333 Z M100,55.5555556 L100,44.4444444 L44.4444444,44.4444444 L44.4444444,55.5555556 L100,55.5555556 L100,55.5555556 Z M66.6666667,33.3333333 L77.7777778,33.3333333 L77.7777778,22.2222222 L100,22.2222222 L100,11.1111111 L77.7777778,11.1111111 L77.7777778,0 L66.6666667,0 L66.6666667,33.3333333 L66.6666667,33.3333333 Z"></path>
    </svg>
  );
}

export default AdvancedIcon;
