import { createGlobalStyle } from "styled-components";
import { blueMedium, blueVeryDark, greyDark } from "../../utils/colorsLocal";

export default createGlobalStyle`
  html {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-overflow-scrolling: touch;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    font-variant-east-asian: normal;
    font-variant-ligatures: normal;
    font-variant-numeric: normal;
    font-variant-caps: normal;
    font-stretch: normal;
    font-style: normal;
    font-weight: 400;
    font-size: 62.5%;
    cursor: default;
  }

  html, body, #root {
    height: 100%;
    margin: 0;
  }

  *, :after, :before {
    box-sizing: inherit;
  }

  body, input, select, button, textarea  {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Open Sans", "Ubuntu", "Droid Sans", "Helvetica Neue", sans-serif;
    font-size: 1.6rem;
    color: ${greyDark};
  }

  a {
    transition: color 100ms;
    background-color: transparent;
    color: ${blueMedium};
    text-decoration: none;

    &:hover {
      color: ${blueVeryDark};
      text-decoration-line: none;
    }
  }

  input {
    background-color: transparent;
    -webkit-appearance: none;
    padding: 0;
    margin: 0;
    border: 0;

    :focus {
      outline: 0;
    }
  }

  select, button {
    background-color: transparent;
    text-transform: none;
    vertical-align: top;
    appearance: none;
    border: none;
    padding: 0;
    margin: 0;

    :focus {
      outline: 0;
    }
  }

  div, span {
    :focus {
      outline: 0;
    }
  }

  svg {
    margin: 0 auto;
    display: block;
  }

  path {
    fill-rule: nonzero;
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
  }

  body div.intercom-app {
    display: none;
  }

  body.allow-intercom div.intercom-app {
    display: block;
  }
`;
