import { random } from "lodash";

export const red = "#EC5252";
export const teal = "#00C9C7";
export const gold = "#BAAD81";
export const green = "#00CA72";
export const yellow = "#F6A807";
export const purple = "#9B52EC";
export const magenta = "#E352EC";

export const blueLight = "#5494d8";
export const bluePowder = "#ECF3FC";
export const blueMedium = "#2684E8";
export const blueDeep = "#5265EC";
export const blueDark = "#3A7ABE";
export const blueVeryDark = "#23527C";

export const greyDark = "#233040";
export const greyMedium = "#566373";
export const greyMidLight = "#929498";
export const greyLight = "#C5C9D1";
export const greyLighter = "#E1E5E9";
export const greyLightest = "#FAFAFA";

export const pastelYellow = "#FDEECD";
export const pastelGreen = "#CCF4E3";
export const pastelBlue = "#D8E4F2";
export const pastelRed = "#FBDCDC";

export const streamsBlue = "#0AA1DC";
export const cmaGreen = "#00AB6B";

export function randomColor() {
  const colors = { red, teal, green, yellow, purple, magenta, blueMedium };
  const keys = [
    "red",
    "teal",
    "green",
    "yellow",
    "purple",
    "magenta",
    "blueMedium"
  ];
  return colors[keys[random(0, 6)]];
}

export function iconColor(name) {
  return {
    agent: red,
    address: blueMedium,
    arbitrary: gold,
    area: blueMedium,
    polygon: blueMedium,
    proximity: blueMedium,
    alert: pastelYellow,
    bathMin: teal,
    bathRange: teal,
    bedMin: teal,
    bedRange: teal,
    cheatSheet: blueMedium,
    city: blueMedium,
    collection: blueMedium,
    error: pastelRed,
    garageMin: blueDeep,
    garageRange: blueDeep,
    info: pastelBlue,
    lotMin: yellow,
    lotRange: yellow,
    mlsnum: magenta,
    offMarketDays: magenta,
    photos: blueMedium,
    price: green,
    priceMin: green,
    priceRange: green,
    search: teal,
    status: magenta,
    storyMin: blueDeep,
    storyRange: blueDeep,
    subType: red,
    success: pastelGreen,
    sqftMin: yellow,
    sqftRange: yellow,
    type: purple,
    zip: blueMedium
  }[name];
}

export function statusColor(status) {
  switch (status) {
    case "sold":
    case "closed":
      return red;
    case "active":
      return green;
    case "backup":
      return teal;
    case "pending":
      return yellow;
    default:
      return greyLight;
  }
}
