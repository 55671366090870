import styled from "styled-components";
import { greyDark, greyLight } from "../../../../utils/colorsLocal";

export const Container = styled.div`
  width: 100%;
  padding-top: 22.5pt;
  margin: 0 auto;
  background-color: white;
  font-family: Helvetica;
  font-weight: 400;

  a {
    color: black;
  }

  .leaflet-control-container {
    display: none;
  }

  @media print {
    html,
    body {
      height: auto;
    }
    body {
      font-family: "Helvetica", sans-serif;
      color: black;
    }
    a {
      text-decoration: none;
      color: black;
    }
    a img {
      border: none;
    }
    a[href]:after {
      content: none !important;
    }
  }
`;

export const Page = styled.div`
  position: relative;
  margin: auto;
  width: 567pt;

  @media screen {
    height: 11in;
  }

  @media print {
    page-break-after: always;

    @page {
      size: 8.5in 11in;
    }
  }
`;

export const Header = styled.section`
  display: flex;
  flex-direction: row;
  flex-wrap: initial;
  font-size: 7pt;
  line-height: 9.6pt;
  padding-bottom: 2.3pt;
  a {
    color: black;
  }
`;

export const BulletPoint = styled.span`
  &::before {
    content: "•";
    color: black;
    margin: 0 6px;
  }
`;

export const ThickDivider = styled.span`
  display: block;
  border-top: 3px solid black;
  width: 100%;
  background-color: black;
  &::before {
    content: "";
  }
`;

export const ThinDivider = styled.span`
  display: block;
  border-top: 1px solid black;
  width: 100%;
  background-color: black;
  &::before {
    content: "";
  }

  @media print {
    page-break-before: auto;
  }
`;

export const Timestamp = styled.span`
  margin-left: auto;
`;

export const PropertyOverview = styled.div`
  display: flex;
  align-items: flex-end;
  padding-top: 20.5pt;
  > div {
    width: 276.75pt;
  }
`;

export const Address = styled.div`
  margin-right: 13.5pt;

  h1 {
    color: ${greyDark};
    font-family: inherit;
    font-size: 2.4rem;
    font-size: 18px;
    font-weight: 700;
    line-height: 3.13333rem;
    line-height: 24px;
    margin: 0;
  }

  span {
    display: block;
    color: #000;
    font-size: 8pt;
    font-weight: 400;
    line-height: 11pt;
    margin: 0;
  }
`;

export const MarketInfo = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: initial;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  color: #000;
  font-size: 8pt;
  font-weight: 400;
  line-height: 11pt;
  margin: 0;

  div {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    h2 {
      color: #233040;
      font-family: inherit;
      font-size: 16px;
      font-weight: 700;
      line-height: 22px;
      margin: 0;
    }
    span {
      text-transform: uppercase;
      width: auto;
    }
  }
`;

export const PhotoAndMap = styled.div`
  display: flex;
  padding: 24px 0 32px;
  > img,
  div {
    width: 370px;
    height: 215px;
    margin: 0;
  }
  > img {
    margin-right: 15px;
    object-fit: cover;
  }

  div {
    position: relative;
  }
`;

export const Description = styled.div`
  display: flex;
  padding: 12px 0 20px;
  font-size: 8pt;
  line-height: 11pt;

  span:first-of-type {
    display: block;
    min-width: 100pt;
    margin-right: 13.5pt;
    font-weight: 700;
  }
`;

export const Features = styled.div`
  display: flex;
  margin: 0;
  padding: 24px 0;
  font-size: 8pt;
  line-height: 11pt;

  div {
    width: 170pt;
    margin-right: 30pt;
    &:last-of-type {
      margin-right: 0;
    }

    div {
      position: relative;
      padding-left: 10px;
      box-sizing: border-box;

      &::before {
        content: "•";
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
`;

export const PrivateRemarks = styled.div`
  padding: 24px 0 0;
  font-size: 8pt;
  line-height: 11pt;
  div {
    display: flex;
    strong {
      display: block;
      min-width: 100pt;
      margin-right: 13.5pt;
    }
  }

  @media print {
    page-break-before: auto;
  }
`;

export const PageBreak = styled.div`
  width: 100%;
  border-top: 1px dashed ${greyLight};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-wrap: initial;
  margin: 60px 0 40px;
  &::after {
    content: "Page Break";
    align-self: center;
    width: 80px;
    color: ${greyLight};
    font-size: 12px;
    font-style: italic;
    margin-top: -8px;
    background-color: white;
    text-align: center;
  }

  @media print {
    display: none;
  }
`;
