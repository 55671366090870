import React, { Component } from "react";
import PropTypes from "prop-types";
import Checkbox from "../common/Checkbox";
import { ListingCard as BaseListingCard } from "@wrstudios/components";
import {
  Container,
  CheckboxLayout,
  AgentAvatar,
  AgentPopup,
  AgentInfo,
  AgentInitials,
  AgentDetails,
  AgentName,
  AgentOffice,
  Contact
} from "./styled/listing-card";

class ListingCard extends Component {
  state = { showAgent: false };

  render() {
    const {
      listing,
      isPublic,
      isSharedRoute,
      isNonDisclosure,
      parentPath,
      hasControls,
      selectedMlsnums,
      handleClickCheckBox
    } = this.props;
    if (parentPath.includes("loading")) return null;

    const pubPrice = isPublic && isNonDisclosure;
    const url = `${parentPath}/listings/${listing.id}`;
    const isChecked = selectedMlsnums.includes(listing.mlsnum);

    return (
      <Container isChecked={isChecked}>
        <BaseListingCard
          listingId={listing.id}
          url={isSharedRoute ? "" : url}
          photos={listing.imageUrls}
          address={listing.address}
          city={listing.city}
          state={listing.state}
          zip={listing.zip}
          built={listing.built}
          statusLabel={listing.statusLabel}
          statusValue={listing.statusValue}
          acresFormatted={listing.acresFormatted}
          priceFormatted={pubPrice ? listing.pricePublic : listing.price}
          bedsFormatted={listing.bedsFormatted}
          bathsFormatted={listing.bathsFormatted}
          sqftFormatted={listing.sqftFormatted}
          updatedAtAgo={listing.dom ? `${listing.dom} DOM` : ""}
          handleMouseEnter={this.handleMouseEnter}
          handleMouseLeave={this.handleMouseLeave}
          handleClick={this.handleClick}
        />
        {hasControls && !isPublic && (
          <AgentAvatar
            bgColor={listing.agentColor}
            onMouseEnter={this.handleShowAgent}>
            {listing.agentInitials}
          </AgentAvatar>
        )}
        <AgentPopup
          showAgent={this.state.showAgent}
          onMouseLeave={this.handleHideAgent}>
          <AgentInfo>
            <AgentInitials bgColor={listing.agentColor}>
              {listing.agentInitials}
            </AgentInitials>
            <AgentDetails>
              <AgentName>{listing.agentName}</AgentName>
              <AgentOffice>{listing.agentOffice}</AgentOffice>
              <Contact>
                <a href={`mailto:${listing.agentEmail}`}>Email</a>
                <span>&nbsp; • &nbsp;</span>
                <a href={`tel:${listing.agentPhone}`}>Call</a>
              </Contact>
            </AgentDetails>
          </AgentInfo>
        </AgentPopup>
        {!isPublic && (
          <CheckboxLayout
            id="checkbox"
            onClick={() => handleClickCheckBox(listing.mlsnum)}>
            <Checkbox isChecked={isChecked} isTransparent={true} />
          </CheckboxLayout>
        )}
      </Container>
    );
  }

  handleMouseEnter = () => {
    this.props.handleHoverListing(this.props.listing.id);
  };

  handleMouseLeave = () => {
    this.props.handleHoverListing("");
  };

  handleClick = () => {
    this.props.handleClickListing(this.props.listing.id);
  };

  handleShowAgent = () => {
    this.setState({ showAgent: true });
  };

  handleHideAgent = () => {
    this.setState({ showAgent: false });
  };
}

ListingCard.defaultProps = {
  handleHoverListing: () => {},
  handleClickListing: () => {},
  handleClickCheckBox: () => {}
};

ListingCard.propTypes = {
  isPublic: PropTypes.bool,
  hasControls: PropTypes.bool,
  isSharedRoute: PropTypes.bool,
  isNonDisclosure: PropTypes.bool,
  selectedMlsnums: PropTypes.array,
  parentPath: PropTypes.string,
  listing: PropTypes.shape({
    id: PropTypes.string,
    mlsnum: PropTypes.string,
    dom: PropTypes.string,
    zip: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    price: PropTypes.string,
    pricePublic: PropTypes.string,
    address: PropTypes.string,
    imageUrls: PropTypes.array,
    statusLabel: PropTypes.string,
    statusValue: PropTypes.string,
    agentName: PropTypes.string,
    agentEmail: PropTypes.string,
    agentPhone: PropTypes.string,
    agentOffice: PropTypes.string,
    agentInitials: PropTypes.string,
    agentColor: PropTypes.string
  }),
  handleClickCheckBox: PropTypes.func,
  handleHoverListing: PropTypes.func,
  handleClickListing: PropTypes.func
};

export default ListingCard;
