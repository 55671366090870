const initial = {
  id: "",
  name: "",
  slug: "",
  listingIds: [],
  shouldTrack: false
};

export function collection(state = initial, { type, payload }) {
  switch (type) {
    case "GET_COLLECTION_SUCCEEDED":
      return { ...state, ...payload };
    case "SET_SHOULD_TRACK_COLLECTION":
      return { ...state, shouldTrack: true };
    case "TRACK_EVENT_FAILED":
    case "TRACK_EVENT_SUCCEEDED":
      return { ...state, shouldTrack: false };
    default:
      return state;
  }
}
