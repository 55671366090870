import numeral from "numeral";
import { startCase, join, compact } from "lodash";

export function hashString(string) {
  let hash = 5381,
    i = string.length;
  while (i) {
    hash = (hash * 33) ^ string.charCodeAt(--i);
  }
  return hash >>> 0;
}

export function getFirstNumber(string = "") {
  return string.match(/\d+/)[0];
}

export function getNumberRange(string = "") {
  const sorted = string.match(/\d+/g).sort((a, b) => a - b);
  return { min: sorted[0], max: sorted[1] };
}

export function getFirstShorthandNumber(string = "") {
  return shorthandToFullNumber(string.match(/\d+(\.\d+)*[km]?/)[0]);
}

export function getShorthandNumberRange(string = "") {
  const numbers = string.match(/\d+(\.\d+)*[km]?/g);
  const sorted = [
    shorthandToFullNumber(numbers[0]),
    shorthandToFullNumber(numbers[1] || "0")
  ].sort((a, b) => a - b);
  return { min: sorted[0], max: sorted[1] };
}

export function shorthandToFullNumber(string = "") {
  if (!string.includes(".")) {
    return string.replace("m", "000000").replace("k", "000");
  }
  const decimalPlaces = (
    string.replace("m", "").replace("k", "").split(".")[1] || ""
  ).length;

  switch (decimalPlaces) {
    case 1:
      return string.replace(".", "").replace("m", "00000").replace("k", "00");
    case 2:
      return string.replace(".", "").replace("m", "0000").replace("k", "0");
    case 3:
      return string.replace(".", "").replace("m", "000").replace("k", "");
    default:
      return string;
  }
}

export function fullNumberToShorthand(string = "") {
  if (string.length < 4) {
    return string;
  }
  const formatted = numeral(string).format("0.000a");
  return formatted
    .replace(/(\.000m$)|(00m$)|(0m$)/, "m")
    .replace(/(\.000k$)|(00k$)|(0k$)/, "k");
}

export function pluralizeWord({ string, amount = 100 }) {
  if (amount === "1" || amount === 1) {
    return string;
  }
  if (string === "search") {
    return "searches";
  }
  if (string === "story") {
    return "stories";
  }
  return `${string}s`;
}

export function formatTitle(string) {
  return startCase((string || "").toLowerCase());
}

export function formatBullets(arrayOfStrings = []) {
  return join(
    compact(arrayOfStrings).map((item, index) =>
      index > 0 ? ` • ${item}` : item
    ),
    ""
  );
}

export function getInitials(string) {
  const initials = (string || "")
    .toUpperCase()
    .split(" ")
    .map((name) => name.charAt(0));
  return initials.length === 1
    ? initials[0]
    : `${initials[0]}${initials[initials.length - 1]}`;
}

export function getEmails(string) {
  return (string || "").match(
    /[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*/g
  );
}

export function getPhones(string) {
  return (string || "").match(/(\+0?1\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}/g);
}

export function capitalize(string) {
  return (string || "").charAt(0).toUpperCase() + (string || "").slice(1);
}

export function getFirstName(string = "") {
  return string.split(" ")[0];
}
