import { history } from "../utils/routing";
import { isSearchRoute } from "../utils/routing";
import { getFilters, isNewLinkOption } from "../modules/search";

export function addFilter({ filter, pathname }) {
  return (dispatch) => {
    if (filter.value === "cheatSheet") {
      dispatch({ type: "SET_OMNI_SEARCH_INPUT_VALUE", payload: "" });
      return dispatch({ type: "SET_IS_CHEAT_SHEET_OPEN", payload: true });
    }

    if (filter.isLinkOption) {
      if (isNewLinkOption({ filter, pathname })) {
        dispatch({ type: "RESET_QUERY_OBJECT" });
      }

      return history.push(filter.pathname);
    }

    if (!isSearchRoute(pathname)) {
      dispatch({ type: "RESET_AND_ADD_TO_QUERY_OBJECT", payload: filter });
      return history.push("/searches/loading");
    }

    dispatch({ type: "ADD_TO_QUERY_OBJECT", payload: filter });
  };
}

export function removeFilter({ filter }) {
  return (dispatch, getState) => {
    const { queryObject } = getState();
    const filters = getFilters({ queryObject });
    const filterFields = filters.map((filter) => filter.field);
    if (!filterFields.includes(filter.field)) {
      return;
    }

    if (filters.length === 1) {
      dispatch({ type: "RESET_QUERY_OBJECT" });
      return history.push("/dashboard");
    }

    dispatch({ type: "REMOVE_FROM_QUERY_OBJECT", payload: filter });
  };
}
