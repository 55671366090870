import { AgentInfoDropdown } from "@wrstudios/components";
import React from "react";
import { history } from "../../../utils/routing";
import { zIndexHeader } from "../../../utils/styling";
import GenericHeader from "../../common/GenericHeader";
import {
  Address,
  AgentDetails,
  Cell,
  Grid,
  SubAddress
} from "./styled/listing-header";

export default function ListingHeader({
  address,
  subAddress,
  isPublic,
  isAuthed,
  hasPhoneNumber,
  hasCompleteAddress,
  firstName,
  initials,
  avatar,
  name,
  email,
  phone,
  company,
  city,
  state,
  zip,
  officePhone,
  zIndex
}) {
  return (
    <GenericHeader>
      <Grid>
        <Cell>
          {isPublic && (
            <AgentDetails>
              <AgentInfoDropdown
                isAuthed={isAuthed}
                hasPhoneNumber={hasPhoneNumber}
                hasCompleteAddress={hasCompleteAddress}
                firstName={firstName}
                initials={initials}
                avatar={avatar}
                name={name}
                email={email}
                phone={phone}
                company={company}
                city={city}
                state={state}
                zip={zip}
                address={address}
                officePhone={officePhone}
                zIndex={zIndexHeader + 1}
              />
            </AgentDetails>
          )}
          {!isPublic && <GenericHeader.Back onClick={() => history.goBack()} />}
        </Cell>
        <Cell>
          <Address>{address}</Address>
          <SubAddress>{subAddress}</SubAddress>
        </Cell>
      </Grid>
    </GenericHeader>
  );
}
