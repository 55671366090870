import React from "react";
import PropTypes from "prop-types";
import SelectTag from "../common/SelectTag";
import { sizeOptions } from "../../modules/listing";
import ChevronLeftIcon from "../icons/ChevronLeftIcon";
import ChevronRightIcon from "../icons/ChevronRightIcon";
import {
  Container,
  DirectionButton,
  PageButton,
  Ellipsis,
  PageControls,
  ViewingInfo
} from "./styled/pagination";
import { ceil, range, compact } from "lodash";

function Pagination({
  page,
  limit,
  isVisible,
  totalCount,
  renderedCount,
  maxPageButtons,
  maxAdjacent,
  handleSelectLimit,
  handleClickPage
}) {
  const pageCount = ceil(totalCount / limit);
  const buttonData = getButtonData({
    maxPageButtons,
    maxAdjacent,
    pageCount,
    page
  });

  return (
    <Container isVisible={isVisible}>
      <PageControls>
        {page > 1 && (
          <DirectionButton onClick={() => handleClickPage(page - 1)}>
            <ChevronLeftIcon />
          </DirectionButton>
        )}
        {buttonData.map(({ pageNumber, isSelected }, index) => {
          if (pageNumber === "ellipsis") {
            return <Ellipsis key={index} />;
          }

          return (
            <PageButton
              onClick={() => handleClickPage(pageNumber)}
              isSelected={isSelected}
              content={pageNumber}
              key={index}
            />
          );
        })}
        {page < pageCount && (
          <DirectionButton onClick={() => handleClickPage(page + 1)}>
            <ChevronRightIcon />
          </DirectionButton>
        )}
      </PageControls>
      <ViewingInfo>
        {formatViewingInfo({ page, limit, totalCount, renderedCount })}
      </ViewingInfo>
      {handleSelectLimit && (
        <SelectTag
          handleSelect={handleSelectLimit}
          options={sizeOptions}
          selected={limit}
          width={130}
        />
      )}
    </Container>
  );
}

function getButtonData({ pageCount, page, maxPageButtons, maxAdjacent }) {
  const pageNumbers = range(pageCount).map((index) => index + 1);

  switch (true) {
    case pageCount <= maxPageButtons:
      return pageNumbers.map((pageNumber) => ({
        pageNumber,
        isSelected: pageNumber === page
      }));
    case page <= maxAdjacent:
      return compact(
        pageNumbers.map((pageNumber) => {
          const isPageButton =
            pageNumber <= maxAdjacent || pageNumber === pageCount;
          if (isPageButton) {
            return { pageNumber, isSelected: pageNumber === page };
          }
          if (pageNumber === maxAdjacent + 1) {
            return { pageNumber: "ellipsis" };
          }
          return null;
        })
      );
    case page > pageCount - maxAdjacent:
      return compact(
        pageNumbers.map((pageNumber) => {
          const isPageButton =
            pageNumber > pageCount - maxAdjacent || pageNumber === 1;
          if (isPageButton) {
            return { pageNumber, isSelected: pageNumber === page };
          }
          if (pageNumber === pageCount - maxAdjacent) {
            return { pageNumber: "ellipsis" };
          }
          return null;
        })
      );
    default:
      return compact(
        pageNumbers.map((pageNumber) => {
          const isPageButton =
            pageNumber === 1 || pageNumber === page || pageNumber === pageCount;
          if (isPageButton) {
            return { pageNumber, isSelected: pageNumber === page };
          }
          if (pageNumber === 2 || pageNumber === pageCount - 1) {
            return { pageNumber: "ellipsis" };
          }
          return null;
        })
      );
  }
}

function formatViewingInfo({ page, limit, totalCount, renderedCount }) {
  const first = (page - 1) * limit + 1;
  const last = (page - 1) * limit + renderedCount;
  return `Viewing ${first} - ${last} of ${totalCount}`;
}

Pagination.defaultProps = {
  isVisible: true,
  maxPageButtons: 6,
  maxAdjacent: 3
};

Pagination.propTypes = {
  page: PropTypes.number,
  limit: PropTypes.number,
  isVisible: PropTypes.bool,
  totalCount: PropTypes.number,
  renderedCount: PropTypes.number,
  maxPageButtons: PropTypes.number,
  handleSelectLimit: PropTypes.func,
  handleClickPage: PropTypes.func
};

export default Pagination;
