export const initial = {
  disclaimer: "",
  isZipAutoComplete: false,
  availablePrimaryFields: {}
};

export function mlsConfigs(state = initial, { type, payload }) {
  switch (type) {
    case "GET_MLS_CONFIGS_SUCCEEDED":
      return { ...state, ...payload };
    case "GET_OPTIONS_FOR_ZIP_SUCCEEDED":
      return { ...state, isZipAutoComplete: payload.isAutoComplete };
    case "GET_AVAILABLE_FIELDS_SUCCEEDED":
      return { ...state, availablePrimaryFields: payload.primary };
    default:
      return state;
  }
}
