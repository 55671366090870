import { getProxiedImageUrl } from "@wrstudios/image-proxy";
import {
  currency,
  getDateLong,
  getDateShort,
  getDateTime
} from "@wrstudios/utils";
import { compact, parseInt, sortBy, uniqBy } from "lodash";
import pluralize from "pluralize";
import { randomColor } from "../utils/colorsLocal";
import {
  formatCurrency,
  formatCurrencyShort,
  formatDelimiters,
  formatNumber
} from "../utils/number";
import { isPseudoListingRoute } from "../utils/routing";
import { formatBullets, formatTitle, getInitials } from "../utils/string";
import { missingImageUrl } from "../utils/styling";

export const sortOptions = [
  { label: "Price High to Low", value: "-price" },
  { label: "Price Low to High", value: "price" },
  { label: "Updated Most Recently", value: "-updated_at" },
  { label: "Updated Longest Ago", value: "updated_at" },
  { label: "Square Footage", value: "sqft" },
  { label: "Property Status", value: "status" }
];

export const sizeOptions = [
  { label: "18 per page", value: 18 },
  { label: "36 per page", value: 36 },
  { label: "54 per page", value: 54 },
  { label: "72 per page", value: 72 },
  { label: "90 per page", value: 90 },
  { label: "108 per page", value: 108 }
];

export function getListingToRender({ listing, sharedSearch }) {
  const sharedListing = sharedSearch.data[sharedSearch.detailId];
  const isPseudoListing = isPseudoListingRoute(sharedSearch);
  const isSharedListing = isPseudoListing || sharedSearch.role === "singleton";
  return isSharedListing ? sharedListing : listing;
}

export function transformListings({ listings, photoProxyStrategy }) {
  return uniqBy(listings, "id").map((listing) => {
    return transformListing({
      listing: listing.attributes,
      photoProxyStrategy
    });
  });
}

export function transformListingsForPins({ listings, photoProxyStrategy }) {
  return uniqBy(listings, "id").reduce((state, listing) => {
    return {
      ...state,
      [listing.id]: transformListing({
        listing: listing.attributes,
        photoProxyStrategy
      })
    };
  }, {});
}

export function transformListing({ listing, photoProxyStrategy }) {
  const details = transformDetails(listing.features);
  const virtualTour = (
    details.find((detail) => detail.field === "Virtual Tour") || {}
  ).value;

  return {
    id: listing.id,
    mlsnum: listing.mlsnum,
    lat: listing.lat || null,
    lon: listing.lon || null,
    zip: listing.zipcode,
    city: listing.city,
    state: listing.state,
    address: formatTitle(listing.address),
    subAddress: getSubAddress(listing),
    price: formatCurrency(listing.price),
    priceShort: formatCurrencyShort(listing.price),
    pricePublic: formatCurrency(listing.price_list),
    listPrice: listing.price_list || 0,
    originalListPrice: listing.price_list_orig || 0,
    soldPrice: listing.sold_price || 0,
    listDate: listing.date_list || "",
    soldDate: listing.price_sold && listing.date_sold ? listing.date_sold : "",
    propType: listing.prop_type || "",
    subType: listing.prop_sub_type || "",
    details,
    dom: `${formatDelimiters(listing.dom) || ""}`,
    domFormatted: listing.dom
      ? `${pluralize("days", parseInt(listing.dom), true)} on market`
      : "",
    beds: `${listing.beds || ""}`,
    bedsFormatted: listing.beds ? pluralize("beds", listing.beds, true) : "",
    baths: listing.baths ? `${listing.baths}` : getWeirdBathsValue(listing),
    bathsFormatted: listing.baths
      ? pluralize("baths", listing.baths, true)
      : getWeirdBathsValue(listing),
    bathsFormattedInline: getFormattedInlineBaths(listing),
    garages: formatNumber(listing.garages, "0"),
    garagesFormatted: listing.garages
      ? pluralize("garage spaces", listing.garages, true)
      : "",
    built: formatNumber(listing.year_built, "0"),
    builtFormatted: listing.year_built ? `built in ${listing.year_built}` : "",
    sqft: formatNumber(listing.sqft, "0[.]0[0]"),
    sqftFormatted: listing.sqft ? `${listing.sqft} sqft` : "",
    acres: formatNumber(
      listing.acres
        ? `${listing.acres}`
        : listing.lotsize
        ? `${getAcresFromLotSize(listing.lotsize || "")}`
        : ""
    ),
    acresFormatted: listing.acres
      ? `${listing.acres} acre lot`
      : listing.lotsize
      ? `${getAcresFromLotSize(listing.lotsize || "")} acre lot`
      : "",
    lotSize: formatNumber(
      listing.lotsize
        ? listing.lotsize
        : getLotSizeFromAcres(listing.acres || ""),
      "0[.]0[0]"
    ),
    pricePerSqft:
      listing.price && listing.sqft
        ? formatCurrency(Math.round(listing.price / listing.sqft))
        : "",
    pricePerSqftPublic: formatCurrency(
      Math.round(listing.price_list / listing.sqft)
    ),
    typeAndArea: compact([
      listing.prop_type,
      listing.prop_sub_type,
      listing.area
    ]).join(" • "),
    updatedAtDateShort: getDateShort(listing.updated_at || ""),
    updatedAtTime: getDateTime(listing.updated_at || ""),
    cardFeatureBullets: getCardFeatureBullets(listing),
    firstImage:
      getProxiedImageUrl({
        url: (listing.photos || [])[0] || "",
        strategy: photoProxyStrategy
      }) || missingImageUrl,
    imageUrls: (listing.photos || []).map(
      (photoUrl) =>
        getProxiedImageUrl({
          url: photoUrl || "",
          strategy: photoProxyStrategy
        }) || missingImageUrl
    ),
    comments: listing.remarks || "",
    showing: listing.showing_inst || "",
    privateRemarks: getPrivateRemarks(listing),
    statusLabel: listing.status,
    statusValue: listing.mapped_status,
    history: transformHistory(listing.changes),
    virtualTour: virtualTour || "",
    agentId: listing.agent_list.id,
    agentName: listing.agent_list.name,
    agentEmail: listing.agent_list.email,
    agentPhone: listing.agent_list.phone,
    agentOffice: listing.office_list.name,
    agentOfficeId: listing.office_list.id,
    agentInitials: getInitials(listing.agent_list.name),
    agentIdFromMls: listing.agent_list.agent_id,
    agentColor: randomColor()
  };
}

function transformHistory(changes) {
  return changes.reduce((state, changeContainer) => {
    const changes = changeContainer.changes.reduce((state, change) => {
      const date = getDateLong(changeContainer.timestamp);
      const { field, old_value, new_value } = change;

      if (field === "price_list") {
        const oldInt = parseInt(old_value);
        const newInt = parseInt(new_value);
        const oldPrice = currency(oldInt);
        const newPrice = currency(newInt);

        if (!old_value) {
          const description = `Initial price set to ${newPrice}`;
          return [...state, { type: "price", date, description }];
        }

        if (oldInt > newInt) {
          const description = `Price decreased from ${oldPrice} to ${newPrice}`;
          return [...state, { type: "price", date, description }];
        }

        if (newInt > oldInt) {
          const description = `Price increased from ${oldPrice} to ${newPrice}`;
          return [...state, { type: "price", date, description }];
        }
      }

      if (field === "status") {
        if (!old_value) {
          const description = `Initial status set to ${new_value}`;
          return [...state, { type: "status", date, description }];
        }

        if (old_value) {
          const description = `Status changed from ${old_value} to ${new_value}`;
          return [...state, { type: "status", date, description }];
        }
      }

      return state;
    }, []);

    return [...state, ...changes];
  }, []);
}

function transformDetails(features) {
  const details = Object.keys(features).map((key) => {
    return {
      field: key,
      value: isFeatureCurrency(key) ? currency(features[key]) : features[key]
    };
  });

  return sortBy(details, "field");
}

function getPrivateRemarks(listing) {
  const privateRemarks = listing.remarks_private || "";
  const showingInstructions = listing.showing_inst || "";

  if (privateRemarks.includes(showingInstructions)) {
    return privateRemarks.replace(showingInstructions, "");
  } else {
    return privateRemarks;
  }
}

function getWeirdBathsValue(listing) {
  if (listing.baths_three_quarter > 0 || listing.baths_quarter > 0) {
    return `${listing.baths_full || 0}/${listing.baths_three_quarter || 0}/${
      listing.baths_half || 0
    }/${listing.baths_quarter || 0}`;
  } else {
    return `${listing.baths_full || 0}/${listing.baths_half || 0}`;
  }
}

export function getFormattedInlineBaths(listing) {
  const bathsFull = listing.baths_full;
  const bathsThreeQuarter = listing.baths_three_quarter;
  const bathsHalf = listing.baths_half;
  const bathsQuarter = listing.baths_quarter;
  return [
    bathsFull ? `${bathsFull} full` : "",
    bathsThreeQuarter ? `${bathsThreeQuarter} three quarter` : "",
    bathsHalf ? `${bathsHalf} half` : "",
    bathsQuarter ? `${bathsQuarter} quarter` : ""
  ]
    .filter(Boolean)
    .join(", ");
}

function getCardFeatureBullets(listing) {
  return formatBullets([
    listing.beds ? `${listing.beds} Beds` : null,
    listing.baths ? `${listing.baths} Baths` : null,
    listing.sqft ? `${formatDelimiters(listing.sqft)} Sqft` : null,
    listing.year_built
  ]);
}

function getSubAddress(listing) {
  return `${listing.city ? listing.city + "," : ""} ${listing.state || ""} ${
    listing.zipcode || ""
  }`.trim();
}

function getAcresFromLotSize(lotsize) {
  if (!lotsize) return lotsize;
  return (parseInt(lotsize) / 43560).toFixed(2);
}

function getLotSizeFromAcres(acres) {
  if (!acres) return acres;
  return (parseInt(acres) * 43560).toFixed(2);
}

function isFeatureCurrency(field) {
  return [
    "Assoc Dues 1",
    "Assoc Dues 2",
    "Association Fee",
    "Association Fee2",
    "Land Lease Transfer Fee",
    "Land Lease Amount",
    "HOA Fee",
    "Taxes"
  ].includes(field);
}
