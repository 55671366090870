import { store } from "../index";
import { history } from "../utils/routing";
import { createApiClient } from "@wrstudios/api";

export function readCasJwt() {
  return window.localStorage.getItem("casJwt");
}

export function writeCasJwt(jwt) {
  return window.localStorage.setItem("casJwt", jwt);
}

export function clearCasJwt() {
  return window.localStorage.removeItem("casJwt");
}

export const apiClient = createApiClient({
  baseURL: `${process.env.REACT_APP_CAS_URL}/graphql/authenticated`
});

export const publicApiClient = createApiClient({
  baseURL: `${process.env.REACT_APP_CAS_URL}/graphql`
});

apiClient.interceptors.request.use(
  (config) => {
    return {
      ...config,
      method: "post",
      headers: { Authorization: `Bearer ${readCasJwt()}` }
    };
  },
  (error) => {
    // normalize REQUEST errors here
    return Promise.reject(error);
  }
);

const UNAUTHORIZED = 401;
apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if ((error.response || {}).status === UNAUTHORIZED) {
      clearCasJwt();
      store.dispatch({ type: "LOGOUT" });
    }
    // normalize RESPONSE errors here
    return Promise.reject(error);
  }
);

apiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // normalize errors here
    if ((error.response || {}).status === 404) {
      history.push("/404");
    }
    return Promise.reject(error);
  }
);

publicApiClient.interceptors.request.use(
  (config) => {
    return {
      ...config,
      method: "post"
    };
  },
  (error) => {
    // normalize REQUEST errors here
    return Promise.reject(error);
  }
);

publicApiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // normalize errors here
    if ((error.response || {}).status === 404) {
      history.push("/404");
    }
    return Promise.reject(error);
  }
);
