import {
  ApplicationSwitcher,
  Navbar,
  NavbarAdditionalItems,
  NavbarHelpLink,
  NavbarNavigationBar,
  NavbarUserMenu
} from "@helix/navbar";
import PlatformService from "@lwolf-common/platform-service/build/PlatformService";
import React, { useEffect, useState } from "react";
import { Link } from "./styled/temp-header";

export default function NewNavigation({
  avatarUrl,
  userName,
  userEmail,
  lwaJwt,
  isLoggedInAs,
  emitAction,
  logoutUser
}) {
  const { data: appSwitcher = {} } = useAppSwitcher({ lwaJwt });

  return (
    <Navbar dataLwtId="default">
      <ApplicationSwitcher
        applicationName="Cloud MLX"
        appSections={appSwitcher?.appSections || []}
        launchpadUrl={process.env.REACT_APP_LW_PLATFORM_URL}
      />

      <NavbarNavigationBar
        navItems={[
          { label: "Search", url: "/dashboard" },
          { label: "Searches", url: "/searches" },
          { label: "Areas", url: "/areas" },
          { label: "Collections", url: "/collections" }
        ]}
        linkWrapper={({ item }) => (
          <Link
            to={item.url}
            onClick={() =>
              item.url === "/dashboard"
                ? emitAction({
                    type: "SET_AUTOFOCUS_TIMESTAMP"
                  })
                : undefined
            }>
            {item.url === "/dashboard" && (
              <svg
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 21 20">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M9.5 3.75a5.25 5.25 0 1 0 0 10.5 5.25 5.25 0 0 0 0-10.5ZM2.75 9a6.75 6.75 0 1 1 12.024 4.213l2.756 2.757a.75.75 0 1 1-1.06 1.06l-2.757-2.756A6.75 6.75 0 0 1 2.75 9Z"
                />
              </svg>
            )}
            <span>{item.label}</span>
          </Link>
        )}
      />
      <NavbarAdditionalItems>
        <NavbarHelpLink
          url="https://lonewolf.my.site.com/s/topic/0TO2L0000008JR8WAM/cloud-mlx"
          linkWrapper={({ url, label }) => (
            <a href={url} target="_blank" rel="noopener noreferrer">
              {label}
            </a>
          )}
        />
        <NavbarUserMenu
          id="avatar"
          user={{
            name: userName,
            email: userEmail,
            avatarURL: avatarUrl
          }}
          menuItems={[
            {
              label: "Account Settings",
              url: `${
                process.env.REACT_APP_CAS_URL
              }/mlx_login?token=${lwaJwt}&redirect_url=${encodeURIComponent(
                `${process.env.REACT_APP_CAS_URL}/app/settings?return_to_app=cloud_mlx`
              )}`
            },
            { divider: true },
            {
              label: "Learning Resources",
              url: "https://learn.cloudagentsuite.com/videos-mlx"
            },
            {
              label: "Support Center",
              url:
                "https://lonewolf.my.site.com/s/topic/0TO2L0000008JR8WAM/cloud-mlx"
            },
            { divider: true },
            {
              label: isLoggedInAs ? "Revert" : "Sign Out",
              url: "/logout"
            }
          ].filter(Boolean)}
          linkWrapper={({ item }) => {
            if (item.label === "Account Settings") {
              return <a href={item.url}>{item.label}</a>;
            }

            const isExternal = [
              "Learning Resources",
              "Support Center"
            ].includes(item.label);

            if (isExternal) {
              return (
                <a href={item.url} target="_blank" rel="noopener noreferrer">
                  {item.label}
                </a>
              );
            }

            if (item.url === "/logout") {
              return (
                <a
                  href={item.url}
                  onClick={(e) => {
                    e.preventDefault();
                    logoutUser();
                  }}>
                  {item.label}
                </a>
              );
            }

            return <Link to={item.url}>{item.label}</Link>;
          }}
        />
      </NavbarAdditionalItems>
    </Navbar>
  );
}

function useAppSwitcher(currentUser) {
  const { lwaJwt } = currentUser || {};
  const [data, setData] = useState();

  useEffect(() => {
    if (!lwaJwt) return;

    PlatformService.initialize({
      lwDomain: process.env.REACT_APP_LW_URL,
      token: lwaJwt,
      appId: "cm"
    })
      .then((platformService) => platformService.getAppSections())
      .then((appSections) => setData({ appSections }));
  }, [lwaJwt]);

  return { data };
}
