import styled from "styled-components";
import {
  greyMedium,
  greyLight,
  statusColor
} from "../../../../utils/colorsLocal";
import {
  mobileBreakpoint,
  absoluteFill,
  noWrap
} from "../../../../utils/styling";

const addressInfoWidth = 220;

export const Container = styled.div`
  ${absoluteFill};
`;

export const FullAddress = styled.div`
  position: absolute;
  right: ${addressInfoWidth}px;
  font-weight: 400;
  color: ${greyMedium};
  padding-left: 20px;
  bottom: 0px;
  left: 0px;
  top: 0px;
  ${noWrap};

  @media (max-width: ${mobileBreakpoint}px) {
    font-size: 14px;
    padding-right: 20px;
    right: 0px;
  }
`;

export const AddressInfo = styled.div`
  position: absolute;
  width: ${addressInfoWidth}px;
  bottom: 0px;
  right: 0px;
  top: 0px;

  @media (max-width: ${mobileBreakpoint}px) {
    display: none;
  }
`;

export const AddressDate = styled.div`
  position: absolute;
  font-size: 15px;
  font-weight: 400;
  color: ${greyLight};
  padding-left: 10px;
  bottom: 0px;
  width: 60%;
  left: 0px;
  top: 0px;
  ${noWrap};
`;

export const AddressStatus = styled.div`
  color: ${({ statusValue }) => statusColor(statusValue)};
  font-size: 14px;
  font-weight: 400;
  padding-right: 20px;
  position: absolute;
  text-align: right;
  bottom: 0px;
  right: 0px;
  top: 0px;
  width: 40%;
  ${noWrap};
`;
