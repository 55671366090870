import React from "react";
import PropTypes from "prop-types";
import { Route as ReactRouterRoute } from "react-router-dom";

function Route({ exact, path, component: Component, ...passedProps }) {
  const pathProps = path ? { exact, path: path } : {};

  return (
    <ReactRouterRoute
      {...pathProps}
      render={(routerProps) => <Component {...passedProps} {...routerProps} />}
    />
  );
}

Route.defaultProps = {
  exact: true
};

Route.propTypes = {
  exact: PropTypes.bool,
  path: PropTypes.string,
  component: PropTypes.elementType
};

export default Route;
