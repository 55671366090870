import { isSharedRoute } from "../utils/routing";

export const initial = {
  isPublic: isSharedRoute(window.location.pathname),
  isNotFound: false,
  forgeToken: null,
  isAppReady: false,
  isSwitcherEnabled: false,
  isShortUrlCopied: false,
  isNavOpen: false,
  lastPathname: "",
  autoFocusTimestamp: Date.now()
};

export function application(state = initial, { type, payload }) {
  switch (type) {
    case "GET_FORGE_TOKEN_SUCCEEDED":
      return { ...state, forgeToken: payload };
    case "SET_IS_APPLICATION_PUBLIC":
      return { ...state, isPublic: payload };
    case "SET_IS_NOT_FOUND":
      return { ...state, isNotFound: payload };
    case "GET_APP_READY_SUCCEEDED":
      return { ...state, isAppReady: true };
    case "SET_IS_SWITCHER_ENABLED":
      return { ...state, isSwitcherEnabled: payload };
    case "SET_IS_SHORT_URL_COPIED":
      return { ...state, isShortUrlCopied: payload };
    case "SET_IS_NAV_OPEN":
      return { ...state, isNavOpen: payload };
    case "SET_LAST_PATHNAME":
      return { ...state, lastPathname: payload };
    case "SET_AUTOFOCUS_TIMESTAMP":
      return { ...state, autoFocusTimestamp: Date.now() };
    default:
      return state;
  }
}
