import { createBrowserHistory } from "history";

export const history = createBrowserHistory();

export function getShortUrl(slug) {
  const url = process.env.REACT_APP_CLOUD_MLX_SHARE_DOMAIN;
  return slug ? `${url}/${slug}` : "";
}

export function isAgentRoute(pathname) {
  const splits = pathname.split("/").length;
  return pathname.includes("/agents/") && splits === 3;
}

export function isSearchRoute(pathname) {
  const splits = pathname.split("/").length;
  return pathname.includes("/searches/") && splits === 3;
}

export function isCollectionRoute(pathname) {
  const splits = pathname.split("/").length;
  return pathname.includes("/collections/") && splits === 3;
}

export function isListingRoute(pathname) {
  const splits = pathname.split("/").length;
  return pathname.includes("/listings/") && (splits === 3 || splits === 5);
}

export function isDashboardRoute(pathname) {
  return pathname.includes("/dashboard");
}

export function isConnectorRoute(pathname) {
  return pathname.includes("/product_connector");
}

export function isSharedRoute(pathname) {
  return pathname.includes("/shared/");
}

export function isPseudoListingRoute(sharedSearch) {
  const isList = ["saved_search", "collection"].includes(sharedSearch.role);
  return isList && !!sharedSearch.detailId;
}

export function isMappableRoute({ pathname, sharedSearch }) {
  const isSavedRole = sharedSearch.role === "saved_search";
  return isSearchRoute(pathname) || (isSavedRole && !sharedSearch.detailId);
}

export function isSearchableRoute({ pathname, sharedSearch }) {
  return (
    !pathname.includes("/agents/") &&
    !pathname.includes("/collections/") &&
    !pathname.includes("/listings/") &&
    !sharedSearch.detailId
  );
}

export function isSearchesIndexRoute(pathname) {
  return pathname === "/searches";
}

export function isAreasIndexRoute(pathname) {
  return pathname === "/areas";
}

export function isCollectionsRoute(pathname) {
  return pathname.includes("/collections");
}
