import React from "react";
import PropTypes from "prop-types";
import { Container, Title, Body } from "./styled/notice";

function Notice({ title, body }) {
  return (
    <Container>
      <Title>{title}</Title>
      <Body dangerouslySetInnerHTML={{ __html: body }} />
    </Container>
  );
}

Notice.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string
};

export default Notice;
