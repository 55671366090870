import styled, { css } from "styled-components";
import { mobileBreakpoint, defaultBorderRadius } from "../../../utils/styling";
import { greyMedium, greyLight, greyLighter } from "../../../utils/colorsLocal";

export const Container = styled.div`
  visibility: ${({ isVisible }) => (isVisible ? "visible" : "hidden")};
  text-align: center;
  margin-top: 40px;
`;

const button = css`
  display: inline-block;
  border-radius: ${defaultBorderRadius}px;
  border-width: 2px;
  border-style: solid;
  color: ${greyMedium};
  vertical-align: top;
  text-align: center;
  margin-right: 10px;
  line-height: 36px;
  overflow: hidden;
  cursor: pointer;
  font-size: 15px;
  height: 40px;
  width: 40px;

  &:last-child {
    margin-right: 0px;
  }

  @media (max-width: ${mobileBreakpoint}px) {
    margin-right: 8px;
    line-height: 33px;
    font-size: 14px;
    height: 36px;
    width: 36px;
  }
`;

export const PageButton = styled.button`
  ${button};
  border-color: ${({ isSelected }) => (isSelected ? greyLight : greyLighter)};
  background-color: ${({ isSelected }) =>
    isSelected ? greyLighter : "transparent"};

  &::before {
    content: "${({ content }) => content}";
  }
`;

export const DirectionButton = styled.button`
  ${button};
  border-color: ${greyLighter};
  padding: 0 12.5px;

  svg {
    fill: ${greyMedium};
  }
`;

export const Ellipsis = styled.div`
  ${button};
  border-color: transparent;
  cursor: default;
  width: 24px;

  &::before {
    content: "•••";
  }

  @media (max-width: ${mobileBreakpoint}px) {
    width: 24px;
  }
`;

export const PageControls = styled.div`
  margin-bottom: 10px;
`;

export const ViewingInfo = styled.div`
  margin-bottom: 10px;
  color: ${greyLight};
  font-size: 13px;
`;
