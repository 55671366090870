import styled from "styled-components";
import { greyMedium, iconColor } from "../../../../utils/colorsLocal";
import { absoluteFill, noWrap } from "../../../../utils/styling";

export const maxOptions = 6;
export const optionHeight = 40;

export const Container = styled.div`
  max-height: ${optionHeight * maxOptions}px;
  background-color: white;
  overflow-y: scroll;
`;

export const Option = styled.div`
  position: relative;
  background-color: ${({ isHighlighted }) =>
    isHighlighted ? "#F0F2F4" : "transparent"};
  line-height: ${optionHeight}px;
  height: ${optionHeight}px;
`;

export const Handler = styled.div`
  ${absoluteFill};
  padding: 0px 20px;
  cursor: pointer;
`;

export const Icon = styled.div`
  position: absolute;
  height: 20px;
  width: 20px;
  left: 20px;
  top: 10px;

  svg {
    fill: ${({ field }) => iconColor(field)};
  }
`;

export const Label = styled.div`
  font-weight: 400;
  color: ${greyMedium};
  padding-left: 40px;
  height: 100%;
  ${noWrap};
`;
