import React from "react";

function ChevronRightIcon() {
  return (
    <svg viewBox="0 0 100 100" preserveAspectRatio="xMidYMid meet">
      <path d="M81.7621244,50.0308375 L35.5090461,3.75491957 C31.6047673,-0.15128719 25.2731177,-0.152850075 21.3669109,3.75142876 C21.3616863,3.75665079 21.3564675,3.7618786 21.3512545,3.76711218 L20.6675786,4.45348599 C16.7759589,8.36046294 16.7816089,14.6803892 20.680208,18.5804017 L52.1192457,50.0308375 L20.6879639,81.4840079 C16.7843462,85.3908753 16.7869805,91.7225246 20.6938479,95.6261423 C20.6945609,95.6268547 20.6952739,95.6275669 20.6959871,95.628279 L21.3548265,96.2680364 C25.2601646,100.167767 31.5864682,100.166324 35.4900265,96.2648117 L81.7621244,50.0308375 Z"></path>
    </svg>
  );
}

export default ChevronRightIcon;
