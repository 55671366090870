import { connect } from "react-redux";
import { MlsDisclaimer } from "@wrstudios/components";

function select({ mlsConfigs }) {
  return {
    disclaimer: mlsConfigs.disclaimer || ""
  };
}

export default connect(select)(MlsDisclaimer);
