import { omit, without } from "lodash";

const initial = {
  isLoading: false,
  hasDeleteError: false,
  isDeleting: false,
  count: 0,
  order: [],
  data: {}
};

export function collections(state = initial, { type, payload }) {
  switch (type) {
    case "GET_COLLECTIONS_INITIATED":
      return { ...state, isLoading: true };
    case "GET_COLLECTIONS_SUCCEEDED":
      return {
        ...initial,
        count: payload.count,
        order: payload.order,
        data: payload.data,
        isLoading: false
      };
    case "GET_COLLECTIONS_FAILED":
      return { ...state, isLoading: false };
    case "DELETE_COLLECTION_INITIATED":
      return { ...state, isDeleting: true, hasDeleteError: false };
    case "DELETE_COLLECTION_FAILED":
      return { ...state, isDeleting: false, hasDeleteError: true };
    case "DELETE_COLLECTION_SUCCEEDED":
      return {
        isDeleting: false,
        order: without(state.order, payload.id),
        data: omit(state.data, payload.id)
      };
    default:
      return state;
  }
}
