import React, { Component } from "react";
import PropTypes from "prop-types";
import { times, parseInt } from "lodash";
import Notice from "../../common/Notice";
import SelectTag from "../../common/SelectTag";
import CommonPhotoCard from "../../common/PhotoCard";
import { PhotoCardLoading } from "@wrstudios/components";
import Pagination from "../../common/Pagination";
import DeleteNotice from "../../common/DeleteNotice";
import Notification from "../../common/Notification";
import { sortOptions } from "../../../modules/search";
import { pagePadding } from "../../../utils/styling";
import { getNewTourUrl } from "../../../modules/interProduct";
import { getNewCmaCompsUrl } from "../../../modules/interProduct";
import { ThreeColumnItem } from "../../common/styled/three-columns";
import { ThreeColumnContainer } from "../../common/styled/three-columns";
import { Header, Title, Sorting } from "../../common/styled/heading";
import { SearchLinkLayout } from "./styled/collections-route";
import { SearchLink } from "./styled/collections-route";
import { Container, Body } from "./styled/collections-route";

class CollectionsRoute extends Component {
  componentDidMount() {
    this.handleNewLocation();
  }

  componentDidUpdate(prevProps) {
    this.handleReloadLocation(prevProps);
    this.handlePaginationChange(prevProps);
    this.handleAsyncDelete(prevProps);
  }

  render() {
    const { isLoading, collections, ssoParams, locale } = this.props;

    return (
      <Container>
        <Header>
          <Title>Collections</Title>
          <Sorting>
            <SelectTag
              handleSelect={this.handleSelectSort}
              selected={this.props.sort}
              options={sortOptions}
              width={184}
            />
          </Sorting>
        </Header>
        <Body>
          {isLoading ? (
            <ThreeColumnContainer>
              {times(9).map((index) => (
                <ThreeColumnItem key={index} bottom={pagePadding}>
                  <PhotoCardLoading />
                </ThreeColumnItem>
              ))}
            </ThreeColumnContainer>
          ) : (
            <ThreeColumnContainer>
              {collections.map((collection, index) => {
                const mlsnums = collection.mlsnums || [];
                const listings = collection.listings || [];

                const buyerTourUrl = getNewTourUrl({
                  mlsnums,
                  ssoParams
                });

                const compsUrl = getNewCmaCompsUrl({
                  mlsnums,
                  listings,
                  ssoParams,
                  locale
                });

                return (
                  <ThreeColumnItem key={index} bottom={pagePadding}>
                    <CommonPhotoCard
                      url={`/collections/${collection.id}`}
                      name={collection.name}
                      photos={collection.photos}
                      lineOne={collection.description}
                      handleClickDelete={() =>
                        this.handleBeginDelete(collection.id)
                      }
                      deleteLabel="Delete Collection"
                      shouldHaveTwoLines={false}
                      showMenu={true}
                      footer={
                        <SearchLinkLayout>
                          <SearchLink
                            href={compsUrl}
                            target="_blank"
                            rel="noopener noreferrer">
                            Make CMA
                          </SearchLink>
                          <SearchLink
                            href={buyerTourUrl}
                            target="_blank"
                            rel="noopener noreferrer">
                            Buyer Tour
                          </SearchLink>
                        </SearchLinkLayout>
                      }
                    />
                  </ThreeColumnItem>
                );
              })}
            </ThreeColumnContainer>
          )}
        </Body>
        <Pagination
          page={this.props.page}
          limit={this.props.limit}
          totalCount={this.props.count}
          renderedCount={collections.length}
          isVisible={!isLoading && collections.length > 0}
          handleClickPage={this.handleClickPage}
        />
        <Notification
          ref={(node) => (this.deleteAlert = node)}
          isDismissable={false}
          shouldAutoDismiss={false}>
          <DeleteNotice
            body="This collection will be permantently deleted"
            handleDelete={this.handleFinishDelete}
            handleCancel={this.handleCancelDelete}
          />
        </Notification>
        <Notification
          ref={(node) => (this.deleteSuccess = node)}
          type="success">
          <Notice title="Success" body="Your collection was deleted" />
        </Notification>
        <Notification ref={(node) => (this.deleteError = node)} type="error">
          <Notice title="Error" body="Your collection could not be deleted" />
        </Notification>
      </Container>
    );
  }

  handleNewLocation = () => {
    if (this.props.isAppReady) {
      this.props.getCollections(1);
    }
  };

  handleReloadLocation = ({ isAppReady }) => {
    if (!isAppReady && this.props.isAppReady) {
      this.props.getCollections(this.props.page);
    }
  };

  handlePaginationChange = ({ page, sort }) => {
    const isNewPage = page !== this.props.page;
    const isNewSort = sort !== this.props.sort;

    if (!this.props.isLoading && (isNewPage || isNewSort)) {
      this.props.getCollections(isNewPage ? this.props.page : 1);
    }
  };

  handleSelectSort = (value) => {
    const { emitAction } = this.props;
    emitAction({ type: "SET_SEARCHES_SORT", payload: value });
  };

  handleClickPage = (value) => {
    const { emitAction } = this.props;
    emitAction({ type: "SET_SEARCHES_PAGE", payload: parseInt(value) });
  };

  handleClickCollection = () => {
    this.props.emitAction({ type: "RESET_QUERY_OBJECT" });
  };

  handleBeginDelete = (id) => {
    this.deleteAlert.display();
    this.deleteId = id;
  };

  handleFinishDelete = () => {
    this.props.deleteCollection(this.deleteId);
    this.deleteAlert.dismiss();
    this.deleteId = null;
  };

  handleCancelDelete = () => {
    this.deleteAlert.dismiss();
    this.deleteId = null;
  };

  handleAsyncDelete = ({ hasError, isDeleting }) => {
    if (!hasError && this.props.hasError) {
      return this.deleteError.display();
    }
    if (isDeleting && !this.props.isDeleting) {
      this.deleteSuccess.display();
      this.props.getUgcStaticOptions();
    }
  };
}

CollectionsRoute.propTypes = {
  locale: PropTypes.string,
  ssoParams: PropTypes.string,
  count: PropTypes.number,
  page: PropTypes.number,
  limit: PropTypes.number,
  sort: PropTypes.string,
  collections: PropTypes.array,
  hasError: PropTypes.bool,
  isLoading: PropTypes.bool,
  isDeleting: PropTypes.bool,
  isAppReady: PropTypes.bool,
  getCollections: PropTypes.func,
  deleteCollection: PropTypes.func,
  getUgcStaticOptions: PropTypes.func,
  emitAction: PropTypes.func
};

export default CollectionsRoute;
