import React from "react";
import PropTypes from "prop-types";
import DropdownArrow from "../icons/DropdownArrow";
import { Container, Select, SelectPointer } from "./styled/select-tag";

function SelectTag({ width, handleSelect, options, selected }) {
  return (
    <Container>
      <Select
        width={width}
        value={selected}
        onChange={({ target }) => handleSelect(target.value)}>
        {options.map((option, index) => (
          <option value={option.value} key={index}>
            {option.label}
          </option>
        ))}
      </Select>
      <SelectPointer>
        <DropdownArrow />
      </SelectPointer>
    </Container>
  );
}

SelectTag.propTypes = {
  width: PropTypes.number,
  selected: PropTypes.any,
  handleSelect: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any
    })
  )
};

export default SelectTag;
