import styled from "styled-components";
import { routeContainer } from "../../../../utils/styling";

export const Container = styled.div`
  ${routeContainer};
`;

export const Body = styled.div`
  padding-top: 20px;
`;

export const SearchLinkLayout = styled.div`
  width: 100%;
  height: 24px;
`;

export const SearchLink = styled.a`
  font-size: 12px;
  cursor: pointer;
  margin-right: 20px;
`;
