import { initial } from "./currentUser";

export function sharingAgent(state = initial, { type, payload }) {
  switch (type) {
    case "SIGN_IN_USER_SUCCEEDED":
    case "GET_CURRENT_USER_SUCCEEDED":
    case "GET_SHARED_SEARCH_SUCCEEDED":
      return { ...state, ...payload.user };
    default:
      return state;
  }
}
