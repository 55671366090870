import { css, keyframes } from "styled-components";
import {
  greyDark,
  greyMedium,
  greyLight,
  greyLightest,
  blueMedium,
  blueVeryDark
} from "./colorsLocal";

export const maxPageWidth = 1180;
export const mobileBreakpoint = 800;

export const twoColumnBreakpoint = 1000;
export const oneColumnBreakpoint = 640;

export const defaultBodySplit = 0.6;
export const defaultMapSplit = 1 - defaultBodySplit;

export const oneColumnBodySplit = 0.5;
export const oneColumnMapSplit = 1 - oneColumnBodySplit;

export const tabletPortraitBreakpoint = 768;

export function mobileBkptWithSplit(isScreenSplit) {
  if (isScreenSplit) {
    return mobileBreakpoint / 0.5;
  }
  return mobileBreakpoint;
}

export function twoColBkptWithSplit(isScreenSplit) {
  if (isScreenSplit) {
    return twoColumnBreakpoint / defaultBodySplit;
  }
  return twoColumnBreakpoint;
}

export function oneColBkptWithSplit(isScreenSplit) {
  if (isScreenSplit) {
    return oneColumnBreakpoint / defaultBodySplit;
  }
  return oneColumnBreakpoint;
}

export function defaultBodyRight({ isMapOpen, isMappable }) {
  if (isMapOpen && isMappable) {
    return `${(1 - defaultBodySplit) * 100}%`;
  }
  return "0px";
}

export function oneColSplitBodyRight({ isMapOpen, isMappable }) {
  if (isMapOpen && isMappable) {
    return `${(1 - oneColumnBodySplit) * 100}%`;
  }
  return "0px";
}

export const headerHeightDesktop = 60;
export const headerHeightMobile = 48;
export const pagePadding = 24;

export const zIndexSelector = 100;
export const zIndexMap = 200;
export const zIndexAdvanced = 300;
export const zIndexHeader = 400;
export const zIndexNavigation = 500;
export const zIndexNotification = 600;

export const missingImageUrl =
  "https://cloudmlx.com/images/no_photo_placeholder-lg.png";

export const defaultBorderRadius = 4;

export const defaultBoxShadow = css`
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
`;

export const overlayBoxShadow = css`
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
`;

export const stickyNavShadow = css`
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
`;

export const resetPositionalContext = css`
  transform: scale(1);
`;

export const noWrap = css`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const absoluteFill = css`
  position: absolute;
  bottom: 0px;
  right: 0px;
  left: 0px;
  top: 0px;
`;

export const routeContainer = css`
  padding: 37px ${pagePadding}px 140px;
  max-width: ${maxPageWidth}px;
  margin: 0px auto;

  @media (max-width: ${mobileBreakpoint}px) {
    padding: 30px ${pagePadding / 2}px 140px;
  }
`;

export const pageTitle = css`
  ${noWrap};
  height: 47px;
  font-size: 36px;
  line-height: 47px;
  color: ${greyDark};

  @media (max-width: ${mobileBreakpoint}px) {
    height: 40px;
    font-size: 28px;
    line-height: 40px;
  }
`;

export const hideScrollbar = css`
  &::-webkit-scrollbar {
    display: none;
  }
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
`;

export const fakeLink = css`
  color: ${blueMedium};
  transition: color 100ms;
  background-color: transparent;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: ${blueVeryDark};
  }
`;

export const h4 = css`
  color: ${greyDark};
  font-size: 18px;
  line-height: 24px;
`;

export const h5 = css`
  color: ${greyDark};
  font-size: 16px;
  line-height: 21px;
`;

export const h6 = css`
  color: ${greyDark};
  font-size: 15px;
  line-height: 20px;
`;

export const defaultText = css`
  color: ${greyMedium};
  font-size: 15px;
  line-height: 24px;
`;

export const smallText = css`
  color: ${greyMedium};
  font-size: 13px;
  line-height: 20px;
`;

export const linkedRow = css`
  position: relative;
  ${defaultBoxShadow};
  transition: box-shadow 200ms;
  background-color: ${greyLightest};
  border-radius: ${defaultBorderRadius}px;
  margin-bottom: 20px;
  background-color: white;

  &:hover {
    ${overlayBoxShadow};
  }
`;

export const linkedRowBody = css`
  min-height: 48px;
  padding: 10px 16px;
`;

export const rotate = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(359deg); }
`;

export const scaleUp = keyframes`
  from { transform: scale(0); }
  to { transform: rotate(1); }
`;

export const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

export function shimmer({ outerColor, innerColor }) {
  return css`
    animation-name: ${shimmerPosition};
    animation-duration: 15s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    background: linear-gradient(
      to right,
      ${outerColor} 20%,
      ${innerColor} 50%,
      ${outerColor} 80%
    );
    background-size: cover;
  `;
}

const shimmerPosition = keyframes`
  from { background-position: 0px 0px; }
  to { background-position: 10000px 0px; }
`;

const delayVisibilityFrames = keyframes`
  from { opacity: 0; }
  to { opacity: 0; }
`;

export const delayVisibility = css`
  animation-name: ${delayVisibilityFrames};
  animation-duration: 60ms;
`;

export const inputBaseStyles = css`
  height: 42px;
  width: 100%;
  max-width: 455px;
  border: 2px solid ${greyLight};
  border-radius: 4px;
  padding: 0 8px;
  color: ${greyMedium};
`;
