import React from "react";
import PropTypes from "prop-types";
import Link from "./Link";
import { Container, Text } from "./styled/card-create";

function CardCreate({ url, text }) {
  return (
    <Link to={url}>
      <Container>
        <svg width="31" height="30" xmlns="http://www.w3.org/2000/svg">
          <g fillRule="evenodd">
            <path d="M14 0h3v30h-3z" />
            <path d="M30.5 13.5v3H.5v-3z" />
          </g>
        </svg>
        <Text>{text}</Text>
      </Container>
    </Link>
  );
}

CardCreate.propTypes = {
  url: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
};

export default CardCreate;
