import styled from "styled-components";
import { defaultBorderRadius } from "../../../utils/styling";
import { greyLight, blueMedium, greyDark } from "../../../utils/colorsLocal";

const inputHeight = 42;
const dashWidth = 30;

export const Container = styled.div``;

export const Input = styled.input`
  border: 2px solid ${greyLight};
  border-radius: ${defaultBorderRadius}px;
  width: calc(50% - ${dashWidth / 2}px);
  height: ${inputHeight}px;
  padding: 0px 10px;

  &::placeholder {
    color: ${greyLight};
  }

  &:focus {
    border-color: ${blueMedium};
  }
`;

export const Dash = styled.div`
  line-height: ${inputHeight}px;
  height: ${inputHeight}px;
  width: ${dashWidth}px;
  display: inline-block;
  vertical-align: top;
  text-align: center;
  font-weight: bold;
  font-size: 20px;

  &::before {
    content: "-";
    color: ${greyDark};
  }
`;
