import React, { Component } from "react";
import PropTypes from "prop-types";
import { partial } from "lodash";
import Selector from "../../common/Selector";
import RangeInput from "../../common/RangeInput";
import DynamicIcon from "../../common/DynamicIcon";
import { formatDelimiters } from "../../../utils/number";
import { mobileBreakpoint } from "../../../utils/styling";
import { offMarketDaysOptions } from "../../../modules/search";
import { buildArbitraryFilter } from "../../../modules/arbitrary";
import {
  ThreeColumnContainer,
  ThreeColumnItem
} from "../../common/styled/three-columns";
import {
  Container,
  Body,
  Layout,
  Title,
  Footer,
  FieldHeading,
  FieldIcon,
  FieldName,
  AutoComplete,
  ArbitraryOptions,
  itemBottom,
  FooterButton
} from "./styled/advanced-search";

class AdvancedSearch extends Component {
  componentDidUpdate(prevProps) {
    this.handleNewLocation(prevProps);
    this.handleNewArbitraryField(prevProps);
    this.handleToggleAdvancedSearch(prevProps);
  }

  componentWillUnmount() {
    this.removeEventListeners();
  }

  render() {
    const {
      isOpen,
      totalCount,
      isSearchRoute,
      queryObject,
      currentArbitraryField,
      arbitraryFields
    } = this.props;

    return (
      <Container isOpen={isOpen}>
        <Body>
          <Layout>
            <Title>Advanced Search</Title>
            <ThreeColumnContainer>
              <ThreeColumnItem bottom={itemBottom}>
                <FieldHeading>
                  <FieldIcon name="type">
                    <DynamicIcon name="type" />
                  </FieldIcon>
                  <FieldName>Property Type</FieldName>
                </FieldHeading>
                <Selector
                  ref={(node) => (this.type = node)}
                  handleSelect={partial(this.handleSelectMultiple, "type")}
                  triggerLabel={this.labelMultiple({
                    field: "type",
                    label: "Select Types"
                  })}
                  selectedValues={isSearchRoute ? queryObject.type : []}
                  options={this.props.type}
                  dataLabel="type"
                />
              </ThreeColumnItem>
              <ThreeColumnItem bottom={itemBottom}>
                <FieldHeading>
                  <FieldIcon name="subType">
                    <DynamicIcon name="subType" />
                  </FieldIcon>
                  <FieldName>Property Sub Type</FieldName>
                </FieldHeading>
                <Selector
                  ref={(node) => (this.subType = node)}
                  handleSelect={partial(this.handleSelectMultiple, "subType")}
                  triggerLabel={this.labelMultiple({
                    field: "subType",
                    label: "Select Sub Types"
                  })}
                  selectedValues={isSearchRoute ? queryObject.subType : []}
                  options={this.props.subType}
                  dataLabel="subType"
                />
              </ThreeColumnItem>
              <ThreeColumnItem bottom={itemBottom}>
                <FieldHeading>
                  <FieldIcon name="status">
                    <DynamicIcon name="status" />
                  </FieldIcon>
                  <FieldName>Status</FieldName>
                </FieldHeading>
                <Selector
                  ref={(node) => (this.status = node)}
                  handleSelect={partial(this.handleSelectMultiple, "status")}
                  triggerLabel={this.labelMultiple({
                    field: "status",
                    label: "Select Statuses"
                  })}
                  selectedValues={isSearchRoute ? queryObject.status : []}
                  options={this.props.status}
                  dataLabel="status"
                />
              </ThreeColumnItem>
              <ThreeColumnItem bottom={itemBottom}>
                <FieldHeading>
                  <FieldIcon name="offMarketDays">
                    <DynamicIcon name="offMarketDays" />
                  </FieldIcon>
                  <FieldName>Days Off Market</FieldName>
                </FieldHeading>
                <Selector
                  ref={(node) => (this.offMarketDays = node)}
                  handleSelect={partial(
                    this.handleSelectSingular,
                    offMarketDaysOptions
                  )}
                  triggerLabel={this.labelSingular({
                    field: "offMarketDays",
                    label: "Select Time",
                    options: offMarketDaysOptions
                  })}
                  options={offMarketDaysOptions}
                  dataLabel="offMarketDays"
                  isSingular={true}
                />
              </ThreeColumnItem>
              <ThreeColumnItem bottom={itemBottom}>
                <FieldHeading>
                  <FieldIcon name="price">
                    <DynamicIcon name="price" />
                  </FieldIcon>
                  <FieldName>Price</FieldName>
                </FieldHeading>
                <RangeInput
                  label="price"
                  minValue={isSearchRoute ? queryObject.priceMin : ""}
                  maxValue={isSearchRoute ? queryObject.priceMax : ""}
                  handleSubmitMin={partial(this.handleSubmitMin, "priceMin")}
                  handleSubmitMax={partial(this.handleSubmitMax, "priceMax")}
                />
              </ThreeColumnItem>
              <ThreeColumnItem bottom={itemBottom}>
                <FieldHeading>
                  <FieldIcon name="sqftMin">
                    <DynamicIcon name="sqftMin" />
                  </FieldIcon>
                  <FieldName>Square Footage</FieldName>
                </FieldHeading>
                <RangeInput
                  label="sqft"
                  minValue={isSearchRoute ? queryObject.sqftMin : ""}
                  maxValue={isSearchRoute ? queryObject.sqftMax : ""}
                  handleSubmitMin={partial(this.handleSubmitMin, "sqftMin")}
                  handleSubmitMax={partial(this.handleSubmitMax, "sqftMax")}
                />
              </ThreeColumnItem>
              <ThreeColumnItem bottom={itemBottom}>
                <FieldHeading>
                  <FieldIcon name="bedMin">
                    <DynamicIcon name="bedMin" />
                  </FieldIcon>
                  <FieldName>Beds</FieldName>
                </FieldHeading>
                <RangeInput
                  label="beds"
                  minValue={isSearchRoute ? queryObject.bedMin : ""}
                  maxValue={isSearchRoute ? queryObject.bedMax : ""}
                  handleSubmitMin={partial(this.handleSubmitMin, "bedMin")}
                  handleSubmitMax={partial(this.handleSubmitMax, "bedMax")}
                />
              </ThreeColumnItem>
              <ThreeColumnItem bottom={itemBottom}>
                <FieldHeading>
                  <FieldIcon name="bathMin">
                    <DynamicIcon name="bathMin" />
                  </FieldIcon>
                  <FieldName>Baths</FieldName>
                </FieldHeading>
                <RangeInput
                  label="baths"
                  minValue={isSearchRoute ? queryObject.bathMin : ""}
                  maxValue={isSearchRoute ? queryObject.bathMax : ""}
                  handleSubmitMin={partial(this.handleSubmitMin, "bathMin")}
                  handleSubmitMax={partial(this.handleSubmitMax, "bathMax")}
                />
              </ThreeColumnItem>
              <ThreeColumnItem bottom={itemBottom}>
                <FieldHeading>
                  <FieldIcon name="arbitrary">
                    <DynamicIcon name="arbitrary" />
                  </FieldIcon>
                  <FieldName>Other Features</FieldName>
                </FieldHeading>
                <Selector
                  ref={(node) => (this.arbitraryField = node)}
                  handleSelect={partial(
                    this.handleSelectArbitraryField,
                    arbitraryFields
                  )}
                  triggerLabel={
                    currentArbitraryField.label || "Select a Feature"
                  }
                  options={arbitraryFields}
                  dataLabel="arbitrary"
                  isSingular={true}
                />
                {currentArbitraryField.isAutoComplete && (
                  <AutoComplete
                    placeholder="Begin typing option name..."
                    onChange={this.handleAutocompleteChange}
                  />
                )}
                <ArbitraryOptions>
                  {this.renderArbitraryOptions()}
                </ArbitraryOptions>
              </ThreeColumnItem>
            </ThreeColumnContainer>
          </Layout>
        </Body>
        <Footer onClick={this.handleClose}>
          <FooterButton>
            {totalCount > 0
              ? `View ${formatDelimiters(totalCount)} Results`
              : "Done"}
          </FooterButton>
        </Footer>
      </Container>
    );
  }

  renderArbitraryOptions = () => {
    const {
      pathname,
      queryObject,
      isSearchRoute,
      currentArbitraryField,
      currentArbitraryOptions
    } = this.props;
    if (
      !currentArbitraryField.id ||
      (currentArbitraryField.isAutoComplete &&
        currentArbitraryOptions.length === 0)
    ) {
      return null;
    }

    if (currentArbitraryField.isSelect) {
      const selectedValues = currentArbitraryField.isSingular
        ? [(queryObject.arbitrary[currentArbitraryField.id] || {}).value]
        : (queryObject.arbitrary[currentArbitraryField.id] || {}).value || [];

      const triggerLabel = () => {
        if (currentArbitraryField.isSingular) {
          return (
            (queryObject.arbitrary[currentArbitraryField.id] || {}).value ||
            "Select Options"
          );
        }
        return selectedValues.length === 0
          ? "Select Options"
          : `Select Options (${selectedValues.length})`;
      };

      const handleSelect = (index) => {
        const value = (currentArbitraryOptions[index] || {}).value;
        const filter = buildArbitraryFilter({
          fieldObject: currentArbitraryField,
          value
        });
        if (currentArbitraryField.isSingular) {
          this.arbitrarySelect.handleClose();
        }

        if (selectedValues.includes(value)) {
          this.props.removeFilter({ filter });
        } else {
          this.props.addFilter({ filter, pathname });
        }
      };

      return (
        <Selector
          ref={(node) => (this.arbitrarySelect = node)}
          shouldOpenOnMountWithData={currentArbitraryField.isAutoComplete}
          selectedValues={
            currentArbitraryField.isSingular ? null : selectedValues
          }
          isSingular={currentArbitraryField.isSingular}
          options={currentArbitraryOptions}
          triggerLabel={triggerLabel()}
          handleSelect={handleSelect}
        />
      );
    }

    return (
      <RangeInput
        ref={(node) => (this.arbitraryRange = node)}
        minValue={
          isSearchRoute
            ? (
                (queryObject.arbitrary[currentArbitraryField.id] || {}).value ||
                {}
              ).min
            : ""
        }
        maxValue={
          isSearchRoute
            ? (
                (queryObject.arbitrary[currentArbitraryField.id] || {}).value ||
                {}
              ).max
            : ""
        }
        handleSubmitMin={this.handleSubmitArbitraryMin}
        handleSubmitMax={this.handleSubmitArbitraryMax}
        label="value"
      />
    );
  };

  handleNewLocation = ({ isDashboard }) => {
    if (!isDashboard && this.props.isDashboard && this.props.isOpen) {
      this.props.emitAction({
        type: "SET_IS_ADVANCED_SEARCH_OPEN",
        payload: false
      });
    }
  };

  handleNewArbitraryField = ({ currentArbitraryField }) => {
    if (currentArbitraryField.id !== this.props.currentArbitraryField.id) {
      if (this.arbitraryRange) {
        this.arbitraryRange.resetState();
      }
      if (
        this.props.currentArbitraryField.isSelect &&
        !this.props.currentArbitraryField.isAutoComplete
      ) {
        this.props.getArbitraryOptions(this.props.currentArbitraryField.field);
      }
    }
  };

  handleToggleAdvancedSearch = ({ isOpen }) => {
    if (!isOpen && this.props.isOpen) {
      window.addEventListener("keydown", this.handleTabKeyDown);
      window.addEventListener("keyup", this.handleTabKeyUp);
    }

    if (isOpen && !this.props.isOpen) {
      window.removeEventListener("keydown", this.handleTabKeyDown);
      window.removeEventListener("keyup", this.handleTabKeyUp);
    }
  };

  labelSingular = ({ field, label, options }) => {
    const { isSearchRoute, queryObject } = this.props;
    const filter =
      options.filter((option) => `${option.value}` === queryObject[field])[0] ||
      {};
    if (!isSearchRoute || !filter.label) {
      return label;
    }
    return filter.label;
  };

  labelMultiple = ({ field, label }) => {
    const { isSearchRoute, queryObject } = this.props;
    if (!isSearchRoute || (queryObject[field] || []).length === 0) {
      return label;
    }
    return `${label} (${queryObject[field].length})`;
  };

  handleSelectArbitraryField = (options, index) => {
    this.props.emitAction({
      type: "SET_CURRENT_ARBITRARY_FIELD",
      payload: options[index]
    });
    this.arbitraryField.handleClose();
  };

  handleAutocompleteChange = ({ target }) => {
    if (target.value) {
      this.props.getAutocompleteSuggestions(target.value);
    }
    if (!target.value) {
      this.props.emitAction({ type: "RESET_ARBITRARY_OPTIONS" });
    }
  };

  handleSelectSingular = (options, index) => {
    this.props.addFilter({
      filter: options[index],
      pathname: this.props.pathname
    });
    if (this[(options[index] || {}).field]) {
      this[options[index].field].handleClose();
    }
  };

  handleSelectMultiple = (field, index) => {
    const { queryObject, pathname } = this.props;
    const filter = (this.props[field] || [])[index] || {};
    const isSelected = (queryObject[field] || []).includes(filter.value);

    if (isSelected) {
      this.props.removeFilter({ filter });
    } else {
      this.props.addFilter({ filter, pathname });
    }
  };

  handleSubmitMin = (field, value) => {
    if (!value) {
      return this.props.removeFilter({ filter: { field, value } });
    }
    this.props.addFilter({
      filter: { field, value },
      pathname: this.props.pathname
    });
  };

  handleSubmitMax = (field, value) => {
    if (!value) {
      return this.props.removeFilter({ filter: { field, value } });
    }
    this.props.addFilter({
      filter: { field, value },
      pathname: this.props.pathname
    });
  };

  handleSubmitArbitraryMin = (value) => {
    const { pathname, currentArbitraryField: fieldObject } = this.props;
    if (!value) {
      return this.props.removeFilter({
        filter: buildArbitraryFilter({ fieldObject, value, isMin: true })
      });
    }
    this.props.addFilter({
      filter: buildArbitraryFilter({ fieldObject, value, isMin: true }),
      pathname
    });
  };

  handleSubmitArbitraryMax = (value) => {
    const { pathname, currentArbitraryField: fieldObject } = this.props;
    if (!value) {
      return this.props.removeFilter({
        filter: buildArbitraryFilter({ fieldObject, value, isMax: true })
      });
    }
    this.props.addFilter({
      filter: buildArbitraryFilter({ fieldObject, value, isMax: true }),
      pathname
    });
  };

  handleTabKeyDown = (event) => {
    if (event.key !== "Tab") {
      return;
    }
    ["status", "type", "subType", "offMarketDays"].forEach((selector) => {
      if (this[selector].isOpen()) {
        this[selector].handleClose();
      }
    });
  };

  handleTabKeyUp = (event) => {
    if (
      event.key !== "Tab" ||
      !window.document.activeElement.dataset.selector
    ) {
      return;
    }
    this[window.document.activeElement.dataset.selector].handleOpen();
  };

  handleClose = () => {
    this.props.emitAction({
      type: "SET_IS_ADVANCED_SEARCH_OPEN",
      payload: false
    });

    if (
      window.innerWidth > mobileBreakpoint &&
      !this.props.hasFilters &&
      !this.props.isMapOpen
    ) {
      this.props.emitAction({
        type: "SET_IS_OMNI_SEARCH_FOCUSED",
        payload: false
      });
    }

    if (window.innerWidth <= mobileBreakpoint) {
      this.props.emitAction({
        type: "SET_IS_OMNI_SEARCH_FOCUSED",
        payload: false
      });
    }
  };

  removeEventListeners = () => {
    window.removeEventListener("keydown", this.handleTabKeyDown);
    window.removeEventListener("keyup", this.handleTabKeyUp);
  };
}

AdvancedSearch.propTypes = {
  isOpen: PropTypes.bool,
  isMapOpen: PropTypes.bool,
  isDashboard: PropTypes.bool,
  isSearchRoute: PropTypes.bool,
  isArbitraryLoading: PropTypes.bool,
  hasFilters: PropTypes.bool,
  totalCount: PropTypes.number,
  status: PropTypes.array,
  type: PropTypes.array,
  subType: PropTypes.array,
  arbitraryFields: PropTypes.array,
  currentArbitraryField: PropTypes.object,
  currentArbitraryOptions: PropTypes.array,
  queryObject: PropTypes.object,
  pathname: PropTypes.string,
  addFilter: PropTypes.func,
  removeFilter: PropTypes.func,
  getArbitraryOptions: PropTypes.func,
  getAutocompleteSuggestions: PropTypes.func,
  emitAction: PropTypes.func
};

export default AdvancedSearch;
