import styled from "styled-components";
import { oneColBkptWithSplit, delayVisibility } from "../../../utils/styling";

export const Container = styled.div`
  ${delayVisibility};
  padding-top: 12px;
`;

export const Controls = styled.div`
  position: relative;
  margin-bottom: 20px;
  z-index: 2;
`;

export const LeftControls = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 50%;

  @media (max-width: ${({ isScreenSplit }) =>
      oneColBkptWithSplit(isScreenSplit)}px) {
    margin-bottom: 10px;
    width: 100%;
  }
`;

export const RightControls = styled.div`
  display: inline-block;
  vertical-align: top;
  text-align: right;
  width: 50%;

  div:last-child {
    margin-left: 10px;
  }

  @media (max-width: ${({ isScreenSplit }) =>
      oneColBkptWithSplit(isScreenSplit)}px) {
    width: 100%;
    text-align: left;

    div:last-child {
      margin-left: 0px;
      float: right;
    }
  }
`;

export const ResultsWrap = styled.div`
  position: relative;
  display: inline-block;
  vertical-align: top;
`;

export const Actions = styled.div`
  position: absolute;
  height: 30px;
  width: 30px;
  right: -38px;
  top: 2px;
`;

export const Listings = styled.div`
  z-index: -1;
`;
