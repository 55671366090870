import React from "react";

function SqftIcon() {
  return (
    <svg viewBox="0 0 100 100" preserveAspectRatio="xMidYMid meet">
      <path d="M29.1666667,64.786585 L64.786585,29.1666667 L58.3333333,29.1666667 L54.1666667,29.1666667 L54.1666667,20.8333333 L58.3333333,20.8333333 L75,20.8333333 L79.1666667,20.8333333 L79.1666667,25 L79.1666667,41.6666667 L79.1666667,45.8333333 L70.8333333,45.8333333 L70.8333333,41.6666667 L70.8333333,35.213415 L35.213415,70.8333333 L41.6666667,70.8333333 L45.8333333,70.8333333 L45.8333333,79.1666667 L41.6666667,79.1666667 L25,79.1666667 L20.8333333,79.1666667 L20.8333333,75 L20.8333333,58.3333333 L20.8333333,54.1666667 L29.1666667,54.1666667 L29.1666667,58.3333333 L29.1666667,64.786585 Z M88.8888889,0 L11.1111111,0 C5,0 0,5 0,11.1111111 L0,88.8888889 C0,95 5,100 11.1111111,100 L88.8888889,100 C95,100 100,95 100,88.8888889 L100,11.1111111 C100,5 95,0 88.8888889,0 L88.8888889,0 Z M88.8888889,88.8888889 L11.1111111,88.8888889 L11.1111111,11.1111111 L88.8888889,11.1111111 L88.8888889,88.8888889 L88.8888889,88.8888889 Z"></path>
    </svg>
  );
}

export default SqftIcon;
