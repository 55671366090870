export const initial = {
  isLoading: false,
  rawListings: [],
  isCards: true,
  filters: [],
  detailId: "",
  slug: "",
  role: "",
  name: "",
  count: 0,
  order: [],
  data: {}
};

export function sharedSearch(state = initial, { type, payload }) {
  switch (type) {
    case "SET_IS_SHARED_CARDS":
      return { ...state, isCards: payload };
    case "GET_SHARED_SEARCH_INITIATED":
      return { ...state, isLoading: true };
    case "GET_SHARED_SEARCH_FAILED":
      return { ...state, isLoading: false };
    case "GET_SHARED_SEARCH_SUCCEEDED":
      return { ...state, ...payload.search };
    case "SET_SHARED_DETAIL_ID":
      return { ...state, detailId: payload };
    case "TRANSFORM_RAW_SHARED_LISTINGS":
      return { ...state, ...payload, isLoading: false };
    default:
      return state;
  }
}
