import React from "react";

function BathIcon() {
  return (
    <svg viewBox="0 0 100 100" preserveAspectRatio="xMidYMid meet">
      <path d="M25,28 C30.5228475,28 35,23.5228475 35,18 C35,12.4771525 30.5228475,8 25,8 C19.4771525,8 15,12.4771525 15,18 C15,23.5228475 19.4771525,28 25,28 Z M45.75,48 C44.2,46.9 42.8,45.7 41.65,44.4 L34.65,36.65 C33.7,35.6 32.5,34.75 31.2,34.15 C29.75,33.45 28.1,33 26.4,33 L26.25,33 C20.05,33 15,38.05 15,44.25 L15,48 L0,48 L0,88 C0,93.5 4.5,98 10,98 L90,98 C95.5,98 100,93.5 100,88 L100,48 L45.75,48 L45.75,48 Z M25,88 L15,88 L15,58 L25,58 L25,88 L25,88 Z M45,88 L35,88 L35,58 L45,58 L45,88 L45,88 Z M65,88 L55,88 L55,58 L65,58 L65,88 L65,88 Z M85,88 L75,88 L75,58 L85,58 L85,88 L85,88 Z M83.25,17.3 L82.9,16.95 C80.05,13.85 78.8,9.9 79.55,5.95 L80,3 L70.55,3 L70.25,5.15 C69.25,11.95 71.6,18.7 76.75,23.75 L77.1,24.05 C79.95,27.15 81.2,31.1 80.45,35.05 L79.9,38 L89.45,38 L89.75,35.85 C90.8,29.05 88.4,22.3 83.25,17.3 L83.25,17.3 Z M63.25,17.3 L62.9,16.95 C60.05,13.85 58.8,9.9 59.55,5.95 L60,3 L50.55,3 L50.25,5.15 C49.25,11.95 51.6,18.7 56.75,23.75 L57.1,24.05 C59.95,27.15 61.2,31.1 60.45,35.05 L59.9,38 L69.45,38 L69.75,35.85 C70.8,29.05 68.4,22.3 63.25,17.3 L63.25,17.3 Z"></path>
    </svg>
  );
}

export default BathIcon;
