import React from "react";
import PropTypes from "prop-types";
import ArbitraryIcon from "../icons/ArbitraryIcon";
import AreaIcon from "../icons/AreaIcon";
import AlertIcon from "../icons/AlertIcon";
import BathIcon from "../icons/BathIcon";
import BedIcon from "../icons/BedIcon";
import CheckmarkIcon from "../icons/CheckmarkIcon";
import CollectionIcon from "../icons/CollectionIcon";
import DayIcon from "../icons/DayIcon";
import ErrorIcon from "../icons/ErrorIcon";
import GarageIcon from "../icons/GarageIcon";
import HelpIcon from "../icons/HelpIcon";
import InfoIcon from "../icons/InfoIcon";
import LocationIcon from "../icons/LocationIcon";
import MapIcon from "../icons/MapIcon";
import MlsNumIcon from "../icons/MlsNumIcon";
import PhotosIcon from "../icons/PhotosIcon";
import PriceIcon from "../icons/PriceIcon";
import SearchIcon from "../icons/SearchIcon";
import StatusIcon from "../icons/StatusIcon";
import StoryIcon from "../icons/StoryIcon";
import SubTypeIcon from "../icons/SubTypeIcon";
import SqftIcon from "../icons/SqftIcon";
import TypeIcon from "../icons/TypeIcon";
import UserIcon from "../icons/UserIcon";

function DynamicIcon({ name }) {
  return {
    agent: <UserIcon />,
    address: <LocationIcon />,
    arbitrary: <ArbitraryIcon />,
    area: <AreaIcon />,
    alert: <AlertIcon />,
    bathMin: <BathIcon />,
    bathRange: <BathIcon />,
    bedMin: <BedIcon />,
    bedRange: <BedIcon />,
    cheatSheet: <HelpIcon />,
    city: <LocationIcon />,
    collection: <CollectionIcon />,
    error: <ErrorIcon />,
    garageMin: <GarageIcon />,
    garageRange: <GarageIcon />,
    info: <InfoIcon />,
    lotMin: <SqftIcon />,
    lotRange: <SqftIcon />,
    mlsnum: <MlsNumIcon />,
    offMarketDays: <DayIcon />,
    photos: <PhotosIcon />,
    polygon: <MapIcon />,
    price: <PriceIcon />,
    priceMin: <PriceIcon />,
    priceRange: <PriceIcon />,
    search: <SearchIcon />,
    status: <StatusIcon />,
    storyMin: <StoryIcon />,
    storyRange: <StoryIcon />,
    subType: <SubTypeIcon />,
    success: <CheckmarkIcon />,
    sqftMin: <SqftIcon />,
    sqftRange: <SqftIcon />,
    type: <TypeIcon />,
    zip: <LocationIcon />
  }[name];
}

DynamicIcon.propTypes = {
  name: PropTypes.string
};

export default DynamicIcon;
