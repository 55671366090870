import { connect } from "react-redux";
import { flatten, take } from "lodash";
import { emitAction } from "../../../../utils/redux";
import { getOmniMatches } from "../../../../actions/search";
import { addFilter, removeFilter } from "../../../../actions/queryObject";
import { isSearchRoute, isSearchableRoute } from "../../../../utils/routing";
import { getFilters, maxOmniMatches } from "../../../../modules/search";
import OmniSearch from "../OmniSearch";

function select(store, { location: { pathname } }) {
  const filters = getFilters({ pathname, queryObject: store.queryObject });

  return {
    isOpen: store.omniSearch.inputValue.length > 1,
    isLoading: store.listings.isLoading,
    isFocused: store.omniSearch.isFocused,
    isMapOpen: store.mapSearch.isOpen,
    isAdvancedOpen: store.advancedSearch.isOpen,
    isCheatSheetOpen: store.cheatSheet.isOpen,
    isSearchRoute: isSearchRoute(pathname),
    inputValue: store.omniSearch.inputValue,
    matches: matches(store.omniSearch),
    staticOptions: flatten(Object.values(store.staticOptions)),
    availablePrimaryFields: store.mlsConfigs.availablePrimaryFields,
    hasFilters: filters.length > 0,
    isSearchable: isSearchableRoute({
      sharedSearch: store.sharedSearch,
      pathname
    }),
    pathname,
    filters
  };
}

function matches({
  synchronousMatches = [],
  asyncZipMatches = [],
  asyncAddressMatches = [],
  asyncMlsNumMatches = [],
  asyncAgentMatches = []
}) {
  const totalMatches = [
    ...synchronousMatches,
    ...asyncZipMatches,
    ...asyncAddressMatches,
    ...asyncMlsNumMatches,
    ...asyncAgentMatches
  ];
  return take(totalMatches, maxOmniMatches);
}

const actions = {
  addFilter,
  removeFilter,
  getOmniMatches,
  emitAction
};

export default connect(select, actions)(OmniSearch);
