import React, { createRef, Component } from "react";
import PropTypes from "prop-types";
import AddressOption from "./AddressOption";
import MapIcon from "../../icons/MapIcon";
import QueryIcon from "../../icons/QueryIcon";
import DynamicIcon from "../../common/DynamicIcon";
import RecentSearches from "../recent/RecentSearches";
import { Icon, Label, optionHeight } from "./styled/search-bar";
import { Options, Option, StandardOption } from "./styled/search-bar";
import { Underline, Input, MapButton, MapLayout } from "./styled/search-bar";
import { Container, InputLayout, InputIcon } from "./styled/search-bar";

class SearchBar extends Component {
  state = { wasClicked: false, isFocused: false, highlighted: 0 };

  inputRef = createRef();

  componentDidMount() {
    this.handleAutoFocus();
    this.handleResetInput();
    this.props.getRecentSearches();
  }

  componentDidUpdate(prevProps) {
    if (this.props.autoFocusTimestamp !== prevProps.autoFocusTimestamp) {
      this.handleAutoFocus();
    }

    this.handleOpenClose(prevProps);
    this.handleNewInputValue(prevProps);
  }

  componentWillUnmount() {
    this.removeEventListeners();
  }

  render() {
    const { wasClicked, isFocused } = this.state;
    const { isOpen, hasRecents, inputValue, hasOneCharacter } = this.props;
    const showRecents =
      (hasRecents && hasOneCharacter) ||
      (hasRecents && isFocused && wasClicked);

    return (
      <Container>
        <InputLayout>
          <Underline isOpen={isOpen || showRecents}>
            <InputIcon isFocused={isFocused}>
              <QueryIcon />
            </InputIcon>
            <Input
              placeholder="Address, MLS#, City, Zip, Status, Price, Agent Name, etc..."
              onFocus={() => this.setState({ isFocused: true })}
              onBlur={() => this.setState({ isFocused: false })}
              onChange={this.handleChange}
              onClick={this.handleClick}
              value={inputValue}
              ref={this.inputRef}
            />
            <MapButton onClick={this.handleMapClick}>
              <span>Map</span>
              <MapLayout>
                <MapIcon />
              </MapLayout>
            </MapButton>
          </Underline>
        </InputLayout>
        {isOpen && (
          <Options ref={(node) => (this.options = node)}>
            {this.props.matches.map((option, index) => {
              const isHighlighted = this.state.highlighted === index;
              const onMouseMove = () => {
                if (isHighlighted) {
                  return;
                }
                this.setState({ highlighted: index });
              };

              return (
                <Option
                  onClick={this.handleSelect}
                  onMouseMove={onMouseMove}
                  isHighlighted={isHighlighted}
                  key={index}>
                  {option.isAddressOption ? (
                    <AddressOption {...option} />
                  ) : (
                    <StandardOption>
                      <Icon field={option.field}>
                        <DynamicIcon name={option.field} />
                      </Icon>
                      <Label>{option.label}</Label>
                    </StandardOption>
                  )}
                </Option>
              );
            })}
          </Options>
        )}
        {!isOpen && showRecents && (
          <RecentSearches recentSearches={this.props.recentSearches} />
        )}
      </Container>
    );
  }

  handleOpenClose = ({ isOpen }) => {
    if (!isOpen && this.props.isOpen) {
      this.handleOpen();
    }
    if (isOpen && !this.props.isOpen) {
      this.handleClose();
    }
  };

  handleNewInputValue = ({ inputValue }) => {
    if (inputValue !== this.props.inputValue) {
      this.props.getOmniMatches({
        inputValue: this.props.inputValue,
        staticOptions: this.props.staticOptions,
        availablePrimaryFields: this.props.availablePrimaryFields
      });
    }
  };

  handleClick = () => {
    this.setState({ wasClicked: true });
  };

  handleChange = (event) => {
    this.props.emitAction({
      type: "SET_OMNI_SEARCH_INPUT_VALUE",
      payload: event.target.value
    });
  };

  handleAutoFocus = () => {
    if (this.inputRef.current) {
      this.inputRef.current.focus();
    }
  };

  handleResetInput = () => {
    this.props.emitAction({ type: "SET_OMNI_SEARCH_INPUT_VALUE", payload: "" });
  };

  handleWindowKeyDown = (event) => {
    if (["Escape", "ArrowUp", "ArrowDown", "Enter"].includes(event.key)) {
      event.preventDefault();
    }

    switch (event.key) {
      case "Enter":
        return this.handleSelect();
      case "ArrowUp":
        return this.handleUpArrow();
      case "ArrowDown":
        return this.handleDownArrow();
      case "Escape":
        return this.handleResetInput();
      default:
        return;
    }
  };

  handleUpArrow = () => {
    if (this.state.highlighted === 0) {
      return;
    }
    this.options.scrollTop = (this.state.highlighted - 3) * optionHeight;
    this.setState({ highlighted: this.state.highlighted - 1 });
  };

  handleDownArrow = () => {
    if (this.state.highlighted === this.props.matches.length - 1) {
      return;
    }
    this.options.scrollTop = (this.state.highlighted - 1) * optionHeight;
    this.setState({ highlighted: this.state.highlighted + 1 });
  };

  handleSelect = () => {
    const filter = this.props.matches[this.state.highlighted];
    this.props.addFilter({ filter, pathname: "/dashboard" });
    this.handleResetInput();
  };

  handleMapClick = () => {
    this.props.emitAction({ type: "RESET_QUERY_OBJECT" });
    this.props.emitAction({ type: "SET_IS_MAP_OPEN_VIA_USER", payload: true });
  };

  handleOpen = () => {
    window.setTimeout(() => (this.options.scrollTop = 0), 0);
    this.addEventListeners();
  };

  handleClose = () => {
    this.setState({ highlighted: 0 });
    this.removeEventListeners();
  };

  addEventListeners = () => {
    window.addEventListener("keydown", this.handleWindowKeyDown);
  };

  removeEventListeners = () => {
    window.removeEventListener("keydown", this.handleWindowKeyDown);
  };
}

SearchBar.propTypes = {
  isOpen: PropTypes.bool,
  matches: PropTypes.array,
  inputValue: PropTypes.string,
  staticOptions: PropTypes.array,
  hasRecents: PropTypes.bool,
  hasOneCharacter: PropTypes.bool,
  recentSearches: PropTypes.array,
  availablePrimaryFields: PropTypes.object,
  isCheatSheetOpen: PropTypes.bool,
  getRecentSearches: PropTypes.func,
  getOmniMatches: PropTypes.func,
  addFilter: PropTypes.func,
  emitAction: PropTypes.func,
  autoFocusTimestamp: PropTypes.number
};

export default SearchBar;
