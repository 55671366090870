import { getForgeSuggestions } from "../modules/forge";
import { transformArbitraryOptions } from "../modules/arbitrary";
import { getForgeArbitraryOptionsByField } from "../modules/forge";

export function getArbitraryOptions(field) {
  return async (dispatch, getState) => {
    const {
      currentUser: { mlsCode },
      application: { forgeToken }
    } = getState();

    dispatch({ type: "GET_ARBITRARY_OPTIONS_INITIATED" });

    try {
      const { mls } = await getForgeArbitraryOptionsByField({
        field,
        mlsCode,
        forgeToken
      });
      dispatch({
        type: "GET_ARBITRARY_OPTIONS_SUCCEEDED",
        payload: transformArbitraryOptions(mls.field.search_values)
      });
    } catch (error) {
      dispatch({ type: "GET_ARBITRARY_OPTIONS_FAILED", payload: error });
    }
  };
}

export function getAutocompleteSuggestions(inputValue) {
  return async (dispatch, getState) => {
    const {
      advancedSearch,
      currentUser: { mlsCode },
      application: { forgeToken }
    } = getState();
    const { field } = advancedSearch.currentArbitraryField;
    const requestedAt = Date.now();

    dispatch({ type: "GET_AUTOCOMPLETE_SUGGESTIONS_INITIATED" });

    try {
      const { mls } = await getForgeSuggestions({
        field,
        inputValue,
        mlsCode,
        forgeToken,
        isArbitrary: true
      });
      dispatch({
        type: "GET_AUTOCOMPLETE_SUGGESTIONS_SUCCEEDED",
        payload: {
          requestedAt,
          data: transformArbitraryOptions(mls.suggestions)
        }
      });
    } catch (error) {
      dispatch({ type: "GET_AUTOCOMPLETE_SUGGESTIONS_FAILED", payload: error });
    }
  };
}
