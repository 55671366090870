import numeral from "numeral";

export function formatNumber(value, format = "0[.]0[0]") {
  if (!value) return "";
  return numeral(value).format(format);
}

export function formatCurrency(number) {
  if (number !== 0 && !number) {
    return "";
  }
  return `$${number.toLocaleString()}`;
}

export function formatCurrencyShort(number) {
  const string = `${number}`;
  if (string.length < 4) {
    return `$${string}`;
  }
  if (string.length < 7) {
    return `$${numeral(string).format("0a")}`.toUpperCase();
  }
  return `$${numeral(string).format("0.0a")}`.toUpperCase();
}

export function formatDelimiters(number) {
  if (number !== 0 && !number) {
    return "";
  }
  return `${number.toLocaleString()}`;
}

export function sqftToAcres(sqft) {
  if (!sqft) {
    return "";
  }
  return window.parseFloat((sqft * 0.000022957).toFixed(2));
}

export function metersToKilometers(meters) {
  if (!meters) {
    return "";
  }
  return window.parseFloat((meters / 1000).toFixed(2));
}

export function metersToMiles(meters) {
  if (!meters) {
    return "";
  }
  return window.parseFloat((meters / 1609.344).toFixed(2));
}

export function milesToMeters(miles) {
  if (!miles) {
    return "";
  }
  return miles * 1609.344;
}

export function formatPhoneNumber(number = "") {
  return number
    .toString()
    .replace(/[-\s()]/g, "")
    .split("")
    .reduce(function (formatted, digit, index) {
      switch (index) {
        case 0:
          formatted += `(${digit}`;
          break;
        case 3:
          formatted += `) ${digit}`;
          break;
        case 6:
          formatted += `-${digit}`;
          break;
        default:
          formatted += digit;
      }
      return formatted;
    }, "");
}
