import React from "react";

function SearchIcon() {
  return (
    <svg viewBox="0 0 100 100" preserveAspectRatio="xMidYMid meet">
      <path d="M75.05,61 L71.05,61 L69.7,59.65 C74.6,53.95 77.55,46.6 77.55,38.5 C77.55,20.55 63,6 45.05,6 C27.1,6 12.55,21 12.55,38.5 L0,38.5 L19.2,58.5 L40,38.5 L22.55,38.5 C22.55,26 32.65,16 45.05,16 C57.45,16 67.55,26.05 67.55,38.5 C67.55,50.9 57.45,61 45.05,61 C41.8,61 38.75,60.3 35.95,59.1 L28.55,66.5 C33.4,69.35 39,71 45.05,71 C53.1,71 60.45,68.05 66.15,63.15 L67.5,64.5 L67.5,68.45 L92.55,93.4 L100,86 L75.05,61 L75.05,61 Z"></path>
    </svg>
  );
}

export default SearchIcon;
