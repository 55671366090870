import styled from "styled-components";
import {
  greyMedium,
  blueMedium,
  iconColor
} from "../../../../utils/colorsLocal";
import {
  mobileBreakpoint,
  absoluteFill,
  noWrap
} from "../../../../utils/styling";

export const maxOptions = 6;
export const optionHeight = 40;
const mapButtonWidthDesktop = 55;
const mapButtonWidthMobile = 20;

export const Container = styled.div`
  z-index: 2;
  position: absolute;
  background-color: white;
  border: 1px solid #e1e5e9;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  transform: translateX(-50%);
  border-radius: 25px;
  overflow: hidden;
  width: 580px;
  left: 50%;

  @media (max-width: ${mobileBreakpoint}px) {
    border-radius: 20px;
    width: 300px;
  }
`;

export const InputLayout = styled.div`
  padding: 0px 20px;
  height: 50px;

  @media (max-width: ${mobileBreakpoint}px) {
    height: 40px;
  }
`;

export const InputIcon = styled.div`
  position: absolute;
  height: 20px;
  width: 20px;
  left: 0px;
  top: 16px;

  svg {
    fill: ${({ isFocused }) => (isFocused ? blueMedium : "#576474")};
    cursor: pointer;
  }

  @media (max-width: ${mobileBreakpoint}px) {
    top: 10px;
  }
`;

export const Input = styled.input`
  position: absolute;
  width: calc(100% - ${mapButtonWidthDesktop}px);
  font-weight: 400;
  color: ${greyMedium};
  padding-right: 8px;
  padding-left: 40px;
  height: 100%;
  left: 0px;
  top: 0px;

  &::placeholder {
    color: #89929d;
  }

  @media (max-width: ${mobileBreakpoint}px) {
    width: calc(100% - ${mapButtonWidthMobile}px);
  }
`;

export const Underline = styled.div`
  border-bottom: ${({ isOpen }) => (isOpen ? "1px solid #E1E5E9" : "none")};
  position: relative;
  height: 100%;
`;

export const MapButton = styled.div`
  position: absolute;
  width: ${mapButtonWidthDesktop}px;
  font-weight: 400;
  line-height: 50px;
  font-size: 14px;
  cursor: pointer;
  color: #576474;
  bottom: 0px;
  right: 0px;
  top: 0px;

  @media (max-width: ${mobileBreakpoint}px) {
    width: ${mapButtonWidthMobile}px;

    span {
      display: none;
    }
  }
`;

export const MapLayout = styled.div`
  position: absolute;
  height: 20px;
  width: 20px;
  right: 0px;
  top: 16px;

  svg {
    fill: #576474;
  }

  @media (max-width: ${mobileBreakpoint}px) {
    top: 10px;
  }
`;

export const Options = styled.div`
  max-height: ${optionHeight * maxOptions}px;
  background-color: white;
  overflow-y: scroll;
`;

export const Option = styled.div`
  position: relative;
  background-color: ${({ isHighlighted }) =>
    isHighlighted ? "#F0F2F4" : "transparent"};
  line-height: ${optionHeight}px;
  height: ${optionHeight}px;
  cursor: pointer;
`;

export const StandardOption = styled.div`
  ${absoluteFill};
  padding: 0px 20px;
`;

export const Icon = styled.div`
  position: absolute;
  height: 20px;
  width: 20px;
  left: 20px;
  top: 10px;

  svg {
    fill: ${({ field }) => iconColor(field)};
  }
`;

export const Label = styled.div`
  font-weight: 400;
  color: ${greyMedium};
  padding-left: 40px;
  height: 100%;
  ${noWrap};
`;
