import React from "react";

function ErrorIcon() {
  return (
    <svg viewBox="0 0 100 100" preserveAspectRatio="xMidYMid meet">
      <path d="M50,0 C22.4,0 0,22.4 0,50 C0,77.6 22.4,100 50,100 C77.6,100 100,77.6 100,50 C100,22.4 77.6,0 50,0 L50,0 Z M10,50 C10,27.9 27.9,10 50,10 C59.25,10 67.75,13.15 74.5,18.45 L18.45,74.5 C13.15,67.75 10,59.25 10,50 L10,50 Z M50,90 C40.75,90 32.25,86.85 25.5,81.55 L81.55,25.5 C86.85,32.25 90,40.75 90,50 C90,72.1 72.1,90 50,90 L50,90 Z"></path>
    </svg>
  );
}

export default ErrorIcon;
