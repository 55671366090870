import { connect } from "react-redux";
import { emitAction } from "../../../../utils/redux";
import { getUgcStaticOptions } from "../../../../actions/session";
import { addFilter, removeFilter } from "../../../../actions/queryObject";
import { createSavedArea, updateSavedArea } from "../../../../actions/area";
import { getSearchPins, getListingsForPin } from "../../../../actions/search";
import { isSearchableRoute, isMappableRoute } from "../../../../utils/routing";
import { isSharedRoute } from "../../../../utils/routing";
import { getFilters } from "../../../../modules/search";
import MapContainer from "../MapContainer";

function select(store, { location: { pathname } }) {
  return {
    jwt: store.currentUser.jwt,
    isOpen: store.mapSearch.isOpen,
    isCreating: store.savedAreas.isCreating,
    isUpdating: store.savedAreas.isUpdating,
    isOpenViaUser: store.mapSearch.isOpenViaUser,
    isMappable: isMappableRoute({ pathname, sharedSearch: store.sharedSearch }),
    isSharedRoute: isSharedRoute(pathname),
    hasCreateError: store.savedAreas.hasCreateError,
    hasUpdateError: store.savedAreas.hasUpdateError,
    hoveredListingId: store.mapSearch.hoveredListingId,
    filters: getFilters({ queryObject: store.queryObject, pathname }),
    listingsForPins: store.mapSearch.listingsForPins,
    pins: Object.values(store.mapSearch.pins),
    listings: getListings({ store, pathname }),
    savedAreas: store.savedAreas,
    searchId: pathname.split("/searches/")[1],
    isSearchable: isSearchableRoute({
      sharedSearch: store.sharedSearch,
      pathname
    }),
    pathname
  };
}

function getListings({ store, pathname }) {
  if (isSharedRoute(pathname)) {
    return store.sharedSearch.order.map((id) => store.sharedSearch.data[id]);
  } else {
    return store.listings.data;
  }
}

const actions = {
  getUgcStaticOptions,
  getListingsForPin,
  createSavedArea,
  updateSavedArea,
  getSearchPins,
  removeFilter,
  addFilter,
  emitAction
};

export default connect(select, actions)(MapContainer);
