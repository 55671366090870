export const white = "#fff";
export const black = "#000";
export const gray = {
  100: "#fff",
  200: "#f9fafb",
  300: "#f4f5f6",
  400: "#eeeff1",
  500: "#e2e5e8",
  600: "#9ba4b0",
  700: "#737e8c",
  800: "#4d5a6a",
  900: "#212d3b"
};
export const cma = {
  100: "#e7fef4",
  200: "#bcf5dd",
  300: "#93ecc6",
  400: "#47d196",
  500: "#20b575",
  600: "#29a36f",
  700: "#178254",
  800: "#0a5c39",
  900: "#004d2c"
};
export const streams = {
  100: "#e6f8ff",
  200: "#b4e9fd",
  300: "#6ed1f7",
  400: "#4dbbe6",
  500: "#0aa1dc",
  600: "#0690c6",
  700: "#036d96",
  800: "#014965",
  900: "#00374d"
};
export const mlx = {
  100: "#e7f2fe",
  200: "#bad8f7",
  300: "#7db1e8",
  400: "#5c97d6",
  500: "#3a7abe",
  600: "#2964a3",
  700: "#174b82",
  800: "#0a325c",
  900: "#00254d"
};
export const attract = {
  100: "#ffebeb",
  200: "#ffcccd",
  300: "#ff999b",
  400: "#ff8082",
  500: "#fd5154",
  600: "#dd3c3f",
  700: "#c32225",
  800: "#790608",
  900: "#4d0001"
};
export const suite = {
  100: "#e7eafe",
  200: "#d1d7fa",
  300: "#939fec",
  400: "#707edb",
  500: "#5362c6",
  600: "#2939a3",
  700: "#172582",
  800: "#0a155c",
  900: "#131943"
};
export const teal = {
  100: "#ccffff",
  200: "#9efaf9",
  300: "#75f0ef",
  400: "#52e0df",
  500: "#33cccc",
  600: "#1fadac",
  700: "#0f8a89",
  800: "#056160",
  900: "#003333"
};
export const green = {
  100: "#e7fee7",
  200: "#a3f5a3",
  300: "#7de87d",
  400: "#5cd65c",
  500: "#40bf40",
  600: "#29a329",
  700: "#178217",
  800: "#0a5c0a",
  900: "#033003"
};
export const yellow = {
  100: "#fef6e6",
  200: "#fde0b4",
  300: "#fbc97e",
  400: "#f6b655",
  500: "#eea02b",
  600: "#da870b",
  700: "#ad6a05",
  800: "#7c4c03",
  900: "#4d2e00"
};
export const blue = {
  100: "#e7eefe",
  200: "#bacff7",
  300: "#93b0ec",
  400: "#7094db",
  500: "#4775d1",
  600: "#2952a3",
  700: "#173b82",
  800: "#0a255c",
  900: "#041b49"
};
export const purple = {
  100: "#f2e7fe",
  200: "#d7baf7",
  300: "#bd93ec",
  400: "#a370db",
  500: "#8953c6",
  600: "#6f2eb8",
  700: "#561b98",
  800: "#310a5c",
  900: "#180330"
};
export const violet = {
  100: "#fce7fe",
  200: "#f4baf7",
  300: "#e793ec",
  400: "#d570db",
  500: "#b840bf",
  600: "#9c29a3",
  700: "#7c1782",
  800: "#570a5c",
  900: "#2e0330"
};
export const red = {
  100: "#fee7e7",
  200: "#f7baba",
  300: "#ef8f8f",
  400: "#e05252",
  500: "#d22d2d",
  600: "#b31a1a",
  700: "#9c1616",
  800: "#760a0a",
  900: "#4b0202"
};

const initialsColors = [
  teal[500],
  green[500],
  yellow[500],
  blue[500],
  purple[500],
  violet[500],
  red[500]
];

export function getInitialsColor(name = "") {
  const charCodeTotal = name
    .split("")
    .reduce((total, char) => total + char.charCodeAt(), 0);
  const colorIndex = charCodeTotal % initialsColors.length;
  return initialsColors[colorIndex];
}

export function getStatusColor(status) {
  switch (status) {
    case "active":
      return green[500];
    case "closed":
      return red[500];
    case "pending":
      return yellow[500];
    case "backup":
      return teal[500];
    case "subject":
      return purple[500];
    default:
      return gray[900];
  }
}

export function getStatusColorSpectrum(status) {
  switch (status) {
    case "active":
      return green;
    case "closed":
      return red;
    case "pending":
      return yellow;
    case "backup":
      return teal;
    case "subject":
      return purple;
    default:
      return gray;
  }
}

export function getColorSpectrumFromColor(color) {
  const colors = {
    gray,
    cma,
    streams,
    mlx,
    attract,
    suite,
    teal,
    green,
    yellow,
    blue,
    purple,
    violet,
    red
  };
  return (
    Object.values(colors).find((spectrum) =>
      Object.values(spectrum)
        .map((color) => color.toLowerCase())
        .includes((color || "").toLowerCase())
    ) || colors.gray
  );
}
