import styled from "styled-components";
import { defaultBorderRadius, mobileBreakpoint } from "../../../utils/styling";
import {
  greyMedium,
  greyLight,
  greyLighter,
  blueMedium
} from "../../../utils/colorsLocal";

export const Container = styled.button`
  transition: background-color 100ms;
  border-radius: ${defaultBorderRadius}px;
  box-shadow: ${({ type }) => `0px 2px 0px ${shadowColor(type)}`};
  background-color: ${({ type }) => backgroundColor(type)};
  color: ${({ type }) => fontColor(type)};
  display: inline-block;
  ${({ stretch }) => stretch && `width: 100%;`}
  vertical-align: top;
  padding: 0px 20px;
  cursor: pointer;
  font-size: 15px;
  line-height: 40px;
  height: 40px;

  &:hover,
  &:disabled {
    background-color: ${({ type }) => hoverColor(type)};
  }
  &:disabled {
    cursor: not-allowed;
  }

  @media (max-width: ${mobileBreakpoint}px) {
    padding: 0px 10px;
    font-size: 14px;
    line-height: 30px;
    height: 30px;
  }
`;

function backgroundColor(type) {
  return {
    primary: blueMedium,
    secondary: greyLighter,
    danger: "#C63233"
  }[type];
}

function hoverColor(type) {
  return {
    primary: "#409EFF",
    secondary: "#EEF1F4",
    danger: "#D65453"
  }[type];
}

function shadowColor(type) {
  return {
    primary: "#076FCD",
    secondary: greyLight,
    danger: "#A0282C"
  }[type];
}

function fontColor(type) {
  return {
    primary: "white",
    secondary: greyMedium,
    danger: "white"
  }[type];
}

export const ButtonRow = styled.div`
  display: inline-flex;
  button {
    margin-right: 12px;
    &:last-of-type {
      margin-right: 0px;
    }
  }
`;
