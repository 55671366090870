import styled from "styled-components";
import { greyMedium } from "../../../utils/colorsLocal";
import { mobileBreakpoint } from "../../../utils/styling";

export const Container = styled.div`
  color: ${greyMedium};
`;

export const Title = styled.div`
  font-weight: bold;
  line-height: 24px;
  font-size: 18px;

  @media (max-width: ${mobileBreakpoint}px) {
    line-height: 20px;
    font-size: 16px;
  }
`;

export const Body = styled.div`
  line-height: 17px;
  font-size: 15px;

  @media (max-width: ${mobileBreakpoint}px) {
    line-height: 16px;
    font-size: 14px;
  }
`;
