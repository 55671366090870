import React from "react";

function ArbitraryIcon() {
  return (
    <svg viewBox="0 0 100 100" preserveAspectRatio="xMidYMid meet">
      <path d="M88.8888889,0 L11.1111111,0 C4.94444444,0 0,5 0,11.1111111 L0,88.8888889 C0,95 4.94444444,100 11.1111111,100 L88.8888889,100 C95.0555556,100 100,95 100,88.8888889 L100,11.1111111 C100,5 95.0555556,0 88.8888889,0 L88.8888889,0 Z M38.8888889,77.7777778 L11.1111111,50 L18.9444444,42.1666667 L38.8888889,62.0555556 L81.0555556,19.8888889 L88.8888889,27.7777778 L38.8888889,77.7777778 L38.8888889,77.7777778 Z"></path>
    </svg>
  );
}

export default ArbitraryIcon;
