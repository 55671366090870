import { omit, without } from "lodash";

const initial = {
  isLoading: false,
  hasCreateError: false,
  hasUpdateError: false,
  hasDeleteError: false,
  isCreating: false,
  isUpdating: false,
  isDeleting: false,
  count: 0,
  order: [],
  data: {}
};

export function savedAreas(state = initial, { type, payload }) {
  switch (type) {
    case "GET_SAVED_AREAS_INITIATED":
      return { ...state, isLoading: true };
    case "GET_SAVED_AREAS_SUCCEEDED":
      return {
        ...initial,
        count: payload.count,
        order: payload.order,
        data: payload.data,
        isLoading: false
      };
    case "GET_OPTIONS_FOR_SAVED_AREAS_SUCCEEDED":
      return {
        ...initial,
        order: payload.savedAreas.order,
        data: payload.savedAreas.data
      };
    case "GET_SAVED_AREAS_FAILED":
      return { ...state, isLoading: false };
    case "CREATE_SAVED_AREA_INITIATED":
      return { ...state, isCreating: true, hasCreateError: false };
    case "CREATE_SAVED_AREA_SUCCEEDED":
      return { ...state, isCreating: false };
    case "CREATE_SAVED_AREA_FAILED":
      return { ...state, isCreating: false, hasCreateError: true };
    case "UPDATE_SAVED_AREA_INITIATED":
      return { ...state, isUpdating: true, hasUpdateError: false };
    case "UPDATE_SAVED_AREA_SUCCEEDED":
      return { ...state, isUpdating: false };
    case "UPDATE_SAVED_AREA_FAILED":
      return { ...state, isUpdating: false, hasUpdateError: true };
    case "DELETE_SAVED_AREA_INITIATED":
      return { ...state, isDeleting: true, hasDeleteError: false };
    case "DELETE_SAVED_AREA_SUCCEEDED":
      return {
        ...state,
        isDeleting: false,
        order: without(state.order, payload.id),
        data: omit(state.data, payload.id)
      };
    case "DELETE_SAVED_AREA_FAILED":
      return { ...state, isDeleting: false, hasDeleteError: true };
    default:
      return state;
  }
}
