import React from "react";

function CollectionIcon() {
  return (
    <svg viewBox="0 0 100 100" preserveAspectRatio="xMidYMid meet">
      <path d="M90,20 L50,20 L40,10 L10,10 C4.45,10 0.05,14.45 0.05,20 L0,80 C0,85.55 4.45,90 10,90 L90,90 C95.55,90 100,85.55 100,80 L100,30 C100,24.45 95.55,20 90,20 L90,20 Z M85,60 L70,60 L70,75 L60,75 L60,60 L45,60 L45,50 L60,50 L60,35 L70,35 L70,50 L85,50 L85,60 L85,60 Z"></path>
    </svg>
  );
}

export default CollectionIcon;
