import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { chunk } from "lodash";
import StaticMap from "../../common/StaticMap";
import { formatPhoneNumber } from "../../../utils/number";
import { getDateLong, getDateTime } from "@wrstudios/utils";
import {
  Container,
  Page,
  Header,
  ThickDivider,
  ThinDivider,
  BulletPoint,
  Timestamp,
  PropertyOverview,
  Address,
  MarketInfo,
  PhotoAndMap,
  Description,
  Features,
  PrivateRemarks,
  PageBreak
} from "./styled/print-route";

class PrintRoute extends Component {
  render() {
    const { isPublic, isNonDisclosure, listings, sharingAgent } = this.props;

    return (
      <Container>
        {listings.map((listing, listingIndex) => {
          const details = listing.details;
          const chunked = chunk(details, Math.ceil(details.length / 3));

          return (
            <Fragment key={listingIndex}>
              <Page>
                <Header>
                  {sharingAgent.name}
                  {sharingAgent.phone && (
                    <>
                      <BulletPoint />
                      <a href={`tel:${sharingAgent.phone}`}>
                        {formatPhoneNumber(sharingAgent.phone)}
                      </a>
                    </>
                  )}
                  {sharingAgent.email && (
                    <>
                      <BulletPoint />
                      <a href={`mailto:${sharingAgent.email}`}>
                        {sharingAgent.email}
                      </a>
                    </>
                  )}
                  <Timestamp>{`${getDateLong(Date.now())} ${getDateTime(
                    Date.now()
                  )}`}</Timestamp>
                </Header>
                <ThickDivider />
                <PropertyOverview>
                  <Address>
                    <h1>{listing.address}</h1>
                    <span>
                      {listing.city}, {listing.state}, {listing.zip}
                    </span>
                    <span>{listing.cardFeatureBullets}</span>
                  </Address>
                  <MarketInfo>
                    <div>{listing.typeAndArea}</div>
                    <div>
                      <h2>
                        {isPublic && isNonDisclosure
                          ? listing.pricePublic
                          : listing.price}
                      </h2>
                      <span>{listing.statusLabel}</span>
                    </div>
                  </MarketInfo>
                </PropertyOverview>
                <PhotoAndMap>
                  <img src={listing.firstImage} alt={listing.address} />
                  <div>
                    <StaticMap
                      lat={listing.lat}
                      lon={listing.lon}
                      hasMarker={true}
                      height={215}
                      width={370}
                    />
                  </div>
                </PhotoAndMap>
                <ThickDivider />
                <Description>
                  <span>Description</span>
                  <span>{listing.comments}</span>
                </Description>
                <ThinDivider />
                <Features>
                  {chunked.map((column, featureIndex) => (
                    <div key={`column-${featureIndex}`}>
                      {column.map((feature, columnIndex) => (
                        <div key={`feature-${featureIndex}-${columnIndex}`}>
                          <strong>{feature.field}</strong>:&nbsp;{feature.value}
                        </div>
                      ))}
                    </div>
                  ))}
                </Features>
                <ThinDivider />
                {!isPublic && (listing.privateRemarks || listing.showing) && (
                  <PrivateRemarks>
                    {listing.privateRemarks && (
                      <div>
                        <strong>Private Remarks</strong>
                        <span>{listing.privateRemarks}</span>
                      </div>
                    )}
                    {listing.privateRemarks && listing.showing && (
                      <div style={{ height: 12 }} />
                    )}
                    {listing.showing && (
                      <div>
                        <strong>Showing Instructions</strong>
                        <span>{listing.showing}</span>
                      </div>
                    )}
                    {(listing.privateRemarks || listing.showing) && (
                      <div style={{ height: 24 }} />
                    )}
                    {(listing.privateRemarks || listing.showing) && (
                      <ThickDivider />
                    )}
                  </PrivateRemarks>
                )}
              </Page>
              <PageBreak />
            </Fragment>
          );
        })}
      </Container>
    );
  }
}

PrintRoute.propTypes = {
  isPublic: PropTypes.bool,
  listings: PropTypes.array,
  sharingAgent: PropTypes.object
};

export default PrintRoute;
