import { addToQueryObject, removeFromQueryObject } from "../modules/search";

export const initial = {
  arbitrary: {}
};

export function queryObject(state = initial, { type, payload }) {
  switch (type) {
    case "ADD_TO_QUERY_OBJECT": {
      return addToQueryObject({ filter: payload, queryObject: state });
    }
    case "RESET_AND_ADD_TO_QUERY_OBJECT": {
      return addToQueryObject({ filter: payload, queryObject: initial });
    }
    case "REMOVE_FROM_QUERY_OBJECT": {
      return removeFromQueryObject({ filter: payload, queryObject: state });
    }
    case "UPDATE_UNSAVED_AREA":
      return {
        ...state,
        polygon: state.polygon.map((filter) => {
          if (payload.prevFilter.value !== filter.value) {
            return filter;
          }
          return { ...filter, value: payload.nextFilter.value };
        })
      };
    case "CREATE_SAVED_AREA_SUCCEEDED":
    case "UPDATE_SAVED_AREA_SUCCEEDED":
      return {
        ...state,
        polygon: state.polygon.map((filter) => {
          if (payload.value !== filter.value) {
            return filter;
          }
          return payload;
        })
      };
    case "GET_SAVED_SEARCH_SUCCEEDED":
    case "CREATE_ADHOC_SEARCH_SUCCEEDED":
    case "UPDATE_SAVED_SEARCH_SUCCEEDED":
      return payload.queryObject;
    case "GET_SAVED_SEARCH_INITIATED":
    case "RESET_QUERY_OBJECT":
      return initial;
    default:
      return state;
  }
}
