import styled from "styled-components";
import { randomColor } from "../../../utils/colorsLocal";
import { mobileBreakpoint } from "../../../utils/styling";

export const Container = styled.div`
  background-color: ${randomColor()};
  background-image: ${({ avatarUrl }) =>
    avatarUrl ? `url(${avatarUrl})` : "none"};
  font-size: ${({ size }) => sizes[size].desktop.fontSize}px;
  line-height: ${({ size }) => sizes[size].desktop.diameter}px;
  height: ${({ size }) => sizes[size].desktop.diameter}px;
  width: ${({ size }) => sizes[size].desktop.diameter}px;
  background-size: cover;
  display: inline-block;
  vertical-align: top;
  border-radius: 50%;
  text-align: center;
  margin: 0 auto;
  color: white;

  &::before {
    content: "${({ avatarUrl, initials }) => (avatarUrl ? "" : initials)}";
  }

  @media (max-width: ${mobileBreakpoint}px) {
    font-size: ${({ size }) => sizes[size].mobile.fontSize}px;
    line-height: ${({ size }) => sizes[size].mobile.diameter}px;
    height: ${({ size }) => sizes[size].mobile.diameter}px;
    width: ${({ size }) => sizes[size].mobile.diameter}px;
  }
`;

const sizes = {
  small: {
    desktop: {
      diameter: 32,
      fontSize: 14
    },
    mobile: {
      diameter: 32,
      fontSize: 14
    }
  },
  medium: {
    desktop: {
      diameter: 48,
      fontSize: 20
    },
    mobile: {
      diameter: 48,
      fontSize: 20
    }
  },
  large: {
    desktop: {
      diameter: 68,
      fontSize: 28
    },
    mobile: {
      diameter: 68,
      fontSize: 28
    }
  },
  xl: {
    desktop: {
      diameter: 90,
      fontSize: 32
    },
    mobile: {
      diameter: 70,
      fontSize: 28
    }
  },
  xxl: {
    desktop: {
      diameter: 124,
      fontSize: 36
    },
    mobile: {
      diameter: 124,
      fontSize: 36
    }
  }
};
