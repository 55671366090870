import styled from "styled-components";
import { greyMedium } from "../../../utils/colorsLocal";

export const Container = styled.div`
  display: inline-block;
  vertical-align: top;
  color: ${greyMedium};
  line-height: 36px;
  font-size: 20px;
  height: 36px;
`;
