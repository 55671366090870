import styled from "styled-components";
import { blueMedium, greyLight } from "../../../utils/colorsLocal";
import { defaultBorderRadius } from "../../../utils/styling";

export const Container = styled.div`
  background-color: ${({ isChecked, isTransparent }) =>
    isChecked
      ? blueMedium
      : isTransparent
      ? "rgba(255, 255, 255, 0.5)"
      : "white"};
  border-color: ${({ isChecked, isTransparent }) =>
    isChecked ? blueMedium : isTransparent ? "white" : greyLight};
  border-radius: ${defaultBorderRadius}px;
  border-style: solid;
  border-width: 2px;
  cursor: pointer;
  padding: 1px;
  height: 100%;

  svg {
    fill: ${({ isChecked }) => (isChecked ? "white" : "transparent")};
  }
`;
