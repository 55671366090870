import React from "react";
import PropTypes from "prop-types";
import Checkbox from "../common/Checkbox";
import { history } from "../../utils/routing";
import {
  Container,
  Scroller,
  Sticky,
  Table,
  Header,
  Row,
  Cell,
  StickyRow,
  StickyCell,
  StickyCellInner,
  CheckboxLayout
} from "./styled/listing-table";

function ListingTable({
  isPublic,
  isSharedRoute,
  isNonDisclosure,
  isLoading,
  listings,
  parentPath,
  selectedMlsnums,
  handleClickCheckBox,
  handleHoverListing,
  handleClickListing
}) {
  return (
    <Container>
      {!isPublic && (
        <Sticky>
          <Table>
            <Header>
              <StickyCell>Select</StickyCell>
            </Header>
            {listings.map(({ mlsnum }, index) => (
              <StickyRow key={index}>
                <StickyCell>
                  <StickyCellInner>
                    <CheckboxLayout
                      onClick={
                        isLoading ? () => {} : () => handleClickCheckBox(mlsnum)
                      }>
                      <Checkbox
                        isChecked={
                          isLoading ? false : selectedMlsnums.includes(mlsnum)
                        }
                      />
                    </CheckboxLayout>
                  </StickyCellInner>
                </StickyCell>
              </StickyRow>
            ))}
          </Table>
        </Sticky>
      )}
      <Scroller isPublic={isPublic}>
        <Table>
          <Header>
            <Cell>Address</Cell>
            <Cell>Price</Cell>
            <Cell>Status</Cell>
            <Cell>Beds</Cell>
            <Cell>Baths</Cell>
            <Cell>SqFt</Cell>
            <Cell>DOM</Cell>
          </Header>
          {listings.map(
            (
              {
                id,
                address,
                city,
                state,
                zip,
                price,
                pricePublic,
                statusLabel,
                beds,
                baths,
                sqft,
                dom
              },
              index
            ) => {
              const listingUrl = `${parentPath}/listings/${id}`;
              const onAuthedClick = () => history.push(listingUrl);
              const onSharedClick = () => handleClickListing(id);
              const onMouseEnter = () => handleHoverListing(id);
              const onMouseLeave = () => handleHoverListing("");

              return (
                <Row
                  onClick={isSharedRoute ? onSharedClick : onAuthedClick}
                  onMouseEnter={onMouseEnter}
                  onMouseLeave={onMouseLeave}
                  isLoading={isLoading}
                  key={index}>
                  <Cell>{`${address}, ${city}, ${state} ${zip}`}</Cell>
                  <Cell>
                    {isPublic && isNonDisclosure ? pricePublic : price}
                  </Cell>
                  <Cell>{statusLabel}</Cell>
                  <Cell>{beds}</Cell>
                  <Cell>{baths}</Cell>
                  <Cell>{sqft}</Cell>
                  <Cell>{dom}</Cell>
                </Row>
              );
            }
          )}
        </Table>
      </Scroller>
    </Container>
  );
}

ListingTable.defaultProps = {
  handleHoverListing: () => {},
  handleClickListing: () => {}
};

ListingTable.propTypes = {
  listings: PropTypes.array,
  parentPath: PropTypes.string,
  isPublic: PropTypes.bool,
  isLoading: PropTypes.bool,
  isSharedRoute: PropTypes.bool,
  isNonDisclosure: PropTypes.bool,
  selectedMlsnums: PropTypes.array,
  handleClickCheckBox: PropTypes.func,
  handleHoverListing: PropTypes.func,
  handleClickListing: PropTypes.func
};

export default ListingTable;
