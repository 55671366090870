import { connect } from "react-redux";
import { emitAction } from "../../../utils/redux";
import { logoutUser } from "../../../actions/session";
import LegacyNavigation from "../LegacyNavigation";

function select({ application, currentUser, mapSearch }) {
  return {
    isMapOpen: mapSearch.isOpen,
    isNavOpen: application.isNavOpen,
    isSwitcherEnabled: application.isSwitcherEnabled,
    initials: currentUser.initials,
    avatarUrl: currentUser.avatarUrl,
    userId: currentUser.id,
    userName: currentUser.name,
    userEmail: currentUser.email,
    id: currentUser.id,
    jwt: currentUser.jwt,
    mlsCode: currentUser.mlsCode,
    isLoggedInAs: currentUser.isLoggedInAs
  };
}

const actions = {
  emitAction,
  logoutUser
};

export default connect(select, actions)(LegacyNavigation);
