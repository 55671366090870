import styled from "styled-components";
import { greyMedium } from "../../../utils/colorsLocal";
import {
  noWrap,
  absoluteFill,
  defaultBorderRadius
} from "../../../utils/styling";

export const Container = styled.div`
  position: relative;

  #checkbox {
    display: ${({ isChecked }) => (isChecked ? "block" : "none")};
  }

  &:hover {
    #checkbox {
      display: block;
    }
  }
`;

export const CheckboxLayout = styled.div`
  position: absolute;
  height: 24px;
  width: 24px;
  z-index: 1;
  left: 10px;
  top: 10px;
`;

export const AgentAvatar = styled.div`
  position: absolute;
  background-color: ${({ bgColor }) => bgColor};
  border-radius: 50%;
  text-align: center;
  line-height: 32px;
  font-size: 14px;
  color: white;
  height: 32px;
  width: 32px;
  z-index: 1;
  right: 10px;
  top: 170px;
`;

export const AgentPopup = styled.div`
  ${absoluteFill};
  display: ${({ showAgent }) => (showAgent ? "block" : "none")};
  top: 212px;
  z-index: 2;
`;

export const AgentInfo = styled.div`
  ${absoluteFill};
  background-color: white;
  border-radius: ${defaultBorderRadius}px;
  top: 0px;

  &::before {
    content: "◆";
    position: absolute;
    font-size: 24px;
    color: white;
    right: 17px;
    top: -17px;
  }
`;

export const AgentInitials = styled.div`
  position: absolute;
  background-color: ${({ bgColor }) => bgColor};
  transform: translateY(-50%);
  border-radius: 50%;
  text-align: center;
  line-height: 60px;
  font-size: 24px;
  color: white;
  height: 60px;
  width: 60px;
  left: 10px;
  top: 50%;
`;

export const AgentDetails = styled.div`
  margin-left: 70px;
  padding: 10px;
`;

export const AgentName = styled.div`
  ${noWrap};
  font-size: 15px;
  margin-bottom: 4px;
`;

export const AgentOffice = styled.div`
  ${noWrap};
  font-size: 13px;
  color: ${greyMedium};
  margin-bottom: 4px;
`;

export const Contact = styled.div`
  ${noWrap};
  font-size: 12px;
`;
