import React from "react";

function ChevronDownIcon() {
  return (
    <svg viewBox="0 0 100 100" preserveAspectRatio="xMidYMid meet">
      <path d="M49.8252581,82.4212635 L96.4673543,35.8978372 C100.690393,31.6855427 100.720041,24.8538683 96.5337212,20.6050793 L96.5337212,20.6050793 C92.3680576,16.3772547 85.563791,16.3268634 81.3359665,20.492527 C81.3279105,20.5004645 81.3198671,20.5084147 81.3118363,20.5163776 L49.8252581,51.7365355 L18.2783547,20.3628631 C14.1524216,16.2595864 7.49759082,16.2253011 3.32959816,20.2858478 L3.32959816,20.2858478 C-0.79605026,24.3051419 -0.882267472,30.9079224 3.13702662,35.0335708 C3.15067091,35.0475762 3.16435456,35.0615431 3.17807741,35.0754715 L49.8252581,82.4212635 Z"></path>
    </svg>
  );
}

export default ChevronDownIcon;
