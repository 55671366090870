import React from "react";
import PropTypes from "prop-types";
import {
  Container,
  FullAddress,
  AddressInfo,
  AddressDate,
  AddressStatus
} from "./styled/address-option";

function AddressOption({ dateLabel, statusLabel, statusValue, fullAddress }) {
  return (
    <Container>
      <FullAddress>{fullAddress}</FullAddress>
      <AddressInfo>
        <AddressDate>{dateLabel}</AddressDate>
        <AddressStatus statusValue={statusValue}>{statusLabel}</AddressStatus>
      </AddressInfo>
    </Container>
  );
}

AddressOption.propTypes = {
  dateLabel: PropTypes.string,
  statusLabel: PropTypes.string,
  statusValue: PropTypes.string,
  fullAddress: PropTypes.string
};

export default AddressOption;
