import React from "react";

function PhotosIcon() {
  return (
    <svg viewBox="0 0 100 100" preserveAspectRatio="xMidYMid meet">
      <path d="M50,71 C58.836556,71 66,63.836556 66,55 C66,46.163444 58.836556,39 50,39 C41.163444,39 34,46.163444 34,55 C34,63.836556 41.163444,71 50,71 Z M35,5 L25.85,15 L10,15 C4.5,15 0,19.5 0,25 L0,85 C0,90.5 4.5,95 10,95 L90,95 C95.5,95 100,90.5 100,85 L100,25 C100,19.5 95.5,15 90,15 L74.15,15 L65,5 L35,5 L35,5 Z M50,80 C36.2,80 25,68.8 25,55 C25,41.2 36.2,30 50,30 C63.8,30 75,41.2 75,55 C75,68.8 63.8,80 50,80 L50,80 Z"></path>
    </svg>
  );
}

export default PhotosIcon;
