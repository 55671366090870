const initial = {
  isListingRoute: false,
  isSharedRoute: false,
  isPublic: true
};

export function print(state = initial, { type, payload }) {
  switch (type) {
    case "SET_PRINT_VIEW":
      return { ...state, ...payload };
    case "RESET_AND_ADD_TO_QUERY_OBJECT":
    case "RESET_QUERY_OBJECT":
      return initial;
    default:
      return state;
  }
}
