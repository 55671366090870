import { connect } from "react-redux";
import { getListingToRender } from "../../../../modules/listing";
import { ListingHighlights } from "@wrstudios/components";

function select({ listing, sharedSearch }) {
  const listingToRender = getListingToRender({ listing, sharedSearch });

  return {
    primaryBeds: Number(listingToRender.beds),
    primaryBaths: Number(listingToRender.baths),
    primarySqft: Number(listingToRender.sqft),
    primaryGarages: Number(listingToRender.garages),
    primaryYearBuilt: Number(listingToRender.built),
    primaryLotSize: Number(listingToRender.lotSize)
  };
}

export default connect(select)(ListingHighlights);
