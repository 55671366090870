import React from "react";

function ListIcon() {
  return (
    <svg viewBox="0 0 100 100" preserveAspectRatio="xMidYMid meet">
      <path d="M0,61.9411765 L23.5294118,61.9411765 L23.5294118,38.4117647 L0,38.4117647 L0,61.9411765 L0,61.9411765 Z M0,91.3529412 L23.5294118,91.3529412 L23.5294118,67.8235294 L0,67.8235294 L0,91.3529412 L0,91.3529412 Z M0,32.5294118 L23.5294118,32.5294118 L23.5294118,9 L0,9 L0,32.5294118 L0,32.5294118 Z M29.4117647,61.9411765 L100,61.9411765 L100,38.4117647 L29.4117647,38.4117647 L29.4117647,61.9411765 L29.4117647,61.9411765 Z M29.4117647,91.3529412 L100,91.3529412 L100,67.8235294 L29.4117647,67.8235294 L29.4117647,91.3529412 L29.4117647,91.3529412 Z M29.4117647,9 L29.4117647,32.5294118 L100,32.5294118 L100,9 L29.4117647,9 L29.4117647,9 Z"></path>
    </svg>
  );
}

export default ListIcon;
