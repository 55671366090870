import React from "react";
import PropTypes from "prop-types";
import { Redirect as ReactRouterRedirect } from "react-router-dom";

function Redirect({ to }) {
  return <ReactRouterRedirect to={to} />;
}

Redirect.propTypes = {
  to: PropTypes.string
};

export default Redirect;
