import { transformListings } from "../modules/listing";
import { apiClient } from "../modules/api";
import { getSearchGQL, getSearchesGQL } from "@wrstudios/api";
import { createSearchGQL, updateSearchGQL } from "@wrstudios/api";
import { deleteSearchGQL, touchSearchGQL } from "@wrstudios/api";
import { isCollectionNameUnique } from "../modules/collection";
import { transformCollections } from "../modules/collection";
import { updateListingsInCollection } from "../modules/collection";
import { transformCollection, transformCollector } from "../modules/collection";

export function getCollections(page) {
  return async (dispatch, getState) => {
    const { mlsConfigs, pagination } = getState();
    const { searchesLimit, searchesSort } = pagination;

    dispatch({ type: "GET_COLLECTIONS_INITIATED", payload: { page } });

    try {
      const response = await getSearchesGQL({
        apiClient,
        roles: ["collection"],
        listingLevel: "collections",
        listingsLimit: 100,
        searchesPage: page,
        searchesLimit,
        searchesSort
      });

      const searches = transformCollections({
        photoProxyStrategy: mlsConfigs.photoProxyStrategy,
        searches: response.data.data.searches
      });

      const payload = { ...searches, count: response.data.data.searches_count };
      dispatch({ type: "GET_COLLECTIONS_SUCCEEDED", payload });
    } catch (error) {
      dispatch({ type: "GET_COLLECTIONS_FAILED", payload: error });
    }
  };
}

export function getCollection({ id, page }) {
  return async (dispatch, getState) => {
    const { mlsConfigs, pagination } = getState();
    dispatch({ type: "GET_COLLECTION_INITIATED", payload: { page } });

    try {
      const response = await getSearchGQL({
        apiClient,
        limit: pagination.listingsLimit,
        sort: pagination.listingsSort,
        page,
        id
      });

      const search = response.data.data.search;

      const payload = {
        ...transformCollection({ search }),
        count: search.total,
        data: transformListings({
          photoProxyStrategy: mlsConfigs.photoProxyStrategy,
          listings: search.listings
        })
      };

      dispatch({ type: "GET_COLLECTION_SUCCEEDED", payload });
      await touchSearchGQL({ apiClient, id });
    } catch (error) {
      dispatch({ type: "GET_COLLECTION_FAILED", payload: error });
    }
  };
}

export function createCollection({ collection, listingId }) {
  return async (dispatch, getState) => {
    const { collections } = getState();

    if (!isCollectionNameUnique({ collection, collections })) {
      return;
    }

    dispatch({ type: "CREATE_COLLECTION_INITIATED" });

    try {
      const response = await createSearchGQL({
        apiClient,
        role: "collection",
        filters: [{ field: "id", eq: [listingId] }],
        name: collection.name,
        listingLevel: "justIds",
        limit: 100
      });

      const payload = transformCollection({
        search: response.data.data.createSearch.search
      });

      dispatch({ type: "CREATE_COLLECTION_SUCCEEDED", payload });
    } catch (error) {
      dispatch({ type: "CREATE_COLLECTION_FAILED", payload: error });
    }
  };
}

export function updateCollection({ collection, listingId }) {
  return async (dispatch, getState) => {
    const nextCollection = updateListingsInCollection({
      collection,
      listingId
    });

    dispatch({ type: "UPDATE_COLLECTION_INITIATED", payload: nextCollection });

    try {
      const response = await updateSearchGQL({
        apiClient,
        role: "collection",
        id: nextCollection.id,
        filters: [{ field: "id", eq: nextCollection.listingIds }],
        listingLevel: "justIds",
        limit: 100
      });

      const collection = transformCollection({
        search: response.data.data.updateSearch.search
      });

      dispatch({ type: "UPDATE_COLLECTION_SUCCEEDED", payload: collection });
    } catch (error) {
      dispatch({ type: "UPDATE_COLLECTION_FAILED", payload: error });
    }
  };
}

export function deleteCollection(id) {
  return async (dispatch) => {
    dispatch({ type: "DELETE_COLLECTION_INITIATED" });

    try {
      await deleteSearchGQL({ apiClient, id });
      dispatch({
        type: "DELETE_COLLECTION_SUCCEEDED",
        payload: { id }
      });
    } catch (error) {
      dispatch({ type: "DELETE_COLLECTION_FAILED", payload: error });
    }
  };
}

export function getCollector() {
  return async (dispatch, getState) => {
    dispatch({ type: "GET_LISTING_COLLECTOR_INITIATED" });

    try {
      const response = await getSearchesGQL({
        apiClient,
        roles: ["collection"],
        listingLevel: "justIds",
        searchesSort: "name",
        searchesLimit: 100,
        listingsLimit: 100
      });

      const searches = transformCollector(response.data.data.searches);
      dispatch({ type: "GET_LISTING_COLLECTOR_SUCCEEDED", payload: searches });
    } catch (error) {
      dispatch({ type: "GET_LISTING_COLLECTOR_FAILED", payload: error });
    }
  };
}
