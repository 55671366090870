import styled from "styled-components";
import {
  greyLighter,
  blueMedium,
  bluePowder
} from "../../../../utils/colorsLocal";
import {
  zIndexSelector,
  defaultBorderRadius,
  defaultBoxShadow,
  noWrap
} from "../../../../utils/styling";

const collectionHeight = 40;
const iconWidth = 18;

export const Container = styled.div`
  position: absolute;
  z-index: ${zIndexSelector};
  border-radius: ${defaultBorderRadius}px;
  background-color: white;
  ${defaultBoxShadow};
  cursor: default;
  right: -1px;
  width: 100%;
  top: 34px;
`;

export const Input = styled.input`
  border-bottom: 1px solid #eee;
  padding: 6px 10px;
  font-size: 14px;
  height: 34px;
  width: 100%;

  &::placeholder {
    color: ${greyLighter};
  }
`;

export const Collections = styled.div`
  max-height: ${collectionHeight * 5}px;
  overflow-y: scroll;
`;

export const Collection = styled.div`
  background-color: ${({ isHighlighted }) =>
    isHighlighted ? bluePowder : "transparent"};
  height: ${collectionHeight}px;
  cursor: pointer;
  padding: 10px;
`;

export const CheckboxLayout = styled.div`
  width: ${iconWidth}px;
  display: inline-block;
  vertical-align: top;
`;

export const CollectionName = styled.div`
  ${noWrap};
  width: calc(100% - ${iconWidth}px);
  display: inline-block;
  vertical-align: top;
  padding-left: 10px;
  line-height: 18px;
  font-size: 14px;
  height: 18px;
`;

export const AddCollection = styled.div`
  height: ${iconWidth}px;
  width: ${iconWidth}px;
  display: inline-block;
  vertical-align: top;
  padding-left: 1px;
  line-height: 14px;
  font-size: 30px;

  &::before {
    content: "+";
    font-weight: bold;
    color: ${blueMedium};
  }
`;
