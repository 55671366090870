import "mapbox-gl/dist/mapbox-gl.css";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";
import Notice from "../../common/Notice";
import ListingEmpty from "./ListingEmpty";
import Notification from "../../common/Notification";
import ListingHeader from "./connected/ListingHeader";
import ListingSlider from "./connected/ListingSlider";
import ListingDescription from "./connected/ListingDescription";
import ListingHighlights from "./connected/ListingHighlights";
import ListingShowings from "./connected/ListingShowings";
import ListingDetails from "./connected/ListingDetails";
import ListingHistory from "./connected/ListingHistory";
import ListingControls from "./connected/ListingControls";
import ListingDisclaimer from "./connected/ListingDisclaimer";
import ListingFullscreenMedia from "./connected/ListingFullscreenMedia";
import ListingMlsDisclaimer from "./connected/ListingMlsDisclaimer";
import {
  Container,
  Body,
  Grid,
  Content,
  Sidebar,
  Group,
  Disclaimer
} from "./styled/listing-route";

class ListingRoute extends Component {
  componentDidMount() {
    this.handleNewLocation();
  }

  componentDidUpdate(prevProps) {
    this.handleReloadLocation(prevProps);
    this.handleNewListingId(prevProps);
    this.handleCreateCollection(prevProps);
    this.handleUpdateCollection(prevProps);
    this.handleTrackEvent(prevProps);
  }

  render() {
    const {
      id,
      pathId,
      isPublic,
      isLoading,
      pageTitle,
      isHistoryHidden,
      isFullscreenMediaOpen
    } = this.props;

    if (isLoading || id !== pathId) {
      return <ListingEmpty />;
    }

    if (isFullscreenMediaOpen) {
      return <ListingFullscreenMedia />;
    }

    return (
      <Container>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        <ListingHeader />
        <ListingSlider />
        <Body>
          <Grid>
            <Content>
              <Group>
                <ListingHighlights />
              </Group>
              <Group>
                <ListingDescription />
              </Group>
              {!isPublic && (
                <Group>
                  <ListingShowings />
                </Group>
              )}
              <Group>
                <ListingDetails />
              </Group>
              {!isHistoryHidden && (
                <Group>
                  <ListingHistory />
                </Group>
              )}
              <Group>
                <ListingMlsDisclaimer />
              </Group>
            </Content>
            <Sidebar>
              <ListingControls />
              <Disclaimer>
                <ListingDisclaimer />
              </Disclaimer>
            </Sidebar>
          </Grid>
        </Body>
        <Notification ref={(node) => (this.createError = node)} type="error">
          <Notice title="Error" body="Your collection could not be created" />
        </Notification>
        <Notification ref={(node) => (this.updateError = node)} type="error">
          <Notice title="Error" body="Your collection could not be updated" />
        </Notification>
      </Container>
    );
  }

  handleNewLocation = () => {
    if (
      this.props.isAppReady &&
      this.props.isListingActive &&
      this.props.isShowingTimeAvailable &&
      this.props.id === this.props.pathId
    ) {
      this.props.getShowingTimeUrl();
    }

    if (this.props.isAppReady && !this.props.isPublic) {
      this.props.getCollector();
    }

    if (this.props.isAppReady && this.props.id !== this.props.pathId) {
      const { pathId, searchId } = this.props;
      this.props.getListing({ searchId, listingId: pathId });
      this.props.emitAction({ type: "SET_SHOULD_TRACK_LISTING" });
    }
  };

  handleReloadLocation = (prevProps) => {
    if (
      !prevProps.isAppReady &&
      this.props.isAppReady &&
      prevProps.isListingActive &&
      prevProps.isShowingTimeAvailable
    ) {
      this.props.getShowingTimeUrl();
    }

    if (
      !prevProps.isAppReady &&
      this.props.isAppReady &&
      !this.props.isPublic
    ) {
      this.props.getCollector();
    }

    if (
      !prevProps.isAppReady &&
      this.props.isAppReady &&
      this.props.id !== this.props.pathId
    ) {
      const { pathId, searchId } = this.props;
      this.props.getListing({ searchId, listingId: pathId });
    }
  };

  handleNewListingId = ({ id }) => {
    const { isListingActive, isShowingTimeAvailable } = this.props;
    if (id !== this.props.id && isListingActive && isShowingTimeAvailable) {
      this.props.getShowingTimeUrl();
    }
  };

  handleCreateCollection = ({ isCreating, hasCreateError }) => {
    if (!hasCreateError && this.props.hasCreateError) {
      return this.createError.display();
    }
    if (isCreating && !this.props.isCreating) {
      this.props.getUgcStaticOptions();
    }
  };

  handleUpdateCollection = ({ hasUpdateError }) => {
    if (!hasUpdateError && this.props.hasUpdateError) {
      return this.updateError.display();
    }
  };

  handleTrackEvent = ({ isLoading }) => {
    if (isLoading && !this.props.isLoading && this.props.shouldTrack) {
      const { eventName, metaData } = this.props;
      this.props.trackEvent({ eventName, metaData });
    }
  };
}

ListingRoute.propTypes = {
  id: PropTypes.string,
  pathId: PropTypes.string,
  searchId: PropTypes.string,
  pageTitle: PropTypes.string,
  eventName: PropTypes.string,
  metaData: PropTypes.object,
  isPublic: PropTypes.bool,
  isLoading: PropTypes.bool,
  isAppReady: PropTypes.bool,
  isCreating: PropTypes.bool,
  shouldTrack: PropTypes.bool,
  hasCreateError: PropTypes.bool,
  hasUpdateError: PropTypes.bool,
  isHistoryHidden: PropTypes.bool,
  isShowingTimeAvailable: PropTypes.bool,
  isFullscreenMediaOpen: PropTypes.bool,
  isListingActive: PropTypes.bool,
  getShowingTimeUrl: PropTypes.func,
  getUgcStaticOptions: PropTypes.func,
  getCollector: PropTypes.func,
  getListing: PropTypes.func,
  trackEvent: PropTypes.func,
  emitAction: PropTypes.func
};

export default ListingRoute;
