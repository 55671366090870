import React from "react";

function DayIcon() {
  return (
    <svg viewBox="0 0 100 100" preserveAspectRatio="xMidYMid meet">
      <path d="M57.1428571,7 C33.4761905,7 14.2857143,26.1895165 14.2857143,49.8549995 L0,49.8549995 L18.5238095,68.3778827 L18.8571429,69.044516 L38.0952381,49.8549995 L23.8095238,49.8549995 C23.8095238,31.4273497 38.7142857,16.5233332 57.1428571,16.5233332 C75.5714286,16.5233332 90.4761905,31.4273497 90.4761905,49.8549995 C90.4761905,68.2826493 75.5714286,83.1866659 57.1428571,83.1866659 C47.952381,83.1866659 39.6190476,79.4249492 33.6190476,73.3776326 L26.8571429,80.1391992 C34.6190476,87.9007158 45.2857143,92.7099991 57.1428571,92.7099991 C80.8095238,92.7099991 100,73.5204826 100,49.8549995 C100,26.1895165 80.8095238,7 57.1428571,7 L57.1428571,7 Z M52.3809524,30.8083331 L52.3809524,54.6166662 L72.7619048,66.7112994 L76.1904762,60.9496828 L59.5238095,51.0454162 L59.5238095,30.8083331 L52.3809524,30.8083331 L52.3809524,30.8083331 Z"></path>
    </svg>
  );
}

export default DayIcon;
