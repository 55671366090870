import styled from "styled-components";
import { defaultBorderRadius } from "../../../utils/styling";
import { greyMedium, greyLighter } from "../../../utils/colorsLocal";

const borderWidth = 2;
const paddingLeft = 10;

export const Container = styled.div`
  position: relative;
  border-radius: ${defaultBorderRadius}px;
  border: ${borderWidth}px solid ${greyLighter};
  padding-left: ${paddingLeft}px;
  display: inline-block;
  vertical-align: top;
  background-color: white;
`;

export const Select = styled.select`
  width: ${({ width }) => width - 2 * borderWidth - paddingLeft}px;
  color: ${greyMedium};
  line-height: 30px;
  font-size: 13px;
  cursor: pointer;
  height: 32px;
`;

export const SelectPointer = styled.div`
  pointer-events: none;
  position: absolute;
  right: 10px;
  top: 13px;

  svg {
    fill: ${greyMedium};
  }
`;
