import { connect } from "react-redux";
import { emitAction } from "../../../../utils/redux";
import NotFound from "../NotFound";

function select({ application }) {
  return {
    isPublic: application.isPublic
  };
}

const actions = {
  emitAction
};

export default connect(select, actions)(NotFound);
