export const initial = {
  savedSearches: [],
  savedAreas: [],
  collections: [],
  status: [],
  type: [],
  subType: [],
  city: [],
  zip: [],
  area: []
};

export function staticOptions(state = initial, { type, payload }) {
  switch (type) {
    case "GET_OPTIONS_FOR_SAVED_SEARCHES_SUCCEEDED":
      return { ...state, savedSearches: payload.options };
    case "GET_OPTIONS_FOR_SAVED_AREAS_SUCCEEDED":
      return { ...state, savedAreas: payload.options };
    case "GET_OPTIONS_FOR_COLLECTIONS_SUCCEEDED":
      return { ...state, collections: payload.options };
    case "GET_OPTIONS_FOR_STATUS_SUCCEEDED":
      return { ...state, status: payload.options };
    case "GET_OPTIONS_FOR_TYPE_SUCCEEDED":
      return { ...state, type: payload.options };
    case "GET_OPTIONS_FOR_SUB_TYPE_SUCCEEDED":
      return { ...state, subType: payload.options };
    case "GET_OPTIONS_FOR_CITY_SUCCEEDED":
      return { ...state, city: payload.options };
    case "GET_OPTIONS_FOR_ZIP_SUCCEEDED":
      return { ...state, zip: payload.options };
    case "GET_OPTIONS_FOR_AREA_SUCCEEDED":
      return { ...state, area: payload.options };
    default:
      return state;
  }
}
