import { GraphQLClient } from "graphql-request";
import { omit, intersection, difference } from "lodash";
import {
  isDynamicMinField,
  isDynamicMaxField,
  transformStaticOptions,
  transformAvailableFields
} from "./search";

const forgeUrl = process.env.REACT_APP_FORGE_API_URL;

const agentsQuery = `query AgentQuery($key: String!, $page: Int, $limit: Int, $sort: QuerySortType, $filter: [FilterQueryType]) {
  mls(key: $key) {
    agents(page: $page, limit: $limit, sort: $sort, filter: $filter) {
      results {
        id
        agent_id
        email
        firstname
        fullname
        lastname
        login
        raw
        updated_at
      }
    }
  }
}`;

const mlsNumsQuery = `query ListingsQuery($key: String!, $page: Int, $limit: Int, $filter: [FilterQueryType]) {
  mls(key: $key) {
    key
    listings(page: $page, limit: $limit, filter: $filter) {
      results {
        id
        mlsnum
      }
    }
  }
}`;

const addressQuery = `query AddressQuery($key: String!, $search: String!) {
  mls(key: $key) {
    address_suggestions(search: $search) {
      results {
        id
        address
        city
        state
        zipcode
        status
        mapped_status
        date_list
      }
    }
  }
}`;

const suggestionsQuery = `query SuggestionsQuery($key: String!, $field: String, $search: String!) {
  mls(key: $key) {
    key
    suggestions(field: $field, search: $search)
  }
}`;

const fieldsQuery = `query FieldsQuery($key: String!) {
  mls(key: $key) {
    fields {
      id
      key
      title
      field_type
      input_type
      autocomplete
    }
  }
}`;

const optionsQuery = `query OptionsQuery($key: String!, $name: String) {
  mls(key: $key) {
    field(name: $name) {
      search_values
      autocomplete
    }
  }
}`;

export async function getForgeAgentsByName({ name, mlsCode, forgeToken }) {
  const forgeClient = buildForgeClient(forgeToken);
  const variables = {
    key: mlsCode,
    page: 1,
    limit: 10,
    filter: [
      { field: "fullname", matches: name },
      { field: "active", eq: "true" }
    ]
  };
  return await forgeClient.request(agentsQuery, variables);
}

export async function getForgeMlsNumbers({ mlsnum, mlsCode, forgeToken }) {
  const forgeClient = buildForgeClient(forgeToken);
  const variables = {
    key: mlsCode,
    page: 1,
    limit: 10,
    filter: [{ field: "mlsnum", eq: mlsnum }]
  };
  return await forgeClient.request(mlsNumsQuery, variables);
}

export async function getForgeSuggestions({
  field,
  inputValue,
  isArbitrary,
  mlsCode,
  forgeToken
}) {
  const forgeClient = buildForgeClient(forgeToken);
  const searchField = isArbitrary ? field : getForgeField(field);
  const variables = { key: mlsCode, field: searchField, search: inputValue };
  return await forgeClient.request(suggestionsQuery, variables);
}

export async function getForgeAddresses({ inputValue, mlsCode, forgeToken }) {
  const forgeClient = buildForgeClient(forgeToken);
  const variables = { key: mlsCode, search: inputValue };
  return await forgeClient.request(addressQuery, variables);
}

export async function getForgeArbitraryOptionsByField({
  field,
  mlsCode,
  forgeToken
}) {
  const forgeClient = buildForgeClient(forgeToken);
  const optionsVariables = { key: mlsCode, name: field };
  return await forgeClient.request(optionsQuery, optionsVariables);
}

export function initialForgeRequests({ dispatch, mlsCode, forgeToken }) {
  return [
    getForgeAvailableFields({ dispatch, mlsCode, forgeToken }),
    getForgeStaticOptionsByField({
      field: "status",
      actionType: "GET_OPTIONS_FOR_STATUS_SUCCEEDED",
      dispatch,
      mlsCode,
      forgeToken
    }),
    getForgeStaticOptionsByField({
      field: "type",
      actionType: "GET_OPTIONS_FOR_TYPE_SUCCEEDED",
      dispatch,
      mlsCode,
      forgeToken
    }),
    getForgeStaticOptionsByField({
      field: "subType",
      actionType: "GET_OPTIONS_FOR_SUB_TYPE_SUCCEEDED",
      dispatch,
      mlsCode,
      forgeToken
    }),
    getForgeStaticOptionsByField({
      field: "city",
      actionType: "GET_OPTIONS_FOR_CITY_SUCCEEDED",
      dispatch,
      mlsCode,
      forgeToken
    }),
    getForgeStaticOptionsByField({
      field: "zip",
      actionType: "GET_OPTIONS_FOR_ZIP_SUCCEEDED",
      dispatch,
      mlsCode,
      forgeToken
    }),
    getForgeStaticOptionsByField({
      field: "area",
      actionType: "GET_OPTIONS_FOR_AREA_SUCCEEDED",
      dispatch,
      mlsCode,
      forgeToken
    })
  ];
}

async function getForgeAvailableFields({ dispatch, mlsCode, forgeToken }) {
  const forgeClient = buildForgeClient(forgeToken);
  const {
    mls: { fields: dataArray }
  } = await forgeClient.request(fieldsQuery, { key: mlsCode });
  dispatch({
    type: "GET_AVAILABLE_FIELDS_SUCCEEDED",
    payload: transformAvailableFields(dataArray)
  });
}

async function getForgeStaticOptionsByField({
  field,
  mlsCode,
  forgeToken,
  actionType,
  dispatch
}) {
  const forgeClient = buildForgeClient(forgeToken);
  const {
    mls: {
      field: forgeField
    }
  } = await forgeClient.request(optionsQuery, {
    key: mlsCode,
    name: getForgeField(field)
  });
  if (forgeField === null) return
  const { search_values, autocomplete } = forgeField
  dispatch({
    type: actionType,
    payload: {
      options: transformStaticOptions({ field, values: search_values }),
      isAutoComplete: autocomplete
    }
  });
}

export function buildListingsFilters({
  queryObject,
  statusMapping,
  isForStreams
}) {
  const primaryFilters = buildPrimaryFilters({
    queryObject,
    statusMapping,
    isForStreams
  });
  const arbitraryFilters = buildArbitraryFilters(queryObject);
  return [...primaryFilters, ...arbitraryFilters];
}

function buildPrimaryFilters({ queryObject, statusMapping, isForStreams }) {
  const primaryObject = omit(queryObject, "arbitrary");
  const hasFilterGroups = isFilterGroupQuery({
    queryObject,
    statusMapping,
    isForStreams
  });

  return Object.keys(primaryObject).reduce((state, field) => {
    switch (true) {
      case isDynamicMinField(field):
        return [
          ...state,
          { field: getForgeField(field), gte: queryObject[field] }
        ];
      case isDynamicMaxField(field):
        return [
          ...state,
          { field: getForgeField(field), lte: queryObject[field] }
        ];
      case isEqField(field):
        return [
          ...state,
          { field: getForgeField(field), eq: queryObject[field] }
        ];
      case isShouldField(field):
        return [
          ...state,
          { field: getForgeField(field), should: queryObject[field] }
        ];
      case !hasFilterGroups && field === "offMarketDays":
        return [
          ...state,
          {
            field: getForgeField(field),
            gte: `${queryObject.offMarketDays} days ago`
          }
        ];
      case !hasFilterGroups && field === "status":
        return [
          ...state,
          { field: getForgeField(field), eq: queryObject[field] }
        ];
      case hasFilterGroups && field === "status":
        return [...state, ...buildFilterGroups({ queryObject, statusMapping })];
      case field === "polygon":
        return [
          ...state,
          ...queryObject.polygon.reduce((polygonState, filter) => {
            if (!filter.value) {
              return polygonState;
            }
            return [
              ...polygonState,
              { field: getForgeField(field), within: `[${filter.value}]` }
            ];
          }, [])
        ];
      case field === "proximity":
        return [
          ...state,
          {
            field: getForgeField(field),
            circle: `[[${queryObject.proximity.lat}, ${queryObject.proximity.lon}, "${queryObject.proximity.radius}mi"]]`
          }
        ];
      default:
        return [...state];
    }
  }, []);
}

function buildArbitraryFilters(queryObject) {
  if (!queryObject.arbitrary) {
    return [];
  }

  return Object.values(queryObject.arbitrary).reduce((state, filter) => {
    if (filter.isRange && filter.value.min && filter.value.max) {
      return [
        ...state,
        { field: filter.field, gte: filter.value.min },
        { field: filter.field, lte: filter.value.max }
      ];
    }

    if (filter.isRange && filter.value.min) {
      return [...state, { field: filter.field, gte: filter.value.min }];
    }

    if (filter.isRange && filter.value.max) {
      return [...state, { field: filter.field, lte: filter.value.max }];
    }

    return [...state, { field: filter.field, eq: filter.value }];
  }, []);
}

function buildFilterGroups({ queryObject, statusMapping }) {
  const actives = intersection(queryObject.status, statusMapping.active);
  const nonActives = difference(queryObject.status, statusMapping.active);

  return [
    { field: "active_group", filter_group: [{ field: "status", eq: actives }] },
    {
      field: "non_active_group",
      filter_group: [
        ...(nonActives.length > 0 ? [{ field: "status", eq: nonActives }] : []),
        {
          field: "date_offmarket",
          gte: `${queryObject.offMarketDays} days ago`
        }
      ]
    }
  ];
}

function isFilterGroupQuery({ queryObject, statusMapping, isForStreams }) {
  if (isForStreams) {
    return false;
  }
  const hasActiveStatus =
    intersection(statusMapping.active, queryObject.status || []).length > 0;
  return !!queryObject.offMarketDays && hasActiveStatus;
}

function isEqField(field) {
  return ["type", "subType", "mlsnum"].includes(field);
}

function isShouldField(field) {
  return ["city", "zip", "area"].includes(field);
}

function buildForgeClient(forgeToken) {
  return new GraphQLClient(forgeUrl, {
    headers: { Authorization: `Bearer ${forgeToken}` }
  });
}

export function getForgeField(mlxField) {
  return mlxToForgeFieldMap[mlxField];
}

export const stringToObjectSortMap = {
  "-price": { field: "price", dir: "desc" },
  price: { field: "price", dir: "asc" },
  "-updated_at": { field: "updated_at", dir: "desc" },
  sqft: { field: "sqft", dir: "desc" },
  status: { field: "status", dir: "desc" }
};

export const mlxToForgeFieldMap = {
  mlsnum: "mlsnum",
  status: "status",
  type: "prop_type",
  subType: "prop_sub_type",
  city: "city",
  zip: "zipcode",
  area: "area",
  polygon: "location",
  proximity: "location",
  priceMin: "price",
  priceMax: "price",
  bedMin: "beds",
  bedMax: "beds",
  bathMin: "baths_search",
  bathMax: "baths_search",
  sqftMin: "sqft",
  sqftMax: "sqft",
  offMarketDays: "date_offmarket",
  garageMin: "garages",
  garageMax: "garages",
  storyMin: "features_stories_total",
  storyMax: "features_stories_total",
  lotMin: "lotsize",
  lotMax: "lotsize"
};
