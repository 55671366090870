export const defaultCheatSheetData = {
  address: {
    field: "address",
    label: "Address",
    value: "123 Main Street",
    isArray: false
  },
  locations: {
    field: "city",
    label: "Location",
    value: ["Some City", "Some Zip Code"],
    isArray: true
  },
  sqft: {
    field: "sqftMin",
    label: "Square Feet",
    value: ["1000 sqft", "1000-3000 sqft"],
    isArray: true
  },
  beds: {
    field: "bedRange",
    label: "Beds",
    value: ["3 beds", "3-5 beds"],
    isArray: true
  },
  baths: {
    field: "bathRange",
    label: "Baths",
    value: ["2 baths", "2-4 baths"],
    isArray: true
  },
  price: {
    field: "priceRange",
    label: "Price",
    value: ["$1m", "$1m-$3m", "$1000000"],
    isArray: true
  },
  agent: {
    field: "agent",
    label: "Agent",
    value: "John Doe",
    isArray: false
  },
  type: {
    field: "type",
    label: "Type",
    value: ["Residential", "Commercial"],
    isArray: true
  },
  status: {
    field: "status",
    label: "Status",
    value: ["Active", "Closed"],
    isArray: true
  },
  search: {
    field: "search",
    label: "Saved Search",
    value: "Your Saved Search Name",
    isArray: false
  },
  lotSize: {
    field: "lotRange",
    label: "Lot Size",
    value: "1000-3000 lot size",
    isArray: false
  },
  stories: {
    field: "storyRange",
    label: "Stories",
    value: ["1-3 stories", "1 story"],
    isArray: true
  },
  garages: {
    field: "garageRange",
    label: "Garages",
    value: "1-3 car garage",
    isArray: false
  },
  mlsnum: {
    field: "mlsnum",
    label: "MLS Number",
    value: "123456789",
    isArray: false
  }
};
