import { transformListings } from "./listing";
import { formatTitle, getInitials } from "../utils/string";

export function transformAgent({ agent, photoProxyStrategy }) {
  const name = formatTitle(`${agent.firstname} ${agent.lastname}`);

  return {
    attributes: {
      name,
      id: agent.id,
      email: agent.email,
      phone: agent.office.phone,
      office: agent.office.name,
      initials: getInitials(name),
      avatarUrl: "", // avatar_url is not avaialable from the API yet
      bre: agent.ao_board_id ? `BRE #${agent.ao_board_id}` : ""
    },
    listings: transformListings({
      listings: agent.listings,
      photoProxyStrategy
    })
  };
}
