import React from "react";

function PrintIcon() {
  return (
    <svg viewBox="0 0 100 100" preserveAspectRatio="xMidYMid meet">
      <path d="M85,30 L15,30 C6.7,30 0,36.7 0,45 L0,75 L20,75 L20,95 L80,95 L80,75 L100,75 L100,45 C100,36.7 93.3,30 85,30 L85,30 Z M70,85 L30,85 L30,60 L70,60 L70,85 L70,85 Z M85,50 C82.25,50 80,47.75 80,45 C80,42.25 82.25,40 85,40 C87.75,40 90,42.25 90,45 C90,47.75 87.75,50 85,50 L85,50 Z M80,5 L20,5 L20,25 L80,25 L80,5 L80,5 Z"></path>
    </svg>
  );
}

export default PrintIcon;
