import styled from "styled-components";
import {
  blueMedium,
  greyLight,
  greyMedium,
  greyLightest,
  iconColor
} from "../../../../utils/colorsLocal";
import {
  noWrap,
  zIndexAdvanced,
  routeContainer,
  pageTitle,
  absoluteFill,
  headerHeightDesktop,
  headerHeightMobile,
  mobileBreakpoint,
  defaultBorderRadius
} from "../../../../utils/styling";

export const itemBottom = 30;
const iconWidth = 20;

export const Container = styled.div`
  ${absoluteFill};
  z-index: ${zIndexAdvanced};
  transition: transform 300ms;
  transform: ${({ isOpen }) =>
    isOpen ? "translateY(0%)" : "translateY(-100%)"};
  background-color: white;
`;

export const Body = styled.div`
  position: absolute;
  top: ${headerHeightDesktop}px;
  bottom: ${headerHeightDesktop}px;
  overflow-y: scroll;
  right: 0px;
  left: 0px;

  @media (max-width: ${mobileBreakpoint}px) {
    top: ${headerHeightMobile}px;
    bottom: ${headerHeightMobile}px;
  }
`;

export const Layout = styled.div`
  ${routeContainer};
`;

export const Title = styled.div`
  ${pageTitle};
  margin-bottom: 30px;

  @media (max-width: ${mobileBreakpoint}px) {
    display: none;
  }
`;

export const Footer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${greyLightest};
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);
  height: ${headerHeightDesktop}px;
  padding: 0 10px;
  text-align: center;
  cursor: pointer;
  bottom: 0px;
  right: 0px;
  left: 0px;

  @media (max-width: ${mobileBreakpoint}px) {
    height: ${headerHeightMobile}px;
  }
`;

export const FieldHeading = styled.div`
  margin-bottom: 12px;
`;

export const FieldIcon = styled.div`
  width: ${iconWidth}px;
  display: inline-block;
  vertical-align: top;

  svg {
    fill: ${({ name }) => iconColor(name)};
  }
`;

export const FieldName = styled.div`
  ${noWrap};
  color: ${greyMedium};
  width: calc(100% - ${iconWidth}px);
  line-height: ${iconWidth}px;
  height: ${iconWidth}px;
  display: inline-block;
  vertical-align: top;
  padding-left: 10px;
  font-size: 15px;
`;

export const ArbitraryOptions = styled.div`
  margin-top: 10px;
`;

export const AutoComplete = styled.input`
  border: 2px solid ${greyLight};
  border-radius: ${defaultBorderRadius}px;
  padding: 0px 10px;
  margin-top: 10px;
  height: 42px;
  width: 100%;

  &::placeholder {
    color: ${greyLight};
  }

  &:focus {
    border-color: ${blueMedium};
  }
`;

export const FooterButton = styled.button`
  transition: background-color 100ms;
  border-radius: ${defaultBorderRadius}px;
  box-shadow: 0px 2px 0px #076fcd;
  background-color: ${blueMedium};
  color: white;
  display: inline-block;
  vertical-align: top;
  padding: 0px 20px;
  cursor: pointer;
  font-size: 15px;
  line-height: 40px;
  height: 40px;

  &:hover {
    background-color: #409eff;
  }

  @media (max-width: ${mobileBreakpoint}px) {
    width: 100%;
  }
`;
