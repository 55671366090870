import React from "react";

function StatusIcon() {
  return (
    <svg viewBox="0 0 100 100" preserveAspectRatio="xMidYMid meet">
      <path d="M50,95 C74.8528135,95 95,74.8528135 95,50 L5,50 C5,74.8528135 25.1471863,95 50,95 Z M50,0 C22.35,0 0,22.35 0,50 C0,77.65 22.35,100 50,100 C77.65,100 100,77.65 100,50 C100,22.35 77.65,0 50,0 Z M50,90 C27.95,90 10,72.05 10,50 C10,27.95 27.95,10 50,10 C72.05,10 90,27.95 90,50 C90,72.05 72.05,90 50,90 Z"></path>
    </svg>
  );
}

export default StatusIcon;
