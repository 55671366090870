import styled from "styled-components";
import { Card as BaseCard } from "@wrstudios/components";
import { Button as BaseButton } from "@wrstudios/components";
import { defaultBorderRadius } from "../../../../utils/styling";
import { green, red, gray } from "../../../../utils/colorsSystem";
import {
  greyMedium,
  greyLighter,
  statusColor
} from "../../../../utils/colorsLocal";

export const Card = styled(BaseCard)`
  position: relative;
`;

export const Action = styled.button`
  position: absolute;
  right: 1.6rem;
  top: 1.2rem;
`;

export const Container = styled.div`
  padding: ${({ noPad }) => (noPad ? 0 : "1.2rem 1.6rem")};
  border-bottom: 0.1rem solid ${gray[400]};
  color: ${gray[700]};

  &:last-child {
    border-bottom: 0;
    border-bottom-left-radius: 0.4rem;
    border-bottom-right-radius: 0.4rem;
  }
`;

export const Button = styled(BaseButton)`
  display: block;
  width: 100%;
  margin-top: 12px;
`;

export const Status = styled.span`
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.6rem;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  color: ${({ status }) => statusColor(status.toLowerCase())};

  @media (max-width: ${({ theme }) => theme.breakpoints.page.oneColumn}px) {
    font-size: 1.3rem;
    line-height: 1.7rem;
  }
`;

export const Dom = styled.span`
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.6rem;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  color: ${gray[700]};

  @media (max-width: ${({ theme }) => theme.breakpoints.page.oneColumn}px) {
    font-size: 1.3rem;
    line-height: 1.7rem;
  }
`;

export const Price = styled.h2`
  font-size: 3rem;
  font-weight: 600;
  line-height: 3.6rem;
  margin: 1.2rem 0 0;
  color: ${gray[900]};

  @media (max-width: ${({ theme }) => theme.breakpoints.page.oneColumn}px) {
    font-size: 2rem;
    line-height: 2.4rem;
  }
`;

export const PricePerSqft = styled.span`
  display: inline-block;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2rem;
  margin-left: 1.2rem;
  color: ${gray[700]};

  @media (max-width: ${({ theme }) => theme.breakpoints.page.oneColumn}px) {
    font-size: 1.5rem;
  }
`;

export const Detail = styled.div`
  display: inline-block;
  margin-left: 1.6rem;
  &:first-child {
    margin-left: 0;
  }
`;

export const DetailValue = styled.strong`
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 2.2rem;
  color: ${gray[900]};

  @media (max-width: ${({ theme }) => theme.breakpoints.page.oneColumn}px) {
    font-size: 1.7rem;
    line-height: 2.4rem;
  }
`;

export const DetailName = styled.span`
  font-size: 1.6rem;
  line-height: 2.2rem;
  color: ${gray[700]};

  @media (max-width: ${({ theme }) => theme.breakpoints.page.oneColumn}px) {
    font-size: 1.7rem;
    line-height: 2.4rem;
  }
`;

export const Date = styled.span`
  display: inline-block;
  font-size: 1.2rem;
  line-height: 1.6rem;
  margin-left: 1.2rem;
  color: ${gray[700]};

  @media (max-width: ${({ theme }) => theme.breakpoints.page.oneColumn}px) {
    font-size: 1.3rem;
    line-height: 1.7rem;
  }
  &:first-child {
    margin-left: 0;
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const RowName = styled.span`
  font-size: 1.4rem;
  font-weight: ${({ isTotal }) => isTotal && 700};
  line-height: 2rem;
  color: ${({ isTotal }) => (isTotal ? gray[900] : gray[800])};

  @media (max-width: ${({ theme }) => theme.breakpoints.page.oneColumn}px) {
    font-size: 1.5rem;
  }
`;

export const RowValue = styled.strong`
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 2rem;
  padding-right: ${({ isTotal, isReportOwnedByCurrentAgent }) =>
    isTotal && isReportOwnedByCurrentAgent && "2.8rem"};
  color: ${({ isDim }) => (isDim ? gray[700] : gray[900])};

  @media (max-width: ${({ theme }) => theme.breakpoints.page.oneColumn}px) {
    font-size: 1.5rem;
  }
`;

export const PriceDifference = styled.strong`
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.6rem;
  color: ${gray[900]};

  @media (max-width: ${({ theme }) => theme.breakpoints.page.oneColumn}px) {
    font-size: 1.3rem;
    line-height: 1.7rem;
  }
`;

export const Difference = styled.strong`
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.6rem;
  color: ${({ value }) => (value > 0 ? red[600] : green[700])};

  @media (max-width: ${({ theme }) => theme.breakpoints.page.oneColumn}px) {
    font-size: 1.3rem;
    line-height: 1.7rem;
  }
`;

export const CollectorButton = styled.div`
  position: relative;
  background-color: ${({ isCollectorOpen }) =>
    isCollectorOpen ? greyLighter : "white"};
  border-radius: ${defaultBorderRadius}px;
  border: 2px solid ${greyLighter};
  display: inline-block;
  vertical-align: top;
  padding-right: 30px;
  padding-left: 10px;
  text-align: left;
  cursor: pointer;
  height: 32px;
  width: 100%;
`;

export const CollectorLabel = styled.div`
  color: ${greyMedium};
  line-height: 28px;
  font-size: 14px;
  height: 100%;
`;

export const CollectorPointer = styled.div`
  position: absolute;
  width: 12px;
  right: 10px;
  top: 8px;

  svg {
    fill: ${greyMedium};
  }
`;
