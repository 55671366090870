import styled from "styled-components";
import { pageTitle } from "../../../utils/styling";

export const Header = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Title = styled.div`
  ${pageTitle};
  display: flex;
  flex-grow: 1;
  margin-bottom: 10px;
  font-weight: 600;
`;

export const Sorting = styled.div`
  align-self: center;
  display: flex;
  margin-bottom: 10px;
`;
