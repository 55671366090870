import React from "react";
import PropTypes from "prop-types";
import { FullscreenMedia } from "@wrstudios/components";
import IconBackArrow from "../../icons/IconBackArrow";
import { Arrow } from "./styled/listing-fullscreen-media";

function ListingFullscreenMedia({ setFullscreenMediaDetails, ...props }) {
  return (
    <FullscreenMedia
      {...props}
      exitIcon={
        <Arrow>
          <IconBackArrow />
        </Arrow>
      }
      onExit={() => {
        setFullscreenMediaDetails({
          open: false,
          tab: "map",
          photoIndex: 0
        });
      }}
    />
  );
}

ListingFullscreenMedia.propTypes = {
  setFullscreenMediaDetails: PropTypes.func.isRequired
};

export default ListingFullscreenMedia;
