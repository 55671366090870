import React, { Component } from "react";
import PropTypes from "prop-types";
import pluralize from "pluralize";
import { SearchMap } from "@wrstudios/components";
import { history } from "../../../utils/routing";
import { filterPins } from "../../../modules/map";
import { getSavedAreaId } from "../../../modules/area";
import { Markers } from "@wrstudios/components";
import IconLoading from "../../icons/IconLoading";
import Notice from "../../common/Notice";
import Notification from "../../common/Notification";
import { ClickContainer, ClickTarget } from "./styled/map-container";
import { Container, LoadingCluster } from "./styled/map-container";

const minZoom = 15;
// const precision = 9;

class MapContainer extends Component {
  state = { zoom: 0, areaIds: {}, selectedAreaName: "" };

  componentDidUpdate(prevProps) {
    this.handleEnterMappableRoute(prevProps);
    this.handleLeaveMappableRoute(prevProps);
    this.handleAsyncCreate(prevProps);
    this.handleAsyncUpdate(prevProps);
  }

  render() {
    if (!this.props.isOpen || !this.props.isSearchable) {
      return null;
    }

    return (
      <Container isMappable={this.props.isMappable}>
        <SearchMap
          casJwt={this.props.jwt}
          env={process.env.NODE_ENV}
          mapboxApiToken={process.env.REACT_APP_MAPBOX_API_TOKEN}
          markers={this.getAllMarkers()}
          areas={this.getAreasFromFilters()}
          radius={this.getRadiusFromFilters()}
          selectedAreaName={this.getSelectedAreaName()}
          priorityId={this.props.hoveredListingId}
          onLoaded={this.onLoaded}
          onAreasAdded={this.onAreasAdded}
          onAreaCreate={this.onAreaCreate}
          onAreaUpdate={this.onAreaUpdate}
          onAreaDelete={this.onAreaDelete}
          onAreaSelect={this.onAreaSelect}
          onAreaRename={this.onAreaRename}
          onRadiusCreate={this.onRadiusCreate}
          onRadiusUpdate={this.onRadiusUpdate}
          onRadiusDelete={this.onRadiusDelete}
          onBoundsUpdate={this.onBoundsUpdate}
          isAutoZoomDisabled={this.state.zoom >= minZoom}
          isRadiusSearchEnabled={!this.props.isSharedRoute}
          isDrawSearchEnabled={!this.props.isSharedRoute}
          isSaveAreasEnabled={!this.props.isSharedRoute}
          isEmptyMapEnabled={!this.props.isSharedRoute}
        />
        <Notification
          ref={(node) => (this.createSuccess = node)}
          type="success">
          <Notice title="Success" body="Your area was successfully saved" />
        </Notification>
        <Notification ref={(node) => (this.createError = node)} type="error">
          <Notice title="Error" body="Your area could not be saved" />
        </Notification>
        <Notification
          ref={(node) => (this.updateSuccess = node)}
          type="success">
          <Notice title="Success" body="Your area was successfully updated" />
        </Notification>
        <Notification ref={(node) => (this.updateError = node)} type="error">
          <Notice title="Error" body="Your area could not be updated" />
        </Notification>
      </Container>
    );
  }

  getAllMarkers = () => {
    // DISABLED PENDING A REDESIGN
    // const pinsAndClusters = this.getMarkersForPinsAndClusters();
    // const listingMarkers = this.getMarkersForListings();
    // return [...pinsAndClusters, ...listingMarkers];
    return this.getMarkersForListings();
  };

  getMarkersForPinsAndClusters = () => {
    const { searchId, isPublic, isNonDisclosure } = this.props;
    const { listings, pathname, pins, isSharedRoute } = this.props;
    const { listingsForPins, getListingsForPin, emitAction } = this.props;
    const filteredPins = filterPins({ pins, listings });

    return filteredPins.map((pin) => {
      const listingIds = pin.records.map(({ id }) => id);
      const onClickMarker = () => getListingsForPin({ searchId, listingIds });

      if (listingIds.length === 1) {
        const listing = listingsForPins[listingIds[0]] || {};

        return {
          id: pin.id,
          lat: pin.lat,
          lon: pin.lon,
          component: (
            <Markers.PinMarker
              onClick={onClickMarker}
              statusValue={pin.records[0].statusValue}
            />
          ),
          popupComponent: this.getListingPopup(listing)
        };
      } else {
        const listings = listingIds.map((id) => listingsForPins[id] || {});
        const validListings = listings.filter(({ id }) => !!id);
        const onClickClusterPopup = ({ target }) => {
          if (!isSharedRoute && target.id) {
            history.push(`${pathname}/listings/${target.id}`);
          }

          if (isSharedRoute) {
            emitAction({ type: "SET_SHARED_DETAIL_ID", payload: target.id });
          }
        };

        return {
          id: pin.id,
          lat: pin.lat,
          lon: pin.lon,
          component: (
            <Markers.ClusterMarker onClick={onClickMarker}>
              {pin.records.length}
            </Markers.ClusterMarker>
          ),
          popupComponent: (
            <Markers.ClusterPopup onClick={onClickClusterPopup}>
              {validListings.length < 1 ? (
                <LoadingCluster>
                  <IconLoading />
                </LoadingCluster>
              ) : (
                validListings.map((listing) => (
                  <ClickContainer key={listing.id}>
                    <Markers.ClusterListing
                      id={listing.id}
                      zip={listing.zip}
                      city={listing.city}
                      state={listing.state}
                      street={listing.address}
                      status={listing.statusLabel}
                      statusValue={listing.statusValue}
                      photo={(listing.imageUrls || [])[0]}
                      price={
                        isPublic && isNonDisclosure
                          ? listing.pricePublic
                          : listing.price
                      }
                    />
                    <ClickTarget id={listing.id} />
                  </ClickContainer>
                ))
              )}
            </Markers.ClusterPopup>
          )
        };
      }
    });
  };

  getMarkersForListings = () => {
    const { listings, hoveredListingId } = this.props;

    return listings.map((listing) => ({
      id: listing.id,
      lat: listing.lat,
      lon: listing.lon,
      component: (
        <Markers.ListingMarker
          price={listing.priceShort}
          statusValue={listing.statusValue}
          isHighlighted={listing.id === hoveredListingId}
        />
      ),
      popupComponent: this.getListingPopup(listing)
    }));
  };

  getListingPopup = (listing) => {
    const { isSharedRoute, emitAction } = this.props;
    const { isPublic, isNonDisclosure, pathname } = this.props;
    const onClick = () => {
      if (isSharedRoute) {
        emitAction({ type: "SET_SHARED_DETAIL_ID", payload: listing.id });
      } else {
        history.push(`${pathname}/listings/${listing.id}`);
      }
    };

    return (
      <Markers.ListingPopup
        id={listing.id}
        offsetY={-3}
        onClick={onClick}
        photos={listing.imageUrls}
        address={listing.address}
        city={listing.city}
        state={listing.state}
        zip={listing.zip}
        statusLabel={listing.statusLabel}
        statusValue={listing.statusValue}
        priceFormatted={
          isPublic && isNonDisclosure ? listing.pricePublic : listing.price
        }
      />
    );
  };

  getPolygonFilters = () => {
    return this.props.filters.filter(({ field }) => field === "polygon");
  };

  getAreasFromFilters = () => {
    const polygons = this.getPolygonFilters();
    return polygons.map(({ value }) => ({ coordinates: JSON.parse(value) }));
  };

  getRadiusFromFilters = () => {
    const { filters } = this.props;
    return (filters.find(({ field }) => field === "proximity") || {}).value;
  };

  getSelectedAreaName = () => {
    const { selectedAreaName } = this.state;
    return selectedAreaName === "Custom Area" ? "" : selectedAreaName;
  };

  getFilterFromArea = (area) => {
    return {
      field: "polygon",
      label: "Custom Area",
      value: JSON.stringify(area.coordinates)
    };
  };

  getFilterFromRadius = (radius) => {
    return {
      field: "proximity",
      label: `Radius - ${radius.radius} ${pluralize("miles", radius.radius)}`,
      value: radius
    };
  };

  onLoaded = () => {
    this.props.emitAction({ type: "RESET_SEARCH_PINS" });
  };

  onAreasAdded = ({ areaIds }) => {
    const idsMap = areaIds.reduce((state, id, index) => {
      return { ...state, [id]: index };
    }, {});

    this.setState({ areaIds: idsMap });
  };

  onAreaCreate = ({ area }) => {
    const filter = this.getFilterFromArea(area);
    this.props.addFilter({ filter, pathname: this.props.pathname });

    this.setState(({ areaIds }) => {
      const index = Object.keys(areaIds).length;
      return { areaIds: { ...areaIds, [area.id]: index } };
    });
  };

  onAreaUpdate = ({ area }) => {
    const { savedAreas, updateSavedArea, emitAction } = this.props;
    const index = this.state.areaIds[area.id];
    const prevFilter = this.getPolygonFilters()[index] || {};
    const nextFilter = this.getFilterFromArea(area);
    const id = getSavedAreaId({ filter: prevFilter, savedAreas });
    const payload = { prevFilter, nextFilter };

    emitAction({ type: "UPDATE_UNSAVED_AREA", payload });

    if (id) {
      const label = (savedAreas.data[id] || {}).label;
      updateSavedArea({ id, filter: { label, value: nextFilter.value } });
    }
  };

  onAreaDelete = ({ area }) => {
    const index = this.state.areaIds[area.id];
    const filter = this.getPolygonFilters()[index] || {};
    this.props.removeFilter({ filter, pathname: this.props.pathname });
  };

  onAreaSelect = ({ area }) => {
    const index = this.state.areaIds[area.id];
    const filter = this.getPolygonFilters()[index] || {};
    this.setState({ selectedAreaName: filter.label });
  };

  onAreaRename = ({ area }) => {
    const { savedAreas } = this.props;
    const index = this.state.areaIds[area.id];
    const prevFilter = this.getPolygonFilters()[index] || {};
    const nextFilter = { label: area.name, value: prevFilter.value };
    const id = getSavedAreaId({ filter: prevFilter, savedAreas });

    if (id) {
      this.props.updateSavedArea({ id, filter: nextFilter });
    } else {
      this.props.createSavedArea(nextFilter);
    }
  };

  onRadiusCreate = ({ radius }) => {
    const filter = this.getFilterFromRadius(radius);
    this.props.addFilter({ filter, pathname: this.props.pathname });
  };

  onRadiusUpdate = ({ radius }) => {
    const filter = this.getFilterFromRadius(radius);
    this.props.addFilter({ filter, pathname: this.props.pathname });
  };

  onRadiusDelete = ({ radius }) => {
    const filter = this.getFilterFromRadius(radius);
    this.props.removeFilter({ filter, pathname: this.props.pathname });
  };

  onBoundsUpdate = ({ mapBounds, zoom, isLoaded }) => {
    if (isLoaded && zoom >= minZoom && !this.props.isSharedRoute) {
      // DISABLED PENDING A REDESIGN
      // this.props.getSearchPins({ precision, coordinates: [mapBounds] });
    }

    if (isLoaded && zoom < minZoom && zoom < this.state.zoom) {
      this.props.emitAction({ type: "RESET_SEARCH_PINS" });
    }

    this.setState({ zoom });
  };

  handleEnterMappableRoute = ({ isMappable, isOpen, isOpenViaUser }) => {
    if (
      !isMappable &&
      this.props.isMappable &&
      !isOpen &&
      isOpenViaUser &&
      this.props.isSearchable
    ) {
      this.props.emitAction({
        type: "SET_IS_MAP_OPEN_VIA_SYSTEM",
        payload: true
      });
    }
  };

  handleLeaveMappableRoute = ({ isMappable }) => {
    if (
      this.props.isOpen &&
      ((isMappable && !this.props.isMappable) || !this.props.isSearchable)
    ) {
      this.props.emitAction({
        type: "SET_IS_MAP_OPEN_VIA_SYSTEM",
        payload: false
      });
    }
  };

  handleAsyncCreate = ({ isCreating, hasCreateError }) => {
    if (!hasCreateError && this.props.hasCreateError) {
      return this.createError.display();
    }

    if (isCreating && !this.props.isCreating) {
      this.props.getUgcStaticOptions();
      this.createSuccess.display();
    }
  };

  handleAsyncUpdate = ({ isUpdating, hasUpdateError }) => {
    if (!hasUpdateError && this.props.hasUpdateError) {
      return this.updateError.display();
    }

    if (isUpdating && !this.props.isUpdating) {
      this.props.getUgcStaticOptions();
      this.updateSuccess.display();
    }
  };
}

MapContainer.propTypes = {
  jwt: PropTypes.string,
  searchId: PropTypes.string,
  isOpen: PropTypes.bool,
  isCreating: PropTypes.bool,
  isUpdating: PropTypes.bool,
  isMappable: PropTypes.bool,
  isSearchable: PropTypes.bool,
  isSharedRoute: PropTypes.bool,
  isOpenViaUser: PropTypes.bool,
  hasCreateError: PropTypes.bool,
  hasUpdateError: PropTypes.bool,
  pathname: PropTypes.string,
  listings: PropTypes.array,
  filters: PropTypes.array,
  pins: PropTypes.array,
  savedAreas: PropTypes.object,
  hoveredListingId: PropTypes.string,
  listingsForPins: PropTypes.object,
  getListingsForPin: PropTypes.func,
  getUgcStaticOptions: PropTypes.func,
  createSavedArea: PropTypes.func,
  updateSavedArea: PropTypes.func,
  getSearchPins: PropTypes.func,
  removeFilter: PropTypes.func,
  addFilter: PropTypes.func,
  emitAction: PropTypes.func
};

export default MapContainer;
