import { throttle } from "lodash";
import { transformPins } from "../modules/map";
import { transformUser } from "../modules/user";
import { apiClient, publicApiClient } from "../modules/api";
import { getCasFiltersFromQueryObject } from "../modules/queryObject";
import { getQueryObjectFromCasFilters } from "../modules/queryObject";
import { transformListings } from "../modules/listing";
import { transformListingsForPins } from "../modules/listing";
import { omniMatch, transformSavedSearches } from "../modules/search";
import { transformAddressLinkOptions } from "../modules/search";
import { transformRecentSearches } from "../modules/search";
import { transformAgentLinkOptions } from "../modules/search";
import { transformMlsNumOptions } from "../modules/search";
import { transformStaticOptions } from "../modules/search";
import { getForgeAgentsByName, getForgeMlsNumbers } from "../modules/forge";
import { getForgeAddresses, getForgeSuggestions } from "../modules/forge";
import { getSearchGQL, getSearchesGQL, touchSearchGQL } from "@wrstudios/api";
import { createSearchGQL, updateSearchGQL, getPinsGQL } from "@wrstudios/api";
import { deleteSearchGQL, getSharedSearchGQL } from "@wrstudios/api";

export function getSavedSearch({ id, page }) {
  return async (dispatch, getState) => {
    const { staticOptions, pagination } = getState();
    const { mlsConfigs, advancedSearch } = getState();

    dispatch({ type: "GET_SAVED_SEARCH_INITIATED", payload: { page } });

    try {
      const response = await getSearchGQL({
        apiClient,
        limit: pagination.listingsLimit,
        sort: pagination.listingsSort,
        page,
        id
      });

      const search = response.data.data.search;

      const payload = {
        id: search.id,
        slug: search.slug,
        name: search.name,
        role: search.role,
        queryObject: getQueryObjectFromCasFilters({
          arbitraryFields: advancedSearch.arbitraryFields,
          savedAreas: staticOptions.savedAreas,
          filters: search.filters
        }),
        count: search.total,
        data: transformListings({
          photoProxyStrategy: mlsConfigs.photoProxyStrategy,
          listings: search.listings
        })
      };

      dispatch({ type: "GET_SAVED_SEARCH_SUCCEEDED", payload });
      await touchSearchGQL({ apiClient, id });
    } catch (error) {
      dispatch({ type: "GET_SAVED_SEARCH_FAILED", payload: error });
    }
  };
}

export function createAdhocSearch() {
  return async (dispatch, getState) => {
    const { staticOptions, advancedSearch } = getState();
    const { queryObject, listings, mlsConfigs } = getState();

    dispatch({ type: "CREATE_ADHOC_SEARCH_INITIATED" });

    try {
      const response = await createSearchGQL({
        apiClient,
        role: "adhoc",
        sort: listings.sort,
        filters: getCasFiltersFromQueryObject({
          statusMapping: mlsConfigs.statusMapping,
          queryObject
        })
      });

      const search = response.data.data.createSearch.search;

      const payload = {
        id: search.id,
        slug: search.slug,
        role: search.role,
        queryObject: getQueryObjectFromCasFilters({
          arbitraryFields: advancedSearch.arbitraryFields,
          savedAreas: staticOptions.savedAreas,
          filters: search.filters
        }),
        count: search.total,
        data: transformListings({
          photoProxyStrategy: mlsConfigs.photoProxyStrategy,
          listings: search.listings
        })
      };

      dispatch({ type: "CREATE_ADHOC_SEARCH_SUCCEEDED", payload });
    } catch (error) {
      dispatch({ type: "CREATE_ADHOC_SEARCH_FAILED", payload: error });
    }
  };
}

export function updateSavedSearch({ id, page, isNaming }) {
  return async (dispatch, getState) => {
    const { pagination, mlsConfigs, savedSearch } = getState();
    const { queryObject, advancedSearch, staticOptions } = getState();

    dispatch({ type: "UPDATE_SAVED_SEARCH_INITIATED", payload: { isNaming } });

    try {
      const response = await updateSearchGQL({
        apiClient,
        id,
        page,
        limit: pagination.listingsLimit,
        sort: pagination.listingsSort,
        name: savedSearch.inputValue,
        role: savedSearch.inputValue ? "saved_search" : "adhoc",
        filters: getCasFiltersFromQueryObject({
          statusMapping: mlsConfigs.statusMapping,
          queryObject
        })
      });

      const search = response.data.data.updateSearch.search;

      const payload = {
        id: search.id,
        slug: search.slug,
        name: search.name,
        role: search.role,
        queryObject: getQueryObjectFromCasFilters({
          arbitraryFields: advancedSearch.arbitraryFields,
          savedAreas: staticOptions.savedAreas,
          filters: search.filters
        }),
        count: search.total,
        data: transformListings({
          photoProxyStrategy: mlsConfigs.photoProxyStrategy,
          listings: search.listings
        })
      };

      dispatch({ type: "UPDATE_SAVED_SEARCH_SUCCEEDED", payload });
    } catch (error) {
      dispatch({ type: "UPDATE_SAVED_SEARCH_FAILED", payload: error });
    }
  };
}

export function deleteSavedSearch(id) {
  return async (dispatch) => {
    dispatch({ type: "DELETE_SAVED_SEARCH_INITIATED" });

    try {
      await deleteSearchGQL({ apiClient, id });
      const payload = { id };
      dispatch({ type: "DELETE_SAVED_SEARCH_SUCCEEDED", payload });
    } catch (error) {
      dispatch({ type: "DELETE_SAVED_SEARCH_FAILED", payload: error });
    }
  };
}

export function getSavedSearches(page) {
  return async (dispatch, getState) => {
    const { mlsConfigs, staticOptions, advancedSearch } = getState();
    const { searchesLimit, searchesSort } = getState().pagination;

    dispatch({ type: "GET_SAVED_SEARCHES_INITIATED", payload: { page } });

    try {
      const response = await getSearchesGQL({
        apiClient,
        roles: ["saved_search"],
        listingLevel: "firstPic",
        listingsLimit: 3,
        searchesPage: page,
        searchesLimit,
        searchesSort
      });

      const searches = transformSavedSearches({
        photoProxyStrategy: mlsConfigs.photoProxyStrategy,
        arbitraryFields: advancedSearch.arbitraryFields,
        savedAreas: staticOptions.savedAreas,
        searches: response.data.data.searches
      });

      const payload = { ...searches, count: response.data.data.searches_count };
      dispatch({ type: "GET_SAVED_SEARCHES_SUCCEEDED", payload });
    } catch (error) {
      dispatch({ type: "GET_SAVED_SEARCHES_FAILED", payload: error });
    }
  };
}

export function getRecentSearches() {
  return async (dispatch, getState) => {
    const { advancedSearch, staticOptions } = getState();

    dispatch({ type: "GET_RECENT_SEARCHES_INITIATED" });

    try {
      const response = await getSearchesGQL({
        apiClient,
        roles: ["adhoc", "saved_search", "collection", "polygon"],
        listingLevel: "none",
        searchLevel: "min",
        searchesLimit: 10
      });

      const searches = transformRecentSearches({
        arbitraryFields: advancedSearch.arbitraryFields,
        savedAreas: staticOptions.savedAreas,
        searches: response.data.data.searches
      });

      dispatch({ type: "GET_RECENT_SEARCHES_SUCCEEDED", payload: searches });
    } catch (error) {
      dispatch({ type: "GET_RECENT_SEARCHES_FAILED", payload: error });
    }
  };
}

export function getSearchPins({ precision, coordinates }) {
  return async (dispatch, getState) => {
    const { mlsConfigs } = getState();

    dispatch({ type: "GET_SEARCH_PINS_INITIATED" });

    try {
      const response = await getPinsGQL({ apiClient, precision, coordinates });

      const pins = transformPins({
        statusMapping: mlsConfigs.statusMapping,
        pins: response.data.data.pins
      });

      dispatch({ type: "GET_SEARCH_PINS_SUCCEEDED", payload: pins });
    } catch (error) {
      dispatch({ type: "GET_SEARCH_PINS_FAILED", payload: error });
    }
  };
}

export function getListingsForPin({ searchId, listingIds }) {
  return async (dispatch, getState) => {
    const { mlsConfigs } = getState();

    dispatch({ type: "GET_LISTINGS_FOR_PIN_INITIATED" });

    try {
      const response = await getSearchGQL({
        apiClient,
        filters: [{ field: "id", eq: listingIds }],
        id: searchId
      });

      const listings = transformListingsForPins({
        photoProxyStrategy: mlsConfigs.photoProxyStrategy,
        listings: response.data.data.search.listings
      });

      dispatch({ type: "GET_LISTINGS_FOR_PIN_SUCCEEDED", payload: listings });
    } catch (error) {
      dispatch({ type: "GET_LISTINGS_FOR_PIN_FAILED", payload: error });
    }
  };
}

export function getOmniMatches({
  inputValue,
  staticOptions,
  availablePrimaryFields
}) {
  return async (dispatch, getState) => {
    getSynchronousMatches({
      inputValue,
      staticOptions,
      availablePrimaryFields,
      dispatch
    });
    await getAsyncMatchesThrottled({ inputValue, dispatch, getState });
  };
}

function getSynchronousMatches({
  inputValue,
  staticOptions,
  availablePrimaryFields,
  dispatch
}) {
  const matches = omniMatch({
    inputValue,
    staticOptions,
    availablePrimaryFields
  });
  dispatch({ type: "SET_SYNCHRONOUS_MATCHES", payload: matches });
}

const getAsyncMatchesThrottled = throttle(getAsyncMatches, 1000);

async function getAsyncMatches({ inputValue, getState, dispatch }) {
  const {
    currentUser: { mlsCode },
    application: { forgeToken },
    mlsConfigs: { isZipAutoComplete }
  } = getState();
  const shouldFetchAgents =
    inputValue.length > 3 && /^[a-zA-Z\s]*$/.test(inputValue);
  const shouldFetchMlsNums = inputValue.length > 3 && /^\S*$/.test(inputValue);
  const shouldFetchZipCodes = isZipAutoComplete && inputValue.length > 3;
  const shouldFetchAddresses = inputValue.length > 3;
  const requestedAt = Date.now();

  if (
    shouldFetchAgents ||
    shouldFetchMlsNums ||
    shouldFetchZipCodes ||
    shouldFetchAddresses
  ) {
    dispatch({ type: "GET_ASYNC_MATCHES_INITIATED" });
  }

  if (shouldFetchAgents) {
    try {
      const {
        mls: {
          agents: { results: dataArray }
        }
      } = await getForgeAgentsByName({ name: inputValue, mlsCode, forgeToken });
      dispatch({
        type: "GET_ASYNC_AGENT_MATCHES_SUCCEEDED",
        payload: { requestedAt, data: transformAgentLinkOptions(dataArray) }
      });
    } catch (error) {
      dispatch({ type: "GET_ASYNC_AGENT_MATCHES_FAILED", payload: error });
    }
  }

  if (shouldFetchMlsNums) {
    try {
      const {
        mls: {
          listings: { results: dataArray }
        }
      } = await getForgeMlsNumbers({ mlsnum: inputValue, mlsCode, forgeToken });
      dispatch({
        type: "GET_ASYNC_MLSNUM_MATCHES_SUCCEEDED",
        payload: { requestedAt, data: transformMlsNumOptions(dataArray) }
      });
    } catch (error) {
      dispatch({ type: "GET_ASYNC_MLSNUM_MATCHES_FAILED", payload: error });
    }
  }

  if (shouldFetchZipCodes) {
    try {
      const {
        mls: { suggestions }
      } = await getForgeSuggestions({
        field: "zip",
        inputValue,
        mlsCode,
        forgeToken
      });
      dispatch({
        type: "GET_ASYNC_ZIP_MATCHES_SUCCEEDED",
        payload: {
          requestedAt,
          data: transformStaticOptions({ field: "zip", values: suggestions })
        }
      });
    } catch (error) {
      dispatch({ type: "GET_ASYNC_ZIP_MATCHES_FAILED", payload: error });
    }
  }

  if (shouldFetchAddresses) {
    try {
      const {
        mls: {
          address_suggestions: { results: dataArray }
        }
      } = await getForgeAddresses({ inputValue, mlsCode, forgeToken });
      dispatch({
        type: "GET_ASYNC_ADDRESS_MATCHES_SUCCEEDED",
        payload: { requestedAt, data: transformAddressLinkOptions(dataArray) }
      });
    } catch (error) {
      dispatch({ type: "GET_ASYNC_ADDRESS_MATCHES_FAILED", payload: error });
    }
  }
}

export function getSharedSearch(slug) {
  return async (dispatch, getState) => {
    const { mlsConfigs, pagination } = getState();
    dispatch({ type: "GET_SHARED_SEARCH_INITIATED" });

    try {
      const response = await getSharedSearchGQL({
        publicApiClient,
        page: pagination.listingsPage,
        limit: pagination.listingsLimit,
        sort: pagination.listingsSort,
        slug
      });
      const search = response.data.data.shared_search;

      const payload = {
        user: transformUser(search.user),
        search: {
          id: search.id,
          slug: search.slug,
          role: search.role,
          name: search.name,
          count: search.total,
          filters: search.filters,
          rawListings: search.listings
        }
      };

      dispatch({ type: "GET_SHARED_SEARCH_SUCCEEDED", payload });

      if (mlsConfigs.photoProxyStrategy) {
        const listings = transformListings({
          photoProxyStrategy: mlsConfigs.photoProxyStrategy,
          listings: payload.search.rawListings
        }).reduce(
          (state, listing) => {
            return {
              order: [...state.order, listing.id],
              data: { ...state.data, [listing.id]: listing }
            };
          },
          { order: [], data: {} }
        );

        dispatch({ type: "TRANSFORM_RAW_SHARED_LISTINGS", payload: listings });
      }
    } catch (error) {
      dispatch({ type: "GET_SHARED_SEARCH_FAILED", payload: error });
    }
  };
}
