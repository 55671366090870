import { isEmpty, omit } from "lodash";

export function getListingMetaData({ pathname, lastPathname, mlsnum }) {
  const isListingNested = pathname.split("/").length > 3;
  const pathId = pathname.split("/")[isListingNested ? 4 : 2];

  if (pathname.includes("/searches/")) {
    return { type: "search_result", listing_id: pathId, mls_number: mlsnum };
  }

  if (pathname.includes("/collections/")) {
    return { type: "collection", listing_id: pathId, mls_number: mlsnum };
  }

  if (lastPathname.includes("/agents/")) {
    return { type: "agent_profile", listing_id: pathId, mls_number: mlsnum };
  }

  return { type: "address_search", listing_id: pathId, mls_number: mlsnum };
}

export function getSearchEventName({ role, queryObject }) {
  if (role === "saved_search") {
    return "saved_search";
  }

  const hasArbitrary = !isEmpty(queryObject.arbitrary);
  const keys = Object.keys(omit(queryObject, "arbitrary"));
  const isOnlyAreas = keys.includes("polygon") && keys.length === 1;
  const isOneArea = (queryObject.polygon || []).length === 1;
  const areaName = ((queryObject.polygon || [])[0] || {}).label;
  const isSavedName = areaName !== "Custom Area";

  if (!hasArbitrary && isOnlyAreas && isOneArea && isSavedName) {
    return "saved_area";
  }

  return "adhoc_search";
}
