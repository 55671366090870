import React, { Component } from "react";
import PropTypes from "prop-types";
import OmniSelect from "./OmniSelect";
import { mobileBreakpoint } from "../../../utils/styling";
import { Container } from "./styled/omni-search";

class OmniSearch extends Component {
  componentDidMount() {
    this.addEventListeners();
    this.setInitialFocus();
  }

  componentDidUpdate(prevProps) {
    this.handleNewLocation(prevProps);
    this.handleClickedLink(prevProps);
    this.handleNewInputValue(prevProps);
  }

  componentWillUnmount() {
    this.removeEventListeners();
  }

  render() {
    return (
      <Container>
        <OmniSelect
          ref={(node) => (this.omniSelect = node)}
          placeholder={this.placeholder()}
          inputValue={this.props.inputValue}
          filters={this.props.filters}
          matches={this.props.matches}
          isOpen={this.props.isOpen}
          isFocused={this.props.isFocused}
          isLoading={this.props.isLoading}
          isMapOpen={this.props.isMapOpen}
          hasFilters={this.props.hasFilters}
          handleDeselect={this.handleDeselect}
          handleSelect={this.handleSelect}
          handleChange={this.handleChange}
          handleEscape={this.handleEscape}
          handleFocus={this.handleFocus}
          handleBlur={this.handleBlur}
        />
      </Container>
    );
  }

  addEventListeners = () => {
    window.addEventListener("resize", this.handleResize);
  };

  setInitialFocus = () => {
    if (
      window.innerWidth > mobileBreakpoint &&
      !this.props.hasFilters &&
      !this.props.isMapOpen
    ) {
      this.forceBlur();
    }
    if (
      window.innerWidth > mobileBreakpoint &&
      (this.props.hasFilters || this.props.isMapOpen)
    ) {
      this.forceFocus();
    }
    if (
      window.innerWidth <= mobileBreakpoint &&
      (this.props.hasFilters || this.props.isMapOpen)
    ) {
      this.forceBlur();
    }
  };

  handleResize = () => {
    const { isFocused, hasFilters, isAdvancedOpen, isMapOpen } = this.props;
    if (
      window.innerWidth > mobileBreakpoint &&
      !isFocused &&
      (hasFilters || isMapOpen)
    ) {
      this.forceFocus();
    }
    if (window.innerWidth <= mobileBreakpoint && isFocused && !isAdvancedOpen) {
      this.forceBlur();
    }
  };

  handleNewLocation = ({ isSearchRoute, isSearchable }) => {
    if (
      this.props.isFocused &&
      ((isSearchRoute && !this.props.isSearchRoute) || !this.props.isSearchable)
    ) {
      this.forceBlur();
    }
    if (
      window.innerWidth > mobileBreakpoint &&
      !this.props.isFocused &&
      !isSearchRoute &&
      this.props.isSearchRoute
    ) {
      this.forceFocus();
    }
  };

  handleNewInputValue = (prevProps) => {
    if (prevProps.inputValue === this.props.inputValue) {
      return;
    }
    const { inputValue, staticOptions, availablePrimaryFields } = this.props;
    this.props.getOmniMatches({
      inputValue,
      staticOptions,
      availablePrimaryFields
    });
  };

  handleClickedLink = ({ hasFilters }) => {
    if (
      window.innerWidth > mobileBreakpoint &&
      !this.props.isFocused &&
      !hasFilters &&
      this.props.hasFilters
    ) {
      this.forceFocus();
    }
  };

  placeholder = () => {
    if (this.props.hasFilters) {
      return "";
    }
    return this.props.isFocused ? 'Search MLS or type "Help"' : "Search MLS";
  };

  handleSelect = (filter) => {
    this.props.addFilter({ filter, pathname: this.props.pathname });
  };

  handleDeselect = (filter) => {
    this.props.removeFilter({ filter });
  };

  handleChange = (event) => {
    this.props.emitAction({
      type: "SET_OMNI_SEARCH_INPUT_VALUE",
      payload: event.target.value
    });
  };

  handleEscape = () => {
    this.props.emitAction({ type: "SET_OMNI_SEARCH_INPUT_VALUE", payload: "" });
  };

  handleFocus = () => {
    this.forceFocus();
    if (window.innerWidth <= mobileBreakpoint && !this.props.isAdvancedOpen) {
      this.props.emitAction({
        type: "SET_IS_ADVANCED_SEARCH_OPEN",
        payload: true
      });
    }
  };

  handleBlur = () => {
    const {
      hasFilters,
      isAdvancedOpen,
      isCheatSheetOpen,
      isMapOpen,
      isOpen
    } = this.props;
    if (isCheatSheetOpen) {
      this.props.emitAction({
        type: "SET_IS_CHEAT_SHEET_OPEN",
        payload: false
      });
    }
    if (
      window.innerWidth <= mobileBreakpoint ||
      hasFilters ||
      isAdvancedOpen ||
      isMapOpen ||
      isOpen
    ) {
      return;
    }
    this.forceBlur();
  };

  forceFocus = () => {
    if (!this.props.isFocused) {
      this.props.emitAction({
        type: "SET_IS_OMNI_SEARCH_FOCUSED",
        payload: true
      });
    }
    if (this.omniSelect.input) {
      this.omniSelect.input.focus();
    }
  };

  forceBlur = () => {
    if (this.props.isFocused) {
      this.props.emitAction({
        type: "SET_IS_OMNI_SEARCH_FOCUSED",
        payload: false
      });
    }
    if (this.omniSelect.input) {
      this.omniSelect.input.blur();
    }
  };

  removeEventListeners = () => {
    window.removeEventListener("resize", this.handleResize);
  };
}

OmniSearch.propTypes = {
  isOpen: PropTypes.bool,
  isLoading: PropTypes.bool,
  isFocused: PropTypes.bool,
  inputValue: PropTypes.string,
  matches: PropTypes.array,
  staticOptions: PropTypes.array,
  availablePrimaryFields: PropTypes.object,
  isAdvancedOpen: PropTypes.bool,
  isCheatSheetOpen: PropTypes.bool,
  isMapOpen: PropTypes.bool,
  isSearchable: PropTypes.bool,
  isSearchRoute: PropTypes.bool,
  hasFilters: PropTypes.bool,
  filters: PropTypes.array,
  pathname: PropTypes.string,
  addFilter: PropTypes.func,
  removeFilter: PropTypes.func,
  getOmniMatches: PropTypes.func,
  emitAction: PropTypes.func
};

export default OmniSearch;
