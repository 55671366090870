import { compact } from "lodash";

export function transformSavedAreas(searches) {
  return searches.reduce(
    (state, search) => {
      return {
        order: [...state.order, `${search.id}`],
        data: { ...state.data, [search.id]: transformSavedArea(search) }
      };
    },
    { order: [], data: {} }
  );
}

export function transformSavedAreaOptions(searches) {
  return searches.map((search) => transformSavedArea(search));
}

export function transformSavedArea(search) {
  const name = search.name || search.display_text;
  const coordinates = search.filters.find(
    (filter) => filter.field === "location"
  ).within;

  return {
    id: `${search.id}`,
    field: "polygon",
    value: transformSavedAreaCoordinates(JSON.parse(coordinates)[0]),
    lowerCase: (name || "").toLowerCase(),
    updatedAt: new Date(search.updated_at),
    label: name,
    name
  };
}

export function getSavedAreaId({ filter, savedAreas }) {
  return compact(
    Object.values(savedAreas.data).map((savedArea) => {
      if (savedArea.value !== filter.value) {
        return null;
      }
      return savedArea.id;
    })
  )[0];
}

function transformSavedAreaCoordinates(coordinates) {
  return JSON.stringify(
    (coordinates || []).map((coordinate) => {
      return [
        window.parseFloat(coordinate[0]),
        window.parseFloat(coordinate[1])
      ];
    })
  );
}
