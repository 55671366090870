import styled, { keyframes } from "styled-components";
import { greyLightest } from "../../../../utils/colorsLocal";
import { absoluteFill } from "../../../../utils/styling";
import {
  mobileBreakpoint,
  headerHeightMobile,
  headerHeightDesktop,
  zIndexMap,
  oneColumnBreakpoint,
  oneColBkptWithSplit,
  defaultMapSplit,
  oneColumnMapSplit
} from "../../../../utils/styling";

export const Container = styled.div`
  position: absolute;
  z-index: ${zIndexMap};
  background-color: ${greyLightest};
  width: ${({ isMappable }) => (isMappable ? defaultMapSplit * 100 : 100)}%;
  top: ${headerHeightDesktop}px;
  overflow: hidden;
  bottom: 0px;
  right: 0px;

  @media (max-width: ${mobileBreakpoint}px) {
    top: ${headerHeightMobile}px;
  }

  @media (max-width: ${oneColBkptWithSplit(true)}px) {
    width: ${({ isMappable }) => (isMappable ? oneColumnMapSplit * 100 : 100)}%;
  }

  @media (max-width: ${oneColumnBreakpoint}px) {
    width: 100%;
  }
`;

const rotate = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(359deg); }
`;

export const LoadingCluster = styled.div`
  padding-top: 5.5rem;
  height: 13.6rem;
  width: 26rem;

  svg {
    animation-name: ${rotate};
    animation-duration: 600ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
`;

export const ClickContainer = styled.div`
  position: relative;
`;

export const ClickTarget = styled.div`
  ${absoluteFill};
`;
