import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEqual, parseInt, without } from "lodash";
import { history } from "../../../utils/routing";
import ListingsData from "../../listing/ListingsData";
import { InputLayout, GhostInput } from "./styled/search-route";
import { getCasFiltersFromQueryObject } from "../../../modules/queryObject";
import { Container, Input, SavedName } from "./styled/search-route";

class SearchRoute extends Component {
  componentDidMount() {
    this.handleNewLocation();
  }

  componentDidUpdate(prevProps) {
    this.handleReloadLocation(prevProps);
    this.handleNewAdhocSearch(prevProps);
    this.handleSavedNameChange(prevProps);
    this.handlePaginationChange(prevProps);
    this.handlePathIdChange(prevProps);
    this.handleQueryChange(prevProps);
    this.handleTrackEvent(prevProps);
  }

  render() {
    return (
      <Container>
        {this.props.isPublic ? (
          <SavedName>{this.props.inputValue}</SavedName>
        ) : (
          <InputLayout
            isLoading={this.props.isLoading}
            isFocused={this.props.isFocused}
            isNamed={!!this.props.savedName}>
            <GhostInput value={this.props.inputValue} />
            <Input
              placeholder={this.getNamePlaceholder()}
              ref={(node) => (this.input = node)}
              isFocused={this.props.isFocused}
              isNamed={!!this.props.savedName}
              value={this.props.inputValue}
              onKeyDown={this.handleNameKeyDown}
              onChange={this.handleNameChange}
              onFocus={this.handleNameFocus}
              onBlur={this.handleNameBlur}
            />
          </InputLayout>
        )}
        <ListingsData
          isPublic={this.props.isPublic}
          isLoading={this.props.isLoading}
          isMapOpen={this.props.isMapOpen}
          isNonDisclosure={this.props.isNonDisclosure}
          isCards={this.props.isCards}
          page={this.props.page}
          limit={this.props.limit}
          sort={this.props.sort}
          listings={this.props.listings}
          parentPath={this.props.pathname}
          totalCount={this.props.totalCount}
          selectedMlsnums={this.props.selectedMlsnums}
          handleSelectSort={this.handleSelectSort}
          handleSelectLimit={this.handleSelectLimit}
          handleClickPage={this.handleClickPage}
          handleToggleView={this.handleToggleView}
          handleHoverListing={this.handleHoverListing}
          handleClickCheckBox={this.handleClickCheckBox}
        />
      </Container>
    );
  }

  handleNewLocation = () => {
    if (this.props.isAppReady && this.props.pathId !== this.props.id) {
      this.props.emitAction({ type: "SET_SHOULD_TRACK_SEARCH" });

      if (this.props.pathId === "loading") {
        this.props.createAdhocSearch();
      } else {
        this.props.getSavedSearch({ id: this.props.pathId, page: 1 });
      }
    }
  };

  handleReloadLocation = ({ isAppReady }) => {
    if (
      !isAppReady &&
      this.props.isAppReady &&
      this.props.id !== this.props.pathId
    ) {
      const { pathId, page } = this.props;
      this.props.getSavedSearch({ id: pathId, page });
    }
  };

  handleNewAdhocSearch = ({ isCreating }) => {
    if (isCreating && !this.props.isCreating && !this.props.hasError) {
      history.replace(`/searches/${this.props.id}`);
    }
  };

  handleSavedNameChange = ({ isNaming }) => {
    if (isNaming && !this.props.isNaming) {
      this.props.getUgcStaticOptions();
    }
  };

  handlePathIdChange = ({ pathId }) => {
    if (pathId !== this.props.pathId && pathId !== "loading") {
      this.props.getSavedSearch({ id: this.props.pathId, page: 1 });
    }
  };

  handlePaginationChange = ({ queryObject, page, limit, sort }) => {
    const isSameQuery = isEqual(queryObject, this.props.queryObject);
    const isNewPage = page !== this.props.page;
    const isNewLimit = limit !== this.props.limit;
    const isNewSort = sort !== this.props.sort;

    if (isSameQuery && (isNewPage || isNewLimit || isNewSort)) {
      const { pathId, getSavedSearch } = this.props;
      getSavedSearch({ id: pathId, page: isNewPage ? this.props.page : 1 });
    }
  };

  handleQueryChange = ({ id, queryObject, updateSavedSearch }) => {
    const isNewQuery = !isEqual(queryObject, this.props.queryObject);

    if (id === this.props.id && isNewQuery && this.props.hasFilters) {
      updateSavedSearch({ id: this.props.pathId, isNaming: false, page: 1 });
    }
  };

  handleTrackEvent = ({ isLoading }) => {
    if (isLoading && !this.props.isLoading && this.props.shouldTrack) {
      this.props.trackEvent({
        eventName: this.props.eventName,
        metaData: {
          criteria: getCasFiltersFromQueryObject({
            statusMapping: this.props.statusMapping,
            queryObject: this.props.queryObject
          })
        }
      });
    }
  };

  handleNameKeyDown = (event) => {
    if (event.key === "Enter" && !this.props.isNaming) {
      this.input.blur();
    }
  };

  handleNameChange = (event) => {
    if (!this.props.isNaming) {
      const payload = event.target.value;
      this.props.emitAction({ type: "SET_SAVED_SEARCH_INPUT_VALUE", payload });
    }
  };

  handleNameFocus = () => {
    const { emitAction } = this.props;
    emitAction({ type: "SET_IS_SAVED_SEARCH_NAME_FOCUSED", payload: true });
  };

  handleNameBlur = () => {
    const { emitAction, page, updateSavedSearch } = this.props;
    emitAction({ type: "SET_IS_SAVED_SEARCH_NAME_FOCUSED", payload: false });
    updateSavedSearch({ id: this.props.pathId, isNaming: true, page });
  };

  handleToggleView = () => {
    const { emitAction } = this.props;
    emitAction({ type: "SET_IS_LISTING_CARDS", payload: !this.props.isCards });
  };

  handleHoverListing = (listingId) => {
    const { emitAction } = this.props;
    emitAction({ type: "SET_HOVERED_LISTING_ID", payload: listingId });
  };

  handleClickCheckBox = (mlsnum) => {
    const { selectedMlsnums } = this.props;
    const add = [...selectedMlsnums, mlsnum];
    const remove = without(selectedMlsnums, mlsnum);
    const payload = selectedMlsnums.includes(mlsnum) ? remove : add;
    this.props.emitAction({ type: "SET_SELECTED_MLSNUMS", payload });
  };

  handleSelectSort = (value) => {
    const { emitAction } = this.props;
    emitAction({ type: "SET_LISTINGS_SORT", payload: value });
  };

  handleSelectLimit = (value) => {
    const { emitAction } = this.props;
    emitAction({ type: "SET_LISTINGS_LIMIT", payload: parseInt(value) });
  };

  handleClickPage = (value) => {
    const { emitAction } = this.props;
    emitAction({ type: "SET_LISTINGS_PAGE", payload: parseInt(value) });
  };

  getNamePlaceholder = () => {
    if (this.props.isNaming) {
      return "Saving...";
    }

    if (this.props.isLoading) {
      return "Loading...";
    }

    return "Name this search";
  };
}

SearchRoute.propTypes = {
  id: PropTypes.string,
  pathId: PropTypes.string,
  pathname: PropTypes.string,
  eventName: PropTypes.string,
  shouldTrack: PropTypes.bool,
  isPublic: PropTypes.bool,
  isAppReady: PropTypes.bool,
  isCreating: PropTypes.bool,
  isLoading: PropTypes.bool,
  isMapOpen: PropTypes.bool,
  isFocused: PropTypes.bool,
  isNaming: PropTypes.bool,
  isCards: PropTypes.bool,
  isNonDisclosure: PropTypes.bool,
  hasFilters: PropTypes.bool,
  savedName: PropTypes.string,
  inputValue: PropTypes.string,
  queryObject: PropTypes.object,
  statusMapping: PropTypes.object,
  listings: PropTypes.array,
  totalCount: PropTypes.number,
  selectedMlsnums: PropTypes.array,
  page: PropTypes.number,
  limit: PropTypes.number,
  sort: PropTypes.string,
  getSavedSearch: PropTypes.func,
  createAdhocSearch: PropTypes.func,
  updateSavedSearch: PropTypes.func,
  getUgcStaticOptions: PropTypes.func,
  trackEvent: PropTypes.func,
  emitAction: PropTypes.func
};

export default SearchRoute;
