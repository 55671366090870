import styled from "styled-components";

export const Grid = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const Cell = styled.div`
  width: calc(100% / 3);
`;

export const Address = styled.div`
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.6rem;
  text-align: center;
  color: #111827;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SubAddress = styled.div`
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1.6rem;
  text-align: center;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #374151;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const AgentDetails = styled.div`
  width: 10.9rem;
  margin-left: 2.4rem;
`;
