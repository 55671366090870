import { connect } from "react-redux";
import { emitAction } from "../../../../utils/redux";
import { getListingToRender } from "../../../../modules/listing";
import { getInCollectorCount } from "../../../../modules/collection";
import ListingControls from "../ListingControls";

function select({ application, listing, sharedSearch, collector }) {
  const listingToRender = getListingToRender({ listing, sharedSearch });

  return {
    status: listingToRender.statusLabel,
    dom: listingToRender.dom,
    price: listingToRender.price,
    pricePerSqft: listingToRender.pricePerSqft,
    beds: listingToRender.beds,
    baths: listingToRender.baths,
    bathsFormatted: listingToRender.bathsFormattedInline,
    sqft: listingToRender.sqft,
    listPrice: listingToRender.listPrice,
    originalListPrice: listingToRender.originalListPrice,
    soldPrice: listingToRender.soldPrice,
    listDate: listingToRender.listDate,
    soldDate: listingToRender.soldDate,
    diffListPriceToOriginalListPrice:
      listingToRender.listPrice - listingToRender.originalListPrice,
    diffSoldPriceToListPricePercentage:
      (listingToRender.soldPrice / listingToRender.originalListPrice) * 100,
    isPublic: application.isPublic,
    showingTimeUrl: listingToRender.showingTimeUrl,
    isCollectorOpen: listingToRender.isCollectorOpen,
    inCollectorCount: getInCollectorCount({
      listingId: listingToRender.id,
      collector
    })
  };
}

const actions = {
  emitAction
};

export default connect(select, actions)(ListingControls);
