import React from "react";
import PropTypes from "prop-types";
import Button from "./Button";
import { Title, Body } from "./styled/notice";
import {
  Container,
  Notice,
  Buttons,
  ButtonLayout
} from "./styled/notice-with-buttons";

const buttonsWidthDesktop = 210;
const buttonsWidthMobile = 150;

function DeleteNotice({ body, handleDelete, handleCancel }) {
  return (
    <Container>
      <Notice {...{ buttonsWidthDesktop, buttonsWidthMobile }}>
        <Title>Delete Confirmation</Title>
        <Body>{body}</Body>
      </Notice>
      <Buttons {...{ buttonsWidthDesktop, buttonsWidthMobile }}>
        <ButtonLayout>
          <Button label="Delete" handleClick={handleDelete} type="danger" />
        </ButtonLayout>
        <ButtonLayout>
          <Button label="Cancel" handleClick={handleCancel} type="secondary" />
        </ButtonLayout>
      </Buttons>
    </Container>
  );
}

DeleteNotice.propTypes = {
  body: PropTypes.string,
  handleDelete: PropTypes.func,
  handleCancel: PropTypes.func
};

export default DeleteNotice;
