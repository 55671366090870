import React from "react";

function MapIcon() {
  return (
    <svg viewBox="0 0 100 100" preserveAspectRatio="xMidYMid meet">
      <path d="M96.3333333,0.166666667 L66.6666667,11.6666667 L33.3333333,0 L2,10.5555556 C0.833333333,10.9444444 0,11.9444444 0,13.2222222 L0,97.2222222 C0,98.7777778 1.22222222,100 2.77777778,100 L3.66666667,99.8333333 L33.3333333,88.3333333 L66.6666667,100 L98,89.4444444 C99.1666667,89.0555556 100,88.0555556 100,86.7777778 L100,2.77777778 C100,1.22222222 98.7777778,0 97.2222222,0 L96.3333333,0.166666667 Z M33.3333333,77.1666667 L33.3333333,11.1111111 L66.6666667,22.8333333 L66.6666667,88.8888889 L33.3333333,77.1666667 Z"></path>
    </svg>
  );
}

export default MapIcon;
