import React, { Component } from "react";
import PropTypes from "prop-types";
import { parseInt, without } from "lodash";
import ListingsData from "../../listing/ListingsData";
import { Container, Title } from "./styled/collection-route";

class CollectionRoute extends Component {
  componentDidMount() {
    this.handleNewLocation();
  }

  componentDidUpdate(prevProps) {
    this.handleReloadLocation(prevProps);
    this.handlePaginationChange(prevProps);
    this.handleTrackEvent(prevProps);
  }

  render() {
    return (
      <Container>
        <Title>{this.props.isLoading ? "Loading..." : this.props.name}</Title>
        <ListingsData
          isPublic={this.props.isPublic}
          isLoading={this.props.isLoading}
          isMapOpen={this.props.isMapOpen}
          isNonDisclosure={this.props.isNonDisclosure}
          isCards={this.props.isCards}
          page={this.props.page}
          limit={this.props.limit}
          sort={this.props.sort}
          listings={this.props.listings}
          parentPath={this.props.pathname}
          totalCount={this.props.totalCount}
          selectedMlsnums={this.props.selectedMlsnums}
          handleSelectSort={this.handleSelectSort}
          handleSelectLimit={this.handleSelectLimit}
          handleClickPage={this.handleClickPage}
          handleToggleView={this.handleToggleView}
          handleHoverListing={this.handleHoverListing}
          handleClickCheckBox={this.handleClickCheckBox}
        />
      </Container>
    );
  }

  handleNewLocation = () => {
    if (this.props.isAppReady) {
      this.props.getCollection({ id: this.props.pathId, page: 1 });
      this.props.emitAction({ type: "SET_SHOULD_TRACK_COLLECTION" });
    }
  };

  handleReloadLocation = ({ isAppReady }) => {
    if (
      !isAppReady &&
      this.props.isAppReady &&
      this.props.id !== this.props.pathId
    ) {
      const { pathId, page } = this.props;
      this.props.getCollection({ id: pathId, page });
    }
  };

  handlePaginationChange = ({ hasListingIds, page, limit, sort }) => {
    const isLoaded = hasListingIds && this.props.hasListingIds;
    const isNewPage = page !== this.props.page;
    const isNewLimit = limit !== this.props.limit;
    const isNewSort = sort !== this.props.sort;

    if (isLoaded && (isNewPage || isNewLimit || isNewSort)) {
      const { pathId, getCollection } = this.props;
      getCollection({ id: pathId, page: isNewPage ? this.props.page : 1 });
    }
  };

  handleTrackEvent = ({ isLoading }) => {
    if (isLoading && !this.props.isLoading && this.props.shouldTrack) {
      const { eventName, metaData } = this.props;
      this.props.trackEvent({ eventName, metaData });
    }
  };

  handleHoverListing = (listingId) => {
    this.props.emitAction({
      type: "SET_HOVERED_LISTING_ID",
      payload: listingId
    });
  };

  handleToggleView = () => {
    this.props.emitAction({
      type: "SET_IS_LISTING_CARDS",
      payload: !this.props.isCards
    });
  };

  handleClickCheckBox = (mlsnum) => {
    const { selectedMlsnums } = this.props;
    const payload = selectedMlsnums.includes(mlsnum)
      ? without(selectedMlsnums, mlsnum)
      : [...selectedMlsnums, mlsnum];
    this.props.emitAction({ type: "SET_SELECTED_MLSNUMS", payload });
  };

  handleSelectSort = (value) => {
    const { emitAction } = this.props;
    emitAction({ type: "SET_LISTINGS_SORT", payload: value });
  };

  handleSelectLimit = (value) => {
    const { emitAction } = this.props;
    emitAction({ type: "SET_LISTINGS_LIMIT", payload: parseInt(value) });
  };

  handleClickPage = (value) => {
    const { emitAction } = this.props;
    emitAction({ type: "SET_LISTINGS_PAGE", payload: parseInt(value) });
  };
}

CollectionRoute.propTypes = {
  id: PropTypes.string,
  pathId: PropTypes.string,
  pathname: PropTypes.string,
  eventName: PropTypes.string,
  metaData: PropTypes.object,
  shouldTrack: PropTypes.bool,
  name: PropTypes.string,
  listingIds: PropTypes.array,
  listings: PropTypes.array,
  totalCount: PropTypes.number,
  selectedMlsnums: PropTypes.array,
  isPublic: PropTypes.bool,
  isCards: PropTypes.bool,
  isLoading: PropTypes.bool,
  isMapOpen: PropTypes.bool,
  isAppReady: PropTypes.bool,
  hasListingIds: PropTypes.bool,
  page: PropTypes.number,
  limit: PropTypes.number,
  sort: PropTypes.string,
  getCollection: PropTypes.func,
  trackEvent: PropTypes.func,
  emitAction: PropTypes.func
};

export default CollectionRoute;
