import React from "react";

function PriceIcon() {
  return (
    <svg viewBox="0 0 100 100" preserveAspectRatio="xMidYMid meet">
      <path d="M52.4444444,43.8888889 C39.8333333,40.6111111 35.7777778,37.2222222 35.7777778,31.9444444 C35.7777778,25.8888889 41.3888889,21.6666667 50.7777778,21.6666667 C60.6666667,21.6666667 64.3333333,26.3888889 64.6666667,33.3333333 L76.9444444,33.3333333 C76.5555556,23.7777778 70.7222222,15 59.1111111,12.1666667 L59.1111111,0 L42.4444444,0 L42.4444444,12 C31.6666667,14.3333333 23,21.3333333 23,32.0555556 C23,44.8888889 33.6111111,51.2777778 49.1111111,55 C63,58.3333333 65.7777778,63.2222222 65.7777778,68.3888889 C65.7777778,72.2222222 63.0555556,78.3333333 50.7777778,78.3333333 C39.3333333,78.3333333 34.8333333,73.2222222 34.2222222,66.6666667 L22,66.6666667 C22.6666667,78.8333333 31.7777778,85.6666667 42.4444444,87.9444444 L42.4444444,100 L59.1111111,100 L59.1111111,88.0555556 C69.9444444,86 78.5555556,79.7222222 78.5555556,68.3333333 C78.5555556,52.5555556 65.0555556,47.1666667 52.4444444,43.8888889 L52.4444444,43.8888889 Z"></path>
    </svg>
  );
}

export default PriceIcon;
