import React from "react";

function IconLoading(props) {
  return (
    <svg
      {...props}
      fill="none"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.75 17a.75.75 0 01-.75.75A7.75 7.75 0 1117.75 10a.75.75 0 01-1.5 0A6.25 6.25 0 1010 16.25a.75.75 0 01.75.75z"
      />
    </svg>
  );
}

export default IconLoading;
