import styled, { css } from "styled-components";
import {
  greyMedium,
  greyLight,
  greyLighter,
  blueMedium,
  bluePowder,
  red,
  streamsBlue,
  cmaGreen
} from "../../../utils/colorsLocal";
import {
  defaultBorderRadius,
  noWrap,
  overlayBoxShadow
} from "../../../utils/styling";

export const Container = styled.div`
  text-align: left;
`;

export const MoreButton = styled.div`
  padding: ${({ isOpen }) => (isOpen ? 8 : 6)}px;
  border-color: ${greyLight};
  border-style: solid;
  border-radius: 50%;
  border-width: 1px;
  cursor: pointer;
  height: 30px;
  width: 30px;

  svg {
    fill: ${blueMedium};
  }
`;

export const MoreMenu = styled.div`
  position: absolute;
  left: ${({ openRight }) => (openRight ? "40px" : "auto")};
  right: ${({ openRight }) => (openRight ? "auto" : "40px")};
  border-radius: ${defaultBorderRadius}px;
  border: 1px solid ${greyLighter};
  background-color: white;
  ${overlayBoxShadow};
  cursor: default;
  width: 220px;
  top: -15px;
  z-index: 1;
`;

export const MainActions = styled.div`
  padding: 10px 0px;
`;

const rowStyles = css`
  ${noWrap};
  font-size: 13px;
  padding: 10px 20px;
`;

export const MainRow = styled.div`
  ${rowStyles};
  cursor: pointer;
  color: ${greyMedium};

  #pointer {
    position: absolute;
    background-color: white;
    border-top: ${({ openRight }) =>
      openRight ? "none" : `1px solid ${greyLighter}`};
    border-right: ${({ openRight }) =>
      openRight ? "none" : `1px solid ${greyLighter}`};
    border-bottom: ${({ openRight }) =>
      openRight ? `1px solid ${greyLighter}` : "none"};
    border-left: ${({ openRight }) =>
      openRight ? `1px solid ${greyLighter}` : "none"};
    left: ${({ openRight }) => (openRight ? "-6px" : "auto")};
    right: ${({ openRight }) => (openRight ? "auto" : "-6px")};
    transform: rotate(45deg);
    border-radius: 2px;
    height: 12px;
    width: 12px;
    top: 23px;
  }

  &:hover {
    background-color: ${bluePowder};

    #pointer {
      background-color: ${bluePowder};
    }
  }
`;

export const DisabledRow = styled.div`
  ${rowStyles};
  color: ${greyLight};
`;

export const DeleteAction = styled.div`
  padding: 10px 0px;
`;

export const DeleteRow = styled.div`
  ${rowStyles};
  color: ${red};
  cursor: pointer;

  #pointer {
    position: absolute;
    background-color: white;
    border-top: ${({ openRight }) =>
      openRight ? "none" : `1px solid ${greyLighter}`};
    border-right: ${({ openRight }) =>
      openRight ? "none" : `1px solid ${greyLighter}`};
    border-bottom: ${({ openRight }) =>
      openRight ? `1px solid ${greyLighter}` : "none"};
    border-left: ${({ openRight }) =>
      openRight ? `1px solid ${greyLighter}` : "none"};
    left: ${({ openRight }) => (openRight ? "-6px" : "auto")};
    right: ${({ openRight }) => (openRight ? "auto" : "-6px")};
    transform: rotate(45deg);
    border-radius: 2px;
    height: 12px;
    width: 12px;
    top: 23px;
  }

  svg {
    fill: ${red};
  }

  &:hover {
    background-color: ${red};
    color: white;

    #pointer {
      background-color: ${red};
    }

    svg {
      fill: white;
    }
  }
`;

const icon = css`
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
  height: 18px;
  width: 18px;
`;

export const MainIcon = styled.div`
  ${icon};

  svg {
    fill: ${({ isCma, isStreams }) =>
      isCma ? cmaGreen : isStreams ? streamsBlue : greyMedium};
  }
`;

export const DeleteIcon = styled.div`
  ${icon};
`;
