import React from "react";

function LogoIcon(props) {
  return (
    <svg viewBox="0 0 100 100" {...props} preserveAspectRatio="xMidYMid meet">
      <path d="M67.4228146,88.6852588 L59.5708566,88.6852588 C58.7062138,88.6852588 58.0052822,87.9843273 58.0052822,87.1196844 L58.0052822,80.207071 C58.0052822,79.4887523 58.5875946,78.90644 59.3059133,78.90644 L67.5191577,78.90644 C73.4041541,78.9256861 79.054016,76.5980481 83.2176189,72.438966 C87.3812217,68.2798838 89.7149962,62.6325539 89.7021433,56.7475401 C89.5555727,46.2752555 81.9426926,37.4053885 71.613737,35.6724994 L71.3247079,35.6724994 C69.9439328,35.430353 68.7670936,34.5320025 68.1694732,33.2639234 L68.0490444,32.99898 C64.2578255,25.1194614 56.2720451,20.1236622 47.5279763,20.1612695 C35.7368091,20.180397 25.8823152,29.1390278 24.7428466,40.8750238 L24.7428466,41.2363102 C24.595917,42.893514 23.5085827,44.3182969 21.9488984,44.8973458 C15.2241736,47.2987568 10.5929931,53.4958078 10.1950471,60.6253476 C9.78630292,68.5858716 14.8273911,75.8102463 22.4399959,78.1735625 C30.0526007,80.5368786 38.2986824,77.437476 42.4699665,70.645024 L49.069465,59.7582602 C49.2443944,59.4667843 49.5283561,59.2571185 49.8584096,59.1757354 C50.1884631,59.0943524 50.537345,59.1479745 50.8277255,59.3247165 L56.9936802,63.0580094 C57.2851562,63.2329389 57.4948219,63.5169005 57.576205,63.846954 C57.657588,64.1770075 57.6039659,64.5258895 57.4272239,64.8162699 L50.8036397,75.7271195 C44.7598736,85.5389354 33.1877835,90.475785 21.9225216,88.0482937 C10.6572598,85.6208024 2.14537282,76.3561636 0.678925123,64.9260118 C-0.787522578,53.49586 5.10994651,42.3825521 15.3975715,37.1899024 C17.7804612,23.751429 28.2906603,13.2246664 41.7253647,10.820618 C55.160069,8.41656961 68.6685583,14.6453524 75.5638018,26.4235673 C89.5200638,29.8712201 99.358663,42.3479922 99.4568764,56.7234543 C99.4302912,74.3926683 85.0920436,88.698574 67.4228146,88.6852588 Z"></path>
    </svg>
  );
}

export default LogoIcon;
