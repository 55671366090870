import React from "react";
import { Container, Back, BackLabel } from "./styled/generic-header";
import PropTypes from "prop-types";

export default function GenericHeader({ children }) {
  return <Container>{children}</Container>;
}

GenericHeader.Back = GenericHeaderBack;

function GenericHeaderBack({ hideLabelOnMobile, onClick }) {
  return (
    <Back aria-label="Back" onClick={onClick}>
      <svg
        width="20"
        height="20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.53 3.47a.75.75 0 0 1 0 1.06L8.06 10l5.47 5.47a.75.75 0 1 1-1.06 1.06L5.94 10l6.53-6.53a.75.75 0 0 1 1.06 0Z"
          fill="#111827"
        />
      </svg>
      <BackLabel>Back</BackLabel>
    </Back>
  );
}

GenericHeaderBack.propTypes = {
  onClick: PropTypes.func.isRequired
};
