import { apiClient } from "../modules/api";
import { getAgentGQL } from "@wrstudios/api";
import { transformAgent } from "../modules/agent";

export function getAgentProfile(id) {
  return async (dispatch, getState) => {
    const { mlsConfigs } = getState();
    const photoProxyStrategy = mlsConfigs.photoProxyStrategy;

    dispatch({ type: "GET_AGENT_PROFILE_INITIATED" });

    try {
      const response = await getAgentGQL({ apiClient, id });

      const { attributes, listings } = transformAgent({
        agent: response.data.data.agent,
        photoProxyStrategy
      });

      const payload = { attributes, listings };
      dispatch({ type: "GET_AGENT_PROFILE_SUCCEEDED", payload });
    } catch (error) {
      dispatch({ type: "GET_AGENT_PROFILE_FAILED", payload: error });
    }
  };
}
