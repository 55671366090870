import React from "react";
import PropTypes from "prop-types";
import { Container } from "./styled/button";

function Button({ type, label, stretch, handleClick, ...attrs }) {
  return (
    <Container {...attrs} type={type} stretch={stretch} onClick={handleClick}>
      {label}
    </Container>
  );
}

Button.defaultProps = {
  type: "primary",
  stretch: false
};

Button.propTypes = {
  label: PropTypes.string,
  handleClick: PropTypes.func,
  stretch: PropTypes.bool,
  type: PropTypes.oneOf(["primary", "secondary", "danger"])
};

export default Button;
