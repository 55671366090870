import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import DynamicIcon from "./DynamicIcon";
import CloseIcon from "../icons/CloseIcon";
import AdvancedIcon from "../icons/AdvancedIcon";
import {
  Container,
  CloseButton,
  Header,
  Title,
  SubTitle,
  AdvancedButton,
  Column,
  RowLeft,
  RowRight,
  Icon,
  Text,
  HelpLink,
  Or
} from "./styled/cheat-sheet";

class CheatSheet extends Component {
  render() {
    return (
      <Container
        isOpen={this.props.isOpen}
        isSwitcherEnabled={this.props.isSwitcherEnabled}>
        <CloseButton onClick={this.handleHide}>
          <CloseIcon />
        </CloseButton>
        <Header>
          <Title>Search Cheat Sheet</Title>
          <SubTitle>
            This smart search bar allows you to search simply by typing natural
            language and making your choices. For additional criteria, use the
            Advanced Search button
          </SubTitle>
          <span>
            <AdvancedButton>
              <AdvancedIcon />
            </AdvancedButton>
          </span>
        </Header>
        {this.props.data.map((column, columnIndex) => (
          <Column key={columnIndex}>
            {column.map((row, rowIndex) => (
              <div key={rowIndex}>
                <RowLeft>
                  <Icon name={row.field}>
                    <DynamicIcon name={row.field} />
                  </Icon>
                  <Text>{row.label}</Text>
                </RowLeft>
                <RowRight>
                  <Text>{this.renderValue(row.value)}</Text>
                </RowRight>
              </div>
            ))}
          </Column>
        ))}
        <HelpLink>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://support.cloudmlx.com/customer/en/portal/topics/926948-cloud-mlx---getting-started-videos/articles">
            Watch Getting Started Videos
          </a>
        </HelpLink>
        <HelpLink>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://lonewolf.my.site.com/s/topic/0TO2L0000008JR8WAM/cloud-mlx">
            Visit our Support Center
          </a>
        </HelpLink>
      </Container>
    );
  }

  handleHide = () => {
    this.props.emitAction({ type: "SET_IS_CHEAT_SHEET_OPEN", payload: false });
  };

  renderValue = (value) => {
    if (typeof value === "string") {
      return value;
    }
    return value.map((item, index) => {
      if (index === 0) {
        return <span key={index}>{item}</span>;
      }
      return (
        <Fragment key={index}>
          <Or />
          <span>{item}</span>
        </Fragment>
      );
    });
  };
}

CheatSheet.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        field: PropTypes.string,
        label: PropTypes.string,
        value: PropTypes.any
      })
    )
  ),
  isOpen: PropTypes.bool,
  isSwitcherEnabled: PropTypes.bool,
  emitAction: PropTypes.func
};

export default CheatSheet;
