import styled from "styled-components";
import { greyLight, greyMedium } from "../../../../utils/colorsLocal";
import { routeContainer, absoluteFill } from "../../../../utils/styling";
import { mobileBreakpoint, pageTitle } from "../../../../utils/styling";
import { delayVisibility } from "../../../../utils/styling";

export const Container = styled.div`
  ${routeContainer};
`;

export const InputLayout = styled.div`
  ${pageTitle};
  ${delayVisibility};
  position: relative;
  display: inline-block;
  vertical-align: top;
  transition: all 600ms;
  min-width: ${({ isNamed }) => (isNamed ? 300 : 0)}px;
  width: ${({ isFocused, isNamed }) =>
    inputLayoutWidthDesktop({ isFocused, isNamed })};
  height: 52px;
  background-image: ${({ isLoading, isFocused, isNamed }) =>
    inputLayoutUnderline({ isLoading, isFocused, isNamed })};
  background-repeat: repeat-x;
  background-position: bottom;
  background-size: 15px 1px;

  @media (max-width: ${mobileBreakpoint}px) {
    transition: width 400ms;
    width: ${({ isFocused, isNamed }) =>
      inputLayoutWidthMobile({ isFocused, isNamed })};
  }
`;

export const GhostInput = styled.div`
  display: inline-block;
  vertical-align: top;
  opacity: 0;

  &::before {
    content: "${({ value }) => value}";
  }
`;

export const SavedName = styled.div`
  ${pageTitle};
  height: 52px;
`;

export const Input = styled.input`
  transition: color 200ms;
  color: ${greyMedium};
  ${absoluteFill};
  ${pageTitle};
  width: 100%;

  &:hover {
    color: ${({ isNamed, isFocused }) =>
      isNamed && !isFocused ? greyLight : greyMedium};
  }

  &::placeholder {
    color: ${greyLight};
  }
`;

function inputLayoutUnderline({ isLoading, isFocused, isNamed }) {
  if (isLoading) {
    return "none";
  }

  if (isFocused || !isNamed) {
    return `linear-gradient(to right, ${greyLight} 70%, white 0%)`;
  }

  return "none";
}

function inputLayoutWidthDesktop({ isFocused, isNamed }) {
  if (isNamed) {
    return isFocused ? "100%" : "auto";
  }

  return isFocused ? "100%" : "310px";
}

function inputLayoutWidthMobile({ isFocused, isNamed }) {
  if (isNamed) {
    return isFocused ? "100%" : "auto";
  }

  return isFocused ? "100%" : "240px";
}
