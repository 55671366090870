import React, { Component } from "react";
import PropTypes from "prop-types";
import { times, parseInt } from "lodash";
import Notice from "../../common/Notice";
import SelectTag from "../../common/SelectTag";
import StaticMap from "../../common/StaticMap";
import Pagination from "../../common/Pagination";
import DeleteNotice from "../../common/DeleteNotice";
import Notification from "../../common/Notification";
import { sortOptions } from "../../../modules/search";
import { pagePadding } from "../../../utils/styling";
import { Card } from "@wrstudios/components";
import { PhotoCard } from "@wrstudios/components";
import { ThreeColumnItem } from "../../common/styled/three-columns";
import { ThreeColumnContainer } from "../../common/styled/three-columns";
import { Header, Title, Sorting } from "../../common/styled/heading";
import { SearchLinkLayout, SearchLink } from "./styled/areas-route";
import { AreaMapLayout, AreaMap, Name } from "./styled/areas-route";
import { Container, Body } from "./styled/areas-route";

class AreasRoute extends Component {
  componentDidMount() {
    this.handleNewLocation();
  }

  componentDidUpdate(prevProps) {
    this.handleReloadLocation(prevProps);
    this.handlePaginationChange(prevProps);
    this.handleAsyncDelete(prevProps);
  }

  render() {
    const { isLoading, savedAreas } = this.props;

    return (
      <Container>
        <Header>
          <Title>Saved Areas</Title>
          <Sorting>
            <SelectTag
              handleSelect={this.handleSelectSort}
              selected={this.props.sort}
              options={sortOptions}
              width={184}
            />
          </Sorting>
        </Header>
        <Body>
          {isLoading ? (
            <ThreeColumnContainer>
              {times(9).map((index) => (
                <ThreeColumnItem key={index} bottom={pagePadding}>
                  <Card>
                    <AreaMapLayout>
                      <AreaMap />
                    </AreaMapLayout>
                    <Name></Name>
                    <Card.Footer>
                      <SearchLinkLayout />
                    </Card.Footer>
                  </Card>
                </ThreeColumnItem>
              ))}
            </ThreeColumnContainer>
          ) : (
            <ThreeColumnContainer>
              {savedAreas.map((savedArea) => (
                <ThreeColumnItem key={savedArea.id} bottom={pagePadding}>
                  <Card>
                    <AreaMapLayout
                      onClick={this.handleClickSavedArea.bind(this, savedArea)}>
                      <StaticMap
                        area={JSON.parse(savedArea.value)}
                        height={160}
                        width={800}
                      />
                    </AreaMapLayout>
                    <Name>{savedArea.name}</Name>
                    <Card.Footer>
                      <SearchLinkLayout>
                        <SearchLink
                          onClick={this.handleClickSavedArea.bind(
                            this,
                            savedArea
                          )}>
                          Search this area
                        </SearchLink>
                      </SearchLinkLayout>
                      <PhotoCard.Menu
                        deleteLabel="Delete Saved Area"
                        handleClickDelete={() =>
                          this.handleBeginDelete(savedArea.id)
                        }
                      />
                    </Card.Footer>
                  </Card>
                </ThreeColumnItem>
              ))}
            </ThreeColumnContainer>
          )}
        </Body>
        <Pagination
          page={this.props.page}
          limit={this.props.limit}
          totalCount={this.props.count}
          renderedCount={savedAreas.length}
          isVisible={!isLoading && savedAreas.length > 0}
          handleClickPage={this.handleClickPage}
        />
        <Notification
          ref={(node) => (this.deleteAlert = node)}
          isDismissable={false}
          shouldAutoDismiss={false}>
          <DeleteNotice
            body="This area will be permantently deleted"
            handleDelete={this.handleFinishDelete}
            handleCancel={this.handleCancelDelete}
          />
        </Notification>
        <Notification
          ref={(node) => (this.deleteSuccess = node)}
          type="success">
          <Notice title="Success" body="Your area was successfully deleted" />
        </Notification>
        <Notification ref={(node) => (this.deleteError = node)} type="error">
          <Notice title="Error" body="Your area could not be deleted" />
        </Notification>
      </Container>
    );
  }

  handleNewLocation = () => {
    if (this.props.isAppReady) {
      this.props.getSavedAreas(1);
    }
  };

  handleReloadLocation = ({ isAppReady }) => {
    if (!isAppReady && this.props.isAppReady) {
      this.props.getSavedAreas(this.props.page);
    }
  };

  handlePaginationChange = ({ page, sort }) => {
    const isNewPage = page !== this.props.page;
    const isNewSort = sort !== this.props.sort;

    if (!this.props.isLoading && (isNewPage || isNewSort)) {
      this.props.getSavedAreas(isNewPage ? this.props.page : 1);
    }
  };

  handleSelectSort = (value) => {
    const { emitAction } = this.props;
    emitAction({ type: "SET_SEARCHES_SORT", payload: value });
  };

  handleClickPage = (value) => {
    const { emitAction } = this.props;
    emitAction({ type: "SET_SEARCHES_PAGE", payload: parseInt(value) });
  };

  handleClickSavedArea = (filter) => {
    this.props.addFilter({ filter, pathname: this.props.pathname });
  };

  handleBeginDelete = (id) => {
    this.deleteAlert.display();
    this.deleteId = id;
  };

  handleFinishDelete = () => {
    this.props.deleteSavedArea(this.deleteId);
    this.deleteAlert.dismiss();
    this.deleteId = null;
  };

  handleCancelDelete = () => {
    this.deleteAlert.dismiss();
    this.deleteId = null;
  };

  handleAsyncDelete = ({ hasError, isDeleting }) => {
    if (!hasError && this.props.hasError) {
      return this.deleteError.display();
    }
    if (isDeleting && !this.props.isDeleting) {
      this.deleteSuccess.display();
      this.props.getUgcStaticOptions();
    }
  };
}

AreasRoute.propTypes = {
  pathname: PropTypes.string,
  count: PropTypes.number,
  page: PropTypes.number,
  limit: PropTypes.number,
  sort: PropTypes.string,
  savedAreas: PropTypes.array,
  hasError: PropTypes.bool,
  isLoading: PropTypes.bool,
  isDeleting: PropTypes.bool,
  isAppReady: PropTypes.bool,
  getSavedAreas: PropTypes.func,
  deleteSavedArea: PropTypes.func,
  getUgcStaticOptions: PropTypes.func,
  addFilter: PropTypes.func,
  emitAction: PropTypes.func
};

export default AreasRoute;
