import React from "react";

function ImageIcon() {
  return (
    <svg viewBox="0 0 100 100" preserveAspectRatio="xMidYMid meet">
      <path d="M100,88.8888889 L100,11.1111111 C100,5 95,0 88.8888889,0 L11.1111111,0 C5,0 0,5 0,11.1111111 L0,88.8888889 C0,95 5,100 11.1111111,100 L88.8888889,100 C95,100 100,95 100,88.8888889 Z M30.5555556,58.3333333 L44.4444444,75.0555556 L63.8888889,50 L88.8888889,83.3333333 L11.1111111,83.3333333 L30.5555556,58.3333333 Z"></path>
    </svg>
  );
}

export default ImageIcon;
