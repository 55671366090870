import React, { Component } from "react";
import PropTypes from "prop-types";
import { getDateShort } from "@wrstudios/utils";
import { AriaMessage } from "@wrstudios/components";
import Overflow from "../../common/connected/Overflow";
import BathsTooltip from "../../common/BathsTooltip";
import Adjustment from "../../common/Adjustment";
import ChevronDownIcon from "../../icons/ChevronDownIcon";
import ListingCollector from "./connected/ListingCollector";
import { pluralize, formatCurrency, formatNumber } from "@wrstudios/utils";
import {
  Card,
  Container,
  Button,
  Status,
  Dom,
  Price,
  PricePerSqft,
  Detail,
  DetailValue,
  DetailName,
  Date,
  Row,
  RowName,
  RowValue,
  PriceDifference,
  Difference,
  Action,
  CollectorButton,
  CollectorLabel,
  CollectorPointer
} from "./styled/listing-controls";

class ListingControls extends Component {
  componentWillUnmount() {
    this.removeEventListeners();
  }

  render() {
    const {
      isPublic,
      isCollectorOpen,
      inCollectorCount,
      showingTimeUrl,
      status,
      dom,
      price,
      pricePerSqft,
      beds,
      baths,
      bathsFormatted,
      sqft,
      listDate,
      soldDate,
      originalListPrice,
      listPrice,
      soldPrice,
      diffListPriceToOriginalListPrice,
      diffSoldPriceToListPricePercentage
    } = this.props;

    return (
      <Card>
        <Action>
          <Overflow />
          <AriaMessage>More actions</AriaMessage>
        </Action>
        {(!!status || !!dom || !!price) && (
          <Container>
            {!!status && <Status status={status}>{status}</Status>}
            {!!status && !!dom && <> &bull; </>}
            {!!dom && <Dom>{pluralize("Days", dom, true)}</Dom>}
            {!!price && (
              <Price>
                {formatCurrency(price)}
                {!!pricePerSqft && (
                  <PricePerSqft>{pricePerSqft}/sqft</PricePerSqft>
                )}
              </Price>
            )}
          </Container>
        )}
        {(!!beds || !!baths || !!sqft) && (
          <Container>
            {!!beds && (
              <Detail>
                <DetailValue>{formatNumber(beds, "0[,][.][0]0")}</DetailValue>{" "}
                <DetailName>{pluralize("beds", beds)}</DetailName>
              </Detail>
            )}
            {!!baths && (
              <Detail>
                <DetailValue>
                  {" "}
                  <BathsTooltip baths={bathsFormatted}>
                    {formatNumber(baths, "0[,][.][0]0")}
                  </BathsTooltip>
                </DetailValue>{" "}
                <DetailName>{pluralize("baths", baths)}</DetailName>
              </Detail>
            )}
            {!!sqft && (
              <Detail>
                <DetailValue>{formatNumber(sqft, "0[,][.][0]0")}</DetailValue>{" "}
                <DetailName>sqft</DetailName>
              </Detail>
            )}
          </Container>
        )}
        {(!!listDate || !!soldDate) && (
          <Container>
            {!!listDate && <Date>Listed: {getDateShort(listDate)}</Date>}
            {!!soldDate && <Date>Sold: {getDateShort(soldDate)}</Date>}
          </Container>
        )}
        {!!originalListPrice && (
          <Container>
            <Row>
              <RowName>Original List Price</RowName>
              <RowValue>{formatCurrency(originalListPrice)}</RowValue>
            </Row>
          </Container>
        )}
        {!!listPrice && (
          <Container>
            <Row>
              <RowName>
                List Price
                {!!diffListPriceToOriginalListPrice && (
                  <Difference value={diffListPriceToOriginalListPrice * -1}>
                    &nbsp;
                    <Adjustment
                      value={
                        diffListPriceToOriginalListPrice < 0
                          ? diffListPriceToOriginalListPrice + 1
                          : diffListPriceToOriginalListPrice - 1
                      }
                      adjustedValue={diffListPriceToOriginalListPrice}
                    />
                  </Difference>
                )}
              </RowName>
              <RowValue isDim={originalListPrice === listPrice}>
                {formatCurrency(listPrice)}
              </RowValue>
            </Row>
          </Container>
        )}
        {!!soldPrice && (
          <Container>
            <Row>
              <RowName>
                Sold Price
                {!!diffSoldPriceToListPricePercentage && (
                  <PriceDifference>
                    &nbsp;
                    {formatNumber(
                      diffSoldPriceToListPricePercentage,
                      "0[.][,][0]"
                    )}
                    %
                  </PriceDifference>
                )}
              </RowName>
              <RowValue>{formatCurrency(soldPrice)}</RowValue>
            </Row>
          </Container>
        )}
        {!isPublic && (
          <Container>
            <CollectorButton
              onClick={this.handleCollectorClick}
              isCollectorOpen={isCollectorOpen}>
              <CollectorLabel>
                {`Add to Collection ${
                  inCollectorCount > 0 ? `(${inCollectorCount})` : ""
                }`}
              </CollectorLabel>
              <CollectorPointer>
                <ChevronDownIcon />
              </CollectorPointer>
              <div ref={(node) => (this.collector = node)}>
                {isCollectorOpen && <ListingCollector />}
              </div>
            </CollectorButton>
            {showingTimeUrl && (
              <a
                href={showingTimeUrl}
                target="_blank"
                rel="noopener noreferrer">
                <Button>Schedule Showing</Button>
              </a>
            )}
          </Container>
        )}
      </Card>
    );
  }

  handleCollectorClick = () => {
    if (this.props.isCollectorOpen) {
      return;
    }
    this.props.emitAction({
      type: "SET_IS_LISTING_COLLECTOR_OPEN",
      payload: true
    });
    window.addEventListener("mouseup", this.handleWindowClick);
  };

  handleWindowClick = ({ target }) => {
    if (!this.props.isCollectorOpen || this.collector.contains(target)) {
      return;
    }
    window.setTimeout(
      () =>
        this.props.emitAction({
          type: "SET_IS_LISTING_COLLECTOR_OPEN",
          payload: false
        }),
      0
    );
    window.removeEventListener("mouseup", this.handleWindowClick);
  };

  removeEventListeners = () => {
    window.removeEventListener("mouseup", this.handleWindowClick);
  };
}

ListingControls.propTypes = {
  status: PropTypes.string,
  dom: PropTypes.string,
  price: PropTypes.string,
  pricePerSqft: PropTypes.string,
  beds: PropTypes.string,
  baths: PropTypes.string,
  bathsFormatted: PropTypes.string,
  sqft: PropTypes.string,
  listPrice: PropTypes.number,
  originalListPrice: PropTypes.number,
  soldPrice: PropTypes.number,
  listDate: PropTypes.string,
  soldDate: PropTypes.string,
  diffListPriceToOriginalListPrice: PropTypes.number,
  diffSoldPriceToListPricePercentage: PropTypes.number,
  isPublic: PropTypes.bool,
  showingTimeUrl: PropTypes.string,
  isCollectorOpen: PropTypes.bool,
  inCollectorCount: PropTypes.number,
  emitAction: PropTypes.func
};

export default ListingControls;
