import styled, { css } from "styled-components";
import {
  outerButtonWidthDesktop,
  appSwitcherWidthDesktop
} from "../../root/styled/header";
import {
  greyLighter,
  greyLight,
  greyMidLight,
  greyMedium,
  iconColor
} from "../../../utils/colorsLocal";
import {
  mobileBreakpoint,
  headerHeightMobile,
  headerHeightDesktop,
  zIndexHeader,
  defaultBorderRadius,
  stickyNavShadow
} from "../../../utils/styling";

export const Container = styled.div`
  position: absolute;
  background-color: white;
  transition: transform 300ms;
  z-index: ${zIndexHeader - 1};
  border-bottom-left-radius: ${defaultBorderRadius}px;
  border-bottom-right-radius: ${defaultBorderRadius}px;
  transform: translateY(${({ isOpen }) => (isOpen ? 0 : "-110%")});
  left: ${({ isSwitcherEnabled }) =>
    isSwitcherEnabled
      ? outerButtonWidthDesktop + appSwitcherWidthDesktop
      : outerButtonWidthDesktop}px;
  right: ${outerButtonWidthDesktop}px;
  top: ${headerHeightDesktop}px;
  padding: 20px 30px 30px;
  ${stickyNavShadow};

  @media (max-width: ${mobileBreakpoint}px) {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    top: ${headerHeightMobile}px;
    right: 0px;
    left: 0px;
  }
`;

export const CloseButton = styled.div`
  position: absolute;
  cursor: pointer;
  height: 18px;
  width: 18px;
  right: 20px;
  top: 27px;

  svg {
    fill: ${greyLight};
  }
`;

export const Header = styled.div`
  margin-bottom: 30px;
`;

export const Title = styled.div`
  font-size: 22px;
  margin-bottom: 2px;
`;

export const SubTitle = styled.span`
  color: ${greyMedium};
  font-size: 13px;
`;

export const AdvancedButton = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin-left: 6px;
  height: 16px;
  width: 14px;

  svg {
    fill: ${greyMedium};
  }
`;

export const Column = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 50%;

  @media (max-width: ${mobileBreakpoint}px) {
    display: block;
    vertical-align: baseline;
    width: 100%;
  }
`;

const row = css`
  display: inline-block;
  vertical-align: top;
  padding-right: 20px;
  padding-top: 30px;
  height: 60px;

  @media (max-width: ${mobileBreakpoint}px) {
    padding-top: 20px;
    height: 40px;
  }
`;

export const RowLeft = styled.div`
  ${row};
  width: 150px;
  border-right: 1px solid ${greyLighter};

  @media (max-width: ${mobileBreakpoint}px) {
    width: 140px;
  }
`;

export const RowRight = styled.div`
  ${row};
  padding-left: 20px;
  width: calc(100% - 150px);

  @media (max-width: ${mobileBreakpoint}px) {
    width: calc(100% - 140px);
  }
`;

export const Icon = styled.div`
  display: inline-block;
  vertical-align: top;
  transform: translateY(-50%);
  margin-right: 10px;
  height: 17px;
  width: 18px;

  svg {
    fill: ${({ name }) => iconColor(name)};
  }

  @media (max-width: ${mobileBreakpoint}px) {
    height: 16px;
    width: 16px;
  }
`;

export const Text = styled.div`
  display: inline-block;
  vertical-align: top;
  transform: translateY(-50%);
  color: ${greyMedium};
  font-size: 15px;

  @media (max-width: ${mobileBreakpoint}px) {
    font-size: 14px;
  }
`;

export const HelpLink = styled.div`
  margin-top: 30px;
  text-align: right;

  &:last-child {
    margin-top: 10px;
  }

  a {
    font-size: 14px;
  }

  @media (max-width: ${mobileBreakpoint}px) {
    text-align: left;

    a {
      font-size: 13px;
    }
  }
`;

export const Or = styled.span`
  color: ${greyMidLight};
  font-style: italic;

  &::before {
    content: " or ";
  }
`;
