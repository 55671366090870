import styled from "styled-components";
import { mobileBreakpoint, noWrap } from "../../../../utils/styling";
import {
  greyMedium,
  greyLight,
  statusColor
} from "../../../../utils/colorsLocal";

export const Container = styled.div``;

export const FullAddress = styled.div`
  position: absolute;
  color: ${greyMedium};
  right: 300px;
  bottom: 0px;
  left: 50px;
  top: 0px;
  ${noWrap};

  @media (max-width: ${mobileBreakpoint}px) {
    right: 100px;
  }
`;

export const AddressInfo = styled.div`
  position: absolute;
  text-align: center;
  width: 300px;
  bottom: 0px;
  right: 0px;
  top: 0px;

  @media (max-width: ${mobileBreakpoint}px) {
    width: 100px;
  }
`;

export const AddressDate = styled.div`
  position: absolute;
  color: ${greyLight};
  text-align: center;
  width: 200px;
  bottom: 0px;
  left: 0px;
  top: 0px;

  @media (max-width: ${mobileBreakpoint}px) {
    display: none;
  }
`;

export const AddressStatus = styled.div`
  color: ${({ statusValue }) => statusColor(statusValue)};
  position: absolute;
  text-align: center;
  padding: 0px 5px;
  width: 100px;
  bottom: 0px;
  right: 0px;
  top: 0px;
  ${noWrap};
`;
