const initial = [];

export function recentSearches(state = initial, { type, payload }) {
  switch (type) {
    case "GET_RECENT_SEARCHES_SUCCEEDED":
      return payload;
    default:
      return state;
  }
}
