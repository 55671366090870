import React from "react";
import PropTypes from "prop-types";
import Switch from "../common/Switch";
import ListIcon from "../icons/ListIcon";
import ListingTable from "./ListingTable";
import ListingCards from "./ListingCards";
import ResultsCount from "./ResultsCount";
import ImageIcon from "../icons/ImageIcon";
import SelectTag from "../common/SelectTag";
import Pagination from "../common/Pagination";
import Overflow from "../common/connected/Overflow";
import { sortOptions } from "../../modules/listing";
import {
  Container,
  Controls,
  LeftControls,
  RightControls,
  ResultsWrap,
  Actions,
  Listings
} from "./styled/listings-data";

function ListingsData(props) {
  const isPaginationVisible =
    !props.isLoading && props.listings.length > 0 && !!props.handleClickPage;

  return (
    <Container>
      {props.hasControls && (
        <Controls>
          <LeftControls isScreenSplit={props.isMapOpen}>
            <ResultsWrap>
              <ResultsCount
                listingsLength={props.listings.length}
                selectedCount={props.selectedMlsnums.length}
                totalCount={props.totalCount}
                isLoading={props.isLoading}
                limit={props.limit}
              />
              <Actions>
                <Overflow />
              </Actions>
            </ResultsWrap>
          </LeftControls>
          <RightControls isScreenSplit={props.isMapOpen}>
            <SelectTag
              handleSelect={props.handleSelectSort}
              selected={props.sort}
              options={sortOptions}
              width={184}
            />
            <Switch
              leftIcon={<ImageIcon />}
              rightIcon={<ListIcon />}
              isLeftSelected={props.isCards}
              handleToggle={props.handleToggleView}
            />
          </RightControls>
        </Controls>
      )}
      <Listings>
        {props.isCards ? (
          <ListingCards
            isPublic={props.isPublic}
            isSharedRoute={props.isSharedRoute}
            isNonDisclosure={props.isNonDisclosure}
            isLoading={props.isLoading}
            isMapOpen={props.isMapOpen}
            hasControls={props.hasControls}
            parentPath={props.parentPath}
            limit={props.limit}
            listings={props.listings}
            selectedMlsnums={props.selectedMlsnums}
            handleClickCheckBox={props.handleClickCheckBox}
            handleHoverListing={props.handleHoverListing}
            handleClickListing={props.handleClickListing}
          />
        ) : (
          <ListingTable
            isPublic={props.isPublic}
            isSharedRoute={props.isSharedRoute}
            isNonDisclosure={props.isNonDisclosure}
            isLoading={props.isLoading}
            parentPath={props.parentPath}
            listings={props.listings}
            selectedMlsnums={props.selectedMlsnums}
            handleClickCheckBox={props.handleClickCheckBox}
            handleHoverListing={props.handleHoverListing}
            handleClickListing={props.handleClickListing}
          />
        )}
      </Listings>
      <Pagination
        page={props.page}
        limit={props.limit}
        totalCount={props.totalCount}
        renderedCount={props.listings.length}
        handleSelectLimit={props.handleSelectLimit}
        handleClickPage={props.handleClickPage}
        isVisible={isPaginationVisible}
      />
    </Container>
  );
}

ListingsData.defaultProps = {
  hasControls: true,
  selectedMlsnums: []
};

ListingsData.propTypes = {
  isPublic: PropTypes.bool,
  isLoading: PropTypes.bool,
  isMapOpen: PropTypes.bool,
  isSharedRoute: PropTypes.bool,
  isNonDisclosure: PropTypes.bool,
  isCards: PropTypes.bool,
  hasControls: PropTypes.bool,
  parentPath: PropTypes.string,
  page: PropTypes.number,
  limit: PropTypes.number,
  sort: PropTypes.string,
  listings: PropTypes.array,
  totalCount: PropTypes.number,
  selectedMlsnums: PropTypes.array,
  handleClickListing: PropTypes.func,
  handleHoverListing: PropTypes.func,
  handleClickCheckBox: PropTypes.func,
  handleSelectSort: PropTypes.func,
  handleToggleView: PropTypes.func,
  handleSelectLimit: PropTypes.func,
  handleClickPage: PropTypes.func
};

export default ListingsData;
