import React, { Component } from "react";
import PropTypes from "prop-types";
import { last } from "lodash";
import AddressOption from "./AddressOption";
import QueryIcon from "../../icons/QueryIcon";
import LoadingIcon from "../../icons/LoadingIcon";
import DynamicIcon from "../../common/DynamicIcon";
import {
  Container,
  Status,
  Content,
  Pill,
  Input,
  MobileEdit,
  Options,
  Option,
  Icon,
  Label,
  optionHeight
} from "./styled/omni-select";

class OmniSelect extends Component {
  state = { highlighted: 0, hoveredPill: null };

  componentDidUpdate(prevProps) {
    this.handleOpen(prevProps);
    this.handleClose(prevProps);
    this.handleFilterChange(prevProps);
  }

  componentWillUnmount() {
    this.removeEventListeners();
  }

  render() {
    const { isFocused, isLoading, isMapOpen, hasFilters } = this.props;
    const { handleDeselect } = this.props;

    return (
      <Container onClick={this.props.handleFocus}>
        <Status
          isFocused={isFocused}
          isLoading={isLoading}
          isMapOpen={isMapOpen}
          hasFilters={hasFilters}>
          {isLoading ? <LoadingIcon /> : <QueryIcon />}
        </Status>
        <Content
          isFocused={isFocused}
          isMapOpen={isMapOpen}
          hasFilters={hasFilters}>
          {this.props.filters.map((filter, index) => {
            const onClick = () => handleDeselect(filter);
            const isHovered = this.state.hoveredPill === index;
            const field = filter.isArbitrary ? "arbitrary" : filter.field;
            const onMouseLeave = () => this.setState({ hoveredPill: null });
            const onMouseMove = () => {
              if (isHovered) {
                return;
              }
              this.setState({ hoveredPill: index });
            };

            return (
              <Pill
                field={field}
                isHovered={isHovered}
                onMouseMove={onMouseMove}
                onMouseLeave={onMouseLeave}
                onClick={onClick}
                key={index}>
                {filter.label}
              </Pill>
            );
          })}
          <Input
            ref={(node) => (this.input = node)}
            value={this.props.inputValue}
            placeholder={this.props.placeholder}
            onChange={this.props.handleChange}
            onBlur={this.props.handleBlur}
            onKeyDown={this.handleInputKeyDown}
            isFocused={isFocused}
            isMapOpen={isMapOpen}
          />
        </Content>
        <MobileEdit
          filterCount={this.props.filters.length}
          hasFilters={hasFilters}
          isFocused={isFocused}
        />
        {this.props.isOpen && (
          <Options ref={(node) => (this.options = node)}>
            {this.props.matches.map((option, index) => {
              const isHighlighted = this.state.highlighted === index;
              const onMouseMove = () => {
                if (isHighlighted) {
                  return;
                }
                this.setState({ highlighted: index });
              };

              return (
                <Option
                  onClick={this.handleSelect}
                  onMouseMove={onMouseMove}
                  isHighlighted={isHighlighted}
                  key={index}>
                  <Icon field={option.field}>
                    <DynamicIcon name={option.field} />
                  </Icon>
                  {option.isAddressOption ? (
                    <AddressOption {...option} />
                  ) : (
                    <Label>{option.label}</Label>
                  )}
                </Option>
              );
            })}
          </Options>
        )}
      </Container>
    );
  }

  handleOpen = ({ isOpen }) => {
    if (!isOpen && this.props.isOpen) {
      window.setTimeout(() => (this.options.scrollTop = 0), 0);
      this.addEventListeners();
    }
  };

  handleClose = ({ isOpen }) => {
    if (isOpen && !this.props.isOpen) {
      this.setState({ highlighted: 0 });
      this.removeEventListeners();
    }
  };

  handleFilterChange = ({ filters }) => {
    if (filters.length !== this.props.filters.length) {
      this.setState({ hoveredPill: null });
    }
  };

  handleInputKeyDown = (event) => {
    if (
      event.target.value ||
      event.key !== "Backspace" ||
      !this.props.hasFilters
    ) {
      return;
    }
    this.props.handleDeselect(last(this.props.filters));
  };

  handleWindowKeyDown = (event) => {
    if (["Escape", "ArrowUp", "ArrowDown", "Enter"].includes(event.key)) {
      event.preventDefault();
    }

    switch (event.key) {
      case "Enter":
        return this.handleSelect();
      case "ArrowUp":
        return this.handleUpArrow();
      case "ArrowDown":
        return this.handleDownArrow();
      case "Escape":
        return this.props.handleEscape();
      default:
        return;
    }
  };

  handleUpArrow = () => {
    if (this.state.highlighted === 0) {
      return;
    }
    this.options.scrollTop = (this.state.highlighted - 3) * optionHeight;
    this.setState({ highlighted: this.state.highlighted - 1 });
  };

  handleDownArrow = () => {
    if (this.state.highlighted === this.props.matches.length - 1) {
      return;
    }
    this.options.scrollTop = (this.state.highlighted - 1) * optionHeight;
    this.setState({ highlighted: this.state.highlighted + 1 });
  };

  handleSelect = () => {
    const option = this.props.matches[this.state.highlighted];
    this.props.handleSelect(option);
  };

  addEventListeners = () => {
    window.addEventListener("keydown", this.handleWindowKeyDown);
  };

  removeEventListeners = () => {
    window.removeEventListener("keydown", this.handleWindowKeyDown);
  };
}

OmniSelect.propTypes = {
  placeholder: PropTypes.string,
  handleSelect: PropTypes.func,
  handleDeselect: PropTypes.func,
  handleChange: PropTypes.func,
  handleEscape: PropTypes.func,
  handleFocus: PropTypes.func,
  handleBlur: PropTypes.func,
  inputValue: PropTypes.string,
  matches: PropTypes.array,
  isOpen: PropTypes.bool,
  isFocused: PropTypes.bool,
  isLoading: PropTypes.bool,
  isMapOpen: PropTypes.bool,
  hasFilters: PropTypes.bool,
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
      label: PropTypes.string
    })
  )
};

export default OmniSelect;
