import React from "react";
import PropTypes from "prop-types";
import Link from "./Link";
import { Card } from "@wrstudios/components";
import { PhotoCard } from "@wrstudios/components";
import { Container, StyledCard, Overlay } from "./styled/photo-card";

function CommonPhotoCard(props) {
  return (
    <Container>
      <StyledCard>
        <Card.Header onClick={props.handleClickLink}>
          <Link to={props.url}>
            <PhotoCard.Photos photos={props.photos} />
          </Link>
        </Card.Header>
        <Card.Body onClick={props.handleClickLink}>
          <Link to={props.url}>
            <PhotoCard.Data
              url={props.url}
              name={props.name}
              lineOne={props.lineOne}
              lineTwo={props.lineTwo}
              hasLineTwo={props.hasLineTwo}
              lineTwoFallback={props.lineTwoFallback}
              hasLineTwoFallback={props.hasLineTwoFallback}
              shouldHaveTwoLines={props.shouldHaveTwoLines}
            />
          </Link>
        </Card.Body>
        <Card.Footer>
          {props.footer && props.footer}
          {props.showMenu && (
            <PhotoCard.Menu
              deleteLabel={props.deleteLabel}
              handleClickDelete={props.handleClickDelete}
            />
          )}
        </Card.Footer>
      </StyledCard>
      {props.isDeleting && <Overlay />}
    </Container>
  );
}

CommonPhotoCard.defaultProps = {
  photos: ["", "", ""],
  handleClickLink: () => {}
};

CommonPhotoCard.propTypes = {
  url: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  photos: PropTypes.array,
  lineOne: PropTypes.string.isRequired,
  lineTwo: PropTypes.array,
  hasLineTwo: PropTypes.bool,
  lineTwoFallback: PropTypes.string,
  hasLineTwoFallback: PropTypes.bool,
  shouldHaveTwoLines: PropTypes.bool,
  footer: PropTypes.element,
  isDeleting: PropTypes.bool,
  showMenu: PropTypes.bool.isRequired,
  deleteLabel: PropTypes.string.isRequired,
  handleClickDelete: PropTypes.func.isRequired,
  handleClickLink: PropTypes.func
};

export default CommonPhotoCard;
