import { isEqual } from "lodash";
import PropTypes from "prop-types";
import qs from "qs";
import React, { Component } from "react";
import { Helmet } from "react-helmet-async";
import { Switch } from "react-router-dom";
import { setShowIntercom } from "../../utils/intercom";
import Notice from "../common/Notice";
import Notification from "../common/Notification";
import PublicNotice from "../common/PublicNotice";
import Redirect from "../common/Redirect";
import Route from "../common/Route";
import CheatSheet from "../common/connected/CheatSheet";
import AgentRoute from "../routes/agent/connected/AgentRoute";
import AreasRoute from "../routes/areas/connected/AreasRoute";
import CollectionRoute from "../routes/collection/connected/CollectionRoute";
import CollectionsRoute from "../routes/collections/connected/CollectionsRoute";
import ConnectorRoute from "../routes/connector/connected/ConnectorRoute";
import DashboardRoute from "../routes/dashboard/DashboardRoute";
import ListingRoute from "../routes/listing/connected/ListingRoute";
import PrintRoute from "../routes/print/connected/PrintRoute";
import SearchRoute from "../routes/search/connected/SearchRoute";
import SearchesRoute from "../routes/searches/connected/SearchesRoute";
import SharedRoute from "../routes/shared/connected/SharedRoute";
import NotFound from "../routes/static/connected/NotFound";
import AdvancedSearch from "../search/advanced/connected/AdvancedSearch";
import MapContainer from "../search/map/connected/MapContainer";
import Global from "./Global";
import Salesforce from "./Salesforce";
import Header from "./connected/Header";
import PublicHeader from "./connected/PublicHeader";
import TempHeader from "./connected/TempHeader";
import { Body, Container } from "./styled/app";

class App extends Component {
  componentDidMount() {
    this.handleAuthenticatedRoute();
    window.addEventListener("load", this.setupIntercom);
  }

  getSnapshotBeforeUpdate(prevProps) {
    this.storeResultsScrollTop(prevProps);
    return null;
  }

  componentDidUpdate(prevProps) {
    this.handleCurrentUser(prevProps);
    this.handleShortUrlCopied(prevProps);
    this.handleIsPublicChanged(prevProps);
    this.handleShowIntercom(prevProps);
    this.setLastPathname(prevProps);
    this.setScrollTop(prevProps);
  }

  render() {
    if (
      !this.props.isSharedRoute &&
      !this.props.isNotFoundRoute &&
      !this.props.jwtFromParams &&
      !this.props.jwt
    ) {
      return null;
    }
    if (this.props.isInactiveUser) {
      window.location = `${process.env.REACT_APP_CAS_URL}/app/settings/mls?return_to_app=cloud_mlx&jwt=${this.props.jwt}`;
    }
    if (this.props.isEmptyPath) {
      return <Redirect to="/dashboard" />;
    }
    if (this.props.isPrintRoute) {
      return <PrintRoute />;
    }

    return (
      <Container>
        <Helmet>
          <title>Cloud MLX</title>
        </Helmet>
        {!this.props.isPublic && <Salesforce />}
        <Route component={AdvancedSearch} />
        {this.props.showHeader && (
          <>
            {this.props.isTempHeader ? (
              <TempHeader />
            ) : (
              <Route component={this.props.isPublic ? PublicHeader : Header} />
            )}
          </>
        )}
        <Body
          ref={(node) => (this.body = node)}
          isMapOpen={this.props.isMapOpen}
          isMappable={this.props.isMappable}
          isAdvancedOpen={this.props.isAdvancedOpen}
          showHeader={this.props.showHeader}>
          <Switch>
            <Route path="/shared/:slug" component={SharedRoute} />
            <Route path="/dashboard" component={DashboardRoute} />
            <Route path="/product_connector" component={ConnectorRoute} />
            <Route path="/areas" component={AreasRoute} />
            <Route path="/agents/:id" component={AgentRoute} />
            <Route path="/listings/:id" component={ListingRoute} />
            <Route path="/searches" component={SearchesRoute} />
            <Route path="/searches/:id" component={SearchRoute} />
            <Route path="/searches/:id/listings/:id" component={ListingRoute} />
            <Route path="/collections" component={CollectionsRoute} />
            <Route path="/collections/:id" component={CollectionRoute} />
            <Route
              path="/collections/:id/listings/:id"
              component={ListingRoute}
            />
            <Route component={NotFound} />
          </Switch>
          <Notification
            type="success"
            ref={(node) => (this.copySuccess = node)}>
            <Notice title="Success" body="The link has been copied" />
          </Notification>
          <Notification
            type="info"
            isDismissable={false}
            shouldAutoDismiss={false}
            ref={(node) => (this.publicView = node)}>
            <PublicNotice handleReturnToAgent={this.handleReturnToAgent} />
          </Notification>
        </Body>
        <CheatSheet />
        <Route component={MapContainer} />
        <Global />
      </Container>
    );
  }

  handleAuthenticatedRoute = () => {
    if (!this.props.isSharedRoute && !this.props.isNotFoundRoute) {
      if (!this.props.jwtFromParams && !this.props.jwt) {
        this.redirectForCasAuth();
      }
      this.props.getUser(this.props.jwtFromParams);
    }
  };

  handleCurrentUser = ({ jwt }) => {
    if (this.props.isSharedRoute || this.props.isNotFoundRoute) {
      return;
    }
    if (this.props.jwt && this.props.isInactiveUser) {
      window.location = `${process.env.REACT_APP_CAS_URL}/app/settings/mls?return_to_app=cloud_mlx&jwt=${this.props.jwt}`;
    }
    if (jwt && !this.props.jwt) {
      this.redirectForCasLogout();
    }
    if (!jwt && this.props.jwt) {
      this.props.getAppReady();
    }
  };

  handleShortUrlCopied = ({ isShortUrlCopied }) => {
    if (!isShortUrlCopied && this.props.isShortUrlCopied) {
      this.copySuccess.display();
    }
  };

  handleIsPublicChanged = ({ isPublic, isPrintRoute }) => {
    if (isPublic && !this.props.isPublic && this.props.jwt) {
      this.publicView.dismiss();
    }
    if (!isPublic && this.props.isPublic && this.props.jwt) {
      this.publicView.display();
    }
    if (
      isPrintRoute &&
      !this.props.isPrintRoute &&
      this.props.isPublic &&
      this.props.jwt
    ) {
      this.publicView.display();
    }
  };

  handleShowIntercom = ({ showIntercom }) => {
    if (!showIntercom && this.props.showIntercom) {
      setShowIntercom(true);
    }
    if (!this.props.showIntercom) {
      setShowIntercom(false);
    }
  };

  handleReturnToAgent = () => {
    const { emitAction } = this.props;
    emitAction({ type: "SET_IS_APPLICATION_PUBLIC", payload: false });
  };

  storeResultsScrollTop = ({ isSearchRoute, isListingRoute, detailId }) => {
    const wasSearch = isSearchRoute && this.props.isListingRoute;
    const wasShared = !detailId && this.props.detailId;

    if (wasSearch || wasShared) {
      this.resultsScrollTop = this.body.scrollTop;
    }
  };

  setScrollTop = (prevProps) => {
    if (!prevProps.isPrintRoute && this.props.isPrintRoute) {
      return;
    }

    const wasPseudo = prevProps.detailId && !this.props.detailId;
    const wasDetail = prevProps.isListingRoute && this.props.isSearchRoute;
    const nextScrollTop = wasDetail || wasPseudo ? this.resultsScrollTop : 0;

    if (
      prevProps.page !== this.props.page ||
      prevProps.limit !== this.props.limit ||
      prevProps.sort !== this.props.sort ||
      prevProps.detailId !== this.props.detailId ||
      prevProps.pathname !== this.props.pathname ||
      !isEqual(prevProps.queryObject, this.props.queryObject)
    ) {
      if (this.body) {
        this.body.scrollTop = nextScrollTop;
      }
    }
  };

  setLastPathname = ({ pathname }) => {
    if (pathname !== this.props.pathname) {
      this.props.emitAction({ type: "SET_LAST_PATHNAME", payload: pathname });
    }
  };

  redirectForCasAuth = () => {
    const queryString = qs.stringify({
      continue_to: `${process.env.REACT_APP_CLOUD_MLX_URL}${this.props.pathname}`
    });
    window.location.replace(
      `${process.env.REACT_APP_CAS_URL}/app/external_login/cloud_mlx?${queryString}`
    );
  };

  redirectForCasLogout = () => {
    window.location.replace(`${process.env.REACT_APP_CAS_URL}/app/logout`);
  };

  setupIntercom = () => {
    window.setTimeout(() => {
      if (this.props.showIntercom) {
        window.document.body.className = "allow-intercom";
        setShowIntercom(true);
      }
    }, 3000);
  };
}

App.propTypes = {
  isMissingUser: PropTypes.bool,
  isInactiveUser: PropTypes.bool,
  isPublic: PropTypes.bool,
  isTempHeader: PropTypes.bool,
  isMapOpen: PropTypes.bool,
  isMappable: PropTypes.bool,
  isEmptyPath: PropTypes.bool,
  isPrintRoute: PropTypes.bool,
  isNotFoundRoute: PropTypes.bool,
  isListingRoute: PropTypes.bool,
  isSearchRoute: PropTypes.bool,
  isSharedRoute: PropTypes.bool,
  isAdvancedOpen: PropTypes.bool,
  isShortUrlCopied: PropTypes.bool,
  showIntercom: PropTypes.bool,
  showHeader: PropTypes.bool,
  queryObject: PropTypes.object,
  detailId: PropTypes.string,
  pathname: PropTypes.string,
  page: PropTypes.number,
  limit: PropTypes.number,
  sort: PropTypes.string,
  jwt: PropTypes.string,
  jwtFromParams: PropTypes.string,
  getUser: PropTypes.func,
  getAppReady: PropTypes.func,
  emitAction: PropTypes.func
};

export default App;
