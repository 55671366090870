import React from "react";

function InfoIcon() {
  return (
    <svg viewBox="0 0 100 100" preserveAspectRatio="xMidYMid meet">
      <path d="M45,75 L55,75 L55,45 L45,45 L45,75 L45,75 Z M50,0 C22.4,0 0,22.4 0,50 C0,77.6 22.4,100 50,100 C77.6,100 100,77.6 100,50 C100,22.4 77.6,0 50,0 L50,0 Z M50,90 C27.95,90 10,72.05 10,50 C10,27.95 27.95,10 50,10 C72.05,10 90,27.95 90,50 C90,72.05 72.05,90 50,90 L50,90 Z M45,35 L55,35 L55,25 L45,25 L45,35 L45,35 Z"></path>
    </svg>
  );
}

export default InfoIcon;
