import { apiClient } from "../modules/api";
import { getSearchesGQL, createSearchGQL } from "@wrstudios/api";
import { updateSearchGQL, deleteSearchGQL } from "@wrstudios/api";
import { transformSavedAreas, transformSavedArea } from "../modules/area";

export function getSavedAreas(page) {
  return async (dispatch, getState) => {
    const { pagination } = getState();
    const { searchesLimit, searchesSort } = pagination;
    dispatch({ type: "GET_SAVED_AREAS_INITIATED", payload: { page } });

    try {
      const response = await getSearchesGQL({
        apiClient,
        roles: ["polygon"],
        listingLevel: "none",
        searchesPage: page,
        searchesLimit,
        searchesSort
      });

      const searches = transformSavedAreas(response.data.data.searches);
      const payload = { ...searches, count: response.data.data.searches_count };
      dispatch({ type: "GET_SAVED_AREAS_SUCCEEDED", payload });
    } catch (error) {
      dispatch({ type: "GET_SAVED_AREAS_FAILED", payload: error });
    }
  };
}

export function createSavedArea(filter) {
  return async (dispatch) => {
    dispatch({ type: "CREATE_SAVED_AREA_INITIATED" });

    try {
      const response = await createSearchGQL({
        apiClient,
        role: "polygon",
        name: filter.label,
        listingLevel: "none",
        filters: [
          {
            field: "location",
            within: JSON.stringify([JSON.parse(filter.value)])
          }
        ]
      });

      dispatch({
        type: "CREATE_SAVED_AREA_SUCCEEDED",
        payload: transformSavedArea(response.data.data.createSearch.search)
      });
    } catch (error) {
      dispatch({ type: "CREATE_SAVED_AREA_FAILED", payload: error });
    }
  };
}

export function updateSavedArea({ id, filter }) {
  return async (dispatch) => {
    dispatch({ type: "UPDATE_SAVED_AREA_INITIATED" });

    try {
      const response = await updateSearchGQL({
        apiClient,
        id: id,
        role: "polygon",
        name: filter.label,
        listingLevel: "none",
        filters: [
          {
            field: "location",
            within: JSON.stringify([JSON.parse(filter.value)])
          }
        ]
      });

      dispatch({
        type: "UPDATE_SAVED_AREA_SUCCEEDED",
        payload: transformSavedArea(response.data.data.updateSearch.search)
      });
    } catch (error) {
      dispatch({ type: "UPDATE_SAVED_AREA_FAILED", payload: error });
    }
  };
}

export function deleteSavedArea(id) {
  return async (dispatch) => {
    dispatch({ type: "DELETE_SAVED_AREA_INITIATED" });

    try {
      await deleteSearchGQL({ apiClient, id });
      dispatch({
        type: "DELETE_SAVED_AREA_SUCCEEDED",
        payload: { id }
      });
    } catch (error) {
      dispatch({ type: "DELETE_SAVED_AREA_FAILED", payload: error });
    }
  };
}
