export const initial = {
  isFocused: false,
  synchronousMatches: [],
  asyncAddressMatches: [],
  asyncMlsNumMatches: [],
  asyncAgentMatches: [],
  asyncZipMatches: [],
  currentAddressDataTime: Date.now(),
  currentMlsNumDataTime: Date.now(),
  currentAgentDataTime: Date.now(),
  currentZipDataTime: Date.now(),
  inputValue: ""
};

export function omniSearch(state = initial, { type, payload }) {
  switch (type) {
    case "SET_IS_OMNI_SEARCH_FOCUSED":
      return {
        ...state,
        isFocused: payload,
        inputValue: payload ? state.inputValue : ""
      };
    case "SET_SYNCHRONOUS_MATCHES":
      return { ...state, synchronousMatches: payload };
    case "GET_ASYNC_ADDRESS_MATCHES_SUCCEEDED":
      if (payload.requestedAt < state.currentAddressDataTime) {
        return state;
      }
      return {
        ...state,
        asyncAddressMatches: payload.data,
        currentAddressDataTime: payload.requestedAt
      };
    case "GET_ASYNC_MLSNUM_MATCHES_SUCCEEDED":
      if (payload.requestedAt < state.currentMlsNumDataTime) {
        return state;
      }
      return {
        ...state,
        asyncMlsNumMatches: payload.data,
        currentMlsNumDataTime: payload.requestedAt
      };
    case "GET_ASYNC_AGENT_MATCHES_SUCCEEDED":
      if (payload.requestedAt < state.currentAgentDataTime) {
        return state;
      }
      return {
        ...state,
        asyncAgentMatches: payload.data,
        currentAgentDataTime: payload.requestedAt
      };
    case "GET_ASYNC_ZIP_MATCHES_SUCCEEDED":
      if (payload.requestedAt < state.currentZipDataTime) {
        return state;
      }
      return {
        ...state,
        asyncZipMatches: payload.data,
        currentZipDataTime: payload.requestedAt
      };
    case "SET_OMNI_SEARCH_INPUT_VALUE":
      return { ...state, inputValue: payload };
    case "GET_SEARCH_RESULTS_INITIATED":
    case "RESET_AND_ADD_TO_QUERY_OBJECT":
    case "ADD_TO_QUERY_OBJECT":
    case "RESET_QUERY_OBJECT":
      return { ...initial, isFocused: state.isFocused };
    default:
      return state;
  }
}
