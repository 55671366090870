const initial = {
  isLoading: false,
  attributes: {},
  listings: [],
  shouldTrack: false
};

export function agent(state = initial, { type, payload }) {
  switch (type) {
    case "GET_AGENT_PROFILE_INITIATED":
      return { ...initial, shouldTrack: state.shouldTrack, isLoading: true };
    case "GET_AGENT_PROFILE_FAILED":
      return { ...initial, isLoading: false };
    case "GET_AGENT_PROFILE_SUCCEEDED":
      return {
        ...state,
        attributes: payload.attributes,
        listings: payload.listings,
        isLoading: false
      };
    case "SET_SHOULD_TRACK_AGENT":
      return { ...state, shouldTrack: true };
    case "TRACK_EVENT_FAILED":
    case "TRACK_EVENT_SUCCEEDED":
      return { ...state, shouldTrack: false };
    default:
      return state;
  }
}
