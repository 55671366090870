import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import Link from "../common/Link";
import CopyIcon from "../icons/CopyIcon";
import LogoIcon from "../icons/LogoIcon";
import MoreIcon from "../icons/MoreIcon";
import CloseIcon from "../icons/CloseIcon";
import EmailIcon from "../icons/EmailIcon";
import PrintIcon from "../icons/PrintIcon";
import TrashIcon from "../icons/TrashIcon";
import SearchIcon from "../icons/SearchIcon";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  Container,
  MoreButton,
  MoreMenu,
  MainActions,
  MainRow,
  MainIcon,
  DisabledRow,
  DeleteAction,
  DeleteRow,
  DeleteIcon
} from "./styled/overflow";

class Overflow extends Component {
  state = { isOpen: false };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.removeEventListeners();
  }

  render() {
    const { isOpen } = this.state;
    const {
      isPublic,
      isListingRoute,
      isCollectionRoute,
      openRight,
      resourceType,
      selectedMlsnums,
      handleDelete
    } = this.props;

    return (
      <Container>
        <MoreButton isOpen={isOpen} onClick={this.handleClickOverflow}>
          {isOpen ? <CloseIcon /> : <MoreIcon />}
        </MoreButton>
        {!isPublic && isOpen && (
          <MoreMenu openRight={openRight}>
            {handleDelete ? (
              <DeleteAction>
                <DeleteRow onClick={handleDelete}>
                  <span id="pointer" />
                  <DeleteIcon>
                    <TrashIcon />
                  </DeleteIcon>
                  <span>Delete</span>
                </DeleteRow>
              </DeleteAction>
            ) : (
              <MainActions>
                <MainRow
                  openRight={openRight}
                  onClick={this.handleClickViewAsClient}>
                  <span id="pointer" />
                  <MainIcon>
                    <SearchIcon />
                  </MainIcon>
                  <span>{`View ${resourceType} as Client`}</span>
                </MainRow>
                {this.props.shortUrl ? (
                  <>
                    <CopyToClipboard
                      text={this.props.shortUrl}
                      onCopy={this.handleClickCopyShortUrl}>
                      <MainRow>
                        <MainIcon>
                          <CopyIcon />
                        </MainIcon>
                        <span>{`Copy Link to ${resourceType}`}</span>
                      </MainRow>
                    </CopyToClipboard>
                    <a href={this.props.mailTo}>
                      <MainRow>
                        <MainIcon>
                          <EmailIcon />
                        </MainIcon>
                        <span>{`Email Link to ${resourceType}`}</span>
                      </MainRow>
                    </a>
                  </>
                ) : (
                  <>
                    <DisabledRow>
                      <MainIcon>
                        <CopyIcon />
                      </MainIcon>
                      <span>{`Copy Link to ${resourceType}`}</span>
                    </DisabledRow>
                    <DisabledRow>
                      <MainIcon>
                        <EmailIcon />
                      </MainIcon>
                      <span>{`Email Link to ${resourceType}`}</span>
                    </DisabledRow>
                  </>
                )}
                <Link to="/print">
                  <MainRow onClick={this.handleClickAgentPrint}>
                    <MainIcon>
                      <PrintIcon />
                    </MainIcon>
                    <span>Print Agent View</span>
                  </MainRow>
                </Link>
                <Link to="/print">
                  <MainRow onClick={this.handleClickClientPrint}>
                    <MainIcon>
                      <PrintIcon />
                    </MainIcon>
                    <span>Print Client View</span>
                  </MainRow>
                </Link>
                {isListingRoute ? (
                  <Fragment>
                    <a
                      href={this.props.newListingCmaUrl}
                      rel="noopener noreferrer"
                      target="_blank">
                      <MainRow>
                        <MainIcon isCma={true}>
                          <LogoIcon />
                        </MainIcon>
                        <span>Make CMA Report</span>
                      </MainRow>
                    </a>
                    <a
                      href={this.props.newListingPropertyUrl}
                      rel="noopener noreferrer"
                      target="_blank">
                      <MainRow>
                        <MainIcon isCma={true}>
                          <LogoIcon />
                        </MainIcon>
                        <span>Make Property Report</span>
                      </MainRow>
                    </a>
                    <a
                      href={this.props.newListingFlyerUrl}
                      rel="noopener noreferrer"
                      target="_blank">
                      <MainRow>
                        <MainIcon isCma={true}>
                          <LogoIcon />
                        </MainIcon>
                        <span>Make Flyer</span>
                      </MainRow>
                    </a>
                  </Fragment>
                ) : (
                  <Fragment>
                    {selectedMlsnums.length > 0 && (
                      <a
                        href={this.props.newSelectedCmaUrl}
                        rel="noopener noreferrer"
                        target="_blank">
                        <MainRow>
                          <MainIcon isCma={true}>
                            <LogoIcon />
                          </MainIcon>
                          <span>Make CMA Report</span>
                        </MainRow>
                      </a>
                    )}
                    {selectedMlsnums.length === 1 && (
                      <Fragment>
                        <a
                          href={this.props.newSelectedPropertyUrl}
                          rel="noopener noreferrer"
                          target="_blank">
                          <MainRow>
                            <MainIcon isCma={true}>
                              <LogoIcon />
                            </MainIcon>
                            <span>Make Property Report</span>
                          </MainRow>
                        </a>
                        <a
                          href={this.props.newSelectedFlyerUrl}
                          rel="noopener noreferrer"
                          target="_blank">
                          <MainRow>
                            <MainIcon isCma={true}>
                              <LogoIcon />
                            </MainIcon>
                            <span>Make Flyer</span>
                          </MainRow>
                        </a>
                      </Fragment>
                    )}
                    {selectedMlsnums.length > 1 && (
                      <a
                        href={this.props.newSelectedTourUrl}
                        rel="noopener noreferrer"
                        target="_blank">
                        <MainRow>
                          <MainIcon isCma={true}>
                            <LogoIcon />
                          </MainIcon>
                          <span>Make Buyer Tour</span>
                        </MainRow>
                      </a>
                    )}
                    {!isCollectionRoute && (
                      <a
                        href={this.props.newStreamUrl}
                        rel="noopener noreferrer"
                        target="_blank">
                        <MainRow>
                          <MainIcon isStreams={true}>
                            <LogoIcon />
                          </MainIcon>
                          <span>Make Cloud Stream</span>
                        </MainRow>
                      </a>
                    )}
                  </Fragment>
                )}
              </MainActions>
            )}
          </MoreMenu>
        )}
        {isPublic && this.state.isOpen && (
          <MoreMenu openRight={openRight}>
            <MainActions>
              <Link to="/print">
                <MainRow onClick={this.handleClickClientPrint}>
                  <MainIcon>
                    <PrintIcon />
                  </MainIcon>
                  <span>{`Print ${resourceType}`}</span>
                </MainRow>
              </Link>
            </MainActions>
          </MoreMenu>
        )}
      </Container>
    );
  }

  handleClickViewAsClient = () => {
    window.setTimeout(() => {
      this.props.emitAction({
        type: "SET_IS_APPLICATION_PUBLIC",
        payload: true
      });
    }, 0);
  };

  handleClickCopyShortUrl = () => {
    this.props.emitAction({ type: "SET_IS_SHORT_URL_COPIED", payload: true });
    window.setTimeout(() => {
      this.props.emitAction({
        type: "SET_IS_SHORT_URL_COPIED",
        payload: false
      });
    }, 500);
  };

  handleClickAgentPrint = () => {
    const { isListingRoute, isSharedRoute } = this.props;
    const payload = { isListingRoute, isSharedRoute, isPublic: false };
    this.props.emitAction({ type: "SET_PRINT_VIEW", payload });
  };

  handleClickClientPrint = () => {
    const { isListingRoute, isSharedRoute } = this.props;
    const payload = { isListingRoute, isSharedRoute, isPublic: true };
    this.props.emitAction({ type: "SET_PRINT_VIEW", payload });
  };

  handleClickOverflow = () => {
    if (!this.state.isOpen) {
      window.addEventListener("mouseup", this.handleWindowClick);
      this.setState({ isOpen: true });

      if (!this.props.shortUrl && this.props.isListingRoute) {
        this.props.getListingSlug(this.props.resourceId);
      }
    }
  };

  handleWindowClick = ({ target }) => {
    if (this.state.isOpen) {
      window.setTimeout(() => {
        if (this._isMounted) {
          this.setState({ isOpen: false });
        }
      }, 0);
      window.removeEventListener("mouseup", this.handleWindowClick);
    }
  };

  removeEventListeners = () => {
    window.removeEventListener("mouseup", this.handleWindowClick);
  };
}

Overflow.propTypes = {
  isPublic: PropTypes.bool,
  isSharedRoute: PropTypes.bool,
  isListingRoute: PropTypes.bool,
  isCollectionRoute: PropTypes.bool,
  openRight: PropTypes.bool,
  sharedSearch: PropTypes.object,
  resourceType: PropTypes.string,
  selectedMlsnums: PropTypes.array,
  resourceId: PropTypes.string,
  shortUrl: PropTypes.string,
  mailTo: PropTypes.string,
  newStreamUrl: PropTypes.string,
  newListingCmaUrl: PropTypes.string,
  newSelectedCmaUrl: PropTypes.string,
  newSelectedTourUrl: PropTypes.string,
  newListingFlyerUrl: PropTypes.string,
  newSelectedFlyerUrl: PropTypes.string,
  newListingPropertyUrl: PropTypes.string,
  newSelectedPropertyUrl: PropTypes.string,
  getListingSlug: PropTypes.func,
  handleDelete: PropTypes.func,
  emitAction: PropTypes.func
};

export default Overflow;
