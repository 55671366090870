import React from "react";
import LegacyNavigation from "./connected/LegacyNavigation";
import NewNavigation from "./connected/NewNavigation";
import { Container } from "./styled/temp-header";

export default function TempHeader({ lwaJwt }) {
  return (
    <Container>{lwaJwt ? <NewNavigation /> : <LegacyNavigation />}</Container>
  );
}
