import styled, { css } from "styled-components";
import { noWrap, defaultBorderRadius } from "../../../utils/styling";
import {
  greyMedium,
  greyLighter,
  greyLightest,
  bluePowder
} from "../../../utils/colorsLocal";

const stickyWidth = 60;
const cellHeight = 50;

export const Container = styled.div`
  position: relative;
  border: 2px solid ${greyLighter};
  border-radius: ${defaultBorderRadius}px;
  overflow: hidden;
`;

export const Sticky = styled.div`
  position: absolute;
  width: ${stickyWidth}px;
  overflow: hidden;
  bottom: 0px;
  left: 0px;
  top: 0px;
`;

export const Scroller = styled.div`
  margin-left: ${({ isPublic }) => (isPublic ? 0 : stickyWidth)}px;
  overflow-x: scroll;
`;

export const Table = styled.div`
  display: table;
  width: 100%;
`;

export const Header = styled.div`
  display: table-header-group;

  > div {
    background-color: ${greyLightest};
  }
`;

export const Row = styled.div`
  display: table-row;
  cursor: pointer;

  div {
    color: ${({ isLoading }) => (isLoading ? "white" : greyMedium)};
  }

  &:last-child > div {
    border-bottom: none;
  }

  &:hover > div {
    background-color: ${bluePowder};
  }
`;

const commonCell = css`
  display: table-cell;
  border-bottom: 1px solid ${greyLighter};
  line-height: ${cellHeight}px;
  height: ${cellHeight}px;
  background-color: white;
  color: ${greyMedium};
  font-size: 14px;
  ${noWrap};
`;

export const Cell = styled.div`
  ${commonCell};
  padding: 0 15px;
`;

export const StickyCell = styled.div`
  ${commonCell};
  border-right: 1px solid ${greyLighter};
  text-align: center;
`;

export const StickyCellInner = styled.div`
  height: ${cellHeight}px;
  overflow: hidden;
`;

export const StickyRow = styled.div`
  display: table-row;

  &:last-child > div {
    border-bottom: none;
  }
`;

export const CheckboxLayout = styled.div`
  position: relative;
  transform: translateY(-10px);
  margin: 0 auto;
  height: 20px;
  width: 20px;
  top: 50%;
`;
