import React, { Component } from "react";
import PropTypes from "prop-types";
import Avatar from "../../common/Avatar";
import ListingsData from "../../listing/ListingsData";
import { InfoLayout, Title, Name, Bre, Office } from "./styled/agent-route";
import { Container, Header, AvatarLayout, Contact } from "./styled/agent-route";

class AgentRoute extends Component {
  componentDidMount() {
    this.handleNewLocation();
  }

  componentDidUpdate(prevProps) {
    this.handleReloadLocation(prevProps);
    this.handleTrackEvent(prevProps);
  }

  render() {
    return (
      <Container>
        <Header>
          {this.props.initials && (
            <AvatarLayout>
              <Avatar
                initials={this.props.initials}
                avatarUrl={this.props.avatarUrl}
                size="xl"
              />
            </AvatarLayout>
          )}
          <InfoLayout>
            <Title>
              <Name>{this.props.name}</Name>
              <Bre>{this.props.bre}</Bre>
            </Title>
            {this.props.office && <Office>{this.props.office}</Office>}
            <Contact>
              <a href={`mailto:${this.props.email}`}>{this.props.email}</a>
              {this.props.phone && <span>&nbsp; • &nbsp;</span>}
              <a href={`tel:${this.props.phone}`}>{this.props.phone}</a>
            </Contact>
          </InfoLayout>
        </Header>
        <ListingsData
          isLoading={this.props.isLoading}
          listings={this.props.listings}
          hasControls={false}
          isMapOpen={false}
          isCards={true}
          parentPath=""
        />
      </Container>
    );
  }

  handleNewLocation = () => {
    if (this.props.isAppReady && this.props.pathId !== this.props.id) {
      this.props.getAgentProfile(this.props.pathId);
      this.props.emitAction({ type: "SET_SHOULD_TRACK_AGENT" });
    }
  };

  handleReloadLocation = ({ isAppReady }) => {
    if (
      !isAppReady &&
      this.props.isAppReady &&
      this.props.id !== this.props.pathId
    ) {
      this.props.getAgentProfile(this.props.pathId);
    }
  };

  handleTrackEvent = ({ isLoading }) => {
    if (isLoading && !this.props.isLoading && this.props.shouldTrack) {
      const { eventName, metaData } = this.props;
      this.props.trackEvent({ eventName, metaData });
    }
  };
}

AgentRoute.propTypes = {
  isLoading: PropTypes.bool,
  isAppReady: PropTypes.bool,
  pathId: PropTypes.string,
  pathname: PropTypes.string,
  eventName: PropTypes.string,
  metaData: PropTypes.object,
  shouldTrack: PropTypes.bool,
  bre: PropTypes.string,
  name: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  office: PropTypes.string,
  initials: PropTypes.string,
  avatarUrl: PropTypes.string,
  listings: PropTypes.array,
  getAgentProfile: PropTypes.func,
  trackEvent: PropTypes.func,
  emitAction: PropTypes.func
};

export default AgentRoute;
