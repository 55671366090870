import styled from "styled-components";
import { mobileBreakpoint } from "../../../utils/styling";

export const Container = styled.div`
  position: relative;
`;

export const Notice = styled.div`
  padding-right: ${({ buttonsWidthDesktop }) => buttonsWidthDesktop}px;

  @media (max-width: ${mobileBreakpoint}px) {
    padding-right: ${({ buttonsWidthMobile }) => buttonsWidthMobile}px;
  }
`;

export const Buttons = styled.div`
  position: absolute;
  text-align: right;
  width: ${({ buttonsWidthDesktop }) => buttonsWidthDesktop}px;
  margin-top: -20px;
  height: 40px;
  right: 0px;
  top: 50%;

  @media (max-width: ${mobileBreakpoint}px) {
    width: ${({ buttonsWidthMobile }) => buttonsWidthMobile}px;
    margin-top: -15px;
    height: 30px;
  }
`;

export const ButtonLayout = styled.div`
  display: inline-block;
  vertical-align: top;
  margin-right: 20px;

  &:last-child {
    margin-right: 0px;
  }

  @media (max-width: ${mobileBreakpoint}px) {
    margin-right: 10px;
  }
`;
