import React from "react";

function LocationIcon() {
  return (
    <svg viewBox="0 0 100 100" preserveAspectRatio="xMidYMid meet">
      <path d="M15,35 C15,61.25 50,100 50,100 C50,100 85,61.25 85,35 C85,15.65 69.35,0 50,0 C30.65,0 15,15.65 15,35 Z M37.5,35 C37.5,28.1 43.1,22.5 50,22.5 C56.9,22.5 62.5,28.1 62.5,35 C62.5,41.9 56.9,47.5 50,47.5 C43.1,47.5 37.5,41.9 37.5,35 Z"></path>
    </svg>
  );
}

export default LocationIcon;
