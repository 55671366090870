import React from "react";

function EmailIcon() {
  return (
    <svg viewBox="0 0 100 100" preserveAspectRatio="xMidYMid meet">
      <path d="M90,10 L10,10 C4.5,10 0.05,14.5 0.05,20 L0,80 C0,85.5 4.5,90 10,90 L90,90 C95.5,90 100,85.5 100,80 L100,20 C100,14.5 95.5,10 90,10 L90,10 Z M90,80 L10,80 L10,30 L50,55 L90,30 L90,80 L90,80 Z M50,45 L10,20 L90,20 L50,45 L50,45 Z"></path>
    </svg>
  );
}

export default EmailIcon;
