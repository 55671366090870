import React from "react";

function MlsNumIcon() {
  return (
    <svg viewBox="0 0 100 100" preserveAspectRatio="xMidYMid meet">
      <path d="M85,0 L30,0 C24.5,0 20,4.5 20,10 L20,70 C20,75.5 24.5,80 30,80 L90,80 C95.5,80 100,75.5 100,70 L100,10 C100,4.5 95.5,0 90,0 L85,0 Z M0,20 L10,20 L10,90 L80,90 L80,100 L10,100 C4.5,100 0,95.5 0,90 L0,20 Z M55.2166315,43.8157965 L56.944202,35.7949324 L64.7182705,35.7949324 L62.9906995,43.8157965 L55.2166315,43.8157965 Z M42.383249,29.3165421 L51.2678985,29.3165421 L53.303964,20 L60.3376445,20 L58.301579,29.3165421 L66.0756475,29.3165421 L68.111713,20 L75.2070925,20 L73.171027,29.3165421 L80.7599985,29.3165421 L80.7599985,35.7949324 L71.81365,35.7949324 L70.0860795,43.8157965 L77.613352,43.8157965 L77.613352,50.355886 L68.6670035,50.355886 L66.630938,59.9809225 L59.535558,59.9809225 L61.5716235,50.355886 L53.7975555,50.355886 L51.76149,59.9809225 L44.727809,59.9809225 L46.7638745,50.355886 L39.36,50.355886 L39.36,43.8157965 L48.1829505,43.8157965 L49.9105215,35.7949324 L42.383249,35.7949324 L42.383249,29.3165421 Z"></path>
    </svg>
  );
}

export default MlsNumIcon;
