import React, { Component } from "react";
import PropTypes from "prop-types";
import Downshift from "downshift";
import matchSorter from "match-sorter";
import Checkbox from "../../common/Checkbox";
import {
  Container,
  Input,
  Collections,
  Collection,
  CheckboxLayout,
  CollectionName,
  AddCollection
} from "./styled/listing-collector";

class ListingCollector extends Component {
  render() {
    const { inputValue, collections, listingId } = this.props;

    return (
      <Container>
        <Downshift
          isOpen={true}
          inputValue={inputValue}
          onChange={this.handleSelect}
          selectedItem={null}
          itemToString={(item) => (item || {}).name}>
          {({ inputValue, getInputProps, getItemProps, highlightedIndex }) => {
            const filteredCollections = matchSorter(
              collections,
              inputValue.toLowerCase(),
              { keys: ["lowerCase"] }
            );
            const isCreateHighlighted =
              highlightedIndex === filteredCollections.length;

            return (
              <div>
                <Input
                  {...getInputProps({
                    onChange: this.handleChange,
                    onKeyDown: this.handleKeyDown,
                    placeholder: "Type a collection name..."
                  })}
                />
                <Collections>
                  {filteredCollections.map((collection, index) => (
                    <Collection
                      {...getItemProps({
                        item: collection,
                        isHighlighted: highlightedIndex === index,
                        key: index
                      })}>
                      <CheckboxLayout>
                        <Checkbox
                          isChecked={collection.listingIds.includes(listingId)}
                        />
                      </CheckboxLayout>
                      <CollectionName>{collection.name}</CollectionName>
                    </Collection>
                  ))}
                  {inputValue && (
                    <Collection
                      {...getItemProps({
                        item: { name: inputValue },
                        isHighlighted: isCreateHighlighted,
                        key: filteredCollections.length
                      })}>
                      <AddCollection />
                      <CollectionName>{inputValue}</CollectionName>
                    </Collection>
                  )}
                </Collections>
              </div>
            );
          }}
        </Downshift>
      </Container>
    );
  }

  handleChange = ({ target }) => {
    this.props.emitAction({
      type: "SET_LISTING_COLLECTOR_INPUT",
      payload: target.value
    });
  };

  handleKeyDown = (event) => {
    if (event.key === "Enter") {
      const { inputValue, listingId, createCollection } = this.props;
      createCollection({ collection: { name: inputValue }, listingId });
    }
  };

  handleSelect = (collection) => {
    if (collection.id) {
      this.props.updateCollection({
        collection,
        listingId: this.props.listingId
      });
    }
    if (!collection.id) {
      this.props.createCollection({
        collection,
        listingId: this.props.listingId
      });
    }
  };
}

ListingCollector.propTypes = {
  listingId: PropTypes.string,
  inputValue: PropTypes.string,
  collections: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      listingIds: PropTypes.array,
      lowerCase: PropTypes.string
    })
  ),
  createCollection: PropTypes.func,
  updateCollection: PropTypes.func,
  emitAction: PropTypes.func
};

export default ListingCollector;
