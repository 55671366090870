import { connect } from "react-redux";
import { emitAction } from "../../../../utils/redux";
import { createCollection } from "../../../../actions/collection";
import { updateCollection } from "../../../../actions/collection";
import ListingCollector from "../ListingCollector";

function select({ listing, collector }) {
  return {
    listingId: listing.id,
    inputValue: collector.inputValue,
    collections: collector.order.map((id) => collector.data[id])
  };
}

const actions = {
  createCollection,
  updateCollection,
  emitAction
};

export default connect(select, actions)(ListingCollector);
