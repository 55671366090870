import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { greyMedium } from "../../utils/colorsLocal";

const Rotator = styled.div`
  transform: rotate(${({ isOpen }) => (isOpen ? 180 : 0)}deg);
  transform-origin: center;
`;

const DropdownArrow = ({ isOpen, color }) => (
  <Rotator isOpen={isOpen}>
    <svg height={7} width={10}>
      <g
        fill="none"
        fillRule="evenodd"
        strokeWidth={2}
        transform="translate(-117 -13)">
        <path
          d="M118.286 14.286L122 18l3.714-3.714"
          stroke={color}
          strokeLinecap="round"
        />
      </g>
    </svg>
  </Rotator>
);

DropdownArrow.propTypes = {
  isOpen: PropTypes.bool,
  color: PropTypes.string
};

DropdownArrow.defaultProps = {
  isOpen: false,
  color: greyMedium
};

export default DropdownArrow;
