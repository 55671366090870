import { connect } from "react-redux";
import ConnectorRoute from "../ConnectorRoute";

function select({ currentUser }) {
  return {
    name: currentUser.name,
    email: currentUser.email
  };
}

export default connect(select)(ConnectorRoute);
