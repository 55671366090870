const initial = {
  searchesPage: 1,
  searchesLimit: 18,
  searchesSort: "-updated_at",
  listingsPage: 1,
  listingsLimit: 18,
  listingsSort: "-price"
};

export function pagination(state = initial, { type, payload }) {
  switch (type) {
    case "SET_SEARCHES_PAGE":
      return { ...state, searchesPage: payload };
    case "SET_SEARCHES_LIMIT":
      return { ...state, searchesLimit: payload };
    case "SET_SEARCHES_SORT":
      return { ...state, searchesSort: payload };
    case "SET_LISTINGS_PAGE":
      return { ...state, listingsPage: payload };
    case "SET_LISTINGS_LIMIT":
      return { ...state, listingsLimit: payload };
    case "SET_LISTINGS_SORT":
      return { ...state, listingsSort: payload };
    case "GET_COLLECTION_INITIATED":
    case "GET_SAVED_SEARCH_INITIATED":
      return { ...state, listingsPage: payload.page };
    case "GET_COLLECTIONS_INITIATED":
    case "GET_SAVED_AREAS_INITIATED":
    case "GET_SAVED_SEARCHES_INITIATED":
      return { ...state, searchesPage: payload.page };
    case "RESET_AND_ADD_TO_QUERY_OBJECT":
    case "REMOVE_FROM_QUERY_OBJECT":
    case "ADD_TO_QUERY_OBJECT":
    case "RESET_QUERY_OBJECT":
      return { ...state, listingsPage: 1 };
    default:
      return state;
  }
}
