import { connect } from "react-redux";
import { emitAction } from "../../../../utils/redux";
import { getCollection } from "../../../../actions/collection";
import { trackEvent } from "../../../../actions/event";
import CollectionRoute from "../CollectionRoute";

function select(store, { location }) {
  return {
    isPublic: store.application.isPublic,
    isAppReady: store.application.isAppReady,
    pathId: location.pathname.split("/collections/")[1],
    pathname: location.pathname,
    eventName: "collection",
    metaData: { count: store.collection.count },
    id: store.collection.id,
    name: store.collection.name,
    listingIds: store.collection.listingIds,
    listings: store.collection.data || [],
    totalCount: store.collection.count || 0,
    selectedMlsnums: store.listings.selectedMlsnums,
    isNonDisclosure: store.mlsConfigs.isNonDisclosure,
    shouldTrack: store.collection.shouldTrack,
    isCards: store.listings.isCards,
    isLoading: store.listings.isLoading,
    isMapOpen: store.mapSearch.isOpen,
    hasListingIds: store.collection.listingIds.length > 0,
    page: store.pagination.listingsPage,
    limit: store.pagination.listingsLimit,
    sort: store.pagination.listingsSort
  };
}

const actions = {
  getCollection,
  trackEvent,
  emitAction
};

export default connect(select, actions)(CollectionRoute);
