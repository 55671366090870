import "@helix/styles/dist/helix.css";
import styled from "styled-components";
import { greyLightest } from "../../../utils/colorsLocal";
import {
  absoluteFill,
  defaultBodyRight,
  headerHeightDesktop,
  headerHeightMobile,
  mobileBreakpoint,
  oneColBkptWithSplit,
  oneColSplitBodyRight,
  oneColumnBreakpoint
} from "../../../utils/styling";

export const Container = styled.div`
  ${absoluteFill};
  overflow: hidden;
`;

export const Body = styled.div`
  position: absolute;
  overflow-x: hidden;
  background-color: ${greyLightest};
  overflow-y: ${({ isAdvancedOpen }) => (isAdvancedOpen ? "hidden" : "scroll")};
  top: ${({ showHeader }) => (showHeader ? headerHeightDesktop : 0)}px;
  bottom: 0px;
  left: 0px;
  right: ${({ isMapOpen, isMappable }) =>
    defaultBodyRight({ isMapOpen, isMappable })};

  @media (max-width: ${({ isMapOpen }) => oneColBkptWithSplit(isMapOpen)}px) {
    right: ${({ isMapOpen, isMappable }) =>
      oneColSplitBodyRight({ isMapOpen, isMappable })};
  }

  @media (max-width: ${mobileBreakpoint}px) {
    top: ${({ showHeader }) => (showHeader ? headerHeightMobile : 0)}px;
  }

  @media (max-width: ${oneColumnBreakpoint}px) {
    right: 0px;
  }
`;
