import { connect } from "react-redux";
import { logoutUser } from "../../../actions/session";
import { emitAction } from "../../../utils/redux";
import NewNavigation from "../NewNavigation";

function select({ currentUser }) {
  return {
    avatarUrl: currentUser.avatarUrl,
    userName: currentUser.name,
    userEmail: currentUser.email,
    lwaJwt: currentUser.lwaJwt,
    isLoggedInAs: currentUser.isLoggedInAs
  };
}

const actions = {
  emitAction,
  logoutUser
};

export default connect(select, actions)(NewNavigation);
