import React from "react";

function TrashIcon() {
  return (
    <svg viewBox="0 0 100 100" preserveAspectRatio="xMidYMid meet">
      <path d="M16.5555556,88.8888889 C16.5555556,95 21.5555556,100 27.6666667,100 L72.1111111,100 C78.2222222,100 83.2222222,95 83.2222222,88.8888889 L83.2222222,22.2222222 L16.5555556,22.2222222 L16.5555556,88.8888889 Z M88.7777778,5.55555556 L69.3333333,5.55555556 L63.7777778,0 L36,0 L30.4444444,5.55555556 L11,5.55555556 L11,16.6666667 L88.7777778,16.6666667 L88.7777778,5.55555556 Z"></path>
    </svg>
  );
}

export default TrashIcon;
