import styled from "styled-components";
import {
  greyMedium,
  greyLighter,
  greyLight,
  bluePowder
} from "../../../utils/colorsLocal";
import {
  noWrap,
  defaultBorderRadius,
  zIndexSelector,
  overlayBoxShadow
} from "../../../utils/styling";

export const optionHeight = 40;
const triggerButtonHeight = 42;
const maxOptionsInView = 5;
const checkboxWidth = 20;

export const Container = styled.div`
  position: relative;
  color: ${greyMedium};
  z-index: ${({ isOpen }) => (isOpen ? zIndexSelector : 0)};

  &:focus {
    button {
      background-color: ${greyLighter};
    }
  }
`;

export const TriggerButton = styled.button`
  position: relative;
  height: ${triggerButtonHeight}px;
  background-color: ${({ isOpen }) => (isOpen ? greyLighter : "white")};
  border-radius: ${defaultBorderRadius}px;
  border: 2px solid ${({ isOpen }) => (isOpen ? greyLighter : greyLight)};
  padding-right: 30px;
  padding-left: 10px;
  text-align: left;
  cursor: pointer;
  width: 100%;
`;

export const TriggerLabel = styled.div`
  ${noWrap};
  color: ${greyMedium};
  line-height: 36px;
  font-size: 15px;
  height: 100%;
`;

export const TriggerPointer = styled.div`
  position: absolute;
  width: 10px;
  right: 12px;
  top: 14px;

  svg {
    fill: ${greyMedium};
  }
`;

export const Options = styled.div`
  position: absolute;
  ${overlayBoxShadow};
  top: ${triggerButtonHeight + 2}px;
  max-height: ${optionHeight * maxOptionsInView}px;
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
  border-radius: ${defaultBorderRadius}px;
  background-color: white;
  overflow-y: scroll;
  cursor: default;
  width: 100%;
  z-index: 2;
`;

export const Option = styled.div`
  background-color: ${({ isHighlighted }) =>
    isHighlighted ? bluePowder : "transparent"};
  height: ${optionHeight}px;
  color: ${greyMedium};
  cursor: pointer;
  padding: 10px;
  font-size: 14px;
`;

export const OptionLabel = styled.div`
  ${noWrap};
  width: calc(100% - ${({ isSingular }) => (isSingular ? 0 : checkboxWidth)}px);
  padding-left: ${({ isSingular }) => (isSingular ? 0 : 10)}px;
  line-height: ${checkboxWidth}px;
  height: ${checkboxWidth}px;
  display: inline-block;
  vertical-align: top;
  font-size: 13px;
`;

export const CheckboxLayout = styled.div`
  display: ${({ isSingular }) => (isSingular ? "none" : "inline-block")};
  width: ${checkboxWidth}px;
  vertical-align: top;
`;
