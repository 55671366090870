import { connect } from "react-redux";
import { ListingHistory } from "@wrstudios/components";

function select({ listing }) {
  return {
    history: listing.history,
    updatedAtDateShort: listing.updatedAtDateShort,
    updatedAtTime: listing.updatedAtTime
  };
}

const actions = {};

export default connect(select, actions)(ListingHistory);
