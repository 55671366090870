import styled from "styled-components";
import {
  mobileBreakpoint,
  routeContainer,
  pageTitle
} from "../../../../utils/styling";

export const Container = styled.div`
  ${routeContainer};
`;

export const Title = styled.div`
  ${pageTitle};
  height: 52px;
`;

export const Body = styled.div`
  padding-top: 40px;

  @media (max-width: ${mobileBreakpoint}px) {
    padding-top: 20px;
  }
`;
