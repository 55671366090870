import { apiClient, publicApiClient } from "../modules/api";
import { trackEventGQL, trackExternalEventGQL } from "@wrstudios/api";

export function trackEvent({ eventName, metaData, isPublic }) {
  return async (dispatch) => {
    const commonArgs = { appName: "cloud_mlx", eventName, metaData };

    dispatch({ type: "TRACK_EVENT_INITIATED" });

    try {
      if (isPublic) {
        const args = { ...commonArgs, publicApiClient };
        const response = await trackExternalEventGQL(args);
        const payload = response.data.data.trackExternalEvent;
        dispatch({ type: "TRACK_EVENT_SUCCEEDED", payload });
      } else {
        const args = { ...commonArgs, apiClient };
        const response = await trackEventGQL(args);
        const payload = response.data.data.trackEvent;
        dispatch({ type: "TRACK_EVENT_SUCCEEDED", payload });
      }
    } catch (error) {
      dispatch({ type: "TRACK_EVENT_FAILED", payload: error });
    }
  };
}
