import { connect } from "react-redux";
import { isShowingTimeAvailable } from "showing-time-client";
import { getCollector } from "../../../../actions/collection";
import { getUgcStaticOptions } from "../../../../actions/session";
import { getShowingTimeUrl } from "../../../../actions/listing";
import { getListing } from "../../../../actions/listing";
import { getListingMetaData } from "../../../../modules/event";
import { trackEvent } from "../../../../actions/event";
import { emitAction } from "../../../../utils/redux";
import ListingRoute from "../ListingRoute";

function select(store, { location: { pathname } }) {
  const isListingNested = pathname.split("/").length > 3;
  const { lastPathname } = store.application;

  return {
    id: store.listing.id,
    pathId: pathname.split("/")[isListingNested ? 4 : 2],
    searchId: isListingNested ? pathname.split("/")[2] : "",
    pageTitle: store.listing.address || "Cloud MLX",
    isPublic: store.application.isPublic,
    isLoading: store.listing.isLoading,
    isAppReady: store.application.isAppReady,
    isCreating: store.collector.isCreating,
    hasCreateError: store.collector.hasCreateError,
    hasUpdateError: store.collector.hasUpdateError,
    isListingActive: store.listing.statusValue === "active",
    isHistoryHidden: store.mlsConfigs.isHistoryHidden,
    isSchoolDataHidden: store.mlsConfigs.isSchoolDataHidden,
    isShowingTimeAvailable: isShowingTimeAvailable(store.currentUser.mlsCode),
    isFullscreenMediaOpen: store.listing.isFullscreenMediaOpen,
    shouldTrack: store.listing.shouldTrack,
    eventName: "listing_view",
    metaData: getListingMetaData({
      mlsnum: store.listing.mlsnum,
      lastPathname,
      pathname
    })
  };
}

const actions = {
  getUgcStaticOptions,
  getShowingTimeUrl,
  getCollector,
  getListing,
  trackEvent,
  emitAction
};

export default connect(select, actions)(ListingRoute);
