import { connect } from "react-redux";
import { flatten, take } from "lodash";
import { emitAction } from "../../../../utils/redux";
import { getRecentSearches, getOmniMatches } from "../../../../actions/search";
import { addFilter } from "../../../../actions/queryObject";
import { maxOptions } from "../styled/search-bar";
import SearchBar from "../SearchBar";

function select(store) {
  return {
    isOpen: store.omniSearch.inputValue.length > 1,
    isCheatSheetOpen: store.cheatSheet.isOpen,
    inputValue: store.omniSearch.inputValue,
    matches: getMatches(store.omniSearch),
    staticOptions: flatten(Object.values(store.staticOptions)),
    availablePrimaryFields: store.mlsConfigs.availablePrimaryFields,
    hasOneCharacter: store.omniSearch.inputValue.length === 1,
    hasRecents: store.recentSearches.length > 0,
    recentSearches: store.recentSearches,
    autoFocusTimestamp: store.application.autoFocusTimestamp
  };
}

function getMatches({
  synchronousMatches = [],
  asyncZipMatches = [],
  asyncAddressMatches = [],
  asyncMlsNumMatches = [],
  asyncAgentMatches = []
}) {
  const totalMatches = [
    ...synchronousMatches,
    ...asyncZipMatches,
    ...asyncAddressMatches,
    ...asyncMlsNumMatches,
    ...asyncAgentMatches
  ];
  return take(totalMatches, maxOptions * 3);
}

const actions = {
  getRecentSearches,
  getOmniMatches,
  addFilter,
  emitAction
};

export default connect(select, actions)(SearchBar);
