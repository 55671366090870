import { connect } from "react-redux";
import { emitAction } from "../../../../utils/redux";
import { getAgentProfile } from "../../../../actions/agent";
import { trackEvent } from "../../../../actions/event";
import AgentRoute from "../AgentRoute";

function select({ agent, application }, { location }) {
  return {
    isLoading: agent.isLoading,
    isAppReady: application.isAppReady,
    pathId: location.pathname.split("/agents/")[1],
    pathname: location.pathname,
    id: agent.attributes.id,
    bre: agent.attributes.bre,
    name: agent.attributes.name,
    email: agent.attributes.email,
    phone: agent.attributes.phone,
    office: agent.attributes.office,
    initials: agent.attributes.initials,
    avatarUrl: agent.attributes.avatarUrl,
    totalCount: agent.listings.length,
    listings: agent.listings,
    shouldTrack: agent.shouldTrack,
    eventName: "agent_profile",
    metaData: { agent_id: agent.attributes.id }
  };
}

const actions = {
  getAgentProfile,
  trackEvent,
  emitAction
};

export default connect(select, actions)(AgentRoute);
