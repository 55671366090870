import styled, { keyframes } from "styled-components";
import { iconColor, greyMedium, greyDark } from "../../../utils/colorsLocal";
import { zIndexNotification, mobileBreakpoint } from "../../../utils/styling";

const slideUp = keyframes`
  from { transform: translateY(200px); }
  to { transform: translateY(0px); }
`;

export const Container = styled.div`
  z-index: ${zIndexNotification};
  position: absolute;
  animation-name: ${slideUp};
  animation-duration: 200ms;
  animation-timing-function: ease-out;
  background-color: ${({ type }) => iconColor(type)};
  box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.2);
  padding-bottom: 30px;
  padding-right: 30px;
  padding-left: 70px;
  padding-top: 30px;
  overflow: hidden;
  bottom: 0px;
  right: 0px;
  left: 0px;

  @media (max-width: ${mobileBreakpoint}px) {
    padding-bottom: 20px;
    padding-right: 20px;
    padding-left: 44px;
    padding-top: 20px;
  }
`;

export const Body = styled.div`
  color: ${greyMedium};
  font-size: 15px;

  @media (max-width: ${mobileBreakpoint}px) {
    font-size: 14px;
  }
`;

export const Icon = styled.div`
  position: absolute;
  margin-top: -19px;
  height: 40px;
  width: 40px;
  left: 20px;
  top: 50%;

  @media (max-width: ${mobileBreakpoint}px) {
    margin-top: -15px;
    height: 30px;
    width: 30px;
    left: 8px;
  }

  svg {
    fill: ${greyMedium};
  }
`;

export const Dismiss = styled.div`
  position: absolute;
  cursor: pointer;
  width: 12px;
  right: 8px;
  top: 8px;

  @media (max-width: ${mobileBreakpoint}px) {
    width: 10px;
    right: 6px;
    top: 6px;
  }

  svg {
    fill: ${greyMedium};

    &:hover {
      fill: ${greyDark};
    }
  }
`;
