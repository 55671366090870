import { connect } from "react-redux";
import { emitAction } from "../../../../utils/redux";
import { trackEvent } from "../../../../actions/event";
import { getMlsConfigs } from "../../../../actions/session";
import { getSharedSearch } from "../../../../actions/search";
import SharedRoute from "../SharedRoute";

function select(store, { match, location }) {
  return {
    slug: match.params.slug,
    pathname: location.pathname || "",
    mlsCode: store.sharingAgent.mlsCode,
    filters: store.sharedSearch.filters,
    detailId: store.sharedSearch.detailId,
    isLoading: store.sharedSearch.isLoading,
    isMapOpen: store.mapSearch.isOpen,
    isSingleton: store.sharedSearch.role === "singleton",
    isCollection: store.sharedSearch.role === "collection",
    photoProxyStrategy: store.mlsConfigs.photoProxyStrategy,
    isFullscreenMediaOpen: store.listing.isFullscreenMediaOpen,
    sharedName: store.sharedSearch.name,
    totalCount: store.sharedSearch.count,
    page: store.pagination.listingsPage,
    limit: store.pagination.listingsLimit,
    sort: store.pagination.listingsSort,
    isCards: store.sharedSearch.isCards,
    rawListings: store.sharedSearch.rawListings,
    hasListings: store.sharedSearch.order.length > 0,
    listingsData: store.sharedSearch.data,
    listings: store.sharedSearch.order.map((id) => {
      return store.sharedSearch.data[id];
    })
  };
}

const actions = {
  getSharedSearch,
  getMlsConfigs,
  trackEvent,
  emitAction
};

export default connect(select, actions)(SharedRoute);
