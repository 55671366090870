import styled from "styled-components";
import { routeContainer, fakeLink, noWrap } from "../../../../utils/styling";

export const Container = styled.div`
  ${routeContainer};
`;

export const Body = styled.div`
  padding-top: 20px;
`;

export const AreaMapLayout = styled.div`
  height: 160px;
  overflow: hidden;
  position: relative;
  border-top-right-radius: 0.3rem;
  border-top-left-radius: 0.3rem;
  cursor: pointer;
`;

export const AreaMap = styled.div`
  height: 100%;
  background-color: #e1e5e9;

  .leaflet-control-container {
    display: none;
  }
`;

export const Name = styled.div`
  font-weight: 600;
  min-height: 46px;
  padding: 1.2rem;
  ${noWrap};
`;

export const SearchLinkLayout = styled.div`
  width: 100%;
  height: 24px;
`;

export const SearchLink = styled.span`
  font-size: 12px;
  ${fakeLink};
`;
