import React from "react";
import PropTypes from "prop-types";
import { Container, Button } from "./styled/switch";

function Switch({ leftIcon, rightIcon, isLeftSelected, handleToggle }) {
  return (
    <Container>
      <Button onClick={handleToggle} isSelected={isLeftSelected}>
        {leftIcon}
      </Button>
      <Button onClick={handleToggle} isSelected={!isLeftSelected}>
        {rightIcon}
      </Button>
    </Container>
  );
}

Switch.propTypes = {
  leftIcon: PropTypes.element,
  rightIcon: PropTypes.element,
  isLeftSelected: PropTypes.bool,
  handleToggle: PropTypes.func
};

export default Switch;
