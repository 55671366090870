import React from "react";
import ReactDOM from "react-dom";
import thunk from "redux-thunk";
import * as serviceWorker from "./serviceWorker";
import { throttle } from "lodash";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import { getRootDiv } from "./utils/dom";
import { history } from "./utils/routing";
import { createLogger } from "redux-logger";
import { ThemeProvider } from "styled-components";
import { HelmetProvider } from "react-helmet-async";
import { createStore, applyMiddleware } from "redux";
import { readFromSessionStorage, writeToSessionStorage } from "./utils/persist";
import App from "./components/root/connected/App";
import Route from "./components/common/Route";
import theme from "./utils/theme";
import reducers from "./reducers";
import { HelixThemeProvider } from "@helix/theming";

const isProd = window.location.hostname === "cloudmlx.com";
const logger = createLogger({ level: "info", collapsed: true });
const middleware = isProd
  ? applyMiddleware(thunk)
  : applyMiddleware(thunk, logger);
export const store = createStore(
  reducers,
  readFromSessionStorage(),
  middleware
);
store.subscribe(throttle(writeToSessionStorage.bind(this, store), 500));

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router history={history}>
        <ThemeProvider theme={theme}>
          <HelmetProvider>
            <HelixThemeProvider>
              <Route component={App} />
            </HelixThemeProvider>
          </HelmetProvider>
        </ThemeProvider>
      </Router>
    </Provider>
  </React.StrictMode>,
  getRootDiv()
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
