import { createPortal } from "react-dom";
import React, { Component } from "react";
import { isNumber } from "lodash";
import PropTypes from "prop-types";
import DynamicIcon from "./DynamicIcon";
import CloseIcon from "../icons/CloseIcon";
import { getRootDiv } from "../../utils/dom";
import { Container, Body, Icon, Dismiss } from "./styled/notification";

class Notification extends Component {
  state = { isPresent: false };

  componentWillUnmount() {
    this.handleTimeout();
  }

  render() {
    if (!this.state.isPresent) {
      return null;
    }
    const { type, children, isDismissable } = this.props;

    return createPortal(
      <Container type={type}>
        <Icon>
          <DynamicIcon name={type} />
        </Icon>
        <Body>{children}</Body>
        {isDismissable && (
          <Dismiss onClick={this.dismiss}>
            <CloseIcon />
          </Dismiss>
        )}
      </Container>,
      getRootDiv()
    );
  }

  handleTimeout = () => {
    if (isNumber(this.timeout)) {
      window.clearTimeout(this.timeout);
    }
  };

  display = () => {
    const { isPresent } = this.state,
      { shouldAutoDismiss } = this.props;
    if (!isPresent && shouldAutoDismiss) {
      this.timeout = window.setTimeout(this.dismiss, 4000);
    }
    if (!isPresent) {
      this.setState({ isPresent: true });
    }
  };

  dismiss = () => {
    if (isNumber(this.timeout)) {
      window.clearTimeout(this.timeout);
    }
    if (this.state.isPresent) {
      this.setState({ isPresent: false });
    }
  };
}

Notification.defaultProps = {
  type: "alert",
  isDismissable: true,
  shouldAutoDismiss: true
};

Notification.propTypes = {
  children: PropTypes.any,
  isDismissable: PropTypes.bool,
  shouldAutoDismiss: PropTypes.bool,
  type: PropTypes.oneOf(["alert", "info", "error", "success"])
};

export default Notification;
