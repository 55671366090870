import { connect } from "react-redux";
import { getListingToRender } from "../../../../modules/listing";
import { ListingDisclaimer } from "@wrstudios/components";

function select({ listing, sharedSearch }) {
  const listingToRender = getListingToRender({ listing, sharedSearch });

  return {
    mlsnum: listingToRender.mlsnum,
    updatedAtDateShort: listingToRender.updatedAtDateShort,
    updatedAtTime: listingToRender.updatedAtTime,
    officeName: listingToRender.agentOffice
  };
}

const actions = {};

export default connect(select, actions)(ListingDisclaimer);
