import React, { Component } from "react";
import PropTypes from "prop-types";
import Link from "../../common/Link";
import IconComputer from "../../icons/IconComputer";
import { Container, Title, Text } from "./styled/not-found";

class NotFound extends Component {
  componentDidMount() {
    this.props.emitAction({ type: "SET_IS_NOT_FOUND", payload: true });
  }

  componentWillUnmount() {
    this.props.emitAction({ type: "SET_IS_NOT_FOUND", payload: false });
  }

  render() {
    return (
      <Container>
        <Title>Page Not Found</Title>
        {!this.props.isPublic && (
          <Text>
            Awkward... wanna <Link to="/">go home</Link>?
          </Text>
        )}
        <IconComputer />
      </Container>
    );
  }
}

NotFound.propTypes = {
  isPublic: PropTypes.bool,
  emitAction: PropTypes.func
};

export default NotFound;
