import { connect } from "react-redux";
import { logoutUser } from "../../../actions/session";
import { emitAction } from "../../../utils/redux";
import TempHeader from "../TempHeader";

function select({ currentUser }) {
  return {
    lwaJwt: currentUser.lwaJwt
  };
}

const actions = {
  emitAction,
  logoutUser
};

export default connect(select, actions)(TempHeader);
