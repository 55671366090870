import { connect } from "react-redux";
import { emitAction } from "../../../../utils/redux";
import { getUgcStaticOptions } from "../../../../actions/session";
import { deleteCollection } from "../../../../actions/collection";
import { getCollections } from "../../../../actions/collection";
import CollectionsRoute from "../CollectionsRoute";

function select({
  application,
  currentUser,
  collections,
  mlsConfigs,
  pagination
}) {
  return {
    ssoParams: currentUser.ssoParams,
    locale: mlsConfigs.locale,
    collections: collections.order.map((id) => collections.data[id]),
    hasError: collections.hasDeleteError,
    isLoading: collections.isLoading,
    isDeleting: collections.isDeleting,
    isAppReady: application.isAppReady,
    count: collections.count,
    page: pagination.searchesPage,
    limit: pagination.searchesLimit,
    sort: pagination.searchesSort
  };
}

const actions = {
  getCollections,
  deleteCollection,
  getUgcStaticOptions,
  emitAction
};

export default connect(select, actions)(CollectionsRoute);
