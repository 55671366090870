const initial = {
  selectedMlsnums: [],
  isLoading: false,
  isCards: true,
  count: 0,
  data: []
};

export function listings(state = initial, { type, payload }) {
  switch (type) {
    case "SET_IS_LISTING_CARDS":
      return { ...state, isCards: payload };
    case "GET_COLLECTION_INITIATED":
    case "GET_SAVED_SEARCH_INITIATED":
    case "CREATE_ADHOC_SEARCH_INITIATED":
    case "UPDATE_SAVED_SEARCH_INITIATED":
    case "GET_SEARCH_RESULTS_INITIATED":
      return { ...state, isLoading: true };
    case "GET_COLLECTION_FAILED":
    case "GET_SAVED_SEARCH_FAILED":
    case "CREATE_ADHOC_SEARCH_FAILED":
    case "UPDATE_SAVED_SEARCH_FAILED":
    case "GET_SEARCH_RESULTS_FAILED":
      return { ...state, isLoading: false };
    case "GET_COLLECTION_SUCCEEDED":
    case "GET_SAVED_SEARCH_SUCCEEDED":
    case "CREATE_ADHOC_SEARCH_SUCCEEDED":
    case "UPDATE_SAVED_SEARCH_SUCCEEDED":
    case "GET_SEARCH_RESULTS_SUCCEEDED":
      return {
        ...state,
        data: payload.data,
        count: payload.count,
        selectedMlsnums: initial.selectedMlsnums,
        isLoading: false
      };
    case "SET_SELECTED_MLSNUMS":
      return { ...state, selectedMlsnums: payload };
    case "RESET_AND_ADD_TO_QUERY_OBJECT":
    case "RESET_QUERY_OBJECT":
      return initial;
    default:
      return state;
  }
}
