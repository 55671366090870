import React from "react";

function CopyIcon() {
  return (
    <svg viewBox="0 0 100 100" preserveAspectRatio="xMidYMid meet">
      <path d="M70.3684211,0 L16.0526316,0 C11.0736842,0 7,4.09090909 7,9.09090909 L7,72.7272727 L16.0526316,72.7272727 L16.0526316,9.09090909 L70.3684211,9.09090909 L70.3684211,0 L70.3684211,0 Z M83.9473684,18.1818182 L34.1578947,18.1818182 C29.1789474,18.1818182 25.1052632,22.2727273 25.1052632,27.2727273 L25.1052632,90.9090909 C25.1052632,95.9090909 29.1789474,100 34.1578947,100 L83.9473684,100 C88.9263158,100 93,95.9090909 93,90.9090909 L93,27.2727273 C93,22.2727273 88.9263158,18.1818182 83.9473684,18.1818182 L83.9473684,18.1818182 Z M83.9473684,90.9090909 L34.1578947,90.9090909 L34.1578947,27.2727273 L83.9473684,27.2727273 L83.9473684,90.9090909 L83.9473684,90.9090909 Z"></path>
    </svg>
  );
}

export default CopyIcon;
