export function transformMls(mls) {
  const statusMapping = mls.forge_config.status_mapping || {};

  return {
    locale: mls.forge_config.locale || "",
    disclaimer: mls.forge_config.disclaimer || "",
    isNonDisclosure: mls.forge_config.is_non_disclosure || false,
    isHistoryHidden: mls.forge_config.hide_change_history || false,
    isSchoolDataHidden: mls.forge_config.hide_school_data || false,
    photoProxyStrategy: mls.forge_config.photo_proxy || "none",
    statusMapping: {
      active: statusMapping.active || ["Active"],
      backup: statusMapping.backup || [],
      pending: statusMapping.pending || [],
      closed: statusMapping.closed || []
    }
  };
}
