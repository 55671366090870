import { getProxiedImageUrl } from "@wrstudios/image-proxy";
import { getDateShort } from "@wrstudios/utils";
import {
  isArray,
  omit,
  orderBy,
  parseInt,
  uniq,
  uniqBy,
  without
} from "lodash";
import matchSorter from "match-sorter";
import pluralize from "pluralize";
import {
  formatBullets,
  formatTitle,
  fullNumberToShorthand,
  getFirstNumber,
  getFirstShorthandNumber,
  getNumberRange,
  getShorthandNumberRange,
  pluralizeWord
} from "../utils/string";
import {
  addArbitraryToQueryObject,
  getArbitraryFilters,
  removeArbitraryFromQueryObject,
  transformArbitraryField
} from "./arbitrary";
import { getForgeField, mlxToForgeFieldMap } from "./forge";
import { getQueryObjectFromCasFilters } from "./queryObject";

const CAS_ROLE_TO_MLX_MAP = {
  saved_search: "search",
  polygon: "area"
};

export const maxOmniMatches = 9;

export const sortOptions = [
  { label: "Updated Most Recently", value: "-updated_at" },
  { label: "Updated Longest Ago", value: "updated_at" },
  { label: "A to Z by Name", value: "name" },
  { label: "Z to A by Name", value: "-name" }
];

export const offMarketDaysOptions = [
  { field: "offMarketDays", label: "1 day back", value: "1" },
  { field: "offMarketDays", label: "3 months back", value: "90" },
  { field: "offMarketDays", label: "6 months back", value: "180" },
  { field: "offMarketDays", label: "9 months back", value: "270" },
  { field: "offMarketDays", label: "1 year back", value: "365" },
  { field: "offMarketDays", label: "18 months back", value: "540" },
  { field: "offMarketDays", label: "2 years back", value: "730" },
  { field: "offMarketDays", label: "30 months back", value: "900" },
  { field: "offMarketDays", label: "3 years back", value: "1095" }
];

export function isDynamicMinField(field) {
  return field.substr(field.length - 3) === "Min";
}

export function isDynamicMaxField(field) {
  return field.substr(field.length - 3) === "Max";
}

export function transformAvailableFields(dataArray) {
  const primaryForgeFields = Object.values(mlxToForgeFieldMap);

  const fields = (dataArray || []).reduce(
    (state, field) => {
      if (field.field_type === "fine_tuning") {
        return {
          ...state,
          arbitrary: [...state.arbitrary, transformArbitraryField(field)]
        };
      }

      if (primaryForgeFields.includes(field.key)) {
        return { ...state, primary: { ...state.primary, [field.key]: true } };
      }

      return state;
    },
    { primary: {}, arbitrary: [] }
  );

  return {
    primary: fields.primary,
    arbitrary: orderBy(fields.arbitrary, "label", "asc")
  };
}

export function transformStaticOptions({ field, values }) {
  return values.map((value) => {
    return {
      field: field,
      value: value,
      label: value,
      lowerCase: (value || "").toLowerCase()
    };
  });
}

export function transformLinkOptions(options) {
  return options.map((option) => {
    return {
      id: `${option.id}`,
      field: CAS_ROLE_TO_MLX_MAP[option.role] || option.role,
      value: option.name,
      label: option.name,
      lowerCase: (option.name || "").toLowerCase(),
      pathname: `/${pluralizeWord({
        string: CAS_ROLE_TO_MLX_MAP[option.role] || option.role
      })}/${option.id}`,
      isLinkOption: true
    };
  });
}

export function transformAgentLinkOptions(dataArray) {
  return dataArray.map((agent) => {
    const name = formatTitle(agent.fullname);

    return {
      id: `${agent.id}`,
      field: "agent",
      value: name,
      label: name,
      lowerCase: (name || "").toLowerCase(),
      pathname: `/agents/${agent.id}`,
      isLinkOption: true
    };
  });
}

export function transformAddressLinkOptions(dataArray) {
  return dataArray.map((listing) => {
    return {
      field: "address",
      dateLabel: `Listed ${getDateShort(listing.date_list || "")}`,
      dateValue: listing.date_list,
      statusLabel: listing.status,
      statusValue: listing.mapped_status,
      fullAddress: `${formatTitle(listing.address)}, ${listing.city}, ${
        listing.state
      } ${listing.zipcode}`,
      pathname: `/listings/${listing.id}`,
      isAddressOption: true,
      isLinkOption: true
    };
  });
}

export function transformMlsNumOptions(dataArray) {
  return dataArray.map((data) => {
    const mlsnum = (data || {}).mlsnum ? data.mlsnum : data;

    return {
      field: "mlsnum",
      value: mlsnum,
      label: mlsnum
    };
  });
}

export function transformSavedSearches({
  searches,
  savedAreas,
  arbitraryFields,
  photoProxyStrategy
}) {
  return searches.reduce(
    (state, search) => {
      const queryObject = getQueryObjectFromCasFilters({
        filters: search.filters,
        arbitraryFields,
        savedAreas
      });

      const id = `${search.id}`;
      const zips = queryObject.zip || [];
      const cities = queryObject.city || [];
      const polygons = queryObject.polygon || [];
      const customAreas = polygons.map((polygon) => polygon.label);

      return {
        order: [...state.order, id],
        data: {
          ...state.data,
          [id]: {
            id,
            queryObject,
            role: search.role,
            name: search.name || "",
            criteria: getSavedSeachBullets(queryObject),
            updatedAt: new Date(search.updated_at),
            locationsFormatted: [...customAreas, ...cities, ...zips].join(", "),
            photos: search.listings.map(({ attributes }) => {
              return getProxiedImageUrl({
                url: (attributes.photos || [])[0] || "",
                strategy: photoProxyStrategy
              });
            })
          }
        }
      };
    },
    { order: [], data: {} }
  );
}

export function transformRecentSearches({
  searches,
  savedAreas,
  arbitraryFields
}) {
  return searches.map(({ id, role, name, filters }) => {
    const field = {
      collection: "collection",
      saved_search: "search",
      polygon: "search",
      adhoc: "search"
    }[role];

    const url = {
      collection: `/collections/${id}`,
      saved_search: `/searches/${id}`,
      polygon: `/searches/${id}`,
      adhoc: `/searches/${id}`
    }[role];

    if (role === "adhoc") {
      const adhocFilters = getFilters({
        queryObject: getQueryObjectFromCasFilters({
          arbitraryFields,
          savedAreas,
          filters
        })
      });

      const labels = adhocFilters.map((filter) => filter.label);

      return { id, field, url, name: formatBullets(labels) };
    } else {
      return { id, field, url, name };
    }
  });
}

export function isNewLinkOption({ filter, pathname }) {
  if (["agent", "collection"].includes(filter.field)) {
    return true;
  }
  const newSavedSearchId = (filter.pathname || "").split("/searches/")[1];
  const oldSavedSearchId = (pathname || "").split("/searches/")[1];
  return newSavedSearchId !== oldSavedSearchId;
}

export function addToQueryObject({ filter, queryObject }) {
  switch (true) {
    case filter.isArbitrary:
      return addArbitraryToQueryObject({ filter, queryObject });
    case filter.field === "polygon":
      return {
        ...queryObject,
        polygon: uniqBy([...(queryObject.polygon || []), filter], "value")
      };
    case isSingleValueField(filter.field):
      return { ...queryObject, [filter.field]: filter.value };
    case isDynamicMinField(filter.field):
      return { ...queryObject, [filter.field]: filter.value };
    case isDynamicMaxField(filter.field):
      const { minField } = getRangeRelatedFieldNames(filter.field);
      if (!queryObject[minField]) {
        return {
          ...queryObject,
          [minField]: "0",
          [filter.field]: filter.value
        };
      }
      return { ...queryObject, [filter.field]: filter.value };
    case isDynamicRangeField(filter.field): {
      const { minField, maxField } = getRangeRelatedFieldNames(filter.field);
      return {
        ...queryObject,
        [minField]: filter.value.min,
        [maxField]: filter.value.max
      };
    }
    default:
      return {
        ...queryObject,
        [filter.field]: uniq([
          ...(queryObject[filter.field] || []),
          filter.value
        ])
      };
  }
}

export function removeFromQueryObject({ filter, queryObject }) {
  switch (true) {
    case filter.isArbitrary:
      return removeArbitraryFromQueryObject({ filter, queryObject });
    case filter.field === "polygon":
      return {
        ...queryObject,
        polygon: queryObject.polygon.filter(
          (polygonFilter) => polygonFilter.value !== filter.value
        )
      };
    case isDynamicMinField(filter.field): {
      const { maxField } = getRangeRelatedFieldNames(filter.field);
      if (queryObject[maxField] && queryObject[filter.field]) {
        return { ...queryObject, [filter.field]: "0" };
      }
      if (queryObject[filter.field]) {
        return omit(queryObject, filter.field);
      }
      return queryObject;
    }
    case isDynamicMaxField(filter.field): {
      if (queryObject[filter.field]) {
        return omit(queryObject, filter.field);
      }
      return queryObject;
    }
    case isDynamicRangeField(filter.field): {
      const { minField, maxField } = getRangeRelatedFieldNames(filter.field);
      return omit(queryObject, [minField, maxField]);
    }
    case isOnlyValueLeft(queryObject[filter.field]):
      return omit(queryObject, filter.field);
    default:
      return {
        ...queryObject,
        [filter.field]: without(queryObject[filter.field], filter.value)
      };
  }
}

export function getFilters({ queryObject, pathname }) {
  if (pathname && !pathname.split("/searches/")[1]) {
    return [];
  }

  const arbitraryFilters = getArbitraryFilters(queryObject);
  const primaryFieldNames = getPrimaryFieldNames(queryObject);
  if (primaryFieldNames.length === 0) {
    return arbitraryFilters;
  }

  const primaryFilters = primaryFieldNames.reduce((state, field) => {
    switch (true) {
      case isStaticOptionField(field):
        return [
          ...state,
          ...transformStaticOptions({ field, values: queryObject[field] })
        ];
      case isDynamicMinField(field):
        return [...state, ...transformDynamicMin({ field, queryObject })];
      case isDynamicMaxField(field):
        return [...state, ...transformDynamicMax({ field, queryObject })];
      case field === "offMarketDays":
        return [...state, ...transformOffMarketDays(queryObject.offMarketDays)];
      case field === "proximity":
        return [...state, ...transformProximityFilter(queryObject.proximity)];
      case field === "mlsnum":
        return [...state, ...transformMlsNumOptions(queryObject.mlsnum)];
      case field === "polygon":
        return [...state, ...queryObject.polygon];
      default:
        return [
          ...state,
          ...transformDynamicOption({ field, value: queryObject[field] })
        ];
    }
  }, []);

  return [...primaryFilters, ...arbitraryFilters];
}

export function omniMatch({
  inputValue,
  staticOptions,
  availablePrimaryFields
}) {
  const valueIncludingDecimals = inputValue.toLowerCase().replace(/,/g, "");
  const valueExcludingDecimals = valueIncludingDecimals.replace(/\./g, "");

  switch (true) {
    case cheatSheetRegex.test(valueIncludingDecimals):
      return [
        {
          field: "cheatSheet",
          value: "cheatSheet",
          label: "Help With Searching"
        }
      ];
    case priceMinRegex.test(valueIncludingDecimals):
      return transformDynamicOption({
        field: "priceMin",
        value: getFirstShorthandNumber(valueIncludingDecimals)
      });
    case priceRangeRegex.test(valueIncludingDecimals):
      return transformDynamicOption({
        field: "priceRange",
        value: getShorthandNumberRange(valueIncludingDecimals)
      });
    case bedMinRegex.test(valueExcludingDecimals):
      return transformDynamicOption({
        field: "bedMin",
        value: getFirstNumber(valueExcludingDecimals)
      });
    case bedRangeRegex.test(valueExcludingDecimals):
      return transformDynamicOption({
        field: "bedRange",
        value: getNumberRange(valueExcludingDecimals)
      });
    case bathMinRegex.test(valueExcludingDecimals):
      return transformDynamicOption({
        field: "bathMin",
        value: getFirstNumber(valueExcludingDecimals)
      });
    case bathRangeRegex.test(valueExcludingDecimals):
      return transformDynamicOption({
        field: "bathRange",
        value: getNumberRange(valueExcludingDecimals)
      });
    case sqftMinRegex.test(valueIncludingDecimals):
      return transformDynamicOption({
        field: "sqftMin",
        value: getFirstShorthandNumber(valueIncludingDecimals)
      });
    case sqftRangeRegex.test(valueIncludingDecimals):
      return transformDynamicOption({
        field: "sqftRange",
        value: getShorthandNumberRange(valueIncludingDecimals)
      });
    case offMarketDays.test(valueExcludingDecimals):
      return transformDynamicOption({
        field: "offMarketDays",
        value: getFirstNumber(valueExcludingDecimals)
      });
    case offMarketMonths.test(valueExcludingDecimals):
      return transformDynamicOption({
        field: "offMarketMonths",
        value: getFirstNumber(valueExcludingDecimals)
      });
    case offMarketYears.test(valueExcludingDecimals):
      return transformDynamicOption({
        field: "offMarketYears",
        value: getFirstNumber(valueExcludingDecimals)
      });
    case garageMinRegex.test(valueExcludingDecimals) &&
      availablePrimaryFields[getForgeField("garageMin")]:
      return transformDynamicOption({
        field: "garageMin",
        value: getFirstNumber(valueExcludingDecimals)
      });
    case garageRangeRegex.test(valueExcludingDecimals) &&
      availablePrimaryFields[getForgeField("garageMin")]:
      return transformDynamicOption({
        field: "garageRange",
        value: getNumberRange(valueExcludingDecimals)
      });
    case storyMinRegex.test(valueExcludingDecimals) &&
      availablePrimaryFields[getForgeField("storyMin")]:
      return transformDynamicOption({
        field: "storyMin",
        value: getFirstNumber(valueExcludingDecimals)
      });
    case storyRangeRegex.test(valueExcludingDecimals) &&
      availablePrimaryFields[getForgeField("storyMin")]:
      return transformDynamicOption({
        field: "storyRange",
        value: getNumberRange(valueExcludingDecimals)
      });
    case lotMinRegex.test(valueExcludingDecimals) &&
      availablePrimaryFields[getForgeField("lotMin")]:
      return transformDynamicOption({
        field: "lotMin",
        value: getFirstShorthandNumber(valueIncludingDecimals)
      });
    case lotRangeRegex.test(valueExcludingDecimals) &&
      availablePrimaryFields[getForgeField("lotMin")]:
      return transformDynamicOption({
        field: "lotRange",
        value: getShorthandNumberRange(valueIncludingDecimals)
      });
    default:
      return matchStaticOptions({
        inputValue: valueExcludingDecimals,
        staticOptions
      });
  }
}

function transformDynamicOption({ field, value }) {
  return {
    priceMin: () => [
      { field, value, label: `$${fullNumberToShorthand(value)} +` }
    ],
    priceRange: () => [
      {
        field,
        value,
        label: `$${fullNumberToShorthand(value.min)} - $${fullNumberToShorthand(
          value.max
        )}`
      }
    ],
    bedMin: () => [
      {
        field,
        value,
        label: `${value} ${pluralizeWord({ string: "bed", amount: value })}`
      }
    ],
    bedRange: () => [
      {
        field,
        value,
        label: `${value.min} - ${value.max} ${pluralizeWord({
          string: "bed",
          amount: value.max
        })}`
      }
    ],
    bathMin: () => [
      {
        field,
        value,
        label: `${value} ${pluralizeWord({ string: "bath", amount: value })}`
      }
    ],
    bathRange: () => [
      {
        field,
        value,
        label: `${value.min} - ${value.max} ${pluralizeWord({
          string: "bath",
          amount: value.max
        })}`
      }
    ],
    sqftMin: () => [
      { field, value, label: `${fullNumberToShorthand(value)} sqft` }
    ],
    sqftRange: () => [
      {
        field,
        value,
        label: `${fullNumberToShorthand(value.min)} - ${fullNumberToShorthand(
          value.max
        )} sqft`
      }
    ],
    garageMin: () => [{ field, value, label: `${value} car garage` }],
    garageRange: () => [
      { field, value, label: `${value.min} - ${value.max} car garage` }
    ],
    storyMin: () => [
      {
        field,
        value,
        label: `${value} ${pluralizeWord({ string: "story", amount: value })}`
      }
    ],
    storyRange: () => [
      {
        field,
        value,
        label: `${value.min} - ${value.max} ${pluralizeWord({
          string: "story",
          amount: value.max
        })}`
      }
    ],
    lotMin: () => [
      { field, value, label: `${fullNumberToShorthand(value)} lot size` }
    ],
    lotRange: () => [
      {
        field,
        value,
        label: `${fullNumberToShorthand(value.min)} - ${fullNumberToShorthand(
          value.max
        )} lot size`
      }
    ],
    offMarketDays: () => [
      {
        field,
        value,
        label: `${value} ${pluralizeWord({
          string: "day",
          amount: value
        })} back`
      }
    ],
    offMarketMonths: () => [
      {
        field: "offMarketDays",
        value: value * 30,
        label: `${value} ${pluralizeWord({
          string: "month",
          amount: value
        })} back`
      }
    ],
    offMarketYears: () => [
      {
        field: "offMarketDays",
        value: value * 365,
        label: `${value} ${pluralizeWord({
          string: "year",
          amount: value
        })} back`
      }
    ]
  }[field]();
}

function transformDynamicMin({ field, queryObject }) {
  const { maxField, rangeField } = getRangeRelatedFieldNames(field);
  if (!queryObject[maxField]) {
    return transformDynamicOption({ field, value: queryObject[field] });
  }
  return transformDynamicOption({
    field: rangeField,
    value: { min: queryObject[field], max: queryObject[maxField] }
  });
}

function transformDynamicMax({ field, queryObject }) {
  const { minField, rangeField } = getRangeRelatedFieldNames(field);
  if (!queryObject[minField]) {
    return transformDynamicOption({
      field: rangeField,
      value: { min: 0, max: queryObject[field] }
    });
  }
  return [];
}

function transformOffMarketDays(value) {
  const numericValue = parseInt(value);
  if (numericValue === 0) {
    return transformDynamicOption({
      field: "offMarketDays",
      value: numericValue
    });
  }
  if (numericValue % 365 === 0) {
    return transformDynamicOption({
      field: "offMarketYears",
      value: numericValue / 365
    });
  }
  if (numericValue % 30 === 0) {
    return transformDynamicOption({
      field: "offMarketMonths",
      value: numericValue / 30
    });
  }
  return transformDynamicOption({
    field: "offMarketDays",
    value: numericValue
  });
}

function transformProximityFilter(value) {
  return [
    {
      value: value,
      field: "proximity",
      label: `Radius - ${value.radius} ${pluralize("miles", value.radius)}`
    }
  ];
}

function matchStaticOptions({ inputValue, staticOptions }) {
  return matchSorter(staticOptions, inputValue, { keys: ["lowerCase"] }).slice(
    0,
    30
  );
}

function getSavedSeachBullets(queryObject) {
  let filters = getFilters({
    queryObject: omit(queryObject, ["city", "polygon", "zip"])
  });
  return filters.map((filter) => filter.label);
}

function getRangeRelatedFieldNames(field) {
  const rawField = field.replace(/(Min|Max|Range)/, "");
  return {
    minField: `${rawField}Min`,
    maxField: `${rawField}Max`,
    rangeField: `${rawField}Range`
  };
}

function getPrimaryFieldNames(queryObject) {
  return Object.keys(queryObject).filter((field) => isPrimaryFieldName(field));
}

function isPrimaryFieldName(field) {
  return mlxToForgeFieldMap.hasOwnProperty(field);
}

function isOnlyValueLeft(values) {
  return !isArray(values) || (isArray(values) && values.length === 1);
}

function isStaticOptionField(field) {
  return ["status", "type", "subType", "city", "zip", "area"].includes(field);
}

function isSingleValueField(field) {
  return ["offMarketDays", "proximity"].includes(field);
}

function isDynamicRangeField(field) {
  return field.substr(field.length - 5) === "Range";
}

const cheatSheetRegex = /^help$/;
const priceMinRegex = /^\$?(\d+|\d+\.\d+)[km]?\s?\+$/;
const priceRangeRegex = /(^(\$\d+|\$?\d+[km]|\$?\d+\.\d+[km])\s?(-|t|to)?\s?\$?$)|(^(\$\d+|\$?\d+[km]|\$?\d+\.\d+[km])\s?(-|t|to)?\s?\$?(\d+[km]?|\d+\.\d+[km])$)/;
const bedMinRegex = /(^\d+\s?b[ed]d?r?o?o?m?s?$)|(^b[ed]d?r?o?o?m?s?\s?\d+\s?(-|t|to)?\s?$)/;
const bedRangeRegex = /^(\d+\s?(-|to)\s?\d+\s?b[ed])|(b[ed]d?r?o?o?m?s?\s?\d+\s?(-|to)\s?\d+)/;
const bathMinRegex = /(^\d+\s?b([at]|at)h?r?o?o?m?s?$)|(^b([at]|at)h?r?o?o?m?s?\s?\d+\s?(-|t|to)?\s?$)/;
const bathRangeRegex = /^(\d+\s?(-|to)\s?\d+\s?b[at])|(b([at]|at)h?r?o?o?m?s?\s?\d+\s?(-|to)\s?\d+)/;
const sqftMinRegex = /(^(\d+[km]?|\d+\.\d+[km])\s?sqf?t?$)|(^sqf?t?\s?(\d+[km]?|\d+\.\d+[km])\s?(-|t|to)?\s?$)/;
const sqftRangeRegex = /^((\d+[km]?|\d+\.\d+[km])\s?(-|to)\s?(\d+[km]?|\d+\.\d+[km])\s?sq)|(sqf?t?\s?(\d+[km]?|\d+\.\d+[km])\s?(-|to)\s?(\d+[km]?|\d+\.\d+[km]))/;
const offMarketDays = /(^\d+\s?days?b?a?c?k?$)|(^days?b?a?c?k?\s?\d+$)/;
const offMarketMonths = /(^\d+\s?mont?h?s?b?a?c?k?$)|(^mont?h?s?b?a?c?k?\s?\d+$)/;
const offMarketYears = /(^\d+\s?year?s?b?a?c?k?$)|(^year?s?b?a?c?k?\s?\d+$)/;
const garageMinRegex = /(^\d+\s?c?a?r?\s?gar?a?g?e?$)|(^c?a?r?\s?gar?a?g?e?\s?\d+\s?(-|t|to)?\s?$)/;
const garageRangeRegex = /^(\d+\s?(-|to)\s?\d+\s?c?a?r?\s?ga)|(c?a?r?\s?gar?a?g?e?\s?\d+\s?(-|to)\s?\d+)/;
const storyMinRegex = /(^\d+\s?stor?y?i?e?s?$)|(^stor?y?i?e?s?\s?\d+\s?(-|t|to)?\s?$)/;
const storyRangeRegex = /^(\d+\s?(-|to)\s?\d+\s?sto)|(stor?y?i?e?s?\s?\d+\s?(-|to)\s?\d+)/;
const lotMinRegex = /(^(\d+[km]?|\d+\.\d+[km])\s?lot?s?i?z?e?$)|(^lot?s?i?z?e?\s?(\d+[km]?|\d+\.\d+[km])\s?(-|t|to)?\s?$)/;
const lotRangeRegex = /^((\d+[km]?|\d+\.\d+[km])\s?(-|to)\s?(\d+[km]?|\d+\.\d+[km])\s?lo)|(lot?s?i?z?e?\s?(\d+[km]?|\d+\.\d+[km])\s?(-|to)\s?(\d+[km]?|\d+\.\d+[km]))/;
