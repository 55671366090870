import React, { Component } from "react";
import PropTypes from "prop-types";
import Checkbox from "./Checkbox";
import ChevronDownIcon from "../icons/ChevronDownIcon";
import {
  Container,
  TriggerButton,
  TriggerLabel,
  TriggerPointer,
  Options,
  Option,
  OptionLabel,
  CheckboxLayout,
  optionHeight
} from "./styled/selector";

class Selector extends Component {
  state = { isOpen: false, highlighted: 0 };

  componentDidMount() {
    this.handleMount();
  }

  componentWillUnmount() {
    this.removeEventListeners();
  }

  render() {
    const {
      dataLabel,
      triggerLabel,
      options,
      selectedValues,
      handleSelect,
      isSingular
    } = this.props;

    return (
      <Container isOpen={this.state.isOpen}>
        <TriggerButton
          isOpen={this.state.isOpen}
          onClick={this.handleOpen}
          data-selector={dataLabel}>
          <TriggerLabel>{triggerLabel}</TriggerLabel>
          <TriggerPointer>
            <ChevronDownIcon />
          </TriggerPointer>
        </TriggerButton>
        <div ref={(node) => (this.selector = node)}>
          <Options
            isOpen={this.state.isOpen}
            ref={(node) => (this.scrollable = node)}>
            {options.map((option, index) => {
              const onClick = () => handleSelect(index);
              const onMouseEnter = () => this.handleHover(index);
              const isHighlighted = this.state.highlighted === index;
              const isChecked = (selectedValues || []).includes(option.value);

              return (
                <Option
                  {...{ onClick, onMouseEnter, isHighlighted, key: index }}>
                  <CheckboxLayout isSingular={isSingular}>
                    <Checkbox isChecked={isChecked} />
                  </CheckboxLayout>
                  <OptionLabel isSingular={isSingular}>
                    {option.label}
                  </OptionLabel>
                </Option>
              );
            })}
          </Options>
        </div>
      </Container>
    );
  }

  isOpen = () => {
    return this.state.isOpen;
  };

  handleMount = () => {
    if (this.props.shouldOpenOnMountWithData && this.props.options.length > 0) {
      this.handleOpen();
    }
  };

  handleOpen = () => {
    if (this.state.isOpen) {
      return;
    }
    this.setState({ isOpen: true, highlighted: 0 });
    window.setTimeout(() => (this.scrollable.scrollTop = 0), 0);
    window.addEventListener("mouseup", this.handleWindowClick);
    window.addEventListener("keydown", this.handleKeyStroke);
  };

  handleClose = () => {
    window.setTimeout(() => this.setState({ isOpen: false }), 0);
    window.removeEventListener("mouseup", this.handleWindowClick);
    window.removeEventListener("keydown", this.handleKeyStroke);
  };

  handleWindowClick = ({ target }) => {
    if (!this.state.isOpen || this.selector.contains(target)) {
      return;
    }
    this.handleClose();
  };

  handleKeyStroke = (event) => {
    if (["Escape", "ArrowUp", "ArrowDown", "Enter"].includes(event.key)) {
      event.preventDefault();
    }

    switch (event.key) {
      case "Escape":
        return this.handleClose();
      case "ArrowUp":
        return this.handleUpArrow();
      case "ArrowDown":
        return this.handleDownArrow();
      case "Enter":
        return this.props.handleSelect(this.state.highlighted);
      default:
        return;
    }
  };

  handleUpArrow = () => {
    if (this.state.highlighted === 0) {
      return;
    }
    this.scrollable.scrollTop = (this.state.highlighted - 3) * optionHeight;
    this.setState({ highlighted: this.state.highlighted - 1 });
  };

  handleDownArrow = () => {
    if (this.state.highlighted === this.props.options.length - 1) {
      return;
    }
    this.scrollable.scrollTop = (this.state.highlighted - 1) * optionHeight;
    this.setState({ highlighted: this.state.highlighted + 1 });
  };

  handleHover = (index) => {
    this.setState({ highlighted: index });
  };

  removeEventListeners = () => {
    window.removeEventListener("mouseup", this.handleWindowClick);
    window.removeEventListener("keydown", this.handleKeyStroke);
  };
}

Selector.defaultProps = {
  options: [],
  handleSelect: () => {}
};

Selector.propTypes = {
  isSingular: PropTypes.bool,
  dataLabel: PropTypes.string,
  triggerLabel: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    })
  ),
  selectedValues: PropTypes.arrayOf(PropTypes.string),
  shouldOpenOnMountWithData: PropTypes.bool,
  handleSelect: PropTypes.func
};

export default Selector;
