import axios from "axios";
import { apiClient } from "../modules/api";
import { transformListing } from "../modules/listing";
import { isShowingTimeEnabled } from "showing-time-client";
import { buildIsEnabledUrl, buildShowingTimeUrl } from "showing-time-client";
import { getSearchGQL, getListingGQL, createSearchGQL } from "@wrstudios/api";

export function getListing({ searchId, listingId }) {
  return async (dispatch, getState) => {
    const { mlsConfigs } = getState();
    dispatch({ type: "GET_LISTING_INITIATED" });

    try {
      if (!searchId) {
        const response = await getListingGQL({ apiClient, id: listingId });

        const listing = transformListing({
          photoProxyStrategy: mlsConfigs.photoProxyStrategy,
          listing: response.data.data.listing.attributes
        });

        dispatch({ type: "GET_LISTING_SUCCEEDED", payload: listing });
      } else {
        const response = await getSearchGQL({
          apiClient,
          filters: [{ field: "id", eq: [listingId] }],
          id: searchId
        });

        const listing = transformListing({
          photoProxyStrategy: mlsConfigs.photoProxyStrategy,
          listing: response.data.data.search.listings[0].attributes
        });

        dispatch({ type: "GET_LISTING_SUCCEEDED", payload: listing });
      }
    } catch (error) {
      dispatch({ type: "GET_LISTING_FAILED", payload: error });
    }
  };
}

export function getListingSlug(listingId) {
  return async (dispatch) => {
    if (!listingId) {
      return;
    }

    dispatch({ type: "GET_LISTING_SLUG_INITIATED" });

    try {
      const response = await createSearchGQL({
        apiClient,
        role: "singleton",
        listingLevel: "none",
        filters: [{ field: "id", eq: [listingId] }]
      });

      const slug = response.data.data.createSearch.search.slug;

      dispatch({ type: "GET_LISTING_SLUG_SUCCEEDED", payload: slug });
    } catch (error) {
      dispatch({ type: "GET_LISTING_SLUG_FAILED", payload: error });
    }
  };
}

export function getShowingTimeUrl() {
  return async (dispatch, getState) => {
    const {
      listing,
      currentUser: { mlsCode, mlsName }
    } = getState();

    const requestingAgentId = mlsName;
    const {
      agentIdFromMls: listingAgentId,
      id,
      mlsnum,
      agentOfficeId,
      statusLabel,
      address,
      city,
      zip,
      price
    } = listing;
    if (!id) {
      return;
    }

    dispatch({ type: "GET_SHOWING_TIME_INITIATED" });
    try {
      const isEnabledUrl = buildIsEnabledUrl({
        mlsCode,
        mlsName,
        mlsnum,
        agentId: requestingAgentId,
        agentOfficeId,
        statusLabel
      });
      const isEnabledResponse = await axios.get(isEnabledUrl);
      const isEnabled = isShowingTimeEnabled(isEnabledResponse.data);
      if (isEnabled) {
        const showingTimeUrl = buildShowingTimeUrl({
          mlsCode,
          mlsName,
          mlsnum,
          requestingAgentId,
          listingAgentId,
          agentOfficeId,
          statusLabel,
          address,
          city,
          zip,
          price
        });
        dispatch({
          type: "GET_SHOWING_TIME_SUCCEEDED",
          payload: showingTimeUrl
        });
      } else {
        dispatch({
          type: "SHOWING_TIME_IS_NOT_ENABLED",
          payload: isEnabledResponse
        });
      }
    } catch (error) {
      dispatch({ type: "GET_SHOWING_TIME_FAILED", payload: error });
    }
  };
}

export function setFullscreenMediaDetails({ open, tab, photoIndex }) {
  return {
    type: "SET_LISTING_FULLSCREEN_MEDIA_DETAILS",
    payload: { open, tab, photoIndex }
  };
}
