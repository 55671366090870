import { connect } from "react-redux";
import PrintRoute from "../PrintRoute";

function select(store) {
  return {
    isPublic: store.print.isPublic,
    isNonDisclosure: store.mlsConfigs.isNonDisclosure,
    sharingAgent: store.sharingAgent,
    listings: printListings(store)
  };
}

function printListings({ print, listing, listings, sharedSearch }) {
  if (print.isSharedRoute && !!sharedSearch.detailId) {
    return [sharedSearch.data[sharedSearch.detailId]];
  }

  if (print.isSharedRoute) {
    return sharedSearch.order.map((id) => sharedSearch.data[id]);
  }

  if (print.isListingRoute) {
    return [listing];
  }

  if (listings.selectedMlsnums.length === 0) {
    return listings.data;
  }

  return listings.selectedMlsnums.map((selectedMlsnum) => {
    return listings.data.find((listing) => listing.mlsnum === selectedMlsnum);
  });
}

const actions = {};

export default connect(select, actions)(PrintRoute);
