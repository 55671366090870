import React from "react";

function HelpIcon() {
  return (
    <svg viewBox="0 0 100 100" preserveAspectRatio="xMidYMid meet">
      <path d="M50,0 C22.4,0 0,22.4 0,50 C0,77.6 22.4,100 50,100 C77.6,100 100,77.6 100,50 C100,22.4 77.6,0 50,0 L50,0 Z M50,90 C27.95,90 10,72.05 10,50 C10,27.95 27.95,10 50,10 C72.05,10 90,27.95 90,50 C90,72.05 72.05,90 50,90 L50,90 Z M55,80 L55,70 L45,70 L45,80 L55,80 Z M50,20 C38.95,20 30,28.95 30,40 L40,40 C40,34.5 44.5,30 50,30 C55.5,30 60,34.5 60,40 C60,50 45,48.75 45,65 L55,65 C55,53.75 70,52.5 70,40 C70,28.95 61.05,20 50,20 L50,20 Z"></path>
    </svg>
  );
}

export default HelpIcon;
