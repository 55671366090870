import React from "react";

function LoadingIcon() {
  return (
    <svg viewBox="0 0 100 100" preserveAspectRatio="xMidYMid meet">
      <path d="M14.493612,64.0955039 C9.25781801,50.892478 11.8333008,36.3429744 20.3986074,25.8682751 L20.3161354,31.7234481 L32.0954938,31.971049 L32.543199,6.00006734 L6.59515048,5.55910184 L6.35009082,17.3472652 L12.0807169,17.4262617 C0.218886476,31.1999489 -3.47350292,50.7486336 3.5425082,68.4438483 C11.2194735,87.8168541 29.7544671,100.001179 49.8752795,100 C53.2707697,100 56.7133867,99.6521796 60.1560038,98.9341369 L57.7489995,87.3923954 C39.545072,91.1936595 21.35057,81.3957361 14.493612,64.0955039 Z M96.4542886,31.5501274 C87.4789784,8.91940046 63.6740238,-3.90279167 39.8513966,1.06219694 L42.2536883,12.6051175 C60.4540812,8.81210672 78.6462269,18.6064929 85.5031848,35.8984718 C90.7436915,49.1121092 88.1646742,63.6734033 79.5899422,74.1492816 L79.664167,68.2445884 L67.8836305,68.0217476 L67.481874,93.9891921 L93.4275661,94.3877118 L93.6490624,82.5995484 L87.9608505,82.5276262 C99.7908703,68.7586552 103.466765,49.2300144 96.4542886,31.5501274 Z"></path>
    </svg>
  );
}

export default LoadingIcon;
