const initial = {
  inputValue: "",
  isCreating: false,
  hasCreateError: false,
  hasUpdateError: false,
  lastData: {},
  order: [],
  data: {}
};

export function collector(state = initial, { type, payload }) {
  switch (type) {
    case "GET_LISTING_COLLECTOR_SUCCEEDED":
      return { ...initial, order: payload.order, data: payload.data };
    case "SET_LISTING_COLLECTOR_INPUT":
      return { ...state, inputValue: payload };
    case "CREATE_COLLECTION_INITIATED":
      return {
        ...state,
        inputValue: "",
        isCreating: true,
        hasCreateError: false
      };
    case "CREATE_COLLECTION_SUCCEEDED":
      return {
        ...state,
        isCreating: false,
        order: [payload.id, ...state.order],
        data: { ...state.data, [payload.id]: payload }
      };
    case "CREATE_COLLECTION_FAILED":
      return { ...state, isCreating: false, hasCreateError: true };
    case "UPDATE_COLLECTION_INITIATED":
      return {
        ...state,
        inputValue: "",
        hasUpdateError: false,
        lastData: state.data,
        data: { ...state.data, [payload.id]: payload }
      };
    case "UPDATE_COLLECTION_SUCCEEDED":
      return {
        ...state,
        lastData: initial.lastData,
        data: { ...state.data, [payload.id]: payload }
      };
    case "UPDATE_COLLECTION_FAILED":
      return {
        ...state,
        hasUpdateError: true,
        lastData: initial.lastData,
        data: state.lastData
      };
    default:
      return state;
  }
}
