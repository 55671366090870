import React from "react";

function AlertIcon() {
  return (
    <svg viewBox="0 0 100 100" preserveAspectRatio="xMidYMid meet">
      <path d="M45,65 L55,65 L55,75 L45,75 L45,65 Z M45,25 L55,25 L55,55 L45,55 L45,25 Z M49.95,0 C22.35,0 0,22.4 0,50 C0,77.6 22.35,100 49.95,100 C77.6,100 100,77.6 100,50 C100,22.4 77.6,0 49.95,0 L49.95,0 Z M50,90 C27.9,90 10,72.1 10,50 C10,27.9 27.9,10 50,10 C72.1,10 90,27.9 90,50 C90,72.1 72.1,90 50,90 L50,90 Z"></path>
    </svg>
  );
}

export default AlertIcon;
