import { connect } from "react-redux";
import { getListingToRender } from "../../../../modules/listing";
import { getFirstName } from "../../../../utils/string";
import ListingHeader from "../ListingHeader";

function select({ application, sharedSearch, sharingAgent, listing }) {
  const listingToRender = getListingToRender({ listing, sharedSearch });

  return {
    isPublic: application.isPublic,

    // Listing
    address: listingToRender.address,
    subAddress: listingToRender.subAddress,

    // Agent
    isAuthed: !!sharingAgent.jwt,
    hasPhoneNumber: !!sharingAgent.phone,
    hasCompleteAddress: false,
    firstName: sharingAgent.name ? getFirstName(sharingAgent.name) : "",
    initials: sharingAgent.initials,
    avatar: sharingAgent.avatarUrl,
    name: sharingAgent.name,
    email: sharingAgent.email,
    phone: sharingAgent.phone,
    company: sharingAgent.office,
    city: sharingAgent.city,
    state: sharingAgent.state
  };
}

export default connect(select)(ListingHeader);
