import React from "react";

function SubTypeIcon() {
  return (
    <svg viewBox="0 0 100 100" preserveAspectRatio="xMidYMid meet">
      <path d="M40,93 L15,93 L15,53 L0,53 L50,8 L100,53 L85,53 L85,93 L60,93 L40,93 Z M66.800032,93 L66.800032,74.1764705 L74.0000325,74.1764705 L50.0000315,53 L26.0000305,74.1764705 L33.2000308,74.1764705 L33.2000308,93 L66.800032,93 Z"></path>
    </svg>
  );
}

export default SubTypeIcon;
