import React from "react";

function MoreIcon() {
  return (
    <svg viewBox="0 0 100 100" preserveAspectRatio="xMidYMid meet">
      <path d="M12.5,38 C5.625,38 0,43.6247189 0,50.4993754 C0,57.3740318 5.625,62.9987507 12.5,62.9987507 C19.375,62.9987507 25,57.3740318 25,50.4993754 C25,43.6247189 19.375,38 12.5,38 Z M87.5,38 C80.625,38 75,43.6247189 75,50.4993754 C75,57.3740318 80.625,62.9987507 87.5,62.9987507 C94.375,62.9987507 100,57.3740318 100,50.4993754 C100,43.6247189 94.375,38 87.5,38 Z M50,38 C43.125,38 37.5,43.6247189 37.5,50.4993754 C37.5,57.3740318 43.125,62.9987507 50,62.9987507 C56.875,62.9987507 62.5,57.3740318 62.5,50.4993754 C62.5,43.6247189 56.875,38 50,38 Z"></path>
    </svg>
  );
}

export default MoreIcon;
