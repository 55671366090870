import axios from "axios";
import { transformMls } from "../modules/mls";
import { transformUser } from "../modules/user";
import { apiClient, publicApiClient } from "../modules/api";
import { transformLinkOptions } from "../modules/search";
import { transformSavedAreaOptions } from "../modules/area";
import { transformSavedAreas } from "../modules/area";
import { initialForgeRequests } from "../modules/forge";
import { writeCasJwt, clearCasJwt } from "../modules/api";
import { getMlsGQL, getSearchesGQL } from "@wrstudios/api";
import { getUserGQL, signInUserGQL } from "@wrstudios/api";

export function getUser(jwtFromParams) {
  return async (dispatch) => {
    dispatch({ type: "GET_CURRENT_USER_INITIATED" });
    try {
      if (jwtFromParams) {
        writeCasJwt(jwtFromParams);
      }

      const response = await getUserGQL({ apiClient });
      const user = transformUser(response.data.data.user);
      writeCasJwt(user.jwt);

      dispatch({ type: "GET_CURRENT_USER_SUCCEEDED", payload: { user } });
      dispatch(getAppReady());
    } catch (error) {
      dispatch({ type: "GET_CURRENT_USER_FAILED", payload: error });
    }
  };
}

export function signInUser({ email, password }) {
  return async (dispatch) => {
    dispatch({ type: "SIGN_IN_USER_INITIATED" });

    try {
      const response = await signInUserGQL({
        publicApiClient,
        email,
        password
      });

      const user = transformUser(response.data.data.signInUser);
      writeCasJwt(user.jwt);

      dispatch({ type: "SIGN_IN_USER_SUCCEEDED", payload: { user } });
    } catch (error) {
      dispatch({ type: "SIGN_IN_USER_FAILED", payload: error });
    }
  };
}

export function logoutUser() {
  return (dispatch) => {
    clearCasJwt();
    dispatch({ type: "LOGOUT" });
  };
}

export function getUgcStaticOptions() {
  return async (dispatch) => {
    dispatch({ type: "GET_UGC_STATIC_OPTIONS_INITIATED" });

    try {
      await unboundGetUgcStaticOptions(dispatch);
    } catch (error) {
      dispatch({ type: "GET_UGC_STATIC_OPTIONS_FAILED", payload: error });
    }
  };
}

async function unboundGetUgcStaticOptions(dispatch) {
  const response = await getSearchesGQL({
    apiClient,
    roles: ["saved_search", "collection", "polygon"],
    listingLevel: "none",
    searchLevel: "min",
    searchesLimit: 300
  });

  const data = response.data.data.searches;

  const searches = data.filter(({ role }) => role === "saved_search");
  const collections = data.filter(({ role }) => role === "collection");
  const areas = data.filter(({ role }) => role === "polygon");

  dispatch({
    type: "GET_OPTIONS_FOR_SAVED_SEARCHES_SUCCEEDED",
    payload: { options: transformLinkOptions(searches) }
  });

  dispatch({
    type: "GET_OPTIONS_FOR_COLLECTIONS_SUCCEEDED",
    payload: { options: transformLinkOptions(collections) }
  });

  dispatch({
    type: "GET_OPTIONS_FOR_SAVED_AREAS_SUCCEEDED",
    payload: {
      options: transformSavedAreaOptions(areas),
      savedAreas: transformSavedAreas(areas)
    }
  });
}

export function getMlsConfigs(code) {
  return async (dispatch) => {
    dispatch({ type: "GET_MLS_CONFIGS_INITIATED" });

    try {
      const response = await getMlsGQL({ publicApiClient, code });
      const mls = transformMls(response.data.data.mls[0] || {});
      dispatch({ type: "GET_MLS_CONFIGS_SUCCEEDED", payload: mls });
    } catch (error) {
      dispatch({ type: "GET_MLS_CONFIGS_FAILED", payload: error });
    }
  };
}

export function getAppReady() {
  return async (dispatch, getState) => {
    const mlsCode = getState().currentUser.mlsCode;
    dispatch({ type: "GET_APP_READY_INITIATED" });

    try {
      const response = await getMlsGQL({ publicApiClient, code: mlsCode });
      const mls = transformMls(response.data.data.mls[0] || {});
      dispatch({ type: "GET_MLS_CONFIGS_SUCCEEDED", payload: mls });

      const forgeToken = process.env.REACT_APP_FORGE_TOKEN;
      dispatch({ type: "GET_FORGE_TOKEN_SUCCEEDED", payload: forgeToken });

      await axios.all([
        unboundGetUgcStaticOptions(dispatch),
        ...initialForgeRequests({ dispatch, mlsCode, forgeToken })
      ]);

      dispatch({ type: "GET_APP_READY_SUCCEEDED" });
    } catch (error) {
      dispatch({ type: "GET_APP_READY_FAILED", payload: error });
    }
  };
}
