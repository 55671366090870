import styled from "styled-components";
import { NavLink as BaseLink } from "react-router-dom";
import { headerHeightDesktop, zIndexHeader } from "../../../utils/styling";

export const Container = styled.div`
  position: absolute;
  z-index: ${zIndexHeader};
  height: ${headerHeightDesktop}px;
  right: 0px;
  left: 0px;
  top: 0px;

  .logo {
    vertical-align: middle;
  }

  .label {
    font-size: 1.4rem !important;
  }

  .description {
    font-size: 1.2rem !important;
  }

  .helix-unified-nav__nav-button.helix-unified-nav__nav-button-user:not(:disabled) {
    cursor: pointer;
  }

  .helix-unified-nav__nav {
    line-height: 1.61rem;
  }

  .helix-unified-nav__navigation-item.active {
    background-color: inherit;
    color: inherit;
  }

  svg {
    margin: 0;

    path {
      fill-rule: evenodd;
    }
  }
`;

export const Link = styled(BaseLink)`
  display: flex !important;
  align-items: center;

  svg {
    width: 2.1rem;
    height: 2rem;
    fill: #4b5563;
    margin: 0 0.8rem 0 0;
  }
`;
