import styled from "styled-components";

export const Container = styled.div`
  text-align: center;
  padding: 5rem;

  svg {
    width: 50rem;
    height: auto;
    margin-top: 5rem;
    @media (max-width: ${({ theme }) => theme.breakpoints.page.oneColumn}px) {
      width: 100%;
    }
  }
`;

export const Title = styled.h2`
  ${({ theme }) => theme.text.h2};
  margin: 0 0 2.1rem;
`;

export const Text = styled.p`
  ${({ theme }) => theme.text.medium};
  margin: 1.6rem 0;
`;
