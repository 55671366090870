import qs from "qs";
import { connect } from "react-redux";
import { getAppReady, getUser } from "../../../actions/session";
import { emitAction } from "../../../utils/redux";
import {
  isAreasIndexRoute,
  isCollectionsRoute,
  isDashboardRoute,
  isConnectorRoute,
  isListingRoute,
  isMappableRoute,
  isSearchesIndexRoute,
  isSearchRoute,
  isSharedRoute
} from "../../../utils/routing";
import App from "../App";

function select(store, { location: { pathname, search } }) {
  const params = qs.parse(search, { ignoreQueryPrefix: true });
  const jwtFromParams = params.jwt || "";

  return {
    isMissingUser: !store.currentUser.jwt,
    isInactiveUser: store.currentUser.isInactive,
    isPublic: store.application.isPublic,
    isPrintRoute: pathname === "/print",
    isNotFoundRoute: pathname === "/404",
    isListingRoute: isListingRoute(pathname),
    isSearchRoute: isSearchRoute(pathname),
    isSharedRoute: isSharedRoute(pathname),
    isMappable: isMappableRoute({ pathname, sharedSearch: store.sharedSearch }),
    isTempHeader: isTempHeader({ store, pathname }),
    isAdvancedOpen: store.advancedSearch.isOpen,
    isMapOpen: store.mapSearch.isOpen,
    isEmptyPath: pathname === "/" || pathname === "",
    isShortUrlCopied: store.application.isShortUrlCopied,
    showIntercom: false,
    showHeader: showHeader(pathname),
    queryObject: store.queryObject,
    detailId: store.sharedSearch.detailId,
    page: store.pagination.listingsPage,
    limit: store.pagination.listingsLimit,
    sort: store.pagination.listingsSort,
    jwt: store.currentUser.jwt,
    jwtFromParams,
    pathname
  };
}

function isTempHeader({ store, pathname }) {
  return (
    (!store.mapSearch.isOpen && isSearchesIndexRoute(pathname)) ||
    (!store.mapSearch.isOpen && isAreasIndexRoute(pathname)) ||
    (!store.mapSearch.isOpen && isCollectionsRoute(pathname)) ||
    (!store.mapSearch.isOpen && isDashboardRoute(pathname)) ||
    (!store.application.isPublic && store.application.isNotFound)
  );
}

function showHeader(pathname) {
  return (
    !isListingRoute(pathname) &&
    // !isSharedRoute(pathname) &&
    !isConnectorRoute(pathname)
  );
}

const actions = {
  getUser,
  getAppReady,
  emitAction
};

export default connect(select, actions)(App);
