import React from "react";

function CloseIcon() {
  return (
    <svg viewBox="0 0 100 100" preserveAspectRatio="xMidYMid meet">
      <polyline points="62.4977904 50.0000001 99.9999999 12.4977903 87.5022101 0 50.0000003 37.5022097 12.4977901 0 0 12.4977903 37.5022095 50.0000001 0 87.5022094 12.4977901 100 50.0000003 62.4977904 87.5022101 100 99.9999999 87.5022094 62.4977904 50.0000001"></polyline>
    </svg>
  );
}

export default CloseIcon;
