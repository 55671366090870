import React from "react";

function StoryIcon() {
  return (
    <svg viewBox="0 0 100 100" preserveAspectRatio="xMidYMid meet">
      <path d="M50,25 L50,5 L0,5 L0,95 L100,95 L100,25 L50,25 L50,25 Z M20,85 L10,85 L10,75 L20,75 L20,85 L20,85 Z M20,65 L10,65 L10,55 L20,55 L20,65 L20,65 Z M20,45 L10,45 L10,35 L20,35 L20,45 L20,45 Z M20,25 L10,25 L10,15 L20,15 L20,25 L20,25 Z M40,85 L30,85 L30,75 L40,75 L40,85 L40,85 Z M40,65 L30,65 L30,55 L40,55 L40,65 L40,65 Z M40,45 L30,45 L30,35 L40,35 L40,45 L40,45 Z M40,25 L30,25 L30,15 L40,15 L40,25 L40,25 Z M90,85 L50,85 L50,75 L60,75 L60,65 L50,65 L50,55 L60,55 L60,45 L50,45 L50,35 L90,35 L90,85 L90,85 Z M80,45 L70,45 L70,55 L80,55 L80,45 L80,45 Z M80,65 L70,65 L70,75 L80,75 L80,65 L80,65 Z"></path>
    </svg>
  );
}

export default StoryIcon;
